import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const loginStyle = theme => ({
  mainContainer: {
    // width: '750px',
    // height:'470px',
    // marginTop: '150px',
    borderRadius: '10px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
  },
  textField: {
    borderRadius: '5px',
    border: '1px solid #f1f1f5',
    color: '#262628',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    height:'50px',
    width:'100%'
  },
  innerContainerLeft: {
    width: '50%',
  },
  innerContainerRight: {
    width: '50%',
  },
  txtForgotPwd: {
    textAlign: 'Right',
    fontFamily: 'Nunito Sans', 
    fontWeight: 400,
    fontSize: '16px',
    color:'#9B9B9B',
    cursor:'pointer',
  },
  txtSignup: {
    textAlign: 'center',
    fontFamily: 'Nunito Sans', 
    fontWeight: 400,
    fontSize: '16px',
    color:'#262628',
    cursor:'pointer',
  },
  txtConnectUsing: {
    fontFamily: 'Nunito Sans', 
    fontWeight: 400,
    fontSize: '16px',
    color:'#262628',
    width: '55%'
  },
  googleimg: {
    width: '40px',
    height: '40px',
  },
  facebookimg: {
    width: '40px',
    height: '40px',
  },
  facebook: {
    borderRadius: '100px',
    border: '0px #FFFFFF',
  },
  txtLogin: {
    marginTop: '5%',
    marginBottom: '5%',
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: '22px',
    marginLeft: window.innerWidth<=920?'':'7%',
  },

  img: {
    width: '100%',
    height: 350,
  },

});
const combinedStyles = combineStyles(commonStyle, loginStyle);
export default combinedStyles; 
