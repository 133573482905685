import React from 'react';
import { Typography, Grid, MenuItem } from '@material-ui/core';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';

const PatientRelationDialog = props => {
    const { open, onClose, onClick } = props;
    const [PatientRelation, setPatientRelation] = React.useState('');

    const handleChange = (e) => {
        setPatientRelation(e.target.value)
    }

    const addPatientRelation = (index) => {
        if (PatientRelation != "") {
            // localStorage.setItem('pincode', PatientRelation)

            // props.index == -1 ? props.wsAddToCart('1') : props.ClosePincode()
            props.handlePatientRelation(PatientRelation)
        } else {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.RELATIONWITHPATIENT);
        }
    };


    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '100%', borderRadius: '100px' }}
                open={open}>
                <ToastsContainer store={ToastsStore} />
                <Grid container justify='center' alignItems='center'
                    style={window.innerWidth <= 420 ? { width: '300px' } : { width: '500px' }}>
                    <Grid direction={'row'} container justify='center' style={{ width: '80%' }}>
                        <Typography><h3>{'Select Relationship with Patient'}</h3></Typography>
                    </Grid>
                    <Grid direction={'row'} container justify='center' style={{ width: '80%', marginBottom: '5%' }}>
                        {/* <FilledTextField
                            rows={1}
                            type={'number'}
                            inputProps={{ maxLength: 6 }}
                            value={PostalCode}
                            name="PostalCode"
                            label="Enter 6 digit Pincode"
                            onChange={handleChange}
                        /> */}
                        <FilledTextFieldUpdated
                            label='Relationship with Patient'
                            rows={1}
                            value={PatientRelation}
                            name={'PatientRelation'}
                            style={{ marginBottom: '10px' }}
                            onChange={handleChange}
                            select>
                            {['Myself', 'Spouse', 'Mother', 'Father', 'Brother', 'Sister', 'Friend', 'Son', 'Daughter', 'Grandfather', 'Grandmother', 'Other'].map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </FilledTextFieldUpdated>

                    </Grid>
                    <Grid direction={window.innerWidth <= 420 ? 'column' : 'row'} container justify={window.innerWidth <= 420 ? 'center' : 'space-between'} alignItems='center' style={{ width: '80%', marginBottom: '5%' }}>
                        <ButtonBase title={'Save'}
                            variant="contained"
                            style={{ width: '150px' }}
                            onClick={() => addPatientRelation(props.index)} />
                        {/* <ButtonBase title={'CANCEL'}
                            variant="contained"
                            style={window.innerWidth <= 420 ? { width: '150px', marginTop: '2%' } : { width: '150px' }}
                            onClick={onClick} /> */}
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (PatientRelationDialog);
