import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CallToOrderStyle from './CallToOrderStyle';
import { container, Grid, CssBaseline, Typography, ButtonBase } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Drafts';
import LocationIcon from '@material-ui/icons/LocationOn';
class CallToOrder extends Component {
    constructor() {
        super();
        this.state = {
            mediumBrowser: false,
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    handleStep(step) {
        this.setState({ open: step })
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <Grid container style={{ width: '100%', padding: '20px' }} direction='column' alignItems='center' justify="space-between"  >
                <CssBaseline />
                <Grid container style={{ width: '80%', backgroundColor: '#f8f8f8' }} direction='row' alignItems='flex-start' justify='center'>
                    <CallIcon className={classes.IconStyle} />
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '15%' }} direction='column' alignItems='center'>
                        <Typography className={classes.JoinTxt}>Call:</Typography>
                        <a href="tel:+91 79480 03311" target="_blank" className={classes.linkStyle}>+91 79480 03311</a>
                        <a href="tel:+91 79400 32000" target="_blank" className={classes.linkStyle}>+91 79400 32000</a>
                    </Grid>
                    <CallIcon className={classes.IconStyle} />
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '15%', }} direction='column' alignItems='center'>
                        <Typography className={classes.JoinTxt}>Whatsapp :</Typography>
                        <a className={classes.linkStyle} href="https://api.whatsapp.com/send?phone=+917600001634&amp;text=Hi, I contacted you Through your website." target="_blank">7600001634</a>
                    </Grid>
                    <EmailIcon className={classes.IconStyle} />
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '15%', }} direction='column' alignItems='center'>
                        <Typography className={classes.JoinTxt}>Email</Typography>
                        <a className={classes.linkStyle} href="mailto:info@medkart.in" target="_blank"> info@medkart.in</a>
                    </Grid>
                    <LocationIcon className={classes.IconStyle} style={{ fontSize: 50 }} />
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '15%', }} direction='column' alignItems='center'>
                        <Typography className={classes.JoinTxt}>GF1 Sangini Complex,Near Doctor House,
                        Above Parimal Underpass,C G Road - Ahmedabad
                         </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(CallToOrderStyle)(CallToOrder);