/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import {
    Container, CssBaseline, Typography, Grid, Checkbox, InputAdornment,
    IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import SignupStyle from './SignupStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Avatar from '@material-ui/core/Avatar';
import Dropzone from 'react-dropzone';
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import TermsConditionDialog from "../../components/TermsConditionDialog/TermsConditionDialog";
import LocationIcon from '@material-ui/icons/HighlightOff';
import ReactGA from 'react-ga';
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: '',
            LastName: '',
            EmailAddress: '',
            MobileNo: '',
            Password: '',
            dropzone: {},
            image64: '',
            imagename: '',
            imageSize: '',
            ProfileBase64: '',
            ProfileImageName: '',
            profilepic: '',
            profilepicname: '',
            showPassword: false,
            loading: false,
            lastlocation: '',
            lastlatitude: '',
            lastlongitude: '',
            GoogleId: '',
            FacebookId: '',
            mediumBrowser: false,
            termsConditionDialog: false,
            setTermsConditionDialog: false,
            checked: true,
        }
        // localStorage.clear();
    }



    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    getLocationFromLatLong() {
        Geocode.setApiKey("AIzaSyATV9qayPdPY8GHlyc0wPIr1r7AyW5UIwQ");
        Geocode.enableDebug(true);
        Geocode.fromLatLng("48.8583701", "2.2922926").then(
            response => {
                const address = response.results[0].formatted_address;
            },
            error => {
                console.error(error);
            }
        );
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    //facebook:2,google:3,Simple signup:1
    ConvertToBase64() {
        const image2base64 = require('image-to-base64');
        image2base64(this.state.dropzone.preview)
            .then((response) => {
                this.setState({ image64: response })
            })
            .catch((error) => {

            })
    }
    componentWillMount() {
        if (this.props.history.location.state != null) {
            if (this.props.history.location.state.FromFacebook == true) {
                this.GetDataFromFaceBook
                    (this.props.history.location.state.detail)
            }
            else if (this.props.history.location.state.FromGoogle == true) {
                this.GetDataFromGoogle
                    (this.props.history.location.state.detail)
            }

        }
    }
    GetDataFromFaceBook(data) {
        var res = [] = data.name.split(" ");
        if (res[0] && res[1] != undefined) {
            this.setState({
                FirstName: res[0],
                LastName: res[1],
                EmailAddress: data.email != null ? data.email : '',
                MobileNumber: data.mobileno != null ? data.mobileno : '',
                profilepic: data.picture.data.url,
                FacebookId: data.id
            })
        }
    }
    GetDataFromGoogle(data) {
        var res = [] = data.profileObj.name.split(" ");
        if (res[0] && res[1] != undefined) {
            this.setState({
                FirstName: res[0],
                LastName: res[1],
                EmailAddress: data.profileObj.email != null ? data.profileObj.email : '',
                MobileNumber: data.mobileno != null ? data.mobileno : '',
                profilepic: data.profileObj.imageUrl,
                GoogleId: data.profileObj.googleId
            })
        }
    }



    addFilesToDropzone(files, dropzone) {
        let file = files[0]
        file['preview'] = URL.createObjectURL(files[0]);
        this.setState({ dropzone: files[0] }, () => {

        });
        this.ConvertToBase64();
        this.setState({ imagename: files[0].name, imageSize: (files[0].size / 2048).toFixed(2) });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            // this.setState({
            //     lastlatitude: this.props.coords.latitude, lastlongitude: this.props.coords.longitude
            // })
            var ID = this.state.GoogleId != '' ? '3' : this.state.FacebookId != '' ? '2' : '1'
            this.wsSignup(ID);
        }
    }



    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };


    validateForm() {
        var IsValid = 0;
        if (this.state.FirstName.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOFIRSTNAME);
        }
        else if (this.state.LastName.trim() == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOLASTNAME);
        }
        else if (this.state.EmailAddress === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOEMAILADDRESS);
        }
        else if (!this.state.EmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDEMAILADDRESS);
        }
        else if (this.state.MobileNo === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        }
        else if (!this.state.MobileNo.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDMOBILENO);
        }
        else if (this.state.MobileNo.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        }
        else if (this.state.Password === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOPASSWORD);
        }
        else if (this.state.checked == false) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOTERMCONDITION);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsSignup(ID) {
        this.setState({ loading: true })
        APIController.registerUser(
            this.state.FirstName,
            this.state.LastName,
            this.state.MobileNo,
            this.state.EmailAddress,
            this.state.Password,
            this.state.imagename,
            this.state.image64,
            // '',//imagename
            // '',//image64
            '',//device id
            ID,//device type id
            '',//fcm token
            this.state.FacebookId,//facebookId
            this.state.GoogleId,//google id
            '2',//login type id
            '',
            this.state.lastlatitude,
            this.state.lastlongitude,
            'en'
        ).then((response) => {
            this.setState({ loading: false })
            if (response.error == null) {
                if (response.object.status == 1) {
                    this.props.history.push({
                        pathname: '/verifyotp',
                        state: { data: response.object }
                    });
                }
                else {
                    ToastsStore.warning(response.object.message)
                }
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
        })
    }

    handleCheckboxChange = event => {
        this.setState({ checked: event.target.checked })
    }

    renderForm() {
        const { showPassword, loading, FirstName, LastName, EmailAddress, MobileNo, termsConditionDialog, } = this.state;
        const { classes } = this.props;
        const { dropzone } = this.state;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={{ width: '100%', }} alignItems="center" justify='center'>
                    <Grid direction={'row'} container style={{ width: '90%', height: '7.91%', }} justify="space-between">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>First Name</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            name="FirstName"
                            style={{width:'48%'}}
                            label='First Name'
                            value={FirstName}
                            onChange={this.handleChange}
                        />
                        <FilledTextFieldUpdated
                            rows={1}
                            name="LastName"
                            style={{width:'48%'}}
                            label='Last Name'
                            value={LastName}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid container direction={'row'} justify={'space-between'} style={{ width: '90%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Last Name</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            name="EmailAddress"
                            style={{width:'48%'}}
                            label='Email Address'
                            onChange={this.handleChange}
                            value={EmailAddress}
                        />
                        <FilledTextFieldUpdated
                            rows={1}
                            name="MobileNo"
                            style={{width:'48%'}}
                            label='Mobile Number'
                            value={MobileNo}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 10
                            }} />
                    </Grid>
                    
                    <Grid container style={{ width: '90%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Password</Typography> */}
                        <FilledTextFieldUpdated type={showPassword ? 'text' : 'password'}
                            name="Password"
                            label='Enter Your Password'
                            onChange={this.handleChange}
                            rows={1}
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                    </Grid>
                    <Grid container style={{ width: '90%', marginTop: '3%', paddingLeft:'0' }} onClick={() => this.setState({ setTermsConditionDialog: true })}>
                        <Checkbox
                            value="secondary"
                            color="primary"
                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                            checked={this.state.checked}
                        // onChange={this.handleCheckboxChange}
                        />
                        <Typography className={classes.subtitle}>Agree Terms and Conditions</Typography>
                    </Grid>
                    <Grid container style={{ width: '90%', marginTop: '5%', marginBottom: '5%' }} justify="space-between">
                        <ButtonBase title={'Create Account'} isLoading={loading} type="submit" style={{ width: '60%',  }} />
                        <ButtonBase type="submit" title={'Login'}
                            onClick={() => this.props.history.push(AppConstants.SCREENS.LOGIN)}
                            style={{
                                width: '35%', 
                                border: '1px solid #E1E4EB', backgroundColor: '#FFFFFF', color: '#39A749'
                            }} />
                    </Grid>
                </Grid>
            </form>
        );
    }
    renderDropZone() {
        const { classes } = this.props;
        const { dropzone, profilepic } = this.state;
        const image = dropzone.preview != undefined ? dropzone.preview : Images.Placeholder;
        return (
            <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                {({ getRootProps, getInputProps }) => (
                    <Grid style={{ marginTop: '50%' }} alignItems="center">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Typography style={{ textAlign: 'center', color: '#BEBEBE', fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>
                                <Avatar
                                    className={classes.AvatarImg}
                                    src={image != '' ? image : profilepic}
                                    alt="profile"
                                />
                                Upload Photo
                            </Typography>
                        </div>
                    </Grid>
                )}
            </Dropzone>
        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }

    render() {
        const { classes } = this.props;
        const { dropzone } = this.state;
        const image = dropzone.preview;
        const { mediumBrowser, setTermsConditionDialog, } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main} justify='center' alignItems='center'>
                <Grid container style={window.innerWidth <= 920 ?{ width: '100%', marginTop:'10%' }:{ width: '100%', padding: '35px 12px 5px 12px' }} direction={'row'} alignItems='center' ></Grid>
                <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%',marginTop:'10%' } : { width: '55%',marginTop:'2%' }} >
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', }} justify='center' alignItems='center'>
                                <Grid container justify={mediumBrowser?'center':'flex-start'} style={mediumBrowser?{ width: '100%', }:{ width: '90%', }} >
                                    <Typography className={classes.txtSignup}>Sign Up</Typography>
                                </Grid>
                                <Grid container justify={mediumBrowser?'center':'flex-start'} style={{ width: '100%', }} >
                                    {this.renderForm()}
                                </Grid>
                            </Grid>
                        {/* <Avatar alt="profile" className={classes.AvatarImg} /> */}
                            {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end' >
                                <img src={Images.SignupSideImage} className={classes.img} alt="" />
                                <LocationIcon style={{ color: '#FFFFFF', position: 'absolute', }} onClick={() => { this.props.history.push(AppConstants.SCREENS.HOMEPAGE) }} />
                            </Grid>}
                            <TermsConditionDialog
                                history={this.props.history}
                                onClose={() => this.setState({ setTermsConditionDialog: false })}
                                open={setTermsConditionDialog}
                                onAgree={() => this.setState({ setTermsConditionDialog: false, checked: true })}
                                onCancel={() => this.setState({ setTermsConditionDialog: false, checked: false })}
                            />
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
            </React.Fragment>
        );
    }
}

// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: false,
//     },
//     userDecisionTimeout: 5000,
// })(Signup);

const combined = withStyles(SignupStyle)(Signup);
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    watchPosition: true,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: true,
    userDecisionTimeout: 5000,
})(combined);
// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: false,
//     },
//     userDecisionTimeout: 5000,
// })(withStyles)(SignupStyle)(Signup);
