import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const AboutUsStyle = theme => ({
    AboutTxt: {
        color: '#282628',
        width:'100%',
        lineHeight: 1.6,
        letterSpacing: 0.1,
        // textAlign:'center',
        fontSize:'30px',
        fontFamily: 'Nunito Sans', fontWeight: 700,
    },
    ParagraphTxt:{
        color: '#282628',
        fontSize:'16px',
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontFamily: 'Nunito Sans', fontWeight: 400,
        // margin:'1%',
        // textAlign:'center',
    },

});
const combinedStyles = combineStyles(commonStyle, AboutUsStyle);
export default combinedStyles; 
