import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const TitleStyle = makeStyles(theme => ({
    titletext: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS30,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.commonTitletxt,
      },
    backytxt: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS18,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.commonTitletxt,
      },
}));

export default TitleStyle;
