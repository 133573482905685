/*eslint-disable eqeqeq*/
import React from 'react';
import clsx from 'clsx';
import {
    Menu,
    MenuItem,
    Button,
    ClickAwayListener
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Grid, Avatar, Badge } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import images from '../../common/Images';
import AddIcon from '@material-ui/icons/Add';
import LocationIcon from '@material-ui/icons/LocationOn';
import * as AppConstants from '../../common/Constants';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Scrollbars } from 'react-custom-scrollbars';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
import CallIcon from '@material-ui/icons/Call';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import * as APIController from '../../api/APIController';
import SearchMedicineModel from "../../containers/SearchMedicine/Model/SearchMedicineModel";
import Compare from '@material-ui/icons/AutorenewOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import MyorderIcon from '@material-ui/icons/ShoppingCart';
import RefillIcon from '@material-ui/icons/Sync';
import PaymentIcon from '@material-ui/icons/Payment';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import NotificationIcon from '@material-ui/icons/Notifications';
import WhyGenericIcon from '@material-ui/icons/HelpOutline';
import SignoutIcon from '@material-ui/icons/ExitToApp';
import AboutUs from '@material-ui/icons/PersonPinOutlined';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import AddIcCallIcon from '@material-ui/icons/Call';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import { ToastsContainer, ToastsStore } from 'react-toasts';

import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import PersonIcon from '@material-ui/icons/Person';
import AddressIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ChangePwdIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Drafts';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import Android from '@material-ui/icons/AndroidOutlined';
import Apple from '@material-ui/icons/Apple';
import Tour from 'reactour'

const drawerWidth = '85%';
const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
    },
    toolBar: {
        // padding: 0,
        // height: '100px',
        shadows: "none",
        border: 0,
    },
    iconMain: {
        height: '100',
        width: '200',
        marginLeft: '10px',
        // position: 'relative'
    },
    listRoot: {
        marginTop: '3%',
        width: '100%',
        maxWidth: '25%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    cardManu: {
        flex: 1,
        width: '50%',
        height: '100%',
        textAlign: 'center',
        marginTop: '1%',
        marginLeft: '5%',
        marginRight: '5%',

    },
    mainButton: {
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: '#f8f8f8',
    },
    cardMedia: {
        width: '50%',
        height: '50%',
    },
    mediaGrid: {
        padding: '3%',
    },
    gridcardManu: {
        marginTop: '9%',
        flex: 1,
    },
    textFillSearchMediumBrowser: {
        borderRadius: 8,
        fontFamily: 'Nunito Sans',
        fontSize: 14,
        // marginRight: theme.spacing(6),
        fontWeight: 500,
        width: '97%',
        marginBottom: '5px',
        // marginLeft:'20%',
        // marginRight: '5%',
        height: '37px',
        // alignSelf: 'flex-start',
        textAlign: 'left',
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    textFillSearch: {
        borderRadius: 8,
        fontFamily: 'Nunito Sans',
        fontSize: 14,
        // marginRight: theme.spacing(6),
        fontWeight: 500,
        width: '100%',
        // marginLeft:'20%',
        // marginRight: '5%',
        height: '37px',
        // alignSelf: 'flex-start',
        textAlign: 'left',
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        textALign: 'center',
        color: '#262628',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Nunito Sans',
    },
    iconButton: {
        // padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    textSingin: {
        color: "#000"
    },
    link: {
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 1.21,
        color: '#000'
    },
    cartCounttxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: 1.21,
        color: '#000'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        backgroundColor: '#39A749'

    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        height: '100%',
        backgroundColor: '#fff'
    },
    drawerPaper1: {
        width: '70%',
        height: '100%',
        backgroundColor: '#fff'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
    drawerHeaderMobileView: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '5%',
        paddingBottom: '5%',
        // ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.25),

        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        right: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(3, 3, 3, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 250,
            '&:focus': {
                width: 450,
                backgroundColor: '#f9f9f9',
            },
        },
    },
    textstyle: {
        fontFamily: 'Nunito Sans',
        cursor: 'pointer',
        fontSize: ' 14px',
        fontWeight: '700',
        lineHeight: 1.22,
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    profileImage: {
        backgroundColor: '#39A749',
        justifyContent: 'center',
        alignItems: 'center',
        resizeMode: 'content',
        width: 40,
        borderRadius: '100%',
        marginLeft: '5px',
    },
    Designation: {
        fontSize: 12
    },
    profileName: {
        display: 'flex',
        alignItems: 'flex-end',
        color: '#000'
    },
    buttonstyle: {
        width: '100%',
        height: '50px',
        alignItem: 'start',
        justifyContent: 'flex-start',
        textAlign: 'left'
    },
    IconStyle: {
        color: '#39a749',
        fontSize: 30,
        marginRight: '20px',
    },
    buttonstyleMenu: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: AppConstants.FONTSIZE.FS15,
        lineHeight: 1.13,
        letterSpacing: 1,
        color: '#FFFFFF',
    },
    IconStyleMenu: {
        color: '#FFFFFF',
        fontSize: AppConstants.FONTSIZE.FS20,
        // marginRight: '10px',
        width: '35px'
    },
    IconStyleMenu_MobileView: {
        color: '#FFFFFF',
        fontSize: AppConstants.FONTSIZE.FS20,
        // marginRight: '10px',
    },
}
));

const Mobiledrawer = props => {
    //menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    //
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [quantityCountOption, setDataOption] = React.useState([]);
    const [openSearchModel, setopenSearchModel] = React.useState(false);
    const [dataSource, setDataSource] = React.useState([]);
    const [CartCount, setCartCount] = React.useState(false);
    const [openmenu, setOpenmenu] = React.useState(false);
    const [openmenuScreen, setOpenmenuScreen] = React.useState(false);
    const [searchtext, setSearchText] = React.useState('');
    const menuId = 'primary-search-account-menu';
    const quantitycount = [];
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [isTourOpen, setisTourOpen] = React.useState(false);

    let i = 1;
    const pussData = () => {
        for (i = 1; i <= 100; i++) {
            quantitycount.push({ "medicinequantity": i });
        }
        setDataOption(quantitycount)
    }
    const closeTour = () => {
        setisTourOpen(false)
    }
    const steps = [
        {
            selector: '.first-step',
            content: 'You can checkout Latest Brands & Campaigns and Place an Order',
        },
        {
            selector: '.second-step',
            content: 'View Historical Orders here',
        },
        {
            selector: '.third-step',
            content: 'Visit the Cart, Verify and Place the Order',
        },
        {
            selector: '.fourth-step',
            content: 'Get List of Locations and Manage Profile',
        }
    ]

    const handleDrawerOpen = () => {
        props.handleStep(true)
        setOpen(true);
    };
    const CloseModal = () => {
        // wsSearchMedicine('')
        setSearchText('')
        setopenSearchModel(false);
    }
    const handleDrawerClose = () => {
        props.handleStep(false)
        setOpen(false);
    };
    const searchClear = () => {
        // wsSearchMedicine('')
        setSearchText('')
        setopenSearchModel(false);
    }

    const handleMenu = () => {
        setOpenmenu(openmenu => !openmenu);
        setOpenmenuScreen(openmenuScreen => !openmenuScreen)
        // setOpenmenu(openmenu==false?true:false)
    }
    const handleChange = (e) => {
        wsSearchMedicine(e.target.value)
        setSearchText(e.target.value)
    }
    const GetdataFromSearchModal = (Data) => {
        props.dataFromsearch(Data)
        // setCartCount(Data.data.length)
        // localStorage.setItem('cartvalue', JSON.stringify(Data))
    }
    const GetMedicineDetailFromSearchModal = (medicineid, compositionid) => {
        props.medicineDetailFromsearch(medicineid, compositionid)
        //for close modal when click on medicine
        // setopenSearchModel(false)

        // localStorage.setItem('cartvalue', JSON.stringify(Data))
    }
    const wsSearchMedicine = (data) => {
        if (data.length > 2 || data == "") {
            setopenSearchModel(true);
            APIController.searchMedicineNew(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), data.toString()).then((response) => {
                if (response.error == null) {
                    pussData()
                    setDataSource(response.object)
                }
                else if (response.error.message.message && response.error.message.message.length > 0) {
                }
            })
        } else {
            setopenSearchModel(false);
            setDataSource([])
        }
    }

    return (
        <div>
            <CssBaseline />
            <ToastsContainer store={ToastsStore} />
            {props.browser == false ? <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolBar}>
                    <Grid container style={{ width: '100%', marginTop: '0.5%' }} direction={'row'} alignItems='center' justify={'space-between'}>
                        {/*left side */}
                        <Grid onClick={() => props.history.push(AppConstants.SCREENS.HOMEPAGE)} container style={{ cursor: 'pointer', width: '27%', }} direction={'row'} alignItems='center' >
                            <img src={images.logo} style={{ backgroundColor: '#39a749' }} className={classes.iconMain} />
                        </Grid>
                        {/**right side */}

                        <Grid className={'first-step'} container style={{ width: '46%', }} justify={'center'} direction={'row'} alignItems='center'>
                            <Paper
                                elevation={0}
                                style={{ border: '2px solid #E1E1E5', height: 45 }}
                                rounded={true}
                                className={classes.textFillSearch} alignItem='center'>
                                <IconButton className={classes.iconButton} aria-label="menu">
                                    <LocationIcon />
                                </IconButton>
                                <Grid>
                                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans', }}> {localStorage.getItem('city')}</Typography>
                                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans', textAlign: 'end' }}> {localStorage.getItem('pincode')} </Typography>
                                </Grid>
                                <Divider className={classes.divider} orientation="vertical" />
                                <InputBase
                                    className={classes.input}
                                    placeholder="Search Medicines"
                                    onFocus={() => props.closeTour && props.closeTour()}
                                    // type="search"
                                    value={searchtext}
                                    name='searchtext'
                                    onChange={handleChange}
                                    alignItem='center'
                                />
                                {searchtext == '' ? <div /> : <CancelIcon style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}
                                    onClick={() => searchClear()} />}
                                <SearchMedicineModel CloseModal={CloseModal.bind(this)} searchtext={searchtext} openModel={openSearchModel} dataSource={dataSource} quantityOption={quantityCountOption} browser={props.browser} GetMedicineDetailFromSearchModal={GetMedicineDetailFromSearchModal} GetdataFromSearchModal={GetdataFromSearchModal} history={props.history} />
                            </Paper>
                        </Grid>
                        {AppConstants.userIdFromLocal() != '' && props.browser && <Grid container style={{ width: '30%', }} direction={'row'} justify={'space-around'} alignItems='center'>
                            <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                badgeContent={<Avatar style={{ height: 20, width: 20, backgroundColor: 'red', position: 'absolute', right: 0, top: 0 }}>
                                    <Typography className={classes.cartCounttxt}>
                                        {props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')}
                                    </Typography>
                                </Avatar>}>
                                <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                    <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.CART) }}>
                                        <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                                    </IconButton>
                                </Avatar>
                            </Badge>

                            <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    // edge="end"
                                    className={clsx(classes.menuButton, open && classes.hide)}>
                                    <MenuIcon />
                                </IconButton>
                            </Avatar>
                        </Grid>}
                        {AppConstants.userIdFromLocal() != '' ?
                            <Grid container style={{ width: '27%' }} justify={'flex-end'} direction={'row'} alignItems='center'>
                                <ClickAwayListener onClickAway={() => setOpenmenu(false)}>
                                    <Grid container alignItems='center' justify='flex-end' style={{ marginLeft: -50, position: 'relative', width: '50%', cursor: 'pointer', }} direction={'row'}>
                                        {AppConstants.profilepicFromLocal() == null || AppConstants.userIdFromLocal() == null ? props.browser == false && <Avatar onClick={() => setOpenmenu(openmenu => !openmenu)} src={AppConstants.profilepicFromLocal()} className={classes.profileImage}></Avatar> : props.browser == false && <Avatar onClick={() => setOpenmenu(openmenu => !openmenu)} src={''} style={{ backgroundColor: '#f1f1f1', color: '#39A749' }}>{AppConstants.fullnameFromLocal().charAt(0).toUpperCase()}</Avatar>}
                                        {openmenu ? (
                                            <Grid
                                                container
                                                direction='column'
                                                justify={'center'}
                                                style={{
                                                    position: 'absolute',
                                                    width: '250px',
                                                    top: 42,
                                                    right: 15,
                                                    left: '50px',
                                                    zIndex: 10,
                                                    border: '1px solid #39A749',
                                                    backgroundColor: '#FFFFFF'
                                                }} >
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.EDITPROFILE) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px', }}><AccountCircleOutlinedIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>MY PROFILE</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.SCHEME) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px', }}><PaymentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>OFFERS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.CHANGEPASSWORD) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><ChangePwdIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>CHANGE PASSWORD</MenuItem>
                                                <MenuItem onClick={() => { props.history.push({ pathname: '/addresses', state: { PatientIdfromPatient: '', Fromlistpage: '1' } }) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><AddressIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>MY ADDRESSES</MenuItem>
                                                <MenuItem onClick={() => { props.history.push({ pathname: '/patient', state: { Fromlistpage: '1' } }) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><PersonIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>MY PATIENTS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.OLDPRESCRIPTION) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><AssignmentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>OLD PRESCRIPTIONS</MenuItem>
                                                {/* <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.HOMEPAGE) }} style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>HOME </MenuItem> */}


                                                <MenuItem onClick={() => { props.history.push({ pathname: '/myorder', state: { orderID: '' } }) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><MyorderIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon> MY ORDERS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.REFILL) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><RefillIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon> MY REFILLS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.PAYMENTS) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><PaymentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>MY PAYMENTS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.NOTIFICATIONS) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><NotificationIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>  NOTIFICATION</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.WHYGENERIC) }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><WhyGenericIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon> WHY GENERIC</MenuItem>
                                                <MenuItem onClick={() => { localStorage.clear(); window.history.replaceState(null, null, "/"); props.history.push('/') }} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#39A749' }}><ListItemIcon style={{ minWidth: '35px' }}><SignoutIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} /></ListItemIcon>SIGNOUT</MenuItem>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </ClickAwayListener>&nbsp;&nbsp;
                                {props.browser == false &&
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'top',

                                            horizontal: 'right',
                                        }}
                                        badgeContent={<Avatar style={{ height: 20, width: 20, backgroundColor: 'red', position: 'absolute', right: 0, top: 0 }}>
                                            <Typography className={classes.cartCounttxt}>
                                                {props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')}
                                            </Typography>
                                        </Avatar>}>
                                        <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                            <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.CART) }}>
                                                <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                                            </IconButton>
                                        </Avatar>
                                    </Badge>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {props.browser == false && <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://play.google.com/store/apps/details?id=com.vmobee.reactnative.medkart" target="_blank">
                                    <Android style={{ color: '#39A749' }} />
                                </IconButton></Avatar>}&nbsp;&nbsp;
                                {props.browser == false && <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://apps.apple.com/us/app/medkart-pharmacy/id1495957793" target="_blank">
                                    <Apple style={{ color: '#39A749' }} />
                                </IconButton></Avatar>}&nbsp;&nbsp;
                            </Grid>
                            :
                            <Grid container style={{ width: '27%', alignItems: 'center' }} direction={'row'} justify='flex-end' alignItems='center'>
                                {/* <IconButton onClick={() => { AppConstants.userIdFromLocal() != '' ? props.history.push(AppConstants.SCREENS.CART) : setLoginDialog(true) }}> */}
                                <IconButton style={{ border: 0, borderRadius: 0 }} onClick={() => { props.history.push(AppConstants.SCREENS.LOGIN) }}>
                                    <Typography style={{ cursor: 'pointer', fontFamily: 'Nunito Sans', fontWeight: 700, fontSize: AppConstants.FONTSIZE.FS16, color: '#FFF', }}>LOGIN</Typography>
                                </IconButton>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={
                                        <Avatar style={{ height: 20, width: 20, backgroundColor: 'red', position: 'absolute', right: 0, top: 0 }}>
                                            <Typography className={classes.cartCounttxt}>0</Typography>
                                        </Avatar>}>

                                    <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                        <IconButton onClick={() => { AppConstants.userIdFromLocal() != '' ? props.history.push(AppConstants.SCREENS.CART) : props.history.push(AppConstants.SCREENS.LOGIN) }}>
                                            <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                                        </IconButton>
                                    </Avatar>
                                </Badge>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://play.google.com/store/apps/details?id=com.vmobee.reactnative.medkart" target="_blank">
                                    <Android style={{ color: '#39A749' }} />
                                </IconButton></Avatar>&nbsp;&nbsp;
                                <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://apps.apple.com/us/app/medkart-pharmacy/id1495957793" target="_blank">
                                    <Apple style={{ color: '#39A749' }} />
                                </IconButton></Avatar>&nbsp;&nbsp;
                            </Grid>}
                        {/* </Grid> */}
                        <ConfirmDialog
                            history={props.history}
                            onClose={() => setLoginDialog(false)}
                            open={LoginDialog}
                            onClick={() => setLoginDialog(false)}
                        />
                    </Grid>
                </Toolbar>
                {/* <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} /> */}
                {props.browser == false &&
                    <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
                        <Grid container direction='row' alignItems='center' justify={'center'} style={{ width: '100%', padding: '1%' }}>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.ECOMMERCEHOMEPAGE) }} ><Buy className={classes.IconStyleMenu} />&nbsp;ABOUT US</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.COMPARE) }}><Compare className={classes.IconStyleMenu} />&nbsp;COMPARE MEDICINES</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.STORES) }}><StoreLocator className={classes.IconStyleMenu} />&nbsp;STORE LOCATOR</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.TESTIMONIALS) }} ><Testimonials className={classes.IconStyleMenu} />&nbsp;TESTIMONIALS</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.JOINUS) }}><JoinUs className={classes.IconStyleMenu} />&nbsp;CAREER</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.FRANCHISEEMEDKART) }}><Partnerwithus className={classes.IconStyleMenu} />&nbsp;FRANCHISEE</Button>
                            <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.CONTACTMEDKART) }}><AddIcCallIcon className={classes.IconStyleMenu} />&nbsp;CONTACT US</Button>
                        </Grid>
                        {/* <Grid container style={{ width: '5%', }} justify={'flex-start'} direction={'row'} alignItems='center'>
                           
                        </Grid> */}
                    </Grid>}
                {/* {props.browser == false && <Divider style={{ width: '100%', height: '2px',borderRadius: '100px', backgroundColor: '#D9DCE3' }} />} */}
            </AppBar> : <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolBar}>
                    <Grid container style={{ width: '100%', marginTop: '0.5%' }} direction={'row'} alignItems='center' justify={'space-between'}>
                        {/*left side */}
                        <Grid onClick={() => props.history.push(AppConstants.SCREENS.HOMEPAGE)} container style={{ cursor: 'pointer', width: '40%', paddingTop: '3%', paddingBottom: '3%' }} direction={'row'} justify='flex-start' alignItems={window.innerWidth <= 320 ? 'center' : 'stretch'} >
                            <img src={images.logo} style={{ height: '40px', backgroundColor: '#39a749' }} />
                        </Grid>
                        {/**right side */}

                        {window.innerWidth <= 920 && AppConstants.userIdFromLocal() != '' && <Grid container style={{ width: '60%', }} direction={'row'} justify={'flex-end'} alignItems='center'>

                            <ClickAwayListener onClickAway={() => setOpenmenu(false)}>
                                <Grid container alignItems='center' justify='flex-end' style={{ marginLeft: -50, position: 'relative', width: '60%', cursor: 'pointer', }} direction={'row'}>
                                    {AppConstants.profilepicFromLocal() == null || AppConstants.userIdFromLocal() == null ? <Avatar onClick={() => setOpenmenu(openmenu => !openmenu)} src={AppConstants.profilepicFromLocal()} className={classes.profileImage}></Avatar> :
                                        <div onClick={() => { props.history.push(AppConstants.SCREENS.CART) }} src={''} style={{ marginRight: '10px', color: 'red' }}>

                                            <Avatar
                                                style={{ position: 'absolute', top: 2, right: 50, backgroundColor: 'yellow', width: 22, fontSize: 18, color: '#000', height: 22 }}>{props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')}</Avatar>
                                            {/* <div style={{ position: 'absolute', fontSize: 18, top: -5, right: 50, color: 'yellow' }}>{props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')}</div> */}
                                            <ShoppingCartOutlinedIcon className={classes.IconStyleMenu} style={{ marginTop: 13, fontSize: 35, color: '#fff' }}></ShoppingCartOutlinedIcon>
                                        </div>}
                                    {AppConstants.profilepicFromLocal() == null || AppConstants.userIdFromLocal() == null ? <Avatar onClick={() => setOpenmenu(openmenu => !openmenu)} src={AppConstants.profilepicFromLocal()} className={classes.profileImage}></Avatar> :
                                        <Avatar onClick={() => setOpenmenu(openmenu => !openmenu)} src={''} style={{ backgroundColor: '#f1f1f1', color: '#262628' }}>{AppConstants.fullnameFromLocal().charAt(0)}</Avatar>}
                                    {openmenu ? (

                                        <Grid
                                            container
                                            direction='column'
                                            justify={'center'}
                                            style={{
                                                position: 'absolute',
                                                width: '200px',
                                                zIndex: 10,
                                                top: 42,
                                                border: '1px solid #39A749',
                                                backgroundColor: '#FFFFFF',
                                                height: 500
                                            }}>
                                            <Scrollbars
                                                style={{ height: '100%', width: '100%' }}
                                                autoHeightMax={'70%'}>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.CART) }} style={props.CartCount == 0 ? { fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px', marginTop: '10px' } : { fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: 'red', minHeight: '40px', marginTop: '10px' }}><ShoppingCartOutlinedIcon className={classes.IconStyleMenu} style={props.CartCount == 0 ? { color: '#39A749' } : { color: 'red' }}></ShoppingCartOutlinedIcon>&nbsp;&nbsp;CART ITEMS ({props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')})</MenuItem>
                                                <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} />
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.EDITPROFILE) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><AccountCircleOutlinedIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY PROFILE</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.SCHEME) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><PaymentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;OFFERS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.CHANGEPASSWORD) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><ChangePwdIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;CHANGE PASSWORD</MenuItem>
                                                <MenuItem onClick={() => { props.history.push({ pathname: '/addresses', state: { PatientIdfromPatient: '', Fromlistpage: '1' } }) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><AddressIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY ADDRESSES</MenuItem>
                                                <MenuItem onClick={() => { props.history.push({ pathname: '/patient', state: { Fromlistpage: '1' } }) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><PersonIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY PATIENTS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.OLDPRESCRIPTION) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><AssignmentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;OLD PRESCRIPTIONS</MenuItem>
                                                {/* <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.HOMEPAGE) }} style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>HOME </MenuItem> */}

                                                <MenuItem onClick={() => { props.history.push({ pathname: '/myorder', state: { orderID: '' } }) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><MyorderIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY ORDERS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.REFILL) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><RefillIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY REFILLS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.PAYMENTS) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><PaymentIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;MY PAYMENTS</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.NOTIFICATIONS) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><NotificationIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;NOTIFICATION</MenuItem>
                                                <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.WHYGENERIC) }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><WhyGenericIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;WHY GENERIC</MenuItem>
                                                <MenuItem onClick={() => { localStorage.clear(); window.history.replaceState(null, null, "/"); props.history.push('/') }} style={{ fontWeight: '700', fontSize: '12px', fontFamily: 'Nunito Sans', color: '#39A749', minHeight: '40px' }}><SignoutIcon className={classes.IconStyleMenu} style={{ color: '#39A749' }} />&nbsp;&nbsp;SIGNOUT</MenuItem>
                                            </Scrollbars>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </ClickAwayListener>&nbsp;&nbsp;
                            <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    // edge="end"
                                    className={clsx(classes.menuButton, open && classes.hide)}>
                                    <MenuIcon />
                                </IconButton>
                            </Avatar>&nbsp;&nbsp;
                        </Grid>}
                        {/* : AppConstants.userIdFromLocal() != '' && props.browser && <Grid container style={{ width: '50%', }} direction={'row'} justify={'flex-end'} alignItems='center'>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={<Avatar style={{ height: 20, width: 20, backgroundColor: 'red', position: 'absolute', right: 0, top: 0 }}>
                                        <Typography className={classes.cartCounttxt}>
                                            {props.CartCount != undefined ? props.CartCount : localStorage.getItem('cartvalue')}
                                        </Typography>
                                    </Avatar>}>
                                    <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                        <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.CART) }}>
                                            <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                                        </IconButton>
                                    </Avatar>
                                </Badge>&nbsp;&nbsp;
                                        <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                    <IconButton
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        // edge="end"
                                        className={clsx(classes.menuButton, open && classes.hide)}>
                                        <MenuIcon />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;
                                        <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://play.google.com/store/apps/details?id=com.vmobee.reactnative.medkart" target="_blank">
                                    <Android style={{ color: '#39A749' }} />
                                </IconButton></Avatar>&nbsp;&nbsp;
                                        <Avatar style={{ backgroundColor: '#f1f1f1' }}><IconButton href="https://apps.apple.com/us/app/medkart-pharmacy/id1495957793" target="_blank">
                                    <Apple style={{ color: '#39A749' }} />
                                </IconButton></Avatar>
                                </Grid>} */}

                        {AppConstants.userIdFromLocal() === '' && <Grid container style={window.innerWidth <= 320 ? { width: '50%', } : { width: '40%', }} direction={'row'} justify='flex-end' alignItems='center'>
                            {/* <IconButton onClick={() => { AppConstants.userIdFromLocal() != '' ? props.history.push(AppConstants.SCREENS.CART) : setLoginDialog(true) }}> */}
                            {window.innerWidth <= 320 ? <IconButton style={{ border: 0, borderRadius: 0 }} onClick={() => { props.history.push(AppConstants.SCREENS.LOGIN) }}>
                                &nbsp;&nbsp;<Typography style={{ cursor: 'pointer', fontFamily: 'Nunito Sans', fontWeight: 700, fontSize: AppConstants.FONTSIZE.FS16, color: '#fff' }}>LOGIN</Typography>
                            </IconButton> : <IconButton style={{ border: 0, borderRadius: 0 }} onClick={() => { props.history.push(AppConstants.SCREENS.LOGIN) }}>
                                <Typography style={{ cursor: 'pointer', fontFamily: 'Nunito Sans', fontWeight: 700, fontSize: AppConstants.FONTSIZE.FS16, color: '#fff' }}>LOGIN</Typography>
                            </IconButton>}
                            &nbsp;&nbsp;
                            <Avatar style={{ backgroundColor: '#f1f1f1' }}>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    // edge="end"
                                    className={clsx(classes.menuButton, open && classes.hide)}>
                                    <MenuIcon />
                                </IconButton>
                            </Avatar>
                        </Grid>}
                        {/* </Grid> */}
                        <ConfirmDialog
                            history={props.history}
                            onClose={() => setLoginDialog(false)}
                            open={LoginDialog}
                            onClick={() => setLoginDialog(false)}
                        />
                    </Grid>

                </Toolbar>
                <Grid className={'first-step'} container style={{ width: '100%', marginBottom: '3px' }} justify={'center'} direction={'row'} alignItems='center'>
                    <Paper
                        elevation={0}
                        style={{ border: '2px solid #E1E1E5', height: 45 }}
                        rounded={true}
                        className={classes.textFillSearchMediumBrowser} alignItem='center'>
                        <IconButton className={classes.iconButton} aria-label="menu">
                            <LocationIcon />
                        </IconButton>
                        <Grid>
                            <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans', width: '40px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}> {localStorage.getItem('city')}</Typography>
                            <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans', textAlign: 'end' }}> {localStorage.getItem('pincode')} </Typography>
                        </Grid>
                        <Divider className={classes.divider} orientation="vertical" />
                        <InputBase
                            className={classes.input}
                            placeholder="Search Medicines"
                            // type="search"
                            value={searchtext}
                            name='searchtext'
                            alignItem='center'
                            onChange={handleChange}
                        />
                        {searchtext == '' ? <div /> : <CancelIcon style={{ color: 'red', cursor: 'pointer' }}
                            onClick={() => searchClear()} />}&nbsp;&nbsp;
                        <SearchMedicineModel CloseModal={CloseModal.bind(this)} searchtext={searchtext} openModel={openSearchModel} dataSource={dataSource} quantityOption={quantityCountOption} browser={props.browser} GetMedicineDetailFromSearchModal={GetMedicineDetailFromSearchModal} GetdataFromSearchModal={GetdataFromSearchModal} history={props.history} />
                    </Paper>
                    {/* <Grid container direction='row' justify='center' alignItems='center'
                                    style={{
                                        padding: '2px 2px',
                                        borderRadius: '10px 10px 10px 10px',
                                        width: '90%',
                                        // marginLeft:'2%',
                                        border: '1px solid #b5bbc1',
                                        alignItems: 'center',
                                        // marginLeft: '10px',
                                    }} >
                                    <SearchIcon style={{ color: '#b5bbc1' }} />
                                    <InputBase
                                        className={classes.input}
                                        placeholder="Search Medicines"
                                        // type="search"
                                        name='searchtext'
                                        onChange={handleChange}
                                    />
                                    {searchtext == '' ? <div /> : <CancelIcon style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => searchClear()} />}
                                    <SearchMedicineModel CloseModal={CloseModal.bind(this)} searchtext={searchtext} openModel={openSearchModel} dataSource={dataSource} quantityOption={quantityCountOption} browser={props.browser} GetMedicineDetailFromSearchModal={GetMedicineDetailFromSearchModal} GetdataFromSearchModal={GetdataFromSearchModal} history={props.history} />
                                </Grid> */}
                </Grid>
            </AppBar>
            }
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={open}
                classes={{ paper: props.data == true ? classes.drawerPaper1 : classes.drawerPaper }} >
                <div className={classes.drawerHeaderMobileView} style={{ backgroundColor: '#39A749' }}>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon style={{ color: '#fff' }} />
                    </IconButton>
                    <Grid justify={'center'} onClick={() => props.history.push(AppConstants.SCREENS.HOMEPAGE)} container style={{ backgroundColor: '#39a749', cursor: 'pointer', width: '100%', height: 50 }} direction={'row'} alignItems='center' >
                        <img src={images.logo} className={classes.iconMain} />
                    </Grid>
                </div>
                {/* <Grid justify={'center'} onClick={() => props.history.push(AppConstants.SCREENS.HOMEPAGE)} container style={{ backgroundColor: '#39a749', cursor: 'pointer', width: '100%', height: 70 }} direction={'row'} alignItems='flex-start' >
                    <img src={images.logo} className={classes.iconMain} />
                </Grid> */}
                <Scrollbars
                    autoHide
                    autoHeightMax={'100%'}>
                    {window.innerWidth <= 920 ? <Grid direction={'row'} alignItem='center' justify='center'>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.ECOMMERCEHOMEPAGE) }} >&nbsp;&nbsp;<Buy className={classes.IconStyle} />ABOUT US</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.COMPARE) }}>&nbsp;&nbsp;<Compare className={classes.IconStyle} />COMPARE MEDICINES</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.STORES) }}>&nbsp;&nbsp;<StoreLocator className={classes.IconStyle} />STORE LOCATOR</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.TESTIMONIALS) }} >&nbsp;&nbsp;<Testimonials className={classes.IconStyle} />TESTIMONIALS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.JOINUS) }}>&nbsp;&nbsp;<JoinUs className={classes.IconStyle} />CAREER</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.FRANCHISEEMEDKART) }}>&nbsp;&nbsp;<Partnerwithus className={classes.IconStyle} />FRANCHISEE</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.CONTACTMEDKART) }}>&nbsp;&nbsp;<AddIcCallIcon className={classes.IconStyle} />&nbsp;CONTACT US</Button>

                        <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} />
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.TERMSCONDITIONS) }}>&nbsp;&nbsp;<PolicyOutlinedIcon className={classes.IconStyle} />TERMS & CONDITIONS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.PRIVACYPOLICY) }}>&nbsp;&nbsp;<PolicyOutlinedIcon className={classes.IconStyle} />PRIVACY POLICY</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.RETURNPOLICY) }}>&nbsp;&nbsp;<RemoveShoppingCartOutlinedIcon className={classes.IconStyle} />RETURN POLICY</Button>

                        <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} />

                        <Button className={classes.buttonstyle} href="https://play.google.com/store/apps/details?id=com.vmobee.reactnative.medkart" target="_blank">&nbsp;&nbsp;<Android className={classes.IconStyle} />DOWNLOAD ANDROID APP</Button>
                        <Button className={classes.buttonstyle} href="https://apps.apple.com/us/app/medkart-pharmacy/id1495957793" target="_blank">&nbsp;&nbsp;<Apple className={classes.IconStyle} />DOWNLOAD iPHONE APP</Button>

                    </Grid> : <Grid direction={'row'} alignItem='center' justify='center'>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.EDITPROFILE) }}><AccountCircleOutlinedIcon className={classes.IconStyle} />MY PROFILE</Button>

                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.HOMEPAGE) }}><HomeIcon className={classes.IconStyle} />HOME</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push({ pathname: '/patient', state: { Fromlistpage: '1' } }) }}><PersonIcon className={classes.IconStyle} />MY PATIENTS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push({ pathname: '/addresses', state: { PatientIdfromPatient: '', Fromlistpage: '1' } }) }}><AddressIcon className={classes.IconStyle} />MY ADDRESSES</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push({ pathname: '/myorder', state: { orderID: '' } }) }}><MyorderIcon className={classes.IconStyle} />MY ORDERS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.REFILL) }}><RefillIcon className={classes.IconStyle} />MY REFILLS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.OLDPRESCRIPTION) }}><AssignmentIcon className={classes.IconStyle} />PRESCRIPTION</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.PAYMENTS) }}><PaymentIcon className={classes.IconStyle} />MY PAYMENTS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.STORES) }}><StoreLocator className={classes.IconStyle} />STORE LOCATOR</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.NOTIFICATIONS) }}><NotificationIcon className={classes.IconStyle} />NOTIFICATIONS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.FRANCHISEEMEDKART) }} ><Partnerwithus className={classes.IconStyle} />PARTNER WITH US</Button>
                        <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.CONTACTMEDKART) }}><AddIcCallIcon className={classes.IconStyleMenu} />&nbsp;CONTACT US</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.WHYGENERIC) }} ><WhyGenericIcon className={classes.IconStyle} />WHY GENERIC</Button>
                        <Button className={classes.buttonstyle} onClick={() => { window.history.replaceState(null, null, "/"); props.history.push('/'); localStorage.clear(); setOpen(false) }} ><SignoutIcon className={classes.IconStyle} /> {'SIGNOUT'}</Button>
                    </Grid>}
                </Scrollbars>
            </Drawer>

            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <div className={classes.drawerHeader} />
            </main>
            <Tour
                rounded={5}
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={closeTour.bind(this)}
                disableInteraction={true}
            />
        </div>
    );
}
export default Mobiledrawer;