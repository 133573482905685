import React from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid } from '@material-ui/core';
import TermsConditionDialogStyles from './TermsConditionDialogStyles';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import Iframe from 'react-iframe';
import { Scrollbars } from 'react-custom-scrollbars';

const CODDialog = props => {
    const classes = TermsConditionDialogStyles();
    const { open, onClose, onCancel, onAgree } = props;
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '630px', borderRadius: '100px' }}
                open={open}>
                <Grid container style={{ width: '100%', }} direction="row" justify="center" alignItems="center">
                <Scrollbars autoHide autoHeight autoHeightMax='400px'>
                    <Grid direction={'row'} container justify='center' alignItems="center" style={{ width: '100%' }}>
                        <Iframe url="https://medkart.in/privacypolicy.aspx"
                            width="94%"
                            height="700px"
                            frameBorder='0'
                        />
                    </Grid>
                    </Scrollbars>

                    <Grid direction={'row'} container justify='space-between' style={{ marginTop: '5%', width: '90%', marginBottom: '5%', }}>
                        <ButtonBase title={'AGREE'}
                            variant="contained"
                            style={{ width: '100px' }}
                            onClick={onAgree} />
                        <ButtonBase title={'CANCEL'}
                            variant="contained"
                            style={{ width: '100px' }}
                            onClick={onCancel} />
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (CODDialog);
