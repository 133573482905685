import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const JoinUsStyle = theme => ({
    JoinTxt: {
        color:'#FFFF',
        fontSize:'30px',
        fontFamily: 'Nunito Sans', fontWeight: 700,
    },
    mailtxt:{
        color:'#FFFF',
        fontSize:'16px',
        fontFamily: 'Nunito Sans', fontWeight: 400,
    },
    mailtxtLink:{
        color:'#FFFF',
        fontSize:'16px',
        fontFamily: 'Nunito Sans', fontWeight: 400,
    },
    Screentitle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS35,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.primary.main,
    },
    img: {
        width: '100%',
        height: 550,
    },
    textFieldContent: {
        width: '48%',
        marginTop: '20px'
    },
    subtxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: AppConstants.FONTSIZE.FS15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        marginLeft: '15px',
        color: '#888',
    },
    titletextGreen: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '22px',
        color: '#262628',
    },
});
const combinedStyles = combineStyles(commonStyle, JoinUsStyle);
export default combinedStyles; 
