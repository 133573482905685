import { makeStyles } from '@material-ui/core/styles';

const buttonBaseStyle = makeStyles(theme => ({
  button: {
    // borderRadius:50,
    height: 48,
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    lineHeight: 1.6,
    letterSpacing: 0.1,
    fontWeight: 600,
    boxShadow: 'none',
  }
}));

export default buttonBaseStyle;
