/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    AppBar,
    Toolbar,
    Typography,
    Grid,
    IconButton,
    Avatar,
} from '@material-ui/core';
import Android from '@material-ui/icons/AndroidOutlined';
import Apple from '@material-ui/icons/Apple';
import FooterStyle from './FooterStyle';
import * as AppConstants from '../../common/Constants';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MobilenoDialog from "../../components/MobilenoDialog/MobilenoDialog";
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Drafts';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    appBar: {
        // transition: theme.transitions.create(['margin', 'width'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),

        // backgroundColor: '#fff'
        top: 'auto',
        bottom: 0,

    },
}
));
const Footer = props => {
    const classes = FooterStyle();
    const [MobileDialog, setMobileDialog] = React.useState(false);
    return (
        <div>
            {window.innerWidth <= 920 ? <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar alignItems='center' style={window.innerWidth === 414 || 375 ? {
                    bottom: '0',
                    width: '100%',
                    // height:'5%',
                    backgroundColor: '#39A749',
                    position: 'fixed',
                    zIndex: 10
                } : {
                    bottom: '0',
                    width: '100%',
                    // height:'5%',
                    backgroundColor: '#39A749',
                    position: 'fixed',
                    zIndex: 10
                }}>
                    <Grid container style={{ width: '100%', left: 0, padding: '1%', backgroundColor: '#39A749' }} >
                        <Grid container direction={'row'} style={{ width: '100%' }} alignItems={'center'} justify={'space-around'}>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton onClick={() => setMobileDialog(true)} target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }} target="_blank">
                                    <PhoneAndroidIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="https://api.whatsapp.com/send?phone=+917600001634&amp;text=Hi, I contacted you Through your website." target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <WhatsAppIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="mailto:info@medkart.in" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <EmailIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Typography style={{ fontSize: '14px', color: '#FFFFFF', fontWeight: 700 }}>|</Typography>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="https://www.facebook.com/medkartpharmacy/" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <FacebookIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="https://www.instagram.com/medkartpharmacy/" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <InstagramIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="https://twitter.com/medkart" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <TwitterIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                            <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                <IconButton href="https://www.linkedin.com/company/medkart-pharmacy" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                    <LinkedInIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                </IconButton>
                            </Avatar>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
                :
                <AppBar position="fixed" className={classes.appBar} elevation={0}>
                    <Toolbar alignItems='flex-end' style={{
                        bottom: '0',
                        width: '100%',
                        // height:'5%',
                        minHeight: 40,
                        backgroundColor: '#39A749',
                        position: 'fixed',
                        alignItems: 'center',
                        alignSelf: 'center',
                        justifyContent: 'center'
                    }}>
                        <Grid alignContent='center' alignItems='center' alignSelf='center' container style={{ width: '100%' }} >
                            <Grid container alignItems='center' justify='flex-start' direction='row' style={props.browser ? { width: '25%' } : { width: '49%' }}>
                                &nbsp;&nbsp;<Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton onClick={() => setMobileDialog(true)} target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }} target="_blank">
                                        <PhoneAndroidIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>
                                &nbsp;&nbsp;

                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="https://api.whatsapp.com/send?phone=+917600001634&amp;text=Hi, I contacted you Through your website." target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <WhatsAppIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;
                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="mailto:info@medkart.in" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <EmailIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;&nbsp;
                                <Typography style={{ fontSize: '14px', color: '#FFFFFF', fontWeight: 700 }}>|</Typography>&nbsp;&nbsp;&nbsp;



                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="https://www.facebook.com/medkartpharmacy/" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <FacebookIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;


                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="https://www.instagram.com/medkartpharmacy/" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <InstagramIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;

                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="https://twitter.com/medkart" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <TwitterIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>

                                </Avatar>&nbsp;&nbsp;
                                <Avatar style={{ backgroundColor: '#f1f1f1', height: 25, width: 25, alignItems: 'center', justify: 'center' }}>
                                    <IconButton href="https://www.linkedin.com/company/medkart-pharmacy" target="_blank" style={{ color: '#FFFFFF', fontSize: AppConstants.FONTSIZE.FS18, fontFamily: 'Nunito Sans', fontWeight: 400, }}>
                                        <LinkedInIcon style={{ color: '#39A749', fontSize: AppConstants.FONTSIZE.FS18, }} />
                                    </IconButton>
                                </Avatar>&nbsp;&nbsp;
                            </Grid>
                            <Grid container justify='flex-end' alignItems='center' style={props.browser ? { width: '25%' } : { width: '49%' }} >
                                <Typography style={{ color: '#FFFFFF', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito Sans', cursor: 'pointer' }} onClick={() => { props.history.push(AppConstants.SCREENS.TERMSCONDITIONS) }}>Terms & Conditions</Typography>&nbsp;
                                <Typography style={{ fontSize: '14px', color: '#FFFFFF', fontWeight: 700 }}>|</Typography>&nbsp;
                                <Typography style={{ color: '#FFFFFF', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito Sans', cursor: 'pointer' }} onClick={() => { props.history.push(AppConstants.SCREENS.PRIVACYPOLICY) }}>Privacy Policy</Typography>&nbsp;
                                <Typography style={{ fontSize: '14px', color: '#FFFFFF', fontWeight: 700 }}>|</Typography>&nbsp;
                                <Typography style={{ color: '#FFFFFF', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito Sans', cursor: 'pointer' }} onClick={() => { props.history.push(AppConstants.SCREENS.RETURNPOLICY) }}>Return & Refund Policy</Typography>&nbsp;
                                <Typography style={{ fontSize: '14px', color: '#FFFFFF', fontWeight: 700 }}>|</Typography>&nbsp;
                                <Typography style={{ color: '#FFFFFF', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito Sans', }}>© {new Date().getFullYear()} Medkart Pharmacy. All Rights Reserved.</Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>

                </AppBar>}
            <MobilenoDialog
                history={props.history}
                onClose={() => setMobileDialog(false)}
                open={MobileDialog}
                onClick={() => setMobileDialog(false)}
            />
        </div>
    );
};

export default withTranslation('translation')(Footer);
