import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const UploadPrescriptionDialogStyle = theme => ({
    Medicinetitle: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 20,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    qntytxt: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: '20px',
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    Dropimgtxt: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    Dropimgsubtxt: {
        fontFamily: 'Nunito Sans', fontWeight: 200,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    oldprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.red,
    },
    newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
    },
});
const combinedStyles = combineStyles(commonStyle, UploadPrescriptionDialogStyle);
export default combinedStyles; 
