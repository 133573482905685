import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const TestimonialsStyle = theme => ({
    title: {
        fontSize: AppConstants.FONTSIZE.FS16,
        fontFamily: 'Nunito Sans', fontWeight: 400,
        lineHeight: 1.13,
        letterSpacing: 0,
        color:'#888',
        paddingBottom: '20px',   
      },
      NameStyle:{
        fontSize: AppConstants.FONTSIZE.FS18,
        fontFamily: 'Nunito Sans', fontWeight: 600,
        margin:'15px',
      },
      Contentcard:{
        // position: 'relative',
        borderTop: '3px solid #39a749',
        borderBottom:'1px solid  #eaeaea',
        borderRight:'1px solid #eaeaea',
        borderLeft:'1px solid #eaeaea',
        borderRadius: '3px',
        padding:'40px 35px',
       
    },
    AvatarImg:{
        height: 60, 
        width: 60,
    },
    Screentitle: {
      fontFamily: 'Nunito Sans', fontWeight: 700,
      fontSize: AppConstants.FONTSIZE.FS35,
      lineHeight: 1.13,
      letterSpacing: 0,
      color: theme.palette.textColor,
  },
  titletextGreen: {
      fontFamily: 'Nunito Sans', fontWeight: 700,
      fontSize: AppConstants.FONTSIZE.FS35,
      lineHeight: 1.13,
      letterSpacing: -0.5,
      color: '#39A749',
  },
     
});
const combinedStyles = combineStyles(commonStyle, TestimonialsStyle);
export default combinedStyles; 
