import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const myStoreStyle = theme => ({
    headingText: {
        marginTop: '1%',
        fontSize: 20,
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        color: '#000000',
    },
    subText: {
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        marginLeft:'10px',
        color: '#B0B4BF',
    },
    shopNameText: {
        marginLeft: '1%',
        fontSize: '18px',
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        color: '#262628',
    },
    shopAddressText:{
        marginLeft: '7%',
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        color: '#B0B4BF',
    },
    shopPhoneNumberText:{
        marginBottom: '1%',
        marginLeft: '7%',
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        textDecoration:'none',
        color: '#262628',
    },
});
const combinedStyles = combineStyles(commonStyle, myStoreStyle);
export default combinedStyles; 
