const en = {
    common: {
        authTitle: 'Continuous Upskilling Platform',
        authSubTitle:
            'Techgigs is a gig marketplace for DevOps work and a platform for companies to access a wide pool of talent that are constantly getting better though contineous upskilling through technical challenges & task.',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        continue: 'Continue',
        fullName: 'Full Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        country: 'Country',
        Countrycode: 'CountryCode',
        city: 'City',
        dob: 'Date of Birth',
        mobile: 'Mobile',
        cancel: 'Cancel',
        submit: 'Submit',
        skipVerification: 'Skip Verification',
        proceedVerification: 'Proceed Verification',
        or: 'Or'
    },
    headreMenu:
    {
        dashboard: 'Dashboard',
        projects: 'Projects',
        clients: 'Clients'
        // freelancer
    },
    commonDashboard: {
        title: 'Dashboard',
        editRecommendation: 'Edit Recommendation',
        name: 'NAME',
        startDate: ' START DATE',
        endDate: '  END DATE',
        budget: '  BUDGET',
        gigs: 'GIGS',
        capitalizeStartDate: 'Start Date',
        capitalizeEndDate: 'End Date',
        hotSkills: 'Hotskills',
        updateMonthly: 'Update Monthly',
        none: 'None',
        DevOps: 'DevOps',
        hotSkillsSubTitle: 'People who acquired this skill earns',
        more: 'more',
        goToArena: 'Go To Arena',
        skillDemandTitle: 'Skill-Market Demand',
        calculation: 'Read how this is calculated',
        upSkillsArena: 'Upskill Via Arena',
        skillDemandSubTitle1: 'Gigs that are looking',
        skillDemandSubTitle2: 'for skills you have'

    },
    login: {
        forgotPassword: 'Forget Password',
        register: "Don't have an account? Register",
        signIn: 'Log In',
        signInWithLinkdin: 'Sign in with Linkedin',
        signInWithGoogle: 'Sign in with Google',
    },
    register: {
        signUp: 'Sign Up',
        signUpWithLinkedIn: 'Sign up with Linkedin',
        signUpWithGoogle: 'Sign up with Google',
        alreadyHaveAccount: 'Already have an account? Sign In',
        signup: 'Sign up'
    },
    profileComplete: {
        profileComplete: ' Profile Complete',
        resetYourPassword: `Enter the email address you used to when you registered an account to reset your password. If the account is present, you'll receive the
    password link via email.`
    },
    profileIncomplete: {
        profileIncomplete: ' Profile Incomplete',
        profileIncompleteMessage: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
    },
    profileCreation: {
        profile: 'Profile',
        social: 'Social',
        mobilenumber: 'Mobile Number',
        company: 'Company',
        education: 'Education',
        experience: 'Experience',
        availibility: 'Availibility',
        emailId: 'email@email.com',
        place: ' Jurong, Singapore',
        twitter: 'Twitter',
        facebook: 'Facebook',
        linkedin: 'LinkedIn',
        quora: 'Quora',
        github: 'Github',
        behance: 'Behance',
        dribble: 'Dribble',
        next: 'Next',
        doItLater: 'Do it later',
        universityCollege: 'University / College',
        educationLevel: 'Educational Level',
        areaOfStudy: 'Area Of Study',
        startDate: 'Start Date',
        endDate: 'End Date',
        behanceoptional: 'Behance (Optional)',
        skillName: 'Skill Name',
        level: 'Level',
        year: 'Year',
        addSkill: 'Add Skill',
        hoursperweek: 'How many hours do you want to work in a week?',
        typeofprojects: 'Type of projects you are interesed in?*',
        hourlyrate: 'Hourly Rate',
        monthlyrate: 'Monthly Rate',
        allStepsCompleted: ` All steps completed - you're finished`,
        profilebottomsummary: 'Techgigs is a gig marketplace for DevOps work and a platform for \ncompanies to access a wide pool of talent that.'
    },
    projects: {
        createGig: {
            name: 'CreateGig',
            newGigs: 'New Gigs',
            gigName: 'Gig Name',
            gigDescription: 'Gig Description',
            skillsRequired: 'Skills Required',
            skillLevel: 'Skill Level',
            budget: 'Budget',
            gigDuration: 'Gig Duration'
        },
        feedback: {
            name: 'FeedBack',
            hiredBy: 'Hired By ',
            tagDone: 'Done',
            buildPipline: 'Build CI/CD Pipeline',
            detail: 'Detail',
            subTitle: 'Need to setup new server with HA for an app that we are doing for our client. Key thing to take Need to setup with HA for an app that we are doing for our client. Key thing to take',
            saveProfile: 'Save Profile',
            recommend: 'Recommend',
            totalHours: 'Total Hours',
            hours: 'Hours',
            totalPaid: 'Total Paid',
            experience: ' How was your experience working with',
            comments: 'Comments'
        },
        projects: 'Projects'
    },
    resetPassword: {
        name: 'Reset Password',
        resetPasswordSubTitle: `Enter the email address you used to when you registered an account to
        reset your password. If the account is present, you'll receive the
        password reset link via email.`,
        resetLink: 'Request Password Reset Link',
        backToLogin: ' Back to Login'
    },
    verifyEmail: {
        name: 'Verify Email',
        verifyEmailSubTitle: `Enter the email address you used to when you registered an account to reset 
        your password. If the account is present, you'll receive the password reset link via email.`,
        VerifyLink: 'Resend Email Verification',
    },
    Agreement: {
        name: 'Non Disclosure Agreements',
        AgreementContent: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac, neque, et, maecenas. Tempus non pellentesque ut aliquet. Magna ultrices pretium turpis quis pellentesque gravida id ac eu. Adipiscing in sagittis purus eget cras lectus condimentum ut. Et curabitur adipiscing ut porttitor enim ullamcorper morbi habitant. Molestie dictumst at vel est arcu at lobortis gravida mi. Laoreet magna proin faucibus eget ligula congue malesuada suscipit. Turpis phasellus nec arcu vitae dolor. Nunc, eleifend nisl egestas tempus sed egestas. Facilisis ac quam id at turpis odio maecenas volutpat. Senectus massa lectus eget vitae tempor turpis arcu. Diam tortor convallis urna, pharetra viverra sollicitudin. Porta proin tempus quis egestas ullamcorper.
        Porttitor molestie urna vel adipiscing donec euismod venenatis facilisis vitae. Suspendisse rhoncus, gravida a aliquam. Nunc mauris placerat nullam congue ipsum egestas mattis. Dignissim sed urna fermentum, ac in semper curabitur nulla. Luctus nunc nulla ligula luctus venenatis, proin. Ut aenean condimentum turpis mauris nibh. Amet feugiat in lectus diam. Lacinia mauris eget semper lobortis lacus. Turpis eget habitant in gravida egestas vitae proin magna sit. Porttitor cursus enim semper non convallis a vestibulum, pharetra, et. Sed elementum lobortis.
        
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac, neque, et, maecenas. Tempus non pellentesque ut aliquet. Magna ultrices pretium turpis quis pellentesque gravida id ac eu. Adipiscing in sagittis purus eget cras lectus condimentum ut. Et curabitur adipiscing ut porttitor enim ullamcorper morbi habitant. Molestie dictumst at vel est arcu at lobortis gravida mi. Laoreet magna proin faucibus eget ligula congue malesuada suscipit. Turpis phasellus nec arcu vitae dolor. Nunc, eleifend nisl egestas tempus sed egestas. Facilisis ac quam id at turpis odio maecenas volutpat. Senectus massa lectus eget vitae tempor turpis arcu. Diam tortor convallis urna, pharetra viverra sollicitudin. Porta proin tempus quis egestas ullamcorper.
        Porttitor molestie urna vel adipiscing donec euismod venenatis facilisis vitae. Suspendisse rhoncus, gravida a aliquam. Nunc mauris placerat nullam congue ipsum egestas mattis. Dignissim sed urna fermentum, ac in semper curabitur nulla. Luctus nunc nulla ligula luctus venenatis, proin. Ut aenean condimentum turpis mauris nibh. Amet feugiat in lectus diam. Lacinia mauris eget semper lobortis lacus. Turpis eget habitant in gravida egestas vitae proin magna sit. Porttitor cursus enim semper non convallis a vestibulum, pharetra, et. Sed elementum lobortis `,
        VerifyLink: 'Resend Email Verification',
        agreeAgreement: 'Agree Agreement'
    },

    uploadDocument: {
        uploadTitle: ' Upload Required Documents'
    },
    uploadDocumentOnBoarding: {
        identification: 'Identification',
        uploadFiles: 'Upload Files'
    },
    userType: {
        freelancer: 'Freelancer',
        client: 'Client',
        title: 'Welcome'
    },
    clientOngoingProjects: {
        title: `Client's Ongoing Projects`,
        projectTitle: 'Implement Azure Data Cubes and generate reports',
        subTitle1: ' Develop large number of cubes based on database provided.',
        subTitle2: 'Generate reports using data cubes, APIs in Azure and Power BI',
        seeGigs: 'See Gigs',
        markComplete: 'Mark As Complete'
    },
    ViewAllProject: {
        title: 'Clients',
        subtitle: 'GovTech',
        menusubtitle: 'Projects',
        addproject: '+ Projects',
        subTitle1: ' Develop large number of cubes based on database provided.',
        subTitle2: 'Generate reports using data cubes, APIs in Azure and Power BI',
        seeGigs: 'See Gigs',
        markComplete: 'Mark As Complete',
        usertype: 'Project Manager',
        buttonTitle: 'See All Gigs',
        detail: 'Detail',
    },
    ViewAllGig: {
        title: 'Clients',
        subtitle: 'Project',
        menusubtitle: 'Project Name',
        addproject: '+ Project',
    },
    GWDashboard: {
        title: 'Dashboard',
        GigInformation: 'Gig Information',
        Requirements:'Requirements',
        editRecommendation: 'Edit Recommendation',
        name: 'NAME',
        startDate: ' START DATE',
        endDate: '  END DATE',
        budget: '  BUDGET',
        gigs: 'GIGS',
        capitalizeStartDate: 'Start Date',
        capitalizeEndDate: 'End Date',
        hotSkills: 'Hotskills',
        updateMonthly: 'Update Monthly',
        none: 'None',
        DevOps: 'DevOps',
        hotSkillsSubTitle: 'People who acquired this skill earns',
        more: 'more',
        goToArena: 'Go To Arena',
        skillDemandTitle: 'Skill-Market Demand',
        calculation: 'Read how this is calculated',
        upSkillsArena: 'Upskill Via Arena',
        skillDemandSubTitle1: 'Gigs that are looking',
        skillDemandSubTitle2: 'for skills you have'
    },
    addtasks: {
        newTask: 'New Task',
        taskName: 'Task Name',
        taskDescription: 'Task Description',
        skillsRequired: 'Skills Required',
        skillLevel: 'Skill Level',
        budget: 'Budget',
        hoursDuration: 'Hours Allocation',
        remarksComment: 'Remarks or Comment',
    }
};
export default en;
