import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import OldPrescriptionStyle from './OldPrescriptionStyle';
import Title from "../../components/Header/Title";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { CssBaseline, Typography, Grid, RadioGroup, Radio, CircularProgress, MenuItem, FormControlLabel, InputAdornment, Select, Button,Card, } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as AppConstants from '../../common/Constants';
import TopMenu from "../../components/Header/TopMenu";
import * as APIController from '../../api/APIController';
import FilledDatePicker from "../../components/FilledDatePicker/FilledDatePicker";
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as moment from 'moment';
import InputMask from 'react-input-mask'
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import LocationIcon from '@material-ui/icons/LocationOn';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactGA from 'react-ga';
class OldPrescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            IsLoading: false,
            OlddataSource: [],
            IsPageLoad:true,

        };
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetOldPrescription()
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    wsGetOldPrescription = () => {
        this.setState({ IsLoading: true })
        APIController.GetOldPrescription(
            0,//oprationid
            AppConstants.userIdFromLocal(),//userid
            AppConstants.mobilenoFromLocal(),//mobilenumber
            1,//getdata
            1,//is used
        ).then((responseJson) => {
            if (responseJson.error == null) {
                this.setState({ OlddataSource: responseJson.object, IsPageLoad: false });
            }
            else {
                ToastsStore.warning(responseJson.error.message)
            }
            // this.addDummyImage();
        })
    }
   

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    renderNodataFound() {
        const { classes } = this.props
        return (
            <Grid className={classes.Contentcardmain} style={{ width: '100%', }} container direction={'row'} justify={'center'} alignItems="center">
                <Typography className={classes.qntytxt} >{'Old Prescription Not Found'}</Typography>
            </Grid>
        )
    }

    renderOldPrescription() {
        const { classes } = this.props
        const { DeliveryCharges, IsSelectPress, IsSelectPressed, CartTotal, MedKartDiscount, GrossTotal, dropzone, dataSource, OlddataSource, isLoadingChoose,mediumBrowser } = this.state

        return (
            <Card raised={true} style={{ flex:1,width: '100%',marginTop:'1.2%',height:'97%',padding:'2%', }} className={mediumBrowser?classes.Contentcardmainwithoutborder_OldPrescription:classes.Contentcardmainwithoutborder} container direction={'row'} justify={'center'} alignItems="center">
                {OlddataSource.length == 0 ? this.renderNodataFound()
                    : <Grid style={{ width: '100%', }} container direction={'row'} alignItems='center' justify='center'>
                        {OlddataSource.map((item, index) =>
                            <Grid onClick={() => window.open(item.imagepath, "_blank")} style={{ border: '2px solid',borderColor: '#b5bbc1',borderRadius: '10px 10px 10px 10px',cursor: 'pointer', height: '200px', width: '180px', margin: '15px', }} container direction={'row'}>
                                {/* <IconButton onClick={() => this.wsAddPrescriptionToNew(item.id, index)} style={{ position: 'absolute' }}>
                                {IsSelectPress == index && IsSelectPressed == 1 ? <AssignmentTurnedInIcon /> : <CheckBoxOutlineBlankIcon />}
                            </IconButton> */}
                                <img
                                    style={{ height: '100%', width: '100%', }}
                                    src={item.imagepath}
                                    alt="name"
                                />
                            </Grid>
                        )}
                    </Grid>
                }
            </Card>
        )
    }

    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, OlddataSource, Fromlistpage, isLoading,IsPageLoad } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    <Title isBack={1} title={'Old Prescriptions'} history={this.props.history} />
                    {/* {mediumBrowser == false && <TopMenu history={this.props.history} />} */}
                    {IsPageLoad ? this.LoadingView() :
                        <Grid container style={mediumBrowser?{ width: '100%',marginTop:'2%' }:{ width: '100%',marginTop:'0.5%' }} justify='center' alignItems='center'>
                             <Grid  style={mediumBrowser?{ width: '100%', }:{ width: '77%', }} container direction={'row'} alignItems="center">
                                <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{'('+  OlddataSource.length + ')' + ' ' + 'Old Prescriptions Found'} </Typography>
                            </Grid>
                            <Grid container justify='center' alignItems='center' style={mediumBrowser ? { width: '100%',marginTop:'1%' } : { width: '78%' }}>
                                {/* <Grid container direction="row" style={{ width: '50%', }} justify={'space-between'}> */}
                                {this.renderOldPrescription()}
                            </Grid>
                        </Grid>}
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}

export default withStyles(OldPrescriptionStyle)(withTranslation('translation')(OldPrescription));
