import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const CallToOrderStyle = theme => ({
    JoinTxt: {
        color: '#2b2b2b',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
    },
    IconStyle: {
        fontSize: '38px',
        color: '#39a749',
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        marginRight: '5px',
    },
    linkStyle: {
        color: '#2b2b2b',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontFamily: 'Nunito Sans',
    },

});
const combinedStyles = combineStyles(commonStyle, CallToOrderStyle);
export default combinedStyles; 
