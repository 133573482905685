import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const FranchiseeMedkartStyle = theme => ({
    Screentitle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS35,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.primary.main,
    },
    mainView: {
        width: '100%',
        minWidth: '400px',
        // height:300,
        zIndex: 300,
        bottom: '63px',
        padding: '10%',
        marginTop:'40%',
        backgroundColor: '#fff',
        // position: 'fixed'
    },
    mainMobileView: {
        width: '100%',
        // minWidth: '250px',
        marginTop:'60%',
        zIndex: 300,
        // bottom: '63px',
        // padding: '10%',
        backgroundColor: '#fff',
        // position: 'fixed'
    },
    thankslogo: {
        fontSize: '100px', textAlign: 'center', color: theme.palette.primary.main
    },
    img: {
        width: '100%',
        height: 550,
    },
    textFieldContent: {
        width: '48%',
        marginTop: '20px'
    },
    subtxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: AppConstants.FONTSIZE.FS17,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        // marginLeft: '15px',
        color: '#888',
    },
    titletextGreen: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: 'normal',
        color: '#262628',
    },
    textField: {
        backgroundColor: '#f1f1f1',
        borderRadius: 4,
        border: '1px solid #f1f1f5',
        color: '#32373c',
        fontFamily: 'Nunito Sans',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: 600,
        height: '62px',
        lineHeight: 1.57

    },
    textField1: {
        backgroundColor: '#f1f1f1',
        borderRadius: 4,
        border: '1px solid #f1f1f5',
        color: '#32373c',
        fontFamily: 'Nunito Sans',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: 600,
        height: '125px',
        lineHeight: 1.57

    },
    titletext: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS30,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.commonTitletxt,
    },
    titletextcolor: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS30,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.primary.main,
    },
    ParagraphTxt: {
        color: '#282628',
        fontSize: '20px',
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontFamily: 'Nunito Sans', fontWeight: 400,
        // margin:'1%',
        // textAlign:'center',
    },
    ParagraphTxtTitle: {
        color: '#282628',
        fontSize: '26px',
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontFamily: 'Nunito Sans', fontWeight: 400,
        // margin:'1%',
        // textAlign:'center',
    },
    myCarousel: {
        background: '#fafafa',
        width: '54%',
        margin: '-6% auto 0',
        padding: '6% 5% 8% 5%',
        border: '1px solid #ddd',
        height: '286px'
    },

});
const combinedStyles = combineStyles(commonStyle, FranchiseeMedkartStyle);
export default combinedStyles; 
