import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const EcommerceHomePageStyle = theme => ({
    Contentcard:{
        // position: 'relative',
        borderTop: '1px solid #eaeaea',
        borderBottom:'1px solid  #eaeaea',
        borderRight:'1px solid #eaeaea',
        borderLeft:'1px solid #eaeaea',
        borderRadius: '3px',
        
        // height:'150px',
        // padding:'120px',   
    },
    IconStyle: {
        color: '#39A749',
        fontSize: '30px',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        paddingRight: '5%',
    },
    buttonstyle: {
        color: '#262628',
        fontSize: '16px',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        
    },
    

 
});
const combinedStyles = combineStyles(commonStyle, EcommerceHomePageStyle);
export default combinedStyles; 
