/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import Login from './containers/Login/Login';
import Signup from './containers/Signup/Signup';
import EditProfile from './containers/EditProfile/EditProfile';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import VerifyOTP from './containers/VerifyOTP/VerifyOTP';
import MedicineDetail from './containers/MedicineDetail/MedicineDetail';
// import OrderMedicine from './containers/OrderMedicine/OrderMedicine';
import CallToOrder from './containers/CallToOrder/CallToOrder';
import MyOrder from './containers/MyOrder/MyOrder';
import OldPrescription from "./containers/OldPrescription/OldPrescription";
import Refill from './containers/Refill/Refill';
import Stores from './containers/Stores/Stores';
import AhmedabadStores from './containers/Stores/AhmedabadStores';
import VadodaraStores from './containers/Stores/VadodaraStores';
import Compare from './containers/Compare/Compare';
import WhyGeneric from './containers/WhyGeneric/WhyGeneric';
import Cart from './containers/Cart/Cart';
import UploadPrescription from './containers/UploadPrescription/UploadPrescription';
import UploadPrescriptionDialog from './containers/UploadPrescriptionDialog/UploadPrescriptionDialog';
import Patient from './containers/Patient/Patient';
import Addresses from './containers/Addresses/Addresses';
import OrderReview from './containers/OrderReview/OrderReview';
import OrderConfirmation from './containers/OrderConfirmation/OrderConfirmation';
// import Footer from './containers/Footer/Footer';
import HomePage from "../src/containers/WebsiteHomePage/HomePage";
import Campaign from "../src/containers/Campaign/Campaign";
import WebsiteHomePage from './containers/WebsiteHomePage/WebsiteHomePage';
import { User } from './prototypes/User';
import JoinUs from './containers/JoinUs/JoinUs';
import Testimonials from './containers/Testimonials/Testimonials';
import WhyMedkart from './containers/WhyMedkart/WhyMedkart';
import AboutUs from './containers/AboutUs/AboutUs';
import Notifications from "../src/containers/Notifications/Notifications";
import Payments from "../src/containers/Payments/Payments";
import ContactMedkart from "../src/containers/ContactMedkart/ContactMedkart";
import FranchiseeMedkart from "../src/containers/FranchiseeMedkart/FranchiseeMedkart";
import ThankYou from "../src/containers/FranchiseeMedkart/ThankYou";
import EcommerceHomepage from "../src/containers/EcommerceHomePage/EcommerceHomePage";
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy';
import ReturnPolicy from './containers/ReturnPolicy/ReturnPolicy';
import Genericawareness from './containers/genericawareness/genericawareness.jsx';
import TermsConditions from "./containers/Terms & Conditions/TermsConditions";
import Scheme from "./containers/Scheme/Scheme";
import TabBar from "./containers/TabMainScreen/TabBar";
import Cashfree from "./containers/Cashfree/Cashfree.jsx";
export function appUser() {
    let json = JSON.parse(localStorage.getItem("user") || "{}")
    return new User(json)
}
class App extends Component {
    render() {
        return (
            <BrowserRouter>
                {/* <Route exact path="/" name="Main" component={Header} /> */}
                <Route exact path="/" name="HomePage" component={HomePage} />
                <Route exact path="/homepage" name="HomePage" component={HomePage} />
                <Route exact path="/campaign" name="Campaign" component={Campaign} />
                <Route exact path="/login" name="Login" component={Login} />
                <Route exact path="/signup" name="Signup" component={Signup} />
                <Route exact path="/editprofile" name="EditProfile" component={EditProfile} />
                <Route exact path="/changepassword" name="ChangePassword" component={ChangePassword} />
                <Route exact path="/forgotpassword" name="ForgotPassword" component={ForgotPassword} />
                <Route exact path="/verifyotp" name="VerifyOTP" component={VerifyOTP} />
                <Route exact path="/medicinedetail" name="MedicineDetail" component={MedicineDetail} />
                <Route exact path="/calltoorder" name="CallToOrder" component={CallToOrder} />
                <Route exact path="/myorder" name="MyOrder" component={MyOrder} />
                <Route exact path="/refill" name="Refill" component={Refill} />
                <Route exact path="/stores" name="Stores" component={Stores} />
                <Route exact path="/ahmedabadstores" name="Stores" component={AhmedabadStores} />
                <Route exact path="/vadodarastores" name="Stores" component={VadodaraStores} />
                <Route exact path="/compare" name="Compare" component={Compare} />
                <Route exact path="/whygeneric" name="WhyGeneric" component={WhyGeneric} />
                <Route exact path="/cart" name="Cart" component={Cart} />
                <Route exact path="/uploadprescription" name="UploadPrescription" component={UploadPrescription} />
                <Route exact path="/prescriptionUpload" name="uploadPrescriptionDialog" component={UploadPrescriptionDialog} />
                <Route exact path="/patient" name="Patient" component={Patient} />
                <Route exact path="/addresses" name="Addresses" component={Addresses} />
                <Route exact path="/orderreview" name="OrderReview" component={OrderReview} />
                <Route exact path="/orderconfirmation" name="OrderConfirmation" component={OrderConfirmation} />
                <Route exact path="/payments" name="Payments" component={Payments} />
                <Route exact path="/notifications" name="Notifications" component={Notifications} />
                {/* <Route exact path="/medicineDetail" name="MedicineDetail" component={MedicineDetail} /> */}
                {/* <Route exact path="/footer" name="Footer" component={Footer} /> */}
                {/* <Route exact path="/websitehomepage" name="WebsiteHomePage" component={WebsiteHomePage} />*/}
                <Route exact path="/joinus" name="JoinUs" component={JoinUs} />
                <Route exact path="/oldprescription" name="OldPrescription" component={OldPrescription} />
                <Route exact path="/testimonials" name="Testimonials" component={Testimonials} />
                <Route exact path="/Cashfree" name="Cashfree" component={Cashfree} />
                <Route exact path="/whymedkart" name="WhyMedkart" component={WhyMedkart} />
                <Route exact path="/aboutus" name="AboutUs" component={AboutUs} />
                <Route exact path="/contactmedkart" name="ContactMedkart" component={ContactMedkart} />
                <Route exact path="/franchiseemedkart" name="FranchiseeMedkart" component={FranchiseeMedkart} />
                {/* <Route exact path="/genericawareness" name="genericawareness" component={Genericawareness} /> */}
                {/* <Route exact path="/genericawareness" name="genericawareness" component={Genericawareness} /> */}
                <Route exact path="/ecommercehomepage" name="EcommerceHomepage" component={EcommerceHomepage} />
                <Route exact path="/thankyou" name="ThankYou" component={ThankYou} />
                <Route exact path="/privacypolicy" name="PrivacyPolicy" component={PrivacyPolicy} />
                <Route exact path="/returnpolicy" name="ReturnPolicy" component={ReturnPolicy} />
                <Route exact path="/termsconditions" name="TermsConditions" component={TermsConditions} />
                <Route exact path="/scheme" name="Scheme" component={Scheme} />
                <Route exact path="/tabbar" name="TabBar" component={TabBar} />

            </BrowserRouter>
        );
    }
}
export default App;
