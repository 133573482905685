import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const BottomMenuStyle = makeStyles(theme => ({
    mainView: {
        width: '100%',
        zIndex: 300,
        top: '50px',
        backgroundColor: '#ffffff',
        position: 'fixed',
    },
    buttonstyleMenu: {
        fontFamily: 'Nunito Sans', 
        fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: '#262628',
    },
    IconStyleMenu: {
        color: '#39A749',
        fontSize: AppConstants.FONTSIZE.FS20,
        marginRight: '10px',
    },
    

}));

export default BottomMenuStyle;
