/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cartStyle from './CartStyle';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { ToastsStore } from 'react-toasts';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CompareArrowsRoundedIcon from '@material-ui/icons/CompareArrowsRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
// import DownArrow from '@material-ui/icons/DownArrow';
import DownArrow from '@material-ui/icons/ArrowDropDownCircle';
import InfoIcon from '@material-ui/icons/Info';
import RemoveIcon from '@material-ui/icons/Remove';
import { Grid, CssBaseline, Typography, IconButton, TextField, Tooltip, CircularProgress, Card } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import Title from "../../components/Header/Title";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import images from '../../common/Images';
import Tour from 'reactour'
import { bounce } from 'react-animations';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import { StyleSheet, css } from 'aphrodite';
import Promocode from "./Promocode";

const styles = StyleSheet.create({
    bounce: {
        animationName: bounce,
        animationDuration: '2s'
    }
})

class Cart extends Component {
    constructor() {
        super();
        this.state = {
            //for Coupon Code
            Isdisabled: true,
            CartType: 1,
            isTourOpen: false,
            promoCodeLoding: false,
            promoDeleteLoading: false,
            PromocodeIsValid: false,
            PromocodeModal: false,
            Promocode: '',
            coupanCodemessage: '',
            cardAnimations: false,
            suggestedMedicineID: '',
            cart: parseInt(localStorage.getItem('cartvalue')),
            count: 1,
            medicineListData: [],
            open: false,
            isLoading: true,
            hide: true,
            selectedItem: 0,
            CartTotal: 0,
            SuggestedCartTotal: 0,
            MedKartDiscount: 0,
            SuggestedMedKartDiscount: 0,
            CartTotalSalePrice: 0,
            MedKartDiscountPercentage: 5,
            DeliveryCharges: 0,
            DeliveryChargeAfter: 0,
            DiscountPercentage: 20,
            GrossTotal: 0,
            CouponDiscount: 0,
            SuggestedGrossTotal: 0,
            sort: 0,
            overallstatus: 0,
            startedBCategory: 0,
            selectedindex: 1,
            FlatlistData: [],
            pictures: [],
            image64: '',
            medicinequantity: [],
            PromoCodeModel: false,
            mediumBrowser: false,
            isLoadingplus: false,
            isLoadingminus: false,
            EmailAddress: '',
            IsPageLoad: true,

        }
    }
    closeTour = () => {
        this.setState({ isTourOpen: false })
        localStorage.setItem('WalktTrough', '3')
    }



    wsGetCart() {
        const PinCode = localStorage.getItem('pincode');
        APIController.GetCart('1', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), PinCode, '1').then((response) => {
            if (response.error == null) {
                if (response.object.length != 0 && response.object[0].couponcode != '') {
                    this.setState({ Promocode: response.object[0].couponcode, PromocodeIsValid: true, coupanCodemessage: 'Coupon Code Applied Successfully' })
                }
                this.wsGetCartInner(response.object);
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }
    wsSendCart(emailaddress) {
        APIController.SendCart(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), emailaddress).then((response) => {
            if (response.error == null) {
                if (response.object.status == 1) {
                    ToastsStore.success(response.object.message)
                }
                else {
                    ToastsStore.warning(response.object.message)
                }
            }
            else {
                ToastsStore.warning(response.object.message)
            }
        })
    }

    CalculateCartTotal(Data) {
        var SubTotal = 0;
        var SuggestedSubTotal = 0;
        var Discount = 0;
        var SuggestedDiscount = 0;
        var CouponDiscount = 0;
        var CartTotalSalePrice = 0;
        var minimumordervalue = 0;
        Data.map(function (item) {
            SubTotal = SubTotal + (item.quantity * item.mrp);
            Discount = Discount + (item.quantity * (item.mrp - item.saleprice));
            CartTotalSalePrice = (SubTotal.toFixed(2) - Discount.toFixed(2))
            minimumordervalue = item.minimumordervalue;
            SuggestedSubTotal = SuggestedSubTotal + (item.suggestedquantity * item.suggestedmarp);
            SuggestedDiscount = SuggestedDiscount + (item.suggestedquantity * (item.suggestedmarp - item.suggestedsaleprice));
            CouponDiscount = SubTotal * item.discountpercentage / 100 > item.maximumdiscount ? item.maximumdiscount : SubTotal * item.discountpercentage / 100
        });
        // (SubTotal - Discount) < global.deliverychargesafter ? this.setState({ DeliveryCharges: global.deliverycharges }) : this.setState({ DeliveryCharges: 0, })
        if (CartTotalSalePrice <= minimumordervalue) {//add because remove cart when quntity remove or delete medicine
            this.WsPromocodeDelete()
        }
        this.setState({
            CartTotal: SubTotal.toFixed(2),
            SuggestedCartTotal: SuggestedSubTotal.toFixed(2),
            MedKartDiscount: (Discount).toFixed(2),
            CartTotalSalePrice: CartTotalSalePrice,//add because remove cart when quntity remove or delete medicine
            SuggestedMedKartDiscount: (SuggestedDiscount).toFixed(2),
            FlatlistData: Data,
            GrossTotal: Math.round((SubTotal - Discount - CouponDiscount + this.state.DeliveryCharges).toFixed(2)),
            SuggestedGrossTotal: Math.round((SuggestedSubTotal - SuggestedDiscount - CouponDiscount + this.state.DeliveryCharges).toFixed(2)),
            CouponDiscount: (CouponDiscount).toFixed(2),
            isLoading: false,
            IsPageLoad: false
        });


    }

    wsGetCartInner(responseJson) {
        localStorage.setItem('cartvalue', JSON.stringify(responseJson.length))
        this.setState({ cart: responseJson.length })
        if (responseJson.length != 0) {
            this.setState({ DeliveryCharges: parseInt(responseJson[0].deliverycharges) })
            localStorage.setItem('deliverycharges', parseInt(responseJson[0].deliverycharges))
            this.CalculateCartTotal(responseJson);
            // global.deliverycharges = this.state.DeliveryCharges
            // const data1 = responseJson.filter(function (item) {
            //     return item.category == 'A'
            // });
            // category = data1.length;
            // localStorage.setItem('cartvalue', JSON.stringify(responseJson.length))
            // this.setState({ cart: responseJson.length })
        }

        else {
            this.setState({ FlatlistData: [], isLoading: false, DeliveryCharges: 0, IsPageLoad: false });
        }
    }
    medicinequantity() {
        const quantitycount = [];
        let i = 1;
        for (i = 1; i <= 100; i++) {
            quantitycount.push({ "medicinequantity": i });
        }
        this.setState({ medicinequantity: quantitycount })

    }

    wsDeleteCart(item) {
        const PinCode = localStorage.getItem('pincode');
        APIController.RemoveCart(2, AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), PinCode, 1, item.quantity, item.medicineid, item.id, item.id,)
            .then((responseJson) => {
                this.wsGetCartInner(responseJson.object);
            })

    }
    wsMinusCart(item, index) {
        this.setState({ isLoadingminus: true, selectedindex: index })
        const PinCode = localStorage.getItem('pincode');
        APIController.UpdateCart(
            item.quantity != 0 ? 1 : 2,
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode,
            1,
            item.quantity - 1,
            item.medicineid,
            item.id,
            item.id,
        ).then((responseJson) => {
            this.wsGetCartInner(responseJson.object);
            this.setState({ isLoadingminus: false })
        })

    }
    wsPlusCart(item, index) {
        this.setState({ isLoadingplus: true, selectedindex: index })
        const PinCode = localStorage.getItem('pincode');
        APIController.UpdateCart(
            item.quantity != 0 ? 1 : 2,
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode,
            1,
            item.quantity + 1,
            item.medicineid,
            item.id,
            item.id,
        ).then((responseJson) => {
            this.wsGetCartInner(responseJson.object);
            this.setState({ isLoadingplus: false })
        })
    }

    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetCart()
        this.medicinequantity()
        if (localStorage.getItem('WalktTrough') == null || localStorage.getItem('WalktTrough') == '1') {
            this.setState({ isTourOpen: true })
        }
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 750 })
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    handleChange = (e) => {
        if (e.target.value != '') {
            this.setState({ [e.target.name]: e.target.value, });
        }
        else {
            this.setState({ [e.target.name]: e.target.value, });
        }

    }
    handleChangePromocode = (e) => {
        if (e.target.value != '') {
            this.setState({ [e.target.name]: e.target.value, Isdisabled: false });
        }
        else {
            this.setState({ [e.target.name]: e.target.value, Isdisabled: true });
        }

    }

    WsPromocodeoffer(codeID, Code) {
        this.setState({ promoCodeLoding: true });
        APIController.ApplyCouponCode(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), codeID, localStorage.getItem('pincode'))
            .then((responseJson) => {
                this.setState({ promoCodeLoding: false, PromoCodeModel: false, });
                if (responseJson.object.status == '1') {
                    this.wsGetCartInner(responseJson.object.data);
                    this.setState({ PromocodeIsValid: true, coupanCodemessage: responseJson.object.message, Isdisabled: true, Promocode: Code });
                    ToastsStore.warning(responseJson.object.message)
                } else {
                    this.setState({ Promocode: '', Isdisabled: true });
                    { responseJson.object.message && ToastsStore.warning(responseJson.object.message) }
                }
            })
    }

    WsPromocodeDelete() {
        this.setState({ promoDeleteLoading: true })
        APIController.RemoveCouponCode(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), localStorage.getItem('pincode'))
            .then((responseJson) => {
                this.setState({ promoDeleteLoading: false });
                if (responseJson.object.status == '1') {
                    this.wsGetCartInner(responseJson.object.data);
                    ToastsStore.warning(responseJson.object.message)
                    this.setState({ PromoCodeModel: false, PromocodeIsValid: false, Promocode: '', Isdisabled: true });
                } else {
                    this.setState({ PromocodeIsValid: false, Promocode: '', Isdisabled: true, FlatlistData: [], medicineListData: [] });
                    //when cart Empty
                    localStorage.setItem('cartvalue', JSON.stringify(0))
                    this.setState({ cart: 0 })
                    ToastsStore.warning(responseJson.object.message)
                }
            })
    }

    renderMainContent() {
        const { classes } = this.props
        const { mediumBrowser, FlatlistData, CouponDiscount, CartTotal, MedKartDiscount, DeliveryCharges, GrossTotal, SuggestedCartTotal, SuggestedMedKartDiscount, SuggestedGrossTotal } = this.state
        return (
            <Grid style={{ width: '100%', }} container direction={'row'} justify={'space-around'}>
                <div style={{ width: '52%', marginLeft: '1%' }}>
                    <Card elevation={2} raised={false} style={{ width: '87%' }}>
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>
                            <Grid style={{ width: '50%' }}>
                                <Typography className={classes.selectiontxt}>{'YOUR ORDER'}</Typography>
                            </Grid>
                            <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ width: '50%' }}>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + CartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + MedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'CouponDiscount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + CouponDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Delivery Charges'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + DeliveryCharges}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} >{'₹ ' + GrossTotal}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    {this.renderCartContent()}
                </div>
                <div style={{ width: '46%', marginRight: '1%' }}>
                    <Card elevation={2} raised={false} style={{ width: '100%', backgroundColor: '#bbffbb' }}  >
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>
                            <Grid style={{ width: '50%' }}>
                                <Typography className={classes.selectiontxt} >{'OUR SUGGESTIONS'}</Typography>
                            </Grid>
                            <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ width: '50%' }}>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedCartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedMedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'CouponDiscount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + CouponDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Delivery Charges'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + DeliveryCharges}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} style={{ color: '#000' }} >{'₹ ' + SuggestedGrossTotal}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    {this.renderOurCartContent()}
                </div>
            </Grid>
        )
    }
    OnPresscartTab(val) {
        this.setState({ CartType: val })
    }
    rendermobileView() {
        const { classes } = this.props
        const { mediumBrowser, CartType, FlatlistData, CartTotal, MedKartDiscount, DeliveryCharges, GrossTotal, SuggestedCartTotal, SuggestedMedKartDiscount, SuggestedGrossTotal } = this.state
        return (
            <Grid container xl={12} justify={'space-between'} md={12} sm={12} xs={12} >
                <Grid container xl={6} md={6} sm={6} xs={6} >
                    <Card elevation={CartType == 1 ? '1' : '0'} onClick={() => this.OnPresscartTab(1)} style={{ marginBottom: '8px', width: '98%' }}>
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>
                            <Grid container direction={'row'} justify={'space-between'} alignItems="center" >
                                <Typography className={mediumBrowser ? classes.Mobileselectiontxt : classes.selectiontxt}>{'YOUR ORDER'}</Typography>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + CartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + MedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Delivery Charges'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + DeliveryCharges}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} >{'₹ ' + GrossTotal}</Typography>
                                </Grid>


                            </Grid>
                        </Grid>



                    </Card>
                </Grid>
                <Grid container xl={6} md={6} sm={6} xs={6} >
                    <Card elevation={CartType == 2 ? '1' : '0'} onClick={() => this.OnPresscartTab(2)} raised={false} style={{ width: '98%', marginBottom: '8px', backgroundColor: '#bbffbb', position: 'relative' }}  >
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>

                            <Grid container direction={'row'} justify={'space-between'} alignItems="center">
                                <Typography className={mediumBrowser ? classes.Mobileselectiontxt : classes.selectiontxt} >{'OUR SUGGESTIONS'}</Typography>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedCartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedMedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Delivery Charges'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + DeliveryCharges}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} style={{ color: '#000' }} >{'₹ ' + SuggestedGrossTotal}</Typography>
                                </Grid>

                                <DownArrow style={{ zIndex: 500, left: '48%', bottom: 0, position: 'absolute', color: '#39A749', }} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {this.state.CartType == 1 ? this.renderCartContent() : this.renderOurCartContent()}
            </Grid>
        )
    }
    navigatetoDetail(item, medicinId) {
        this.props.history.push({
            pathname: '/medicinedetail',
            state: {
                id: medicinId == 0 ? item.medicineid : item.suggestedid,
                compositionid: item.compositionid,
                quantityOption: this.state.medicinequantity,
                medicinequantity: item.quantity
            }
        })
    }

    handelTranferCart(Item, Index) {
        this.wsDeleteCart(Item)

        this.setState({ isLoading: true, selectedindex: Index, suggestedMedicineID: Item.suggestedid })

        APIController.AddToCart(
            Item.quantity != 0 ? 1 : 2,//opraion Id 1 for add, 2 for delete
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            localStorage.getItem('pincode'),
            1,//get data
            Item.quantity,
            Item.suggestedid,
            Item.id //id
        ).then((responseJson) => {
            this.wsGetCartInner(responseJson.object.data);
            this.setState({ isLoading: false, cardAnimations: true })
        })
    }

    renderCartContent() {
        const { classes } = this.props
        const { mediumBrowser, cardAnimations, FlatlistData, selectedindex, isLoadingplus, isLoadingminus, suggestedMedicineID, } = this.state
        return (
            <Grid item xl={12} md={12} sm={12} xs={12} >
                {
                    FlatlistData.map((item, index) =>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={1} raised={false} style={{ padding: '1%', margin: "1% 0", width: mediumBrowser ? '100%' : '87%' }} className={cardAnimations && suggestedMedicineID == item.medicineid ? css(styles.bounce) : ''}>
                                <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>

                                    <Grid onClick={() => this.navigatetoDetail(item, 0)} container xl={12} md={12} style={{ cursor: 'pointer' }} sm={12} xs={12} >
                                        <Grid alignItems={'center'} container xl={12} md={12} sm={12} xs={12} >
                                            <img src={item.medicinetypename == "TABLET" ?
                                                images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                    images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                        images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                            images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                                images.Icon_tube : images.Icon_pill}
                                                style={{ height: '20px', width: '20px' }} />
                                            &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle}>{item.medicinename}</Typography>
                                            {item.companyname != null && item.companyname != '' && <>
                                                &nbsp; <Typography style={{}} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                                &nbsp;<Typography style={{}} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                            </>}
                                        </Grid>
                                        {/* <Grid container xl={6} md={6} sm={6} xs={6} >
                                            <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'By: '}</Typography>
                                            &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '95%', marginLeft: '5%' }} container direction={'row'} justify={'space-between'}>
                                    <Grid style={{ width: '50%' }} container direction={'row'} justify={'space-between'}>
                                        <Grid style={{ width: '40%', }} container direction={'row'} justify={'center'} alignItems={'center'}>
                                            <Typography className={classes.qntytxt}>{'₹ ' + item.saleprice + ' x'}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '59%' }} alignItems={'center'} container direction={'row'} justify={'space-evenly'}>
                                            <IconButton style={{ padding: "0px", backgroundColor: '#ACACAC' }}
                                                disabled={item.allowedit ? false : true}
                                                onClick={() => { if (item.quantity > 1) { this.wsMinusCart(item, index); } }} >
                                                {item.allowedit == false ? <Grid /> : isLoadingminus == true && selectedindex == index ? <CircularProgress size={20} /> : <RemoveIcon style={{ color: '#000' }} />}
                                            </IconButton>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#282628', }}>{item.quantity}</Typography>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#282628', }}>({(item.packsize) * (item.quantity).toFixed(2)})</Typography>
                                            <IconButton style={{ padding: "0px", backgroundColor: '#ACACAC' }}
                                                disabled={item.allowedit ? false : true}
                                                onClick={() => { this.wsPlusCart(item, index) }}>
                                                {item.allowedit == false ? <Grid /> : isLoadingplus == true && selectedindex == index ? <CircularProgress size={20} /> : <AddIcon style={{ color: '#000' }} />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '50%' }} container direction={'row'} justify={'space-between'}>
                                        <Grid style={{ width: '75%', }} container alignItems={'center'} direction={'row'} justify={'space-evenly'}>
                                            <Typography style={{ textDecorationLine: 'line-through', fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: 'red' }}>
                                                {'₹ ' + ((item.mrp).toFixed(2) * (item.quantity).toFixed(2)).toFixed(2)}</Typography>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#39A749' }}>
                                                {'₹ ' + ((item.saleprice).toFixed(2) * (item.quantity).toFixed(2)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ width: '25%' }} alignItems={'center'} container direction={'row'} justify={'space-evenly'}>
                                            {item.allowedit &&
                                                <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                                                    <IconButton style={{ padding: "4px" }} onClick={() => { this.wsDeleteCart(item) }}>
                                                        <DeleteIcon style={{ color: '#BEBEBE' }} />
                                                    </IconButton>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                    <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                    &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                </Grid> */}
                            </Card>

                            {!mediumBrowser && item.medicineid != item.suggestedid &&
                                <div className={'second-step'} style={mediumBrowser ? { alignSelf: 'center', marginLeft: '2.5%' } : { alignSelf: 'center', marginLeft: '5%' }}>
                                    <CompareArrowsRoundedIcon style={{ backgroundColor: '#39A749', borderRadius: '50%', height: '30px', width: '30px', color: '#FFF', padding: '2px', cursor: 'pointer' }}
                                        onClick={() => { this.handelTranferCart(item, index) }} />
                                </div>}
                        </div>
                    )
                }
            </Grid>
        )
    }

    renderOurCartContent() {
        const { classes } = this.props
        const { FlatlistData, mediumBrowser, selectedindex, isLoadingplus, isLoadingminus } = this.state
        return (
            <div style={{ width: '100%' }}>
                {
                    FlatlistData.map((item, index) =>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={1} raised={false} container direction="row" justify="center" alignItems="baseline" style={{ padding: '1%', margin: "1.2% 0", width: '100%', backgroundColor: '#bbffbb' }}>
                                <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                                    {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                        <img src={item.medicinetypename == "TABLET" ?
                                            images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                    images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                        images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                            images.Icon_tube : images.Icon_pill}
                                            style={{ height: '5%', width: '5%' }} />
                                        &nbsp;&nbsp;&nbsp;<Typography onClick={() => this.navigatetoDetail(item, 1)} className={classes.Medicinetitle}>{item.suggestedname}</Typography>
                                        &nbsp;&nbsp;&nbsp;
                                        {item.packsize != item.suggestedpacksize ?
                                            <Tooltip title="Different Pack Size" placement="right">
                                                <IconButton>
                                                    <InfoIcon style={{ color: 'black', fontSize: '15' }} title="Add" placement="bottom-start" />
                                                </IconButton>
                                            </Tooltip> : null}
                                        {mediumBrowser && item.medicineid != item.suggestedid &&
                                            <CompareArrowsRoundedIcon className={'second-step'} style={{ position: 'absolute', right: '30px', backgroundColor: '#39A749', borderRadius: '50%', height: '20px', width: '20px', color: '#FFF', padding: '2px', cursor: 'pointer' }}
                                                onClick={() => { this.handelTranferCart(item, index) }} />}
                                    </Grid> */}

                                </Grid>

                                <Grid container xl={12} md={12} style={{ cursor: 'pointer' }} sm={12} xs={12} >
                                    <Grid onClick={() => this.navigatetoDetail(item, 1)} alignItems={'center'} container xl={12} md={12} sm={12} xs={12} >
                                        <img src={item.medicinetypename == "TABLET" ?
                                            images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                    images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                        images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                            images.Icon_tube : images.Icon_pill}
                                            style={{ height: '20px', width: '20px' }} />
                                        &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle}>{item.suggestedname}</Typography>
                                        {item.packsize != item.suggestedpacksize ?
                                            <Tooltip title="Different Pack Size" placement="right">
                                                <IconButton>
                                                    <InfoIcon style={{ color: 'black', fontSize: '15' }} title="Add" placement="bottom-start" />
                                                </IconButton>
                                            </Tooltip> : null}
                                        {item.companyname != null && item.companyname != '' && <>
                                            &nbsp; <Typography className={classes.Medicinetitle}>{' ( By:'}</Typography>
                                            <Typography className={classes.Medicinetitle}>{item.companyname + ' ) '}</Typography>
                                        </>}
                                        {mediumBrowser && item.medicineid != item.suggestedid &&
                                            <CompareArrowsRoundedIcon className={'second-step'} style={{ position: 'absolute', right: '30px', backgroundColor: '#39A749', borderRadius: '50%', height: '20px', width: '20px', color: '#FFF', padding: '2px', cursor: 'pointer' }}
                                                onClick={() => { this.handelTranferCart(item, index) }} />}
                                    </Grid>
                                    {/* <Grid container xl={6} md={6} sm={6} xs={6} >
                                        {item.packsize != item.suggestedpacksize ?
                                            <Tooltip title="Different Pack Size" placement="right">
                                                <IconButton>
                                                    <InfoIcon style={{ color: 'black', fontSize: '15' }} title="Add" placement="bottom-start" />
                                                </IconButton>
                                            </Tooltip> : null}
                                        <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                        &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                        {mediumBrowser && item.medicineid != item.suggestedid &&
                                            <CompareArrowsRoundedIcon className={'second-step'} style={{ position: 'absolute', right: '30px', backgroundColor: '#39A749', borderRadius: '50%', height: '20px', width: '20px', color: '#FFF', padding: '2px', cursor: 'pointer' }}
                                                onClick={() => { this.handelTranferCart(item, index) }} />}
                                    </Grid> */}

                                </Grid>
                                <Grid style={{ width: '95%', marginLeft: '5%' }} container direction={'row'} justify={'space-between'}>
                                    <Grid style={{ width: '50%' }} container direction={'row'} justify={'space-between'}>
                                        <Grid style={{ width: '40%', }} container direction={'row'} justify={'center'} alignItems={'center'}>
                                            <Typography className={classes.qntytxt}>{'₹ ' + item.suggestedsaleprice + ' x'}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '59%' }} alignItems={'center'} container direction={'row'} justify={'space-evenly'}>
                                            {item.suggestedpacksize == item.packsize &&
                                                <IconButton style={{ padding: "0px", backgroundColor: '#ACACAC' }}
                                                    disabled={item.allowedit ? false : true}
                                                    onClick={() => { if (item.suggestedquantity > 1) { this.wsMinusCart(item, index); } }} >
                                                    {item.allowedit == false ? <Grid /> : isLoadingminus == true && selectedindex == index ? <CircularProgress size={20} /> : <RemoveIcon style={{ color: '#000' }} />}
                                                </IconButton>}
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#282628', }}> {item.suggestedquantity} </Typography>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#282628', }}> ({(item.suggestedpacksize) * (item.suggestedquantity).toFixed(2)}) </Typography>
                                            {item.suggestedpacksize == item.packsize &&
                                                <IconButton style={{ padding: "0px", backgroundColor: '#ACACAC' }}
                                                    disabled={item.allowedit ? false : true}
                                                    onClick={() => { this.wsPlusCart(item, index) }}>
                                                    {item.allowedit == false ? <Grid /> : isLoadingplus == true && selectedindex == index ? <CircularProgress size={20} /> : <AddIcon style={{ color: '#000' }} />}
                                                </IconButton>}
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '50%' }} container direction={'row'} justify={'space-between'}>
                                        <Grid style={{ width: '75%', }} container alignItems={'center'} direction={'row'} justify={'space-evenly'}>
                                            <Typography style={{ textDecorationLine: 'line-through', fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: 'red' }}>
                                                {'₹ ' + ((item.suggestedmarp).toFixed(2) * (item.suggestedquantity).toFixed(2)).toFixed(2)}</Typography>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: AppConstants.FONTSIZE.FS13, lineHeight: 1.13, letterSpacing: -0.5, color: '#000' }}>
                                                {'₹ ' + ((item.suggestedsaleprice).toFixed(2) * (item.suggestedquantity).toFixed(2)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid style={{ width: '25%' }} alignItems={'center'} container direction={'row'} justify={'space-evenly'}>
                                            {item.allowedit &&
                                                <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                                                    <IconButton style={{ padding: "4px" }} onClick={() => { this.wsDeleteCart(item) }}>
                                                        <DeleteIcon style={{ color: '#BEBEBE' }} />
                                                    </IconButton>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                    <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                    &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                </Grid> */}
                            </Card>
                        </div>
                    )
                }
            </div>
        )
    }

    renderUploadPrescription() {
        const { classes } = this.props
        const { mediumBrowser, DeliveryCharges, CartTotal, MedKartDiscount, GrossTotal, FlatlistData } = this.state
        return (
            <Card raised={false} container direction="row" justify="center" alignItems="center" style={mediumBrowser ? { width: '100%', height: '95%', padding: '2%', marginTop: '20px' } : { width: '47.5%', position: 'fixed', padding: '1%' }}>
                <Card elevation='0' className={classes.ContentcardmainCart} raised={true} container alignItems={'center'} justify={'center'} direction="row" style={mediumBrowser ? { width: '98%', marginTop: '2.5%', marginBottom: '2.5%', marginLeft: '0.8%', padding: 4 } : { marginBottom: '1.5%', marginLeft: '0.8%', width: '98%', padding: 4 }}  >
                    {this.state.Promocode != '' && this.state.PromocodeIsValid == true
                        ? this.renderPromoCard()
                        : this.renderPromoCodeField()}
                </Card>
                <Grid className={classes.ContentcardmainCart} container direction={'row'} justify={'space-between'} alignItems="center">
                    <Grid style={{ width: '100%', marginBottom: 10 }} container direction={'row'} justify={'space-between'} alignItems="center">
                        <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                        <Typography className={classes.qntytxt} >{'₹ ' + CartTotal}</Typography>
                    </Grid>
                    <Grid style={{ width: '100%', marginBottom: 10 }} container direction={'row'} justify={'space-between'} alignItems="center">
                        <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                        <Typography className={classes.qntytxt} >{'₹ ' + MedKartDiscount}</Typography>
                    </Grid>
                    <Grid style={{ width: '100%', marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                        <Typography className={classes.qntytxt} >{'Delivery Charges'}</Typography>
                        <Typography className={classes.qntytxt} >{'₹ ' + DeliveryCharges}</Typography>
                    </Grid>
                    <Grid style={{ width: '100%', marginBottom: 10 }} container direction={'row'} justify={'space-between'} alignItems="center">
                        <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                        <Typography className={classes.newprice} >{'₹ ' + GrossTotal}</Typography>
                    </Grid>
                </Grid>
                <Grid className={'third-step'} style={{ padding: '1% 1% 0% 1%', marginTop: '5px' }} container direction={'row'} justify='center' alignItems="center">
                    <ButtonBase
                        startIcon={<CloudUploadIcon />}
                        disabled={FlatlistData.length == 0 || this.state.GrossTotal <= 0 ? true : false}
                        onClick={() => this.props.history.push(AppConstants.SCREENS.UPLOADPRESCRIPTION)} title={'UPLOAD PRESCRIPTION'}></ButtonBase>
                </Grid>

            </Card>
        )
    }
    renderCartEmpty() {
        const { classes } = this.props
        return (
            <Grid className={classes.ContentcardmainCart} style={{ padding: '0.8%' }} container direction={'row'} justify={'center'} alignItems="center">
                {/* <Typography className={classes.qntytxt} >{''}</Typography> */}
                <Typography style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', color: '#92929d' }}> {'Your cart is empty'}</Typography>
            </Grid>
        )
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.wsGetCartInner(data.data)

    }
    renderPromoCodeField() {
        const { classes } = this.props
        const { cart, isLoading, FlatlistData, mediumBrowser } = this.state
        return (
            <Grid container onClick={() => this.setState({ PromoCodeModel: true })} justify={'space-between'} style={{ cursor: 'pointer', width: '98%' }} direction="row" alignItems='center' >
                <Grid container direction="row" alignItems='center' style={{ cursor: 'pointer', width: '70%', paddingLeft: '2%', height: '38px', }} >
                    <LocalOfferIcon color='disabled' />
                    <Grid container justify={'space-between'} style={{ width: '80%', cursor: 'pointer', }}>
                        <TextField
                            defaultValue={this.state.Promocode}
                            disabled={true}
                            InputProps={{
                                maxLength: 20,
                                disableUnderline: true,
                            }}
                            placeholder='Apply Coupons'
                            rows={1}
                            editable={this.state.promoCodeLoding == true || this.state.PromocodeIsValid == true ? false : true}
                            style={{ fontWeight: 'bold', cursor: 'pointer', }}
                            value={(this.state.Promocode).toUpperCase()}
                            name="Promocode"
                            onChange={(e) => this.handleChangePromocode(e)}
                        />
                    </Grid>
                </Grid>
                {/* {this.state.promoCodeLoding ? <CircularProgress size={22} style={{ color: '#39A749' }} /> :
                    <IconButton onClick={() => this.WsPromocodeoffer()} disabled={this.state.Isdisabled == true ? true : false}
                        style={{ borderRadius: 0, padding: "5px" }}>
                        <Typography
                            style={{ textTransform: 'uppercase' }}
                            className={this.state.Isdisabled ? classes.disableoldprice : classes.oldprice} >
                            {'Apply'}
                        </Typography>
                    </IconButton>
                } */}
            </Grid>
        )
    }

    renderPromoCard() {
        const { classes } = this.props
        const { cart, isLoading, FlatlistData, mediumBrowser } = this.state
        return (
            <Grid container justify={'space-between'} style={{ width: '100%' }} direction="row" alignItems='center' >
                <Typography className={classes.txtcouponcode} style={{ marginLeft: '2%', textTransform: 'uppercase' }}>
                    {'Coupon Code :-'} {this.state.Promocode}
                </Typography>
                {this.state.promoDeleteLoading ? <CircularProgress size={22} style={{ color: '#39A749', margin: '1.5%' }} /> :
                    <IconButton onClick={() => this.WsPromocodeDelete()}>
                        <CancelRoundedIcon style={{ color: 'red' }} />
                    </IconButton>
                }
                <Grid container direction="row" alignItems='center' style={{ width: '100%', marginLeft: '2%' }} >
                    <CheckCircleRoundedIcon style={{ color: '#39A749' }} />
                    <Typography style={{ marginLeft: '10px' }} className={classes.txtcouponmessagecount}>
                        {this.state.coupanCodemessage}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { IsPageLoad, isLoading, FlatlistData, mediumBrowser } = this.state
        const steps = [
            {
                selector: '.second-step',
                content: 'Click Here To Change Your Medicine With Our Suggested Generic Medicine',
                style: {
                    fontSize: 18,
                },
            },
            {
                selector: '.third-step',
                content: 'To Process Ahead Please Click Here To Upload Your prescription',
                style: {
                    fontSize: 18,
                },
            },
        ]
        return (
            <Grid style={{ width: '100%' }}>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} dataFromsearch={this.dataFromsearch.bind(this)} CartCount={this.state.cart} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Cart'} history={this.props.history} />
                    {/* <Grid style={{ padding: '0 0.8% 0.4% 0.8%' }} container direction={'row'} justify='space-between' alignItems="center">
                        <Grid container style={{ width: '15%', padding: '0.7%' }} direction="row" alignItems='center'>
                            <Typography className={classes.Medicinecount}>
                                {FlatlistData.length} {'Items'}
                            </Typography>
                        </Grid>
                        <ButtonBase
                            style={mediumBrowser ? { height: '35px', width: '50%', backgroundColor: '#FFF100', color: '#39A749' } : { height: '35px', width: '10%', backgroundColor: '#FFF100', color: '#39A749' }}
                            startIcon={<CloudUploadIcon />}
                            onClick={() => this.props.history.push(AppConstants.SCREENS.SCHEME)} title={'Offers'}></ButtonBase>
                    </Grid> */}
                    {IsPageLoad ? this.LoadingView() :
                        <>
                            {FlatlistData.length == 0 ? this.renderCartEmpty() :
                                <>
                                    {mediumBrowser ? this.rendermobileView() :
                                        this.renderMainContent()}
                                </>}
                        </>}
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 0.5%' }}> */}
                    {FlatlistData.length != 0 && <Grid style={mediumBrowser ? { marginTop: '5px', } : {}} container xl={12} md={12} justify='space-between' sm={12} xs={12}>
                        <Grid first-step container direction={'row'} justify='space-between' alignItems="center"
                            style={window.innerWidth <= 1050 ? { width: '100%', } : { width: '46%' }}>
                            <Card elevation='0' className={classes.ContentcardmainCart} raised={true} alignItems={'center'} justify={'center'} direction="row"
                                style={mediumBrowser ? { height: '48px', width: '46%', border: '2px solid #d4d4d9' } : { height: '48px', marginLeft: '1%', width: '45%', border: '2px solid #d4d4d9' }}  >
                                <TextField
                                    defaultValue={this.state.EmailAddress}
                                    InputProps={{ maxLength: 50, disableUnderline: true, }}
                                    placeholder='Enter Email Address'
                                    rows={1}
                                    // editable={this.state.promoCodeLoding == true || this.state.PromocodeIsValid == true ? false : true}
                                    style={{ paddingTop: '5px', fontWeight: 'bold' }}
                                    value={this.state.EmailAddress}
                                    name="EmailAddress"
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Card>
                            <Grid style={mediumBrowser ? { width: "46.5%" } : { width: "50%" }} container direction={'row'} justify='center' alignItems="center">
                                <ButtonBase
                                    style={{ fontSize: "14px !important" }}
                                    // startIcon={<CloudUploadIcon />}
                                    // disabled={FlatlistData.length == 0 || this.state.GrossTotal <= 0 ? true : false}
                                    onClick={() => this.wsSendCart(this.state.EmailAddress)} title={'Send Email'}></ButtonBase>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} justify='space-between' alignItems="center"
                            style={window.innerWidth <= 1050 ? { width: '100%' } : { width: '47%' }}>
                            <Card elevation='0' className={classes.ContentcardmainCart} raised={true} alignItems={'center'} justify={'center'} direction="row"
                                style={mediumBrowser ? { margin: '1.5%', width: '100%', padding: 4, border: '2px solid #d4d4d9' } : { margin: '1.5%', width: '55%', padding: 4, border: '2px solid #d4d4d9' }}  >
                                {this.state.Promocode != '' && this.state.PromocodeIsValid == true ?
                                    this.renderPromoCard() :
                                    this.renderPromoCodeField()}
                            </Card>
                            <Grid className={'third-step'} style={mediumBrowser ? { width: "100%" } : { width: "40%" }} container direction={'row'} justify='center' alignItems="center">
                                <ButtonBase
                                    style={{ fontSize: "14px !important" }}
                                    startIcon={<CloudUploadIcon />}
                                    disabled={FlatlistData.length == 0 || this.state.GrossTotal <= 0 ? true : false}
                                    onClick={() => this.props.history.push(AppConstants.SCREENS.UPLOADPRESCRIPTION)} title={'UPLOAD PRESCRIPTION'}></ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>}
                    <Tour
                        rounded={5}
                        steps={steps}
                        onAfterOpen={() => this.OnPresscartTab(2)}
                        isOpen={this.state.isTourOpen}
                        onRequestClose={this.closeTour.bind(this)} />
                    <Alertdialog
                        onClose={() => this.setState({ PromoCodeModel: false })}
                        style={{ height: '700px', borderRadius: '100px' }}
                        open={this.state.PromoCodeModel}>
                        <Grid container justify='center' alignItems='center' style={{ padding: '0px', width: '100%' }}>
                            {this.state.PromoCodeModel && <Promocode WsPromocodeoffer={this.WsPromocodeoffer.bind(this)} onClose={() => this.setState({ PromoCodeModel: false })} />}
                        </Grid>

                    </Alertdialog>
                </main>
                <Footer history={this.props.history} />
            </Grid>

        );
    }
}
export default withStyles(cartStyle)(Cart);
