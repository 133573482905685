import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const CampaignStyle = theme => ({
    root: {
        Width: '100%',
    },
    header: {
        alignItems: 'center',   
    },
    IconStyle: {
        color: '#39A749',
        fontSize: AppConstants.FONTSIZE.FS20,
        margin:'10px'
    },
    slidermain:{
        height:800,
        width:'100%',
        
            slider: 'slider',
            previousButton: 'previousButton',
            nextButton: 'nextButton',
            buttonDisabled: 'disabled',
            track: 'track',
            slide: 'slide',
            hidden: 'hidden',
            previous: 'previous',
            current: 'current',
            next: 'next',
            animateIn: 'animateIn',
            animateOut: 'animateOut',
          
    },
    buttonstyle: {
        fontFamily:'Nunito Sans',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: '700',
        color: '#262628',
      
    },
   
   
    
});
const combinedStyles = combineStyles(commonStyle, CampaignStyle);
export default combinedStyles; 
