import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import addressesStyle from './AddressesStyle';
import { CssBaseline, Typography, Grid, Paper, Button, CircularProgress, RadioGroup, Radio, FormControlLabel, Checkbox, Card, } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Title from "../../components/Header/Title";
import TopMenu from "../../components/Header/TopMenu";
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';

import FilledTextField from '../../components/FilledTextField/FilledTextField';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import ButtonBase from '../../components/ButtonBase/ButtonBase';

import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";

import uuid from 'react-uuid';
import InputMask from 'react-input-mask';

import LocationIcon from '@material-ui/icons/LocationOn';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactGA from 'react-ga';

class Addresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            IsLoadingEdit: false,
            IsLoading: false,
            AddressID: '',
            PatientIDStsteAddress: '',
            IdForEdit: -1,
            dataSource: [],
            AddressPatientName: '',
            AddressLine1: '',
            AddressLine2: '',
            AddressAres: '',
            AddressCity: '',
            AddressMobileNo: '',
            AddressPinCode: '',
            AddressType: '1',
            AddressIsDefault: '1',
            loading: false,
            Fromlistpage: ''
        };
    }
    componentWillMount() {
        this.setState({
            Fromlistpage: this.props.history.location.state.Fromlistpage,
            PatientIDStsteAddress: this.props.history.location.state.PatientIdfromPatient
        })
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsAddresses();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    OnPressEditList(index, id) {
        this.WsGetAddrerssById(id, index);
    }

    WsGetAddrerssById(ID, index) {
        this.setState({ IsLoadingEdit: true })
        APIController.GetAddressById('4', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                if (response.error == null) {
                    this.SetData(response.object.data[0]);
                    this.setState({ IsLoadingEdit: false, IdForEdit: index });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    SetData(data) {
        this.setState({
            AddressPatientName: data.name,
            AddressID: data.id,
            AddressLine1: data.address1,
            AddressLine2: data.address2,
            AddressAres: data.area,
            AddressCity: data.city,
            AddressMobileNo: data.mobileno,
            AddressPinCode: data.postcode,
            AddressType: data.addresstypeid.toString(),
            AddressIsDefault: data.isdefault.toString(),
        });
    }

    blankState() {
        this.setState(
            {
                AddressPatientName: '',
                AddressLine1: '',
                AddressLine2: '',
                AddressAres: '',
                AddressCity: '',
                AddressMobileNo: '',
                AddressPinCode: '',
                AddressType: '1',
                AddressIsDefault: '1',
                IdForEdit: -1,
            }
        )
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    wsAddresses() {
        this.setState({ IsLoading: true })
        APIController.GetData('4', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSource: response.object, IsLoading: false })
                    if (response.object.length != 0) {
                        if (this.state.Fromlistpage == 0) {//oder flow
                            this.WsGetAddrerssById(response.object[0].id, 0)
                        }
                        else if (this.state.Fromlistpage == 1) { //without order flow
                            this.WsGetAddrerssById(response.object[0].id, 0)
                        }
                    }

                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    handleSubmit(addressid) {
        if (this.validateForm()) {
            this.wsAddAddress(addressid);
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.AddressPatientName.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.PATIENTNAME);
        }
        else if (this.state.AddressLine1.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.ADDRESSLINE1);
        }
        else if (this.state.AddressLine2.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.ADDRESSLINE2);
        }
        else if (this.state.AddressCity.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOCITY);
        }
        else if (this.state.AddressMobileNo === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        }
        else if (!this.state.AddressMobileNo.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDMOBILENO);
        }
        else if (this.state.AddressMobileNo.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        }
        else if (this.state.AddressPinCode === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOPINCODE);
        }
        else if (!this.state.AddressPinCode.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDPINCODE);
        }
        else if (this.state.AddressPinCode.length < 6) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.PINCODELENGTH);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsAddAddress(addressid) {
        this.setState({ loading: true })
        APIController.AddEditAddress(AppConstants.userIdFromLocal(),
            this.state.AddressMobileNo,
            addressid,
            uuid(),
            this.state.AddressPatientName, this.state.AddressLine1, this.state.AddressLine2,
            this.state.AddressAres, this.state.AddressCity, this.state.AddressPinCode, this.state.AddressType, this.state.AddressIsDefault)
            .then((response) => {
                this.setState({ loading: false });
                if (response.error == null) {
                    ToastsStore.warning(response.object.message);
                    this.wsAddresses();
                    this.blankState();
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            });
    }
    wsDeleteAddress(ID) {
        APIController.DeleteAddress(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                this.setState({ loading: false });
                if (response.error == null) {
                    this.wsAddresses();
                    this.blankState();
                    ToastsStore.warning(response.object[0].message);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    renderAddressList() {
        const { IdForEdit, dataSource, IsLoading, Fromlistpage, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', height: '97%', padding: '2%', }}>
                {
                    IsLoading ? this.LoadingView() :
                        <Scrollbars autoHide autoHeight autoHeightMax='500px'>
                            {
                                dataSource.map((item, index) =>
                                    <Grid container direction='row' justify='space-between' alignItems='flex-start' onClick={() => this.OnPressEditList(index, item.id)} className={IdForEdit == index ? classes.ContentSelectcardmainAddress : classes.ContentcardmainAddress} style={{ cursor: 'pointer', textAlign: 'left' }}>
                                        <Grid container justify={window.innerWidth <= 420 ? 'flex-start' : 'center'} alignItems='flex-start' style={window.innerWidth <= 420 ? { width: '10%', marginTop: '2%' } : window.innerWidth <= 920 ? { width: '8%', marginTop: '1.3%' } : { width: '5%', marginTop: '0.8%', }}>
                                            {IdForEdit == index ? <CheckBoxIcon style={mediumBrowser ? { color: '#39A749', } : { color: '#39A749', }} /> : <CheckBoxOutlineBlankIcon style={mediumBrowser ? { color: '#9B9B9B', } : { color: '#9B9B9B', }} />}
                                        </Grid>
                                        <Grid container
                                            direction='column'
                                            style={window.innerWidth <= 420 ? { width: '70%' } : window.innerWidth <= 920 ? { width: '70%', } : { width: '80%', }} >
                                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '24px', fontFamily: 'Nunito Sans', }}>{item.name} </Typography>
                                            <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{item.address1}, {item.address2},  </Typography>
                                            <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{item.area}, {item.city}, {item.postcode} </Typography>
                                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {item.mobileno} </Typography>
                                        </Grid>
                                        <Grid container
                                            direction='column'
                                            justify='flex-start'
                                            alignItems='flex-end'
                                            style={mediumBrowser ? { width: '20%', } : { width: '15%', }}>
                                            {/* <RadioGroup row>
                                            <FormControlLabel
                                                control={<Radio
                                                    color="primary"
                                                    value={index}
                                                    name='selectAddress'
                                                    onChange={this.handleChange} />}
                                                labelPlacement="end" />
                                        </RadioGroup> */}
                                            <Typography style={item.addresstypeid == 1 ?
                                                {
                                                    color: '#E1E4EB',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Nunito Sans',
                                                    backgroundColor: '#4A90E2',
                                                    // width: '75%',
                                                    width: window.innerWidth <= 420 ? '100%' : '75%',
                                                    marginTop: '10px',
                                                    borderRadius: '50px 50px 50px 50px',
                                                    textAlign: 'center',
                                                } : item.addresstypeid == 2 ? {
                                                    color: '#E1E4EB',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Nunito Sans',
                                                    backgroundColor: '#E2634A',
                                                    width: window.innerWidth <= 420 ? '100%' : '75%',
                                                    marginTop: '10px',
                                                    borderRadius: '50px 50px 50px 50px',
                                                    textAlign: 'center',
                                                } : {
                                                    color: '#E1E4EB',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Nunito Sans',
                                                    backgroundColor: '#ECB32F',
                                                    width: window.innerWidth <= 420 ? '100%' : '75%',
                                                    marginTop: '10px',
                                                    borderRadius: '50px 50px 50px 50px',
                                                    textAlign: 'center',
                                                }
                                            }>{item.addresstypeid == 1 ? 'Home' : item.addresstypeid == 2 ? "Work" : "Other"} </Typography>
                                        </Grid>
                                    </Grid>
                                    // </Card>
                                )
                            }
                        </Scrollbars>
                }
                <Grid container direction='row' style={mediumBrowser ? { width: '94%', marginLeft: '10px', marginBottom: '25px' } : { width: '100%', marginBottom: '20px' }} alignItems='flex-start' justify='flex-start'>
                    {Fromlistpage == '1' ? <div /> : <ButtonBase title='REVIEW ORDER'
                        disabled={IdForEdit == -1 ? true : false}
                        onClick={() => {
                            this.props.history.push({
                                pathname: '/orderreview',
                                state: { PatientIdfromAddress: this.state.PatientIDStsteAddress, AddressIdfromAddress: this.state.AddressID }
                            });
                        }}
                        style={mediumBrowser ? { width: '100%', marginTop: '10px', padding: '2%' } : { width: '40%', marginLeft: '10px', marginTop: '10px' }} />
                    }
                </Grid>
            </Card>
        )
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSelectChange = (e) => {
        this.setState({ AddressType: e.target.value });
    }
    handleisdefaulutChange = (e) => {
        this.setState({ AddressIsDefault: e.target.value });
    }

    renderaddAddress() {
        const { classes } = this.props;
        const { loading, IsLoadingEdit, AddressPatientName, AddressLine1, AddressLine2, AddressAres, AddressMobileNo, AddressCity, AddressPinCode, mediumBrowser } = this.state;
        return (
            <Grid container direction="row" style={{ width: '100%', }} >
                {IsLoadingEdit && <Grid container direction='row' style={{ width: '100%', height: '60px', }} >
                    <CircularProgress />
                </Grid>}
                <form noValidate style={{ width: '100%' }}>
                    <Card raised={true} container direction="row" style={mediumBrowser ? { width: '100%', height: '97%', padding: '4%', marginTop: '1%' } : { width: '100%', height: '97%', padding: '4%', }}>
                        <FilledTextFieldUpdated
                            defaultValue={this.state.AddressPatientName}
                            label='* Patient Name'
                            rows={1}
                            value={AddressPatientName}
                            name="AddressPatientName"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />

                        <FilledTextFieldUpdated
                            label='* Address Line 1'
                            rows={1}
                            value={AddressLine1}
                            name="AddressLine1"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />

                        <FilledTextFieldUpdated
                            label='* Address Line 2'
                            rows={1}
                            value={AddressLine2}
                            name="AddressLine2"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='  Area'
                            rows={1}
                            value={AddressAres}
                            name="AddressAres"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='* City'
                            rows={1}
                            value={AddressCity}
                            name="AddressCity"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />

                        <FilledTextFieldUpdated
                            label='* Mobile Number'
                            name="AddressMobileNo"
                            value={AddressMobileNo}
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }}
                            InputProps={{
                                className: classes.inputMask_Field,
                                disableUnderline: true,
                            }}
                            inputProps={{
                                maxLength: 10
                            }}
                        >
                        </FilledTextFieldUpdated>

                        {/* <InputMask
                            mask="9999999999"
                            maskChar=''
                            value={AddressMobileNo}
                            onChange={this.handleChange}
                        >
                            {() => <FilledTextField
                                label='* Mobile Number'
                                name="AddressMobileNo"
                                style={{ marginBottom: '10px' }} />}
                        </InputMask> */}

                        <FilledTextFieldUpdated
                            label='* Pin Code'
                            name="AddressPinCode"
                            style={{ marginBottom: '10px' }}
                            value={AddressPinCode}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 6
                            }}
                            InputProps={{
                                className: classes.inputMask_Field,
                                disableUnderline: true,
                            }}>
                        </FilledTextFieldUpdated>
                        {/* <InputMask
                            mask="999999"
                            maskChar=''
                            value={AddressPinCode}
                            onChange={this.handleChange}
                        >
                            {() => <FilledTextField
                                label='* Pin Code'
                                name="AddressPinCode"
                                style={{ marginBottom: '10px' }} />
                            }</InputMask> */}
                        <Grid container direction='column' style={{ marginLeft: '1%', textAlign: 'left' }}>
                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Address Type</Typography>
                            <RadioGroup row
                                value={this.state.AddressType}>
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value="1"
                                        name='AddressType'
                                        onChange={this.handleSelectChange} />}
                                    label="Home"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value="2"
                                        name='AddressType'
                                        onChange={this.handleSelectChange} />}
                                    label="Work"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value="3"
                                        name='AddressType'
                                        onChange={this.handleSelectChange} />}
                                    label="Others"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Grid>
                        <Grid container direction='column' style={{ marginLeft: '1%', textAlign: 'left' }}>
                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Is Default Address</Typography>
                            <RadioGroup row
                                value={this.state.AddressIsDefault} >
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value="1"
                                        name='AddressIsDefault'
                                        onChange={this.handleisdefaulutChange} />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value="0"
                                        name='AddressIsDefault'
                                        onChange={this.handleisdefaulutChange} />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Grid>
                        <Grid container direction='row' justify={this.state.IdForEdit == -1 ? 'flex-start' : 'space-between'} style={{ marginLeft: '1%', marginBottom: '5%', marginTop: '5%', width: '100%' }}>
                            <ButtonBase title={this.state.IdForEdit == -1 ? 'Add Address' : 'Save Changes'}
                                isLoading={loading}
                                onClick={() => { this.state.IdForEdit == -1 ? this.handleSubmit(0) : this.handleSubmit(this.state.AddressID) }}
                                style={window.innerWidth <= 920 ? { width: '60%', } : { width: '40%', }} />

                            {this.state.IdForEdit == -1 ? <div /> : <ButtonBase title='Delete'
                                isLoading={loading}
                                onClick={() => { this.wsDeleteAddress(this.state.AddressID) }}
                                style={{ width: '30%', border: '1px solid #E1E4EB', backgroundColor: '#FFFFFF', color: 'red', marginRight: '2%' }} />
                            }
                        </Grid>
                    </Card>
                </form>
            </Grid>
        )
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, Fromlistpage, dataSource, } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main} justify='center'>
                    <Title title={Fromlistpage == '1' ? 'Addresses' : 'Select Address'} isBack={Fromlistpage == '1' ? 1 : 2} history={this.props.history} />
                    {/* {Fromlistpage == '0' || mediumBrowser ? <div /> : <TopMenu history={this.props.history} />} */}
                    <ToastsContainer store={ToastsStore} />
                    <Grid container justify='center' alignItems='center' alignSelf='center'>
                        <Grid container direction='row' style={mediumBrowser ? { width: '100%', marginBottom: '0.5%' } : { width: '98.5%', marginBottom: '0.5%' }} justify='space-between' alignItems='center'>
                            <Grid container style={mediumBrowser ? { width: '50%', } : { width: '50%', }} >
                                <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', textAlign: 'center' }}>{'(' + dataSource.length + ')' + ' ' + 'Addresses Found'} </Typography>
                            </Grid>
                            <Grid container justify='flex-end' style={mediumBrowser ? { width: '50%', } : { width: '50%', }} >
                                <Button onClick={() => this.blankState()}>
                                    <Typography style={{ color: '#39A749', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {'Add New'} </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={mediumBrowser ? { width: '100%' } : Fromlistpage == '1' ? { width: '98.5%', } : { width: '98.5%', }} justify="space-between">
                            <Grid alignItems='cetner' container style={mediumBrowser ? { width: '100%', } : { width: '39%', }} >
                                {this.renderAddressList()}
                            </Grid>
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '60%', }} >
                                {this.renderaddAddress()}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(addressesStyle)(withTranslation('translation')(Addresses));
