import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const editProfileStyle = theme => ({
    mainContainer: {
        // marginTop: '100px',
        // marginBottom: '8%',
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)',
      },
      txtEditProfile: {
        // marginTop: '8%',
        // marginBottom: '7%',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '22px',
        marginLeft: '7%',
      },
      img: {
        width: '100%',
        height: 450,
    },
    AvatarImg: {
      height: '150px',
      width: '150px',
      borderRadius: '75px',
      marginBottom:'15%',
      backgroundColor: '#39A749',
      resizeMode: 'content',
      fontSize: 36,
    },
    IconStyle: {
      color: '#39A749',
      fontSize: '30px',
      fontFamily: 'Nunito Sans', fontWeight: 700,
      paddingRight: '5%',
  },
  buttonstyle: {
      color: '#262628',
      fontSize: '16px',
      fontFamily: 'Nunito Sans', fontWeight: 700,
      
  },
});
const combinedStyles = combineStyles(commonStyle, editProfileStyle);
export default combinedStyles; 
