import { makeStyles } from '@material-ui/core/styles';

const filledTextFieldUpdatedStyle = makeStyles(theme => ({
    textField: {
        borderRadius: '5px',
        border: '1px solid #f1f1f5',
        color: '#262628',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        fontWeight: 400,
        height:'50px',
        // width:'328px'
    },
    label: {
        color: '#B0B4BF',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 16,   
    }
}));
export default filledTextFieldUpdatedStyle;