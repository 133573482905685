import * as moment from 'moment';

export const ValidationErrorMessages = {
    NOOTP: 'Please enter OTP',
    OTPNOTANUMBER: 'OTP should be Digits',
    OTPLENGTH: 'OTP should be 4 Digits',
    NOEMAILADDRESS: 'Please Enter Email Address',
    NOPASSWORD: 'Please Enter Password',
    INVALIDURL: 'Please Enter Valid Link Address',
    INVALIDEMAILADDRESS: 'Email Address is Invalid',
    PASSWORDMINLENGTH: 'Password should be minimum 6 Characters',
    NOCONFIRMPASSWORD: 'Please Enter Confirm Password',
    PASSWORDDONOTMATCH: 'Password and Confirm Password not match',
    NOUSERTYPE: 'Please select User Type',
    NOFIRSTNAME: 'Please Enter First Name',
    NOLASTNAME: 'Please Enter Last Name',
    NOCOUNTRY: 'Please Select Country',
    NOCITY: 'Please Enter City',
    NOMOBILE: 'Please Enter Mobile No',
    NOBIRTHDATE: 'Please Select Date of Birth',
    INVALIDMOBILENO: 'Mobile No should be Digits Only',
    MOBILENOMINLENGTH: 'Mobile No should be 10 digit',
    NOFILE: 'Please Select File',
    NOSOCIALACCOUNT: 'Please Enter Any One Social',
    NOTASKTITLE: 'Please Enter Task Title',
    NOTITLE: 'Please Enter Title',
    NOTASKDESCRIPTION: 'Please Enter Task Description',
    NOTASKHOURS: 'Please Enter Task Hours',
    NOTASKDURATION: 'Please Enter Task Duration',
    NOGIGTITLE: 'Please Enter Gig Title',
    NOGIGDESCRIPTION: 'Please Enter Gig Description',
    NOGIGTYPE: 'Please Select Gig Type',
    NOSTARTEDATE: 'Please Enter Start Date',
    NOENDDATE: 'Please Enter End Date',
    NOBUDGET: 'Please Enter Gig Budget',
    VALIDLINKDIN: 'Please Enter Valid LinkedIn ',
    VALIDTWITTER: 'Please Enter Valid Twitter ',
    VALIDQUORA: 'Please Enter Valid Quora ',
    VALIDGITHUB: 'Please Enter Valid Github ',
    VALIDMOBILE: 'Please Enter Valid Mobile number',
    PATIENTNAME: 'Please Enter Patient Name',
    ADDRESSLINE1: 'Please Enter Address Line 1',
    ADDRESSLINE2: 'Please Enter Address Line 2',
    NOPINCODE: 'Please Enter Pincode',
    INVALIDPINCODE: 'Pincode should be Numbers Only',
    PINCODELENGTH: 'Pincode should be minimum 6 Characters',
    DOCTORNAME: 'Please Enter Doctor Name',
    RELATIONWITHPATIENT: 'Please Select Relation With Patient',
    ISDEFAULTPATIENT: 'Please Select Default Patient Or Not',
    NoName: 'Please Enter Your Name',
    NoLocation: 'Please Enter Your Location',
    NoEmail: 'Please Enter Your EmailAddress',
    NoDateofBirth: 'Please Select Date of birth',
    NoPhone: 'Please Enter Your Phone Number',
    NOTPHONE: 'Please Enter Valid Phone Number',
    VALIDCAPTCHA: 'Please Verify Captcha Code',
    NODATFORREFILL: 'No Data Found',
    NOTERMCONDITION: 'Please agree to Terms and Condition'
}

export const SCREENS = {
    MAIN: '/',
    LOGIN: '/login',
    CAMPAIGN: 'Campaign',
    SIGNUP: '/signup',
    EDITPROFILE: '/EditProfile',
    CHANGEPASSWORD: '/ChangePassword',
    FORGOTPASSWORD: '/ForgotPassword',
    VERIFYOTP: '/VerifyOTP',
    SEARCHMEDICINE: '/SearchMedicine',
    CALLTOORDER: '/CallToOrder',
    MYORDER: '/MyOrder',
    REFILL: '/Refill',
    STORES: '/Stores',
    COMPARE: '/Compare',
    WHYGENERIC: '/WhyGeneric',
    CART: '/Cart',
    UPLOADPRESCRIPTION: '/UploadPrescription',
    OLDPRESCRIPTION: '/OldPrescription',
    PATIENT: '/Patient',
    ADDRESSES: '/Addresses',
    ORDERREVIEW: '/OrderReview',
    ORDERCONFIRMATION: '/OrderConfirmation',
    PAYMENTS: '/Payments',
    NOTIFICATIONS: '/Notifications',
    MEDICINEDETAIL: '/MedicineDetail',
    HOMEPAGE: 'HomePage',
    JOINUS: 'JoinUs',
    TESTIMONIALS: 'Testimonials',
    WHYMEDKART: 'WhyMedkart',
    ABOUTUS: 'AboutUs',
    CONTACTMEDKART: 'ContactMedkart',
    FRANCHISEEMEDKART: 'FranchiseeMedkart',
    ECOMMERCEHOMEPAGE: 'AboutUs',
    PRIVACYPOLICY: 'PrivacyPolicy',
    RETURNPOLICY: 'ReturnPolicy',
    TERMSCONDITIONS: 'TermsConditions',
    SCHEME: 'Scheme',
    TABBAR: 'TabBar'
}

export function GetTodaysDate() {
    return GetDateInFormat(new Date());
}

//for Cashfree sendBox
let sendBoxApiKey = '14894231307cd73e7675aec3fe249841'
let sendBoxapiSecret = 'a329681430fe2089236f4dd38de9a52f9d23ffe3'

//for Cashfree Live
let LiveApiKey = '14894231307cd73e7675aec3fe249841'
let LiveapiSecret = 'a329681430fe2089236f4dd38de9a52f9d23ffe3'

export const apiKey = sendBoxApiKey; // put your apiKey here 
export const apiSecret = sendBoxapiSecret; // put your apiSecret here 

export function AddDaysInDate(tempDate, Duration) {
    var matches = Duration.match(/(\d+)/);
    var enddate = tempDate;
    if (Duration.indexOf('week') !== '-1') {
        enddate = tempDate.setDate(tempDate.getDate() + (7 * matches[0]));
    } else if (Duration.indexOf('day') !== '-1') {
        enddate = tempDate.setDate(tempDate.getDate() + (matches[0]));
    } else if (Duration.indexOf('month') !== '-1') {
        enddate = tempDate.setDate(tempDate.getDate() + (30 * matches[0]));
    } else if (Duration.indexOf('hour') !== '-1') {
        enddate = tempDate.setHour(tempDate.getHour() + (matches[0]));
    }
    return GetDateInFormat(new Date(enddate));
}
export function GetDateInFormat(tempDate) {
    return tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
}

export function DateFormate(date) {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    var momentTime = moment(date);
    return momentTime.format('YYYY-MM-DD')
}

export function MobileNoValidation(code, number) {
    if (code === '+91') {
        return number.length === 10
    } else if (code === '+60') {
        return number.length === 10
    } else if (code === '+65') {
        return number.length === 10
    }
}

export function LeaveDateFormate(date) {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    var momentTime = moment(date);
    return momentTime.format('D MMM YY')
}

export function LeaveTimeFormate(date) {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    var momentTime = moment(date);
    return momentTime.format('hh:mm')
}

export function userIdFromLocal() {
    var locale = localStorage.getItem('user')
    var user = JSON.parse(locale)
    return localStorage.getItem('user') != null ? user.id : ''
}

export function mobilenoFromLocal() {
    var locale = localStorage.getItem('user')
    var user = JSON.parse(locale)
    return localStorage.getItem('user') != null ? user.mobileno : ''
}
export function profilepicFromLocal() {
    var locale = localStorage.getItem('user')
    var user = JSON.parse(locale)
    return localStorage.getItem('user') != null ? user.profilepic : ''
}
export function fullnameFromLocal() {
    var locale = localStorage.getItem('user')
    var user = JSON.parse(locale)
    return localStorage.getItem('user') != null ? user.fullname : ''
}

export const FONTFAMILY = {
    InterBold: 'Inter-Bold',
    InterExtraBold: 'Inter-ExtraBold',
    InterMedium: 'Inter-Medium',
    InterRegular: 'Inter-Regular',
    poppinssemibold: 'Inter-Bold',
    PoppinsBold: 'Nunito Sans-Bold'
}
export const FONTSIZE = {
    FS5: '5px',
    FS6: '6px',
    FS7: '7px',
    FS8: '8px',
    FS9: '9px',
    FS10: '10px',
    FS11: '11px',
    FS12: '12px',
    FS13: '13px',
    FS14: '14px',
    FS15: '15px',
    FS16: '16px',
    FS17: '17px',
    FS18: '18px',
    FS19: '19px',
    FS20: '20px',
    FS22: '22px',
    FS25: '25px',
    FS30: '30px',
    FS35: '35px',
    FS38: '38px',
    FS40: '40px',
}
