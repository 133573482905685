/*eslint-disable eqeqeq*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PincodeDialog from "../../../components/PincodeDialog/PincodeDialog";
import { Typography, Grid, Button, TextField, Select, ClickAwayListener } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import * as APIController from '../../../api/APIController';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import * as AppConstants from '../../../common/Constants';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import images from '../../../common/Images';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { isThisISOWeek } from 'date-fns';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'fixed',
    },
    gridMain: {
        position: "fixed",
        backgroundColor: '#009645',
        top: '11.50%',
        width: '41.50%',
        borderRdius: 4,
        border: '1px solid #0f0',
    },
    Medicinetitle: {
        fontFamily: 'Nunito Sans',
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.textColor,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '80%',
        display: 'block'
    },
}));
const Header = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.openModel);
    const [dataSource, setdataSource] = React.useState([]);
    const [cartCount, SetcartCount] = React.useState(0);
    const [Index, setIndex] = React.useState('');
    const [quantityOption] = React.useState([]);
    const [quantity, setquantity] = React.useState('');
    const [PostalCode, setPostalCode] = React.useState('');
    const [selectItem, setSelectItem] = React.useState(-1);
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [OpenPincodeDialog, setOpenPincodeDialog] = React.useState(false);

    React.useEffect(() => { // Pass in a callback function!
        setdataSource(props.dataSource)
    });
    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const onPressList = (index) => {
        setSelectItem(index)
    }

    const handleClickAway = () => {
        props.CloseModal()
    };
    const OpenModal = (data) => {

    }

    const handleChange = (e, index) => {
        setquantity(e.target.value);
        // setSelectItem(index)
        BuyNow(5, 10)
    }

    const wsMedicineDetail = (medicineid, compositionid) => {
        props.GetMedicineDetailFromSearchModal(medicineid, compositionid)
        props.history.push({
            pathname: '/medicinedetail',
            state: {
                id: medicineid,
                compositionid: compositionid,
                quantityOption: props.quantityOption,
                medicinequantity: quantity
            }
        })
        // APIController.MedicineDetail(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), medicineid, compositionid)
        //     .then((response) => {
        //         if (response.error == null) {
        //             // props.history.replace({
        //             //     pathname: '/medicinedetail',
        //             //     state: {
        //             //         id: medicineid,
        //             //         compositionid: compositionid,
        //             //         quantityOption: props.quantityOption,
        //             //         medicinequantity: quantity
        //             //     }
        //             // })

        //         }
        //         else if (response.error.message && response.error.message.length > 0) {
        //             ToastsStore.warning(response.error.message)
        //         }
        //     })
    }
    const wsSearchMedicine = (data) => {

        APIController.searchMedicineNew(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), data.toString()).then((response) => {
            if (response.error == null) {

                setdataSource(response.object)
            }
            else if (response.error.message.message && response.error.message.message.length > 0) {
            }
        })


    }


    const BuyNow = (index, quantitydefoult) => {
        setIndex(index)
        AppConstants.userIdFromLocal() == '' ? setLoginDialog(true) :
            localStorage.getItem('pincode') == '' ?
                setOpenPincodeDialog(true) :
                wsAddToCart(index, quantitydefoult)
    }
    const WsGetcart = () => {
        const PinCode = localStorage.getItem('pincode');
        APIController.GetCart('8', AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode, '0')
            .then((response) => {
                if (response.error == null) {
                    SetcartCount(response.object[0].cartcount)
                    // localStorage.setItem('cartvalue', JSON.stringify(response.object[0].cartcount))
                }
                else {

                }
            })

    }
    const ClosePincode = () => {
        setOpenPincodeDialog(false)
    }
    const wsAddToCart = (index, quantitydefoult) => {
        const PinCode = localStorage.getItem('pincode');
        let flatListData = [...dataSource];
        flatListData[index].quantity = quantitydefoult;
        APIController.AddToCart(
            quantitydefoult != 0 ? 1 : 2,//opraion Id 1 for add, 2 for delete
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode,
            1,//get data
            quantitydefoult,
            flatListData[index].id,
            flatListData[index].cartid,//id
        ).then((response) => {
            if (response.error == null) {
                props.GetdataFromSearchModal(response.object)
                SetcartCount(response.object.data.length)
                // WsGetcart()
                ToastsStore.warning(response.object.message)
                //if reload medicine detail from click aad to cart
                // wsMedicineDetail(flatListData[index].id, flatListData[index].compositionid)
            }
            else if (response.error.message.message && response.error.message.message.length > 0) {
            }
        })
        wsSearchMedicine(props.searchtext)
        setSelectItem(-1)
        setOpenPincodeDialog(false)
    }

    return (
        <div>


            <ClickAwayListener
                onClickAway={handleClickAway.bind(this)}
                style={{ position: "fixed" }}>
                <Grid style={props.browser === false ? {
                    position: "fixed",
                    zIndex: 10,
                    top: '60px',
                    left: '27.5%',
                    width: '45%',
                    // border: '0px solid #009645',
                    borderColor: '#39A749',
                    // borderStyle: 'solid',
                    borderStyle: dataSource != null && dataSource.length > 0 ? 'solid' : 'none',
                    borderLeftWidth: '2px',
                    borderRightWidth: '2px',
                    borderTopWidth: '2px',
                    borderBottomWidth: '2px',
                    overflow: 'hidden',

                } : {
                    position: "fixed",
                    top: '110px',
                    // top: window.innerWidth <= 800 ? '110px' : window.innerWidth <= 1000 ?'140px':'110px',
                    left: '2%',
                    width: '96%',
                    // border: '0px solid #009645',
                    borderColor: '#39A749',
                    // borderStyle: 'solid',
                    borderStyle: dataSource != null && dataSource.length > 0 ? 'solid' : 'none',
                    borderLeftWidth: '2px',
                    borderRightWidth: '2px',
                    borderTopWidth: '2px',
                    borderBottomWidth: '2px',
                    overflow: 'hidden',
                }
                }>
                    <Scrollbars autoHeight hideTracksWhenNotNeeded={true} autoHeightMax='400px'>
                        {props.openModel ? (
                            <Grid item>
                                {
                                    dataSource != null && dataSource.map((item, index) =>
                                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" style={{ backgroundColor: '#fff', width: '100%', paddingLeft: '5px', paddingRight: '15px', border: '1px solid #39A749', overflow: 'hidden', }}>
                                            <Grid container style={window.innerWidth <= 920 ? { backgroundColor: '#fff', width: '80%', margin: '5px', marginTop: '1.5%' } : { backgroundColor: '#fff', width: '80%', margin: '5px', }}>
                                                <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>

                                                    <Grid onClick={() => wsMedicineDetail(item.id, item.compositionid, item.cartid)} container xl={12} md={12} style={{ cursor: 'pointer' }} sm={12} xs={12} >
                                                        <Grid alignItems={'center'} container xl={12} md={12} sm={12} xs={12} >
                                                            <img src={item.medicinetypename == "TABLET" ?
                                                                images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                                    images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                                        images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                                            images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                                                images.Icon_tube : images.Icon_pill}
                                                                style={{ height: '20px', width: '20px' }} />
                                                            &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle}>{item.name}</Typography>
                                                            {item.companyname != null && item.companyname != '' && <>
                                                                &nbsp; <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                                                &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                                            </>}
                                                        </Grid>
                                                        {/* <Grid container xl={6} md={6} sm={6} xs={6} >
                                                            <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                                            &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                                {/* <img src={item.medicinetypename == "TABLET" ?
                                                    images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                        images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                            images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                                images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                                    images.Icon_tube : images.Icon_pill}
                                                    style={{ height: '5%', width: '5%' }} /> */}
                                                <Grid container direction="column" style={{ cursor: 'pointer', paddingLeft: '5px', width: '90%', backgroundColor: '#fff' }} onClick={() => wsMedicineDetail(item.id, item.compositionid, item.cartid)}>


                                                    {/* <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito Sans' }}> {item.name} </Typography> */}
                                                    <Grid container direction="row">
                                                        <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>Price:&nbsp;</Typography>
                                                        <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>₹ {item.saleprice}&nbsp;&nbsp;</Typography>
                                                        <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', textDecorationLine: 'line-through', }}>₹ {item.mrp}</Typography>
                                                        <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Pack of:</Typography>
                                                        <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;{item.packsize}</Typography>
                                                        {item.category == 'C' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> Not For Online Sale </Typography>) : <div />}
                                                        {item.medicineclass == 'DIS' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> This Medicine is Discontinued </Typography>) : <div />}
                                                    </Grid>
                                                    {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                                                        <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                                        &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                                    </Grid> */}

                                                    {/* <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.medicineclass} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.category} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.quantity} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.medicinetypename} </Typography> */}
                                                </Grid>
                                            </Grid>

                                            <Grid container
                                                direction="row"
                                                justify='flex-end'
                                                alignItems="center"
                                                style={{
                                                    backgroundColor: '#fff',
                                                    width: '20%',
                                                    margin: '5px'
                                                }}>
                                                {


                                                    item.allowedit == '0' ? <ButtonBase
                                                        style={{
                                                            width: '70px',
                                                            height: '20%', color: '#fff',
                                                            backgroundColor: '#fff',
                                                            fontFamily: 'Nunito Sans',
                                                            fontSize: '14px'
                                                        }}
                                                        size="small"
                                                        variant="contained"
                                                        disabled
                                                        title='' />
                                                        : selectItem == index || dataSource[index].quantity != 0 ?

                                                            <div>
                                                                <Select native
                                                                    onChange={(text) => BuyNow(index, text.target.value)}
                                                                    style={{
                                                                        width: '68px',
                                                                        height: '20%',
                                                                        backgroundColor: '#fff',
                                                                        border: '1px solid ',
                                                                        borderColor: '#39A749',
                                                                        // paddingLeft:'15px'
                                                                    }} >
                                                                    {/* <option value={''} style={{ color: '#fff', backgroundColor: 'green' }}>{item.quantity}</option> */}
                                                                    {
                                                                        props.quantityOption.map((item1, index) => <option style={{ marginLeft: '20px' }} selected={item1.medicinequantity == item.quantity ? true : false} value={item1.medicinequantity} >
                                                                            &nbsp;&nbsp;{item1.medicinequantity}</option>)
                                                                    }
                                                                </Select>
                                                            </div>

                                                            : <div>

                                                                {
                                                                    item.medicineclass == 'DIS'
                                                                        ? <ButtonBase
                                                                            style={{
                                                                                width: '70px',
                                                                                height: '20%', color: 'red'
                                                                            }}
                                                                            size="small"
                                                                            variant="contained"
                                                                            disabled
                                                                            title='DIS' />

                                                                        :


                                                                        < Grid > {
                                                                            item.category == 'A' ||
                                                                                item.category == 'B' ||
                                                                                item.category == 'D' ? (
                                                                                <ButtonBase
                                                                                    style={{
                                                                                        width: '70px',
                                                                                        height: '20%',
                                                                                        fontFamily: 'Nunito Sans',
                                                                                        fontSize: '14px'
                                                                                    }}
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    // disabled={index == selectItem ? false : true}
                                                                                    onClick={() => BuyNow(index, '1')}
                                                                                    title='+ Add' />
                                                                                // <ArrowForwardIcon disabled={index == selectItem ? false : true} style={{ backgroundColor: '#fff' }} onClick={() => wsAddToCart(item, index + 1)}/>
                                                                            ) : item.category == 'F' ||
                                                                                item.category == 'E' ? (
                                                                                <ButtonBase
                                                                                    style={{
                                                                                        width: '70px',
                                                                                        height: '20%',
                                                                                        fontFamily: 'Nunito Sans',
                                                                                        fontSize: '14px'
                                                                                    }}
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    onClick={() => { BuyNow(index, '1'); ToastsStore.warning("Price Needs to be Confirmed.") }}
                                                                                    title='+ Add' />
                                                                            ) : (
                                                                                <ButtonBase
                                                                                    style={{
                                                                                        width: '70px',
                                                                                        height: '20%', color: 'red',
                                                                                        fontFamily: 'Nunito Sans',
                                                                                        fontSize: '14px'
                                                                                    }}
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    disabled
                                                                                    title='N/A' />
                                                                            )
                                                                        }
                                                                        </Grid>
                                                                }

                                                            </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        ) : null}
                    </Scrollbars>
                    <ConfirmDialog
                        history={props.history}
                        onClose={() => setLoginDialog(false)}
                        open={LoginDialog}
                        onClick={() => setLoginDialog(false)}
                    />
                    <PincodeDialog
                        history={props.history}
                        onClose={() => setOpenPincodeDialog(false)}
                        open={OpenPincodeDialog}
                        index={Index}
                        // wsAddToCart={wsAddToCart.bind(this)}
                        ClosePincode={ClosePincode.bind(this)}
                        onClick={() => setOpenPincodeDialog(false)}
                    />
                </Grid>
            </ClickAwayListener>
        </div >

    );
}
export default (Header);
{/* <Button
                                                variant="contained"
                                                size="small"
                                                disabled={index == selectItem ? false : true}
                                                component="span"
                                                onClick={() => wsAddToCart(item, index + 1)}
                                                style={{ width: '44%', margin: '5px', }} >Add To Cart</Button> */}

{/* <ButtonBase
                                                    size="small"
                                                    variant="contained"
                                                    disabled={index == selectItem ? false : true}
                                                    onClick={() => wsAddToCart(item, index + 1)}
                                                    style={{ width: '45%', height:'45%',color: '#FFF',margin: '5px', }}
                                                    title='Add to Cart'/> */}