import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ThankYouStyle from './ThankYouStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, CssBaseline, Typography, Button, Toolbar } from '@material-ui/core';
import { Images } from '../../common';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import LocationIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

const CheckMark = Images.CheckMark;

class ThankYou extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleStep(step) {
        this.setState({ open: step })
    }


    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
                <Helmet>
                    <title>About Medkart Online Store for Generic Medicines</title>
                    <meta name="description" content="Know more about Medkart stores, medkart owner, medkart funding and the mission. Get familiar with us before you chose to buy standard generics online at Medkart" />
                    <meta name="keywords" content="Medkart Online | Medkart Owner | Medkart Funding" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    {/* <Title title={'About Us'} isBack={false} history={this.props.history} /> */}
                    <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">

                        <Grid container className={mediumBrowser ? classes.mainMobileView : classes.mainView} style={{ width: '100%', }} direction="column" justify="center" alignItems="center">
                            <LocationIcon style={{ color: '#888', resize: 'cover', position: 'absolute', cursor: 'pointer', top: '1%', right: '0.8%' }} onClick={() => this.setState({ OpenThankYou: false })} />
                            {/* <CheckCircleIcon className={classes.thankslogo} /> */}
                            <img src={Images.CheckMark} style={{height:100,width:100}}
                                className={classes.thankslogo} alt="" />
                            <div style={{ display: 'grid', textAlign: 'center' }}>
                                <label className={classes.titletextcolor}>Thank You!</label>
                                <label className={classes.subtxt}>Your response has been recorded</label>
                            </div>

                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
            </Grid>
        );
    }
}



export default withStyles(ThankYouStyle)(ThankYou);
