import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import orderConfirmationStyle from './OrderConfirmationStyle';
import { CssBaseline, Typography, Grid,CircularProgress, } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import { Images } from '../../common';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as moment from 'moment';
import ReactGA from 'react-ga';

class OrderConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            orderID: '',
            orderreferenceno: '',
            estimateddeliverydate: '',
            dataSource: [],
            IsLoading: true,
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.wsOrderConfirmation();
    }

    wsOrderConfirmation() {
        APIController.OrderConfirmation('11', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.props.history.location.state.OrderIDfromOrderReview)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ orderID: response.object.data[0].id, orderreferenceno: response.object.data[0].orderreferenceno, estimateddeliverydate: response.object.data[0].estimateddeliverydate, IsLoading: false })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    wsCopyOrder(orderid) {
        APIController.CopyOrder(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), orderid)
            .then((response) => {
                if (response.error == null) {
                    ToastsStore.warning(response.object.message)
                    this.props.history.push({
                        pathname: '/cart',
                    });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })

    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    medicineDetailFromsearch(data) {}
    render() {
        const { classes } = this.props;
        const { IsLoading } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    {IsLoading ? this.LoadingView() :
                        <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
                            <Grid container justify='space-between' alignItems='center' style={{ width: '98%' }}>
                                <Grid container justify='center' alignItems='center' direction='column' className={classes.ContentcardmainwithRadius} style={{ width: '45%', height: '100%' }}>
                                    <img src={Images.success} alt="" />
                                    <Typography className={classes.titleText}>Congratulations!</Typography>
                                    <Typography className={classes.txtOrderSuccess}>Order Placed successfully!</Typography>
                                    <Typography className={classes.txtOrderSuccessMsg}>Once our pharmacist verifies your order with the prescription, you will be notified for Payment. Normally, It takes up to 4 working hours</Typography>
                                </Grid>
                                <Grid container direction='column' className={classes.ContentcardmainwithRadius} style={{ width: '45%', height: '100%' }}>
                                    <Typography className={classes.titleText}>Order #{this.state.orderreferenceno}</Typography>
                                    <Grid container justify='flex-end' style={{ bottom: '0%' }} direction='column' style={{ width: '100%' }}>
                                        <Grid container>
                                            <Typography className={classes.txtOrderSuccess}>Expected Delivery : </Typography>
                                            <Typography className={classes.txtDeliveryDate}> {moment(this.state.estimateddeliverydate).format("DD-MMM-YYYY")}</Typography>
                                        </Grid>
                                        <Grid container justify='flex-end'>
                                            <ButtonBase type="submit" title={'Copy Order'} variant="contained" className={classes.btnCopyOrder}
                                                onClick={() => {
                                                    this.wsCopyOrder(this.state.orderID);
                                                }} />
                                            <ButtonBase type="submit" title={'Details'} variant="contained" className={classes.btnDetails}
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname: '/myorder',
                                                        state: { orderConfomID: this.state.orderID }
                                                    });
                                                }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(orderConfirmationStyle)(withTranslation('translation')(OrderConfirmation));
