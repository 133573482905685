import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cartStyle from './CartStyle';
import { ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import Search from '@material-ui/icons/Search';
import { Grid, CssBaseline, Typography, CircularProgress, IconButton, InputAdornment, ClickAwayListener, Card } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import DeleteIcon from '@material-ui/icons/Delete';
import * as AppConstants from '../../common/Constants';
import Title from "../../components/Header/Title";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import InputBase from '@material-ui/core/InputBase';

import images from '../../common/Images';
import uuid from 'react-uuid';

class Compare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            medicinename: '',
            selectedItem: -1,
            FlatlistData: [],
            EmailAdreess: '',
            Suugestedmedicine: [{ "Name": 'Metacine tablet 425' }, { "Name": 'calpol tablet 425' }, { "Name": 'Metacine tablet 425' }, { "Name": 'Metacine tablet 425' }, { "Name": 'Metacine tablet 425' }],
            MedicineModal: false,
            MobileNo: '',
            CompareFlatListHeader: ['No', 'Medicine', 'MRP', 'Medkart Rate', 'Medkart Medicine'],
            CompareFlatList: [],
            loading: true,
            SessionId: '',
            searchText: '',
            TotalMedkartRate: 0,
            GrossTotal: 0,
            CartTotal: 0,
            SuggestedCartTotal: 0,
            MedKartDiscount: 0,
            SuggestedMedKartDiscount: 0,
            SuggestedGrossTotal: 0,
            isLoading: false,
            IsPageLoad: true,
            cart: parseInt(localStorage.getItem('cartvalue')),
        }
    }
    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))

    }
    medicinequantity() {
        const quantitycount = [];
        let i = 1;
        for (i = 1; i <= 100; i++) {
            quantitycount.push({ "medicinequantity": i });
        }
        this.setState({ medicinequantity: quantitycount })

    }

    renderCartEmpty() {
        const { classes } = this.props
        return (
            <Grid className={classes.ContentcardmainCart} style={{ padding: '0.8%' }} container direction={'row'} justify={'center'} alignItems="center">
                {/* <Typography className={classes.qntytxt} >{''}</Typography> */}
                <Typography style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', color: '#92929d' }}> {'Your Compare Medicines is empty'}</Typography>
            </Grid>
        )
    }
    componentDidMount() {

        if (localStorage.getItem('SessionId')) {
            this.setState({ SessionId: localStorage.getItem('SessionId') })
            this.wsCompare_GetMedicines(localStorage.getItem('SessionId'))
        }
        else {
            localStorage.setItem('SessionId', uuid())
            this.wsCompare_GetMedicines(uuid())
            this.setState({ SessionId: uuid() })

        }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetCart()
        this.medicinequantity()

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 750 })
    }
    wsGetCart() {
        const PinCode = localStorage.getItem('pincode');
        APIController.GetCart('1', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), PinCode, '1').then((response) => {
            console.log('response: cart', response)
            if (response.error == null && response.object != null) {
                if (response.object.length != 0 && response.object[0].couponcode != '') {
                    this.setState({ Promocode: response.object[0].couponcode, PromocodeIsValid: true, coupanCodemessage: 'Coupon Code Applied Successfully' })
                }

            }
            else if (response.error != null && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }

    wsCompare_GetMedicines(SessionId) {

        APIController.Compare_GetMedicines(SessionId).then((response) => {
            if (response.error == null) {
                this.setState({ CompareFlatList: response.object, IsPageLoad: false })
                this.CalculateCartTotal(response.object)
                console.log('response:', response)

            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }
    wsCompare_DeleteMedicines(id, sessionid) {
        APIController.Compare_DeleteMedicines(sessionid, id).then((response) => {
            if (response.error == null) {
                this.wsCompare_GetMedicines(localStorage.getItem('SessionId'))
                // this.setState({ CompareFlatList: response.object })
                console.log('response:', response)

            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }
    wsCompare_SearchMedicines(searchtext) {
        APIController.Compare_SearchMedicines(searchtext).then((response) => {
            if (response.error == null) {
                this.setState({ Suugestedmedicine: response.object })
                console.log('response:', response)

            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }
    wsCompare_AddMedicine(sessionid, medicineid) {
        APIController.Compare_AddMedicine(sessionid, medicineid).then((response) => {
            if (response.error == null) {
                this.setState({ CompareFlatList: response.object, MedicineModal: false })

                this.CalculateCartTotal(response.object)
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)

            }
        })
    }

    Print() {
        window.print()
    }

    wsCompare_EmailMedicines(Type, Email, sessionid, Mobile) {
        console.log('Mobile:', Mobile)
        if (Type == 0 && Email == '') {
            ToastsStore.warning('please Enter Valid Email')
        } else if (Type == 1 && Mobile == '') {
            ToastsStore.warning('Please Enter Valid Mobile')
        }
        else {

            APIController.Compare_EmailMedicines(Email, sessionid, Mobile).then((response) => {
                if (response.error == null) {
                    ToastsStore.warning(response.object.message)
                    console.log('wsCompare_EmailMedicines:', response)

                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)

                }
            })
        }
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, });
    }
    handleClickAway() {
        this.setState({ MedicineModal: false })
    }
    searchText(e, index) {
        this.setState({
            searchText: e.target.value,
            // selectedItem: index,
            // CompareFlatList: this.state.CompareFlatList.map((item, idx) => idx != index ? item : { ...item, medicinename: e.target.value })
        })
        if (e.target.value.length > 3) {
            console.log('e.target.value:', e.target.value)
            this.wsCompare_SearchMedicines(e.target.value)
            this.setState({ MedicineModal: true })
        }
        else if (e.target.value.length == 0) {
            this.setState({ MedicineModal: false })

        }
    }
    CalculateCartTotal(Data) {
        var SubTotal = 0, SuggestedSubTotal = 0;
        var Discount = 0, SuggestedDiscount = 0;
        Data.map(function (item) {
            SubTotal = SubTotal + (item.quantity * item.mrp);
            Discount = Discount + (item.quantity * (item.mrp - item.saleprice));
            SuggestedSubTotal = SuggestedSubTotal + (item.suggestedquantity * item.suggestedmarp);
            SuggestedDiscount = SuggestedDiscount + (item.suggestedquantity * (item.suggestedmarp - item.suggestedsaleprice));
        });
        this.setState({
            CartTotal: SubTotal.toFixed(2),
            SuggestedCartTotal: SuggestedSubTotal.toFixed(2),
            MedKartDiscount: (Discount).toFixed(2),
            SuggestedMedKartDiscount: (SuggestedDiscount).toFixed(2),
            searchText: '',
            GrossTotal: Math.round((SubTotal - Discount).toFixed(2)),
            SuggestedGrossTotal: Math.round((SuggestedSubTotal - SuggestedDiscount).toFixed(2)),
            isLoading: false,
            IsPageLoad: false
        });

    }
    renderCartContent() {
        const { classes } = this.props
        const { mediumBrowser, cardAnimations, CompareFlatList, selectedindex, isLoadingplus, isLoadingminus, suggestedMedicineID, } = this.state
        return (
            <Grid item xl={12} md={12} sm={12} xs={12} >
                {
                    CompareFlatList.map((item, index) =>

                        <div style={{ display: 'flex' }}>
                            <Card raised={true} style={{ padding: '1%', margin: '5px', width: mediumBrowser ? '100%' : '100%', position: 'relative' }} >
                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                        <img src={item.medicinetypename == "TABLET" ?
                                            images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                    images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                        images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                            images.Icon_tube : images.Icon_pill}
                                            style={{ height: '20px', width: '20px' }} />
                                        &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle}>{item.medicinename}</Typography>

                                        <IconButton style={{ position: 'absolute', right: 5, top: 5, padding: "4px" }} onClick={() => { this.wsCompare_DeleteMedicines(item.id, localStorage.getItem('SessionId')) }}>
                                            <DeleteIcon style={{ color: '#BEBEBE' }} />
                                        </IconButton>
                                        <Grid className={classes.SecoundLineContetnt} container direction="row">
                                            <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Price:&nbsp;</Typography>
                                            <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>₹ {item.saleprice}&nbsp;&nbsp;</Typography>
                                            <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', textDecorationLine: 'line-through', }}>₹ {item.mrp}</Typography>
                                            <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Pack of:</Typography>
                                            <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;{item.packsize}</Typography>
                                            {item.category == 'C' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> Not For Online Sale </Typography>) : <div />}
                                            {item.medicineclass == 'DIS' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> This Medicine is Discontinued </Typography>) : <div />}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    )
                }
            </Grid>
        )
    }

    renderOurCartContent() {
        const { classes } = this.props
        const { CompareFlatList, mediumBrowser, selectedindex, isLoadingplus, isLoadingminus } = this.state
        return (
            <div style={{ width: '100%' }}>
                {
                    CompareFlatList.map((item, index) =>
                        <div style={{ display: 'flex' }}>
                            <Card raised={true} style={{ padding: '1%', margin: '5px', width: mediumBrowser ? '100%' : '100%', backgroundColor: '#bbffbb' }} >

                                <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                        <img src={item.medicinetypename == "TABLET" ?
                                            images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                    images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                        images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                            images.Icon_tube : images.Icon_pill}
                                            style={{ height: '20px', width: '20px' }} />
                                        &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle}>{item.suggestedname}</Typography>
                                        &nbsp;&nbsp;&nbsp;
                                    </Grid>
                                    <Grid className={classes.SecoundLineContetnt} container direction="row">
                                        <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Price:&nbsp;</Typography>
                                        <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>₹ {item.suggestedsaleprice}&nbsp;&nbsp;</Typography>
                                        <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', textDecorationLine: 'line-through', }}>₹ {item.suggestedmarp}</Typography>
                                        <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Pack of:</Typography>
                                        <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;{item.suggestedpacksize}</Typography>
                                        {item.category == 'C' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> Not For Online Sale </Typography>) : <div />}
                                        {item.medicineclass == 'DIS' ? (<Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> This Medicine is Discontinued </Typography>) : <div />}
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    )
                }
            </div>
        )
    }
    renderMainContent() {
        const { classes } = this.props
        const { mediumBrowser, FlatlistData, CartTotal, MedKartDiscount, DeliveryCharges, GrossTotal, SuggestedCartTotal, SuggestedMedKartDiscount, SuggestedGrossTotal } = this.state
        return (
            <Grid container xl={12} justify={'space-between'} md={12} sm={12} xs={12} >
                <Grid container xl={6} md={6} sm={6} xs={6} >
                    <Card raised={false} style={mediumBrowser ? { height: '110px', margin: '5px', width: '100%' } : { margin: '5px', width: '100%' }}>
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>
                            <Grid container direction={'row'} justify={'space-between'} alignItems="center" >
                                <Typography className={mediumBrowser ? classes.Mobileselectiontxt : classes.selectiontxt}>{'YOUR ORDER'}</Typography>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + CartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + MedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} >{'₹ ' + GrossTotal}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    {this.renderCartContent()}
                </Grid>
                <Grid container xl={6} md={6} sm={6} xs={6} >
                    <Card raised={false} style={mediumBrowser ? { height: '110px', margin: '5px', width: '100%', backgroundColor: '#bbffbb' } : { width: '100%', margin: '5px', backgroundColor: '#bbffbb' }}  >
                        <Grid container direction={'row'} justify={'space-between'} alignItems="center" style={{ padding: '2%' }}>

                            <Grid container direction={'row'} justify={'space-between'} alignItems="center">
                                <Typography className={mediumBrowser ? classes.Mobileselectiontxt : classes.selectiontxt} >{'OUR SUGGESTIONS'}</Typography>
                                <Grid style={{ width: '100%', marginBottom: 5 }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'MRP Total'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedCartTotal}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: 5, paddingBottom: 5, borderBottom: '1px solid #b5bbc1' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.qntytxt} >{'Discount'}</Typography>
                                    <Typography className={classes.qntytxt} >{'₹ ' + SuggestedMedKartDiscount}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%' }} container direction={'row'} justify={'space-between'} alignItems="center">
                                    <Typography className={classes.calculatetxt} >{'Gross Total'}</Typography>
                                    <Typography className={classes.newprice} style={{ color: '#000' }} >{'₹ ' + SuggestedGrossTotal}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    {this.renderOurCartContent()}
                </Grid>
            </Grid>
        )
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    render() {
        const { classes } = this.props
        const { CompareFlatList, IsPageLoad, mediumBrowser, } = this.state
        return (
            <Grid style={{ width: '100%' }}>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} dataFromsearch={this.dataFromsearch.bind(this)} CartCount={this.state.cart} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Compare'} history={this.props.history} />

                    {IsPageLoad ? this.LoadingView() :
                        <>
                            <>
                                <Grid container direction={'row'} justify='space-between' alignItems="center"
                                    style={mediumBrowser ? { width: '100%', } : { width: '58%' }}>
                                    <Card elevation='0' className={classes.ContentcardmainCart} raised={true} alignItems={'center'} justify={'center'} direction="row"
                                        style={mediumBrowser ? { width: '100%', padding: 4, border: '0px solid #d4d4d9' } : { width: '55%', padding: 4, border: '0px solid #d4d4d9' }}  >

                                        <ClickAwayListener
                                            onClickAway={this.handleClickAway.bind(this)}
                                            style={{ position: "fixed" }}>
                                            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                                                <InputBase
                                                    className={classes.input}
                                                    startAdornment={<InputAdornment position="start"> <Search /> </InputAdornment>}

                                                    style={{ width: '100%', border: '1px solid #cfcfcf', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder='Search Medicine / Composition / Brand'
                                                    value={this.state.searchText} name="medicinename" onChange={(e) => this.searchText(e)}
                                                />
                                                {/* <TextField InputProps={{
                                                    maxLength: 20, disableUnderline: true, startAdornment: (<InputAdornment position="start"> <Search /> </InputAdornment>)
                                                }} className={classes.input} style={{ width: '100%', border: '1px solid #cfcfcf', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder='Search Medicine / Composition / Brand' rows={1} value={this.state.searchText} name="medicinename" onChange={(e) => this.searchText(e)} /> */}

                                                {this.state.MedicineModal ? (
                                                    <Grid style={{
                                                        position: "absolute",
                                                        zIndex: 10000,
                                                        // width: mediumBrowser ? '50%' : '18%',
                                                        backgroundColor: '#fff',
                                                        borderColor: '#39A749',
                                                        borderStyle: 'solid',
                                                        borderLeftWidth: '2px',
                                                        borderRightWidth: '2px',
                                                        borderTopWidth: '2px',
                                                        borderBottomWidth: '2px',
                                                        // height: '0px', 
                                                        overflow: 'auto',
                                                        // overflow: 'hidden',

                                                    }}>
                                                        {this.state.Suugestedmedicine.map((obj) => {
                                                            console.log('obj:', obj)
                                                            if (obj.Compositions) {

                                                                let Id = obj.Compositions.split('^@^')[0]
                                                                let MedicineName = obj.Compositions.split('^@^')[1]
                                                                return (

                                                                    <div onClick={() => this.wsCompare_AddMedicine(this.state.SessionId, Id)} className={classes.textContent} style={{ padding: '15px', lineHeight: '15px', overflow: 'hidden', textAlign: 'left', height: '10px', position: 'relative' }}>
                                                                        <div className={classes.textTitles} style={{ color: '#000', cursor: 'pointer' }}>
                                                                            {MedicineName}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        </ClickAwayListener>
                                    </Card>
                                </Grid>

                            </>
                            {CompareFlatList.length == 0 ? this.renderCartEmpty()
                                :
                                <>

                                    {this.renderMainContent()}
                                    <Grid container style={{ width: '100%', padding: '20px 0px 20px 0px', }} justify={'flex-end'} alignItems='center'>
                                        <ButtonBase
                                            style={{ width: '80px', fontFamily: '', backgroundColor: '#337ab7', marginRight: '8px', height: '30px', fontSize: "12px !important" }}
                                            onClick={() => this.Print()} title={'Print'}></ButtonBase>
                                        <ButtonBase
                                            style={{ width: '100px', fontFamily: '', height: '30px', marginRight: '8px', fontSize: "12px !important" }}
                                            onClick={() => this.wsCompare_GetMedicines(0)} title={'Clear All'}></ButtonBase>
                                    </Grid>
                                    <Grid style={{ padding: '30px 0px 0px 0px', backgroundColor: '#39A749', width: '100%', border: '1px solid #39A749' }} container direction="row" justify='flex-end' >

                                        <Grid container xl={4} md={4} sm={7} xs={7} >
                                            <InputBase className={classes.input} style={{ width: '100%', border: '1px solid #cfcfcf', backgroundColor: '#fff', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder='Email Me Saving Details' value={this.state.EmailAdreess} name="EmailAdreess" onChange={(e) => this.setState({ EmailAdreess: e.target.value })} />
                                            {/* <TextField InputProps={{ maxLength: 20, disableUnderline: true, }} className={classes.input} style={{ backgroundColor: '#fff', border: '1px solid #cfcfcf', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder='Email me saving Details' rows={1} value={this.state.EmailAdreess} name="EmailAdreess" onChange={(e) => this.setState({ EmailAdreess: e.target.value })} /> */}
                                        </Grid>


                                        <ButtonBase
                                            style={{ width: '134px', fontFamily: '', height: '34px', marginRight: '8px', marginLeft: '8px', backgroundColor: '#337ab7', fontSize: "12px !important" }}
                                            onClick={() => this.wsCompare_EmailMedicines('0', this.state.EmailAdreess, this.state.SessionId, this.state.MobileNo)} title={'Send Email'}></ButtonBase>

                                    </Grid>
                                    <Grid style={{ padding: '10px 0px 30px 0px', backgroundColor: '#39A749', width: '100%', border: '1px solid #39A749' }} container direction="row" justify='flex-end' >

                                        <Grid container xl={4} md={4} sm={7} xs={7} >
                                            <InputBase className={classes.input} style={{ width: '100%', border: '1px solid #cfcfcf', backgroundColor: '#fff', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder=' Mobile Number' value={this.state.MobileNo} name="Mobile" onChange={(e) => this.setState({ MobileNo: e.target.value })} />
                                            {/* <TextField InputProps={{ maxLength: 20, disableUnderline: true, }} className={classes.input} style={{ backgroundColor: '#fff', border: '1px solid #cfcfcf', borderRadius: 5, padding: "0px 5px 0px 10px" }} placeholder='Mobile No' rows={1} value={this.state.MobileNo} name="Mobile" onChange={(e) => this.setState({ MobileNo: e.target.value })} /> */}
                                        </Grid>


                                        <ButtonBase
                                            style={{ width: '134px', height: '34px', marginRight: '8px', marginLeft: '8px', fontFamily: '', backgroundColor: '#f0ad4e', fontSize: "12px !important" }}
                                            onClick={() => this.wsCompare_EmailMedicines('1', this.state.EmailAdreess, this.state.SessionId, this.state.MobileNo)} title={'Call me'}></ButtonBase>

                                    </Grid>

                                </>}
                        </>}

                </main>
                <Grid style={{ position: 'fixed', bottom: 0, padding: '10px', backgroundColor: '#39A749', width: '100%', border: '1px solid #39A749' }} container direction={'row'} justify='center' alignItems="center">
                    <Typography className={classes.txttotalsaving}>YOUR TOTAL SAVING IS :  {' ₹ ' + ((this.state.GrossTotal) - (this.state.SuggestedGrossTotal))}</Typography>
                </Grid>
                {/* <Footer history={this.props.history} /> */}
            </Grid>
        )
    }
}
export default withStyles(cartStyle)(Compare);
