import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyARjN00gb5MLWa17feZcCINYFPv-AftR54",
    authDomain: "medkart-21f14.firebaseapp.com",
    projectId: "medkart-21f14",
    storageBucket: "medkart-21f14.appspot.com",
    messagingSenderId: "518409753847",
    appId: "1:518409753847:web:bb8c83b16beeaec991aff2",
    measurementId: "G-B5DPZSXDF3"

  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    return token;
  } catch (error) {
    console.error(error);
  }
}
export const onMessageListener = () =>

  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
});
