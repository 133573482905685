import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import uploadPrescriptionStyle from './UploadPrescriptionDialogStyle';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import * as AppConstants from '../../common/Constants';
import Title from "../../components/Header/Title";
import * as APIController from '../../api/APIController';
import Dropzone from 'react-dropzone';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import PhotoCameraIcon from '@material-ui/icons/AddAPhoto';
import PhotoGalleryIcon from '@material-ui/icons/AddPhotoAlternate';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, Typography, IconButton, CircularProgress, CssBaseline, Button } from '@material-ui/core';
import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import MobileLoginDialog from "../../components/MobileLoginDialog/MobileLoginDialog";
import WebcamCapture from "../../components/WebcamCapture/WebcamCapture";

class UploadPrescriptionDialog extends Component {
    constructor() {
        super();
        this.state = {
            dropzone: {},
            open: false,
            image64: '',
            imagename: '',
            isLoading: false,
            isLoadingChoose: false,
            isRemoveLoading: false,
            IsSelectPress: -1,
            IsSelectPressed: 0,
            IsRemovePress: -1,
            imageSize: '',
            dataSource: [],
            OlddataSource: [],
            ImageDataLocal: [],
            openCamera: false,
            openMobileDialog: false,
            ImageDataAPICall: []
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        // this.wsGetPrescription([])

    }
    addDummyImage() {
        var dataSource = [...this.state.dataSource];
        dataSource.push({ id: '0', prescreptionpic: '', lastsyncstatus: 1 });
        this.setState({ dataSource, isLoading: false });
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 420 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }

    wsGetPrescription(DS) {
        if (DS.length == 0) {
            APIController.GetPrescription(
                0,//oprationid
                AppConstants.userIdFromLocal(),//userid
                AppConstants.MobileNoValidation(),//mobileno
                1,//getdata
                0,//is used
            ).then((responseJson) => {
                this.setState({ dataSource: responseJson.object });
            })
            // this.addDummyImage()
        } else {
            if (DS.data != null) {
                this.setState({ dataSource: DS.data });
            }
        }
    }

    bindPrescriptions(DS) {
        this.setState({ dataSource: DS });
        this.addDummyImage()
    }

    wsAddPrescriptionToNew = (id, index) => {
        this.setState({ IsSelectPress: index, IsSelectPressed: 1 })
        APIController.AddPrescriptionToNew(
            AppConstants.userIdFromLocal(),//userid
            AppConstants.mobilenoFromLocal(),//mobileno
            id,//prescription id
            2,//oprationid
            1,//getdata
            0,//is used
        ).then((responseJson) => {
            this.setState({ IsSelectPressed: id })
            window.scrollTo(0, 0);
            this.bindPrescriptions(responseJson.object)
        })
    }

    addFilesToDropzone(files, dropzone) {
        let file = files[0]
        let data = this.state.ImageDataLocal
        file['preview'] = URL.createObjectURL(files[0]);
        data.unshift({ imagePreview: files[0].preview, imagename: files[0].name })
        this.setState({ dropzone: files[0] }, () => { });
        this.setState({ imagename: files[0].name, imageSize: (files[0].size / 2048).toFixed(2), ImageDataLocal: data });
        // this.ConvertToBase64();
    }

    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    LoadingView() {
        return (<Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
            <CircularProgress />
        </Grid>)
    }

    medicineDetailFromsearch(data) { }

    handleimageSrc(imageData) {
        console.log("data", imageData)
        let data = this.state.ImageDataLocal
        data.unshift({ imagePreview: imageData, imagename: uuid() + ".jpeg", })
        this.setState({ ImageDataLocal: data })
    }

    removeimage(index) {
        const arr = this.state.ImageDataLocal.filter((item, ind) => ind !== index);
        this.setState({ ImageDataLocal: arr })
    };

    renderUpload() {
        const { classes } = this.props
        const { IsRemovePress, isRemoveLoading, ImageDataLocal, openCamera, mediumBrowser } = this.state
        const videoConstraints = {
            facingMode: "user"
        };
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.Contentcardmainwithoutborder}
                style={{ width: '100%', justifyContent: "center", border: '2px solid #D9DCE3', borderRadius: '8px' }} >
                {!ImageDataLocal.length ?
                    this.addPhotoFirst()
                    : <>
                        <div style={mediumBrowser ? { display: 'grid', height: '550px', width: '100%' } : { display: 'grid', height: '390px', width: '100%' }}>
                            <div style={mediumBrowser ? { height: '480px', width: '100%' } : { height: '320px', width: '100%' }}>
                                {/* {openCamera ?
                                    <div style={mediumBrowser ? { height: '450px', width: '100%' } : { height: '325px', width: '100%' }}>
                                        <WebcamCapture imageSrc={this.handleimageSrc.bind(this)} />
                                    </div>
                                    : */}
                                <div style={mediumBrowser ? { height: '420px', width: '100%' } : { height: '250px', width: '100%' }}>
                                    <div style={{ height: '95%', width: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url(${ImageDataLocal[0].imagePreview})` }}>
                                        {/* <img style={{ height: '100%', width: '100%' }} src={ImageDataLocal[0].imagePreview} alt="image" /> */}
                                        <Typography style={{ backgroundColor: '#D9DCE3', fontSize: '14px', color: '#000', textAlign: 'center' }}>Share your prescription and our team will call you to confirm your order </Typography>
                                    </div>
                                    <div style={{ display: 'flex', border: '2px solid #39A749', borderRadius: '8px', margin: '10px 0 0' }}>
                                        {ImageDataLocal != null && ImageDataLocal.map((item, index) =>
                                            <Grid container direction="row" className={item.id != 0 && classes.ContentSelectcardmain}
                                                style={{ padding: 0, position: 'relative', height: '50px', width: '50px' }}  >
                                                {item.id != 0 &&
                                                    <IconButton onClick={() => this.removeimage(index)} style={{ color: '#39A749', top: -25, right: -25, position: 'absolute' }}>
                                                        {isRemoveLoading && item.id == IsRemovePress
                                                            ? <CircularProgress size={30} />
                                                            : <CancelIcon style={{ color: 'red', fontSize: 20, backgroundColor: '#fff', borderRadius: 50 }} />}
                                                    </IconButton>}
                                                {item.id == '0' ? null : <img style={{ height: '100%', width: '100%' }} src={item.imagePreview} alt="image" />}
                                            </Grid>
                                        )}
                                    </div>
                                </div>
                                {/* } */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', width: '100%', border: '2px solid #39A749', borderRadius: '8px' }}>
                                {/* {mediumBrowser && <PhotoCameraIcon style={{ color: '#39A749', fontSize: 50, margin: '0 10px', cursor: 'pointer' }} onClick={() => this.setState({ openCamera: true })} />} */}
                                {mediumBrowser && this.addDummyCameraPhoto()}
                                {this.addDummyPhoto()}
                                <SendIcon style={{ color: '#fff', fontSize: 40, cursor: 'pointer', borderRadius: '50%', padding: '3px', backgroundColor: '#39A749', marginLeft: '2%' }}
                                    onClick={() => this.setState({ openMobileDialog: true })} />
                            </div>
                        </div>
                    </>}
            </Grid>
        )
    }

    addPhotoFirst() {
        const { classes } = this.props
        const { mediumBrowser, openCamera } = this.state
        return (
            <>
                {/* {mediumBrowser &&
                    <Grid container direction={'row'}
                        style={openCamera ? { height: '450px', width: '300px', margin: '15px 15px 15%', border: '1px dashed #000', borderRadius: 0 } : { height: '200px', width: '200px', margin: '15px', border: '1px dashed #000', borderRadius: 0 }}>
                        {!openCamera ? <Button style={{ width: '200px', height: '200px' }}
                            onClick={() => this.setState({ openCamera: true })}>
                            <Grid container direction={'column'} justify='center' alignItems='center'>
                                <PhotoCameraIcon style={{ color: '#D9DCE3', fontSize: 50 }} />
                                <Typography className={classes.Dropimgtxt}> {'Click image'}</Typography>
                            </Grid>
                        </Button> :
                            <WebcamCapture imageSrc={this.handleimageSrc.bind(this)} />}
                    </Grid>} */}
                {mediumBrowser && <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button style={{ width: '200px', height: '200px', border: '1px dashed #000', marginBottom: '15px' }}
                                onClick={() => this.addDummyPhoto()}>
                                <Grid container direction={'column'} justify='center' alignItems='center'>
                                    <PhotoCameraIcon style={{ color: '#D9DCE3', fontSize: 50 }} />
                                    <Typography className={classes.Dropimgtxt}> {'Click image'}</Typography>
                                </Grid>
                            </Button>
                        </div>
                    )}
                </Dropzone>}
                <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button style={{ width: '200px', height: '200px', border: '1px dashed #000' }}
                                onClick={() => this.addDummyPhoto()}>
                                <Grid container direction={'column'} justify='center' alignItems='center'>
                                    <PhotoGalleryIcon style={{ color: '#D9DCE3', fontSize: 50 }} />
                                    <Typography className={classes.Dropimgtxt}> {'Drop image'}</Typography>
                                    <Typography style={{ marginTop: 10 }} className={classes.Dropimgsubtxt}>{'.jpg, .pdf, .png allowed'}</Typography>
                                </Grid>
                            </Button>
                        </div>
                    )}
                </Dropzone>
            </>
        )
    }
    addDummyPhoto() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <PhotoGalleryIcon style={{ color: 'rgb(57, 167, 73)', fontSize: 50, margin: '0 10px', cursor: 'pointer' }} onClick={() => this.setState({ openCamera: false })} />
                    </div>
                )}
            </Dropzone>
        )
    }
    addDummyCameraPhoto() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <PhotoCameraIcon style={{ color: 'rgb(57, 167, 73)', fontSize: 50, margin: '0 10px', cursor: 'pointer' }} onClick={() => this.setState({ openCamera: false })} />
                    </div>
                )}
            </Dropzone>
        )
    }

    render() {
        const { classes } = this.props
        const { isLoading } = this.state
        return (
            <Grid style={{ width: '100%' }}>
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Upload Prescription'} history={this.props.history} />
                    {isLoading ? this.LoadingView() :
                        <Grid container direction="row" style={{ width: '100%' }} justify={'center'}>
                            {this.renderUpload()}
                            {/* {this.renderOldPrescription()} */}
                        </Grid>
                    }

                </main>
                <Footer history={this.props.history} />
                {this.state.openMobileDialog && <MobileLoginDialog
                    history={this.props.history}
                    onClose={() => this.setState({ openMobileDialog: false })}
                    open={this.state.openMobileDialog}
                    onClick={() => this.setState({ openMobileDialog: false })}
                    ImageDataLocal={this.state.ImageDataLocal} />}
            </Grid>
        );
    }
}
export default withStyles(uploadPrescriptionStyle)(withTranslation('translation')(UploadPrescriptionDialog));
