import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const CODDialogStyle = makeStyles(theme => ({
    Imagecardmain: {
        // margin: '10px',
        backgroundColor: '#fff',
        padding: '12px 12px',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    txtResendPwd: {
        marginTop:'2%',
        marginLeft:'30%',
        // marginBottom: '30px', 
        textAlign: 'right',
        fontFamily: 'Nunito Sans', 
        fontWeight: 400,
        fontSize: '16px',
        color:'#9B9B9B',
        cursor:'pointer',
    },
    titletext: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS30,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.commonTitletxt,
        // marginBottom: '10px'
    },
    txtEnterOTP: {
        fontFamily: 'Nunito Sans', fontWeight: 400,
        fontSize: AppConstants.FONTSIZE.FS20,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.darkgray,
        marginTop: '15px',
        marginBottom: '15px'
    },
    buttonstyle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: '#262628',
    },
    IconStyle: {
        color: '#39A749',
        fontSize: AppConstants.FONTSIZE.FS20,
        margin: '10px'
    },

}));

export default CODDialogStyle;
