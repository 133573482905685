import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const NotificationsStyle = theme => ({
    
      newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
      },
      Contentcardmain_Notification: {
        // margin: '10px',
        backgroundColor: '#fff',
        padding:window.innerWidth<=420?'4%':'2%',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
      },
});
const combinedStyles = combineStyles(commonStyle, NotificationsStyle);
export default combinedStyles; 
