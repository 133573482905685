const Location = window.location.toString();

export const URLs = {
    base: Location.indexOf('medkart.in') != -1
        ? 'https://medkart.api.thestaging.cc/API/'  // Live API
        : 'https://medkart.api.thestaging.cc/API/', //Vmobee Local Server

    // Profile
    login: 'Profile/Login',
    sociallogin: 'Profile/LoginWithSocialMedia',
    signup: 'Profile/Signup',
    editprofile: 'Profile/EditProfile',

    resendotp: 'Profile/ResendOTP',
    verifyotp: 'Profile/VerifyOTP',
    changepassword: 'Profile/ChangePassword',
    forgotpassword: 'Profile/ForgotPassword',
    getmyprofile: 'Profile/GetMyProfile',
    contactus: 'Profile/ContactUs',
    contactUs: "Profile/ContactUs",
    getstorelist: "Profile/GetStoreList",
    notifications: "Profile/GetNotifications",
    getData: "Profile/GetData", //31/Augest/2019
    addfranchiserequest: "Profile/AddFranchiseRequest",
    ValidateForPrescriptions: "Profile/ValidateForPrescriptions",//02-02-2022

    // Misc
    searchmedicine: 'Misc/SearchMedicine',
    addeditaddress: 'Misc/AddEditAddress',
    deleteaddress: 'Misc/DeleteAddress',
    addeditpatient: "Misc/AddEditPatient",
    deletepatient: "Misc/DeletePatient",
    getmedicinedetails: "Misc/GetMedicineDetails",
    manageRefills: "Misc/ManageRefills",
    cancelOrReturnOrder: "Misc/CancelOrReturnOrder",//01/06/2020
    sendorderotp: "Misc/SendOrderOTP", //01/06/2020
    resendorderotp: "Misc/ResendOrderOTP", //01/06/2020
    verifyorderotp: "Misc/VerifyOrderOTP", //01/06/2020

    // Process
    addorder: 'Process/AddOrder',
    copyorder: 'Process/CopyOrder',
    processcart: 'Process/ProcessCart',
    addeditprescription: "Process/ProcessPrescription",
    ApplyCouponCode: "Process/ApplyCouponCode",//24/10/2020
    RemoveCouponCode: "Process/RemoveCouponCode",
    GeneratePayTMChecksum: "Process/GeneratePayTMChecksum",//6-11-2020    
    SendCart: "Process/SendCart",//04-02-2022
    InitiateCashFreeTransaction: 'Process/InitiateCashFreeTransaction',

    //for compare 1/4/2022
    Compare_GetMedicines: "Process/Compare_GetMedicines",//1/4/2022
    Compare_SearchMedicines: "Process/Compare_SearchMedicines",//1/4/2022
    Compare_AddMedicine: "Process/Compare_AddMedicine",//1/4/2022
    Compare_DeleteMedicines: "Process/Compare_DeleteMedicines",//1/4/2022
    Compare_EmailMedicines: "Process/Compare_EmailMedicines",//1/4/2022

    ContactUsWeb: 'Profile/ContactUsWeb',
    GetCoupons: 'Process/GetCoupons',//15-4-2022

    OrderToken: 'Process/InitiateCashFreeTransaction',
    NotifyUrl: 'WebHook/WebHook_Live_CashFree'
}