/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import medicineDetailStyle from './MedicineDetailStyle';

import { CssBaseline, Typography, Grid, Select, CircularProgress, Divider, Card, } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';

import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';

import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ShoppingCart from '@material-ui/icons/AddShoppingCart';
import PincodeDialog from "../../components/PincodeDialog/PincodeDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import Title from "../../components/Header/Title";
import images from '../../common/Images';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReactGA from 'react-ga';

class MedicineDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: parseInt(localStorage.getItem('cartvalue')),
            open: false,
            LoginDialog: false,
            mediumBrowser: false,
            // id: '',
            medicineid: '',
            allowedit: '',
            compositionid: '',
            medicinename: '',
            medicinepackof: '',
            toggle: false,
            medicineprice: '',
            IsLoading: true,
            medicinemrp: '',
            companyname: '',
            compositionname: '',
            packsize: '',
            OpenPincodeDialog: false,
            medicineClass: '',
            medicineCategory: '',
            medicineQuantity: '',
            medicineTypeName: '',
            selectedItem: -1,
            dataSource: [],
            cartid: '',
            medicinePercentageDifference: '',
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
        this.WsGetcart()
        this.setState({
            medicineid: this.props.history.location.state.id,
            cartid: this.props.history.location.state.cartid,
            compositionid: this.props.history.location.state.compositionid,
            medicineQuantity: this.props.history.location.state.medicinequantity
        })
        this.wsMedicineDetail(this.props.history.location.state.id, this.props.history.location.state.compositionid);
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    WsGetcart() {
        const PinCode = localStorage.getItem('pincode');
        APIController.GetCart('8', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), PinCode, '0')
            .then((response) => {
                if (response.error == null) {
                    if (response.object != null) {
                        this.setState({ cart: response.object[0].cartcount })
                        localStorage.setItem('cartvalue', JSON.stringify(response.object[0].cartcount))
                    }
                }
            })
    }

    // WsGetcart() {
    //     APIController.GetCart('8', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), '380051', '0')
    //         .then((response) => {
    //             if (response.error == null) {
    //                 // localStorage.setItem('cartvalue', JSON.stringify(response.object[0].cartcount))
    //             }
    //         })
    // }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }

    wsMedicineDetail(medicineid, compositionid) {
        this.setState({ IsLoading: true })
        APIController.MedicineDetail(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), medicineid, compositionid)
            .then((response) => {
                if (response.error === null) {

                    var TempAlternatives = response.object.alternatives;
                    TempAlternatives != null && TempAlternatives.map((item) => {

                        item.percentagedifference = Math.ceil(
                            (100 *
                                (item.saleprice / item.packsize -
                                    response.object.medicinedetails[0].saleprice /
                                    response.object.medicinedetails[0].packsize)) /
                            (response.object.medicinedetails[0].saleprice /
                                response.object.medicinedetails[0].packsize)
                        );
                    });
                    this.setState({ dataSource: response.object.alternatives })

                    this.setState({
                        IsLoading: false,
                        medicinename: response.object.medicinedetails[0].name,
                        medicineid: response.object.medicinedetails[0].id,
                        medicinepackof: response.object.medicinedetails[0].packsize,
                        medicineprice: response.object.medicinedetails[0].saleprice,
                        medicinemrp: response.object.medicinedetails[0].mrp,
                        packsize: response.object.medicinedetails[0].packsize,
                        companyname: response.object.medicinedetails[0].companyname,
                        compositionname: response.object.medicinedetails[0].compositionname,
                        medicineClass: response.object.medicinedetails[0].medicineclass,
                        allowedit: response.object.medicinedetails[0].allowedit,
                        medicineCategory: response.object.medicinedetails[0].category,
                        medicineQuantity: response.object.medicinedetails[0].quantity,
                        toggle: response.object.medicinedetails[0].quantity == 0 ? false : true,
                        medicineTypeName: response.object.medicinedetails[0].medicineTypeName,
                        // medicinePercentageDifference: response.object.medicinedetails[0].percentagedifference,
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    BuyNow(quantitydefoult) {
        AppConstants.userIdFromLocal() == '' ? this.setState({ LoginDialog: true }) :
            localStorage.getItem('pincode') == '' ?
                this.setState({ OpenPincodeDialog: true }) :
                this.wsAddToCart(quantitydefoult)
    }


    wsAddToCart(quantitydefoult) {
        const PinCode = localStorage.getItem('pincode');
        this.state.medicineQuantity = quantitydefoult;
        this.setState({ toggle: quantitydefoult >= 1 ? true : false })
        APIController.AddToCart(
            quantitydefoult != 0 ? 1 : 2,//opraion Id 1 for add 2 for delete
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode,
            1,//get data
            quantitydefoult, // quantity
            this.state.medicineid,
            this.state.cartid,
        ).then((response) => {
            if (response.error == null) {
                localStorage.setItem('cartvalue', JSON.stringify(response.object.data.length))
                this.setState({ cart: response.object.data.length })
                ToastsStore.warning(response.object.message)
            }
            else if (response.error.message.message && response.error.message.message.length > 0) {
            }
        })
        this.setState({ OpenPincodeDialog: false, })
    }

    handleStep(step) {
        this.setState({ open: step })
    }

    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }

    renderMedicineDetailList() {
        const { dataSource, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            // <Grid container direction="row">
            <Card raised={true} container direction='row' style={{ width: '100%', height: '97%', padding: '4%', marginTop: '1.5%' }}>
                <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', }}> Alternative Medicines  </Typography>
                <Grid container >
                    <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', marginTop: '15px', marginBottom: '15px' }}>
                        {dataSource === undefined || dataSource.length == 0 ? 'No alternate medicines available' : dataSource.length + '  alternate medicines available'} </Typography>
                </Grid>
                <Scrollbars autoHide autoHeight autoHeightMax='1500px'>
                    {dataSource === undefined || dataSource.length == 0 ? <Grid /> : <div>
                        {
                            dataSource.map((item, index) =>
                                <Grid container
                                    style={{ cursor: 'pointer' }}
                                >
                                    {/* <Grid container direction='row' alignItems='center'>
                                        <img src={this.state.medicineTypeName == "TABLET" ?
                                            images.Icon_tablet : this.state.medicineTypeName == "INJECTION" ?
                                                images.Icon_injection : this.state.medicineTypeName == "SYRUP" ?
                                                    images.Icon_syrup : this.state.medicineTypeName == "DROPS" ?
                                                        images.Icon_drop : this.state.medicineTypeName == "CREAM" ?
                                                            images.Icon_tube : images.Icon_pill}
                                            style={window.innerWidth <= 420 ? { width: '10%', height: '100%', } : { width: '6%' }} />
                                        {/* <LocalHospitalIcon style={{ width: '30px' }} /> 
                                        <Typography style={{ backgroundColor: '#fff', color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {item.name} </Typography>
                                    </Grid> */}
                                    <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>

                                        <Grid onClick={() => { this.wsMedicineDetail(item.id, item.compositionid); this.setState({ medicineid: item.id, compositionid: item.compositionid }) }} container xl={12} md={12} style={{ cursor: 'pointer' }} sm={12} xs={12} >
                                            <Grid alignItems={'center'} container xl={12} md={12} sm={12} xs={12} >
                                                <img src={item.medicineTypeName == "TABLET" ?
                                                    images.Icon_tablet : item.medicineTypeName == "INJECTION" ?
                                                        images.Icon_injection : item.medicineTypeName == "SYRUP" ?
                                                            images.Icon_syrup : item.medicineTypeName == "DROPS" ?
                                                                images.Icon_drop : item.medicineTypeName == "CREAM" ?
                                                                    images.Icon_tube : images.Icon_pill}
                                                    style={{ height: '20px', width: '20px' }} />
                                                &nbsp;&nbsp;&nbsp;<Typography className={classes.Medicinetitle} style={{ backgroundColor: '#fff', color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {item.name} </Typography>
                                                {item.companyname != null && item.companyname != '' && <>
                                                    &nbsp; <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                                    &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                                </>}

                                            </Grid>
                                            {/* <Grid container xl={6} md={6} sm={6} xs={6} >
                                                <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                                &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={mediumBrowser ? 'column' : "row"} style={mediumBrowser ? { marginLeft: '30px' } : { marginLeft: '30px' }}>
                                        <Grid container direction="row" alignItems='center'
                                            style={mediumBrowser ? { backgroundColor: '#fff', width: '70%', } : { backgroundColor: '#fff', width: '40%', }} >
                                            <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> Pack of:  </Typography>
                                            <Typography style={{ backgroundColor: '#fff', color: '#262628', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans', }}>&nbsp;{item.packsize} </Typography>&nbsp;&nbsp;
                                            <Typography style={{ backgroundColor: '#fff', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans', color: item.percentagedifference >= 0 ? 'red' : '#39A749' }}>
                                                {item.percentagedifference}%{" "}
                                            </Typography>
                                            {item.percentagedifference >= 0 ? <ArrowDropUpIcon style={{ backgroundColor: '#fff', fontSize: '20px', fontWeight: '700', fontFamily: 'Nunito Sans', color: 'red' }} />
                                                : <ArrowDropDownIcon style={{ backgroundColor: '#fff', fontSize: '20px', fontWeight: '700', fontFamily: 'Nunito Sans', color: '#39A749' }} />}
                                        </Grid>
                                        <Grid container direction="row" alignItems='center'
                                            style={mediumBrowser ? { backgroundColor: '#fff', width: '70%', } : { backgroundColor: '#fff', width: '50%', }} >
                                            <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> Price: </Typography>
                                            <Typography style={{ backgroundColor: '#fff', color: '#39A749', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans', marginLeft: '10px' }}> ₹ {item.saleprice} </Typography>
                                            <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', marginLeft: '5px', textDecorationLine: 'line-through', }}> ₹ {item.mrp} </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ cursor: 'pointer' }}>
                                        <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                        &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                    </Grid> */}
                                    <Divider style={{ height: '1px', width: '100%', marginTop: '15px', marginBottom: '20px' }} orientation="horizontal" />
                                </Grid>
                            )
                        }
                    </div>
                    }
                </Scrollbars>
            </Card>
        )
    }

    renderaddMedicineDetail() {
        return (
            // <Grid container
            //     direction="column"
            //     style={{ marginLeft: '10px', marginBottom: '100px' }}>
            <Card raised={true} container direction='row' style={{ textAlign: 'left', width: '100%', height: '97%', padding: '2%', marginTop: '1%' }}>
                <Grid container
                    direction="row"
                    justify='space-around'>
                    <Typography style={window.innerWidth <= 420 ? { width: '50%', color: '#262628', fontSize: AppConstants.FONTSIZE.FS25, fontWeight: '700', fontFamily: 'Nunito Sans', } : { width: '50%', color: '#262628', fontSize: AppConstants.FONTSIZE.FS35, fontWeight: '700', fontFamily: 'Nunito Sans', }}> {this.state.medicinename} </Typography>
                    <Grid container
                        direction="column"
                        justify='flex-end'
                        alignItems='flex-end'
                        style={{ width: '50%', }}>
                        <Typography style={window.innerWidth <= 420 ? { color: '#39A749', fontSize: AppConstants.FONTSIZE.FS25, fontWeight: '700', fontFamily: 'Nunito Sans', } : { color: '#39A749', fontSize: AppConstants.FONTSIZE.FS35, fontWeight: '700', fontFamily: 'Nunito Sans', }}> ₹ {this.state.medicineprice}</Typography>
                        <Typography style={{ color: '#9B9B9B', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', textDecorationLine: 'line-through', }}> ₹ {this.state.medicinemrp} </Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '20px' }} alignItems='center'>
                    <Grid container
                        direction="column"
                        style={{ width: '50%', backgroundColor: '#fff', }}>
                        <Typography style={{ color: '#9B9B9B', backgroundColor: '#fff', fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> By {this.state.companyname}</Typography>
                        {this.state.medicineTypeName == 'TABLET' ? (
                            <Typography style={{ color: '#262628', backgroundColor: '#fff', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>
                                {this.state.packsize == null || '' ? (
                                    <Typography></Typography>
                                ) : (this.state.medicinepackof + " " + "Tablet(s) in Strip")}
                            </Typography>
                        ) : (<Typography></Typography>)}
                        {this.state.medicineCategory == 'C' ? (
                            <Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>
                                Not For Online Sale
                            </Typography>
                        ) : this.state.medicineClass == 'DIS' ? (
                            <Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>
                                This Medicine is Discontinued
                            </Typography>
                        ) : (<Grid />)}
                    </Grid>

                    <Grid container
                        direction="row"
                        justify='flex-end'
                        style={{ width: '50%', backgroundColor: '#fff', }}>
                        {this.state.allowedit == '0' ? <ButtonBase
                            style={{
                                width: '70px',
                                height: '20%', color: '#fff',
                                backgroundColor: '#fff',
                                fontFamily: 'Nunito Sans',
                                fontSize: '14px'
                            }}
                            size="small"
                            variant="contained"
                            disabled
                            title='' />
                            : <div>
                                {


                                    this.state.toggle == false && this.state.medicineQuantity == 0
                                        ?
                                        <div>
                                            {

                                                this.state.medicineClass == 'DIS'
                                                    ? <ButtonBase
                                                        style={{
                                                            width: '80px',
                                                            height: '35px', color: 'red'
                                                        }}
                                                        size="small"
                                                        variant="contained"
                                                        disabled
                                                        title='DIS' />
                                                    :
                                                    this.state.medicineClass == '1' ? <ButtonBase
                                                        style={{
                                                            width: '70px',
                                                            height: '20%', color: '#fff',
                                                            backgroundColor: 'gray',
                                                            fontFamily: 'Nunito Sans',
                                                            fontSize: '14px'
                                                        }}
                                                        size="small"
                                                        variant="contained"
                                                        disabled
                                                        title='DIS' />
                                                        :




                                                        <Grid> {this.state.medicineCategory == 'A' ||
                                                            this.state.medicineCategory == 'B' ||
                                                            this.state.medicineCategory == 'D' ? (
                                                            <ButtonBase
                                                                style={{
                                                                    width: '70px',
                                                                    height: '35px',
                                                                }}
                                                                size="small"
                                                                variant="contained"
                                                                onClick={() => this.BuyNow('1')}
                                                                title='+ Add' />

                                                        ) : this.state.medicineCategory == 'F' ||
                                                            this.state.medicineCategory == 'E' ? (
                                                            <ButtonBase
                                                                style={{
                                                                    width: '70px',
                                                                    height: '35px',
                                                                }}
                                                                size="small"
                                                                variant="contained"
                                                                onClick={() => { this.BuyNow('1'); ToastsStore.warning("Price Needs to be Confirmed.") }}
                                                                title='+ Add' />
                                                        ) : (
                                                            <ButtonBase
                                                                style={{
                                                                    width: '80px',
                                                                    height: '35px', color: 'red'
                                                                }}
                                                                size="small"
                                                                variant="contained"
                                                                disabled
                                                                title='N/A' />
                                                        )
                                                        }
                                                        </Grid>
                                            }
                                        </div>

                                        :
                                        <Select native
                                            onChange={(text) => this.BuyNow(text.target.value)}
                                            style={{
                                                width: '80px',
                                                height: '35px',
                                                backgroundColor: '#fff',
                                                border: '1px solid ',
                                                borderColor: '#39A749',
                                                paddingLeft: '15px'
                                            }}>
                                            {/* <option style={{ backgroundColor: 'green', color: '#fff' }} value={''}>{this.state.medicineQuantity}</option> */}
                                            {
                                                this.props.history.location.state.quantityOption.map((item, index) => <option style={{ marginLeft: '20px' }} selected={item.medicinequantity == this.props.history.location.state.medicinequantity ? true : false} value={item.medicinequantity} >&nbsp;&nbsp;{item.medicinequantity}</option>)
                                            }
                                        </Select>}
                            </div>
                        }
                    </Grid>
                </Grid>
                {/* <Typography style={{ color: '#5C70FF', textAlign: 'right', marginTop: '15px' }}> Get 30%* off on your first medicine order </Typography> */}
                <Grid container
                    direction="column"
                    style={{ padding: '15px', backgroundColor: '#F8F8F8', marginTop: '20px' }}>
                    <Typography style={{ color: '#262628', fontSize: '24px', fontWeight: '700', fontFamily: 'Nunito Sans', }}>Composition: </Typography>
                    {this.state.compositionname == null ? <Typography style={{ color: '#262628', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>Composition not available</Typography> : <Typography style={{ color: '#262628', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>{this.state.compositionname} </Typography>}
                </Grid>
                {/* <Grid container
                    direction="row"
                    style={{ marginTop: '20px' }}>
                    <Typography style={{ color: '#262628', fontSize: '24px', fontWeight: 'bold', }}> Medical Description </Typography>
                    <Typography style={{ color: '#9B9B9B', marginTop: '10px' }}> This medicine contains Acetylsalicylic acid or Aspirin that belongs to the group of medicines called antiplatelet agent (to treat blood clots). </Typography>
                </Grid>
                <Grid container
                    direction="row"
                >
                    <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> Uses of Ecosprin 75mg Strip Of 14 Tablets </Typography>
                    <Typography style={{ color: '#9B9B9B', fontSize: '14px', }}> Prevention of heart attack.
                    Prevention of strokes.
                    Prevention of heart problems like stable or unstable angina (chest pain).
                    Treatment of acute heart attack.
                    Prevention of blood clot formation post heart surgery. </Typography>
                </Grid> */}
            </Card>
        )
    }
    medicineDetailFromsearch(medicineid, compositionid, quantity) {
        this.wsMedicineDetail(medicineid, compositionid, quantity)
        // this.setState({
        //     medicinename: response.medicinedetails[0].name,
        //     dataSource: response.alternatives,
        //     toggle: false,
        //     medicineid: response.medicinedetails[0].id,
        //     cartid: response.medicinedetails[0].cartid,
        //     medicinepackof: response.medicinedetails[0].packsize,
        //     medicineprice: response.medicinedetails[0].saleprice,
        //     medicinemrp: response.medicinedetails[0].mrp,
        //     packsize: response.medicinedetails[0].packsize,
        //     companyname: response.medicinedetails[0].companyname,
        //     compositionname: response.medicinedetails[0].compositionname,
        //     medicineClass: response.medicinedetails[0].medicineclass,
        //     medicineCategory: response.medicinedetails[0].category,
        //     medicineQuantity: response.medicinedetails[0].quantity,
        //     medicineTypeName: response.medicinedetails[0].medicineTypeName,
        // })
    }

    render() {
        const { classes } = this.props;
        const { IsLoading, mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Medicine Details'} history={this.props.history} />
                    <ToastsContainer store={ToastsStore} />
                    {IsLoading ? this.LoadingView() :
                        <Grid container style={{ width: '100%' }} justify='center'>
                            <Grid container style={{ width: '98.3%', }} justify={mediumBrowser ? 'center' : 'space-between'}>
                                <Grid container style={mediumBrowser ? { width: '100%' } : { width: '59%' }}>
                                    {this.renderaddMedicineDetail()}
                                </Grid>
                                {/* {mediumBrowser? <Grid></Grid> : <Divider style={{ height: '450px', width: '0.1%', }} /> } */}
                                <Grid container style={mediumBrowser ? { width: '100%' } : { width: '39%' }} >
                                    {this.renderMedicineDetailList()}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </main>
                <ConfirmDialog
                    history={this.props.history}
                    onClose={() => this.setState({ LoginDialog: false })}
                    open={this.state.LoginDialog}
                    onClick={() => this.setState({ LoginDialog: false })}
                />
                <PincodeDialog
                    history={this.props.history}
                    onClose={() => this.setState({ OpenPincodeDialog: false })}
                    open={this.state.OpenPincodeDialog}
                    index={-1}
                    wsAddToCart={this.wsAddToCart.bind(this)}
                    onClick={() => this.setState({ OpenPincodeDialog: false })}
                />
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(medicineDetailStyle)(withTranslation('translation')(MedicineDetail));
{/* {this.state.medicineClass == 'DIS' ||
                            this.state.medicineCategory == 'C' ? (
                                <Grid />
                            ) : (<Select native
                                onChange={(text) => this.setState({ medicineQuantity: text.target.value })}
                                style={{
                                    // width: '100px',
                                    backgroundColor: '#fff',
                                    border: '1px solid ',
                                    borderColor: 'green',
                                    margin: '5px',
                                }}>
                                <option value={''}>Qty</option>
                                {
                                    this.props.history.location.state.quantityOption.map((item, index) => <option value={item.medicinequantity} >{this.state.medicineQuantity == '' ? item.medicinequantity : this.state.medicineQuantity}</option>)
                                }
                            </Select>)} */}