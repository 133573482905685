import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const CancelOrderDialogStyle = makeStyles(theme => ({
  Imagecardmain: {
    // margin: '10px',
    backgroundColor: '#fff',
    padding: '12px 12px',
    border: '2px solid ',
    borderColor: theme.palette.headerText,
    borderRadius: '0px 0px 0px 0px',
  },
  titletext: {
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: AppConstants.FONTSIZE.FS30,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.commonTitletxt,
    marginBottom: '10px'
  },
  OrderIdtext: {
    fontFamily: 'Nunito Sans', fontWeight: 400,
    fontSize: AppConstants.FONTSIZE.FS20,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: '25px'
  },
  issuetitletext: {
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: AppConstants.FONTSIZE.FS18,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.primary.main,
    marginBottom: '10px'
  },
  imagetitletext: {
    fontFamily: 'Nunito Sans', fontWeight: 400,
    fontSize: AppConstants.FONTSIZE.FS18,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.darkgray,
    marginTop: '15px'
  },
  Issuetext: {
    fontFamily: 'Nunito Sans', fontWeight: 400,
    fontSize: AppConstants.FONTSIZE.FS18,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.commonTitletxt,
    // marginBottom: '5px'
  },
  AvatarImg: {
    height: '150px',
    width: '100%',
    // borderRadius: '75px',
    // marginBottom:'15%'
  },
  Imagecardmainwithoutborder: {
    margin: '5px',
    backgroundColor: '#fff',
    // padding: '12px 12px',
    borderRadius: '0px 0px 0px 0px',
  },
  buttonstyle: {
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: AppConstants.FONTSIZE.FS16,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: '#262628',
  },
  IconStyle: {
    color: '#39A749',
    fontSize: AppConstants.FONTSIZE.FS20,
    margin: '10px'
  },

}));

export default CancelOrderDialogStyle;
