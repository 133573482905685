import React from 'react';
import { withTranslation } from 'react-i18next';
import Compare from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import * as AppConstants from '../../common/Constants';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import ConfirmDialogStyle from './ConfirmDialogStyle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
// import * as AppConstants from '../../common/Constants';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";

const ConfirmDialog = props => {
    const classes = ConfirmDialogStyle();
    const { open, onClose, onClick } = props;
    // const [open, setopen] = React.useState(open);
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '700px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center'
                    style={window.innerWidth <= 920 ? { width: '100%' }:{ height: '250px', width: '500px' }}>
                    {window.innerWidth <= 920 ? <Grid direction={'column'} container justify='center' style={window.innerWidth <= 920 ? { width: '80%',padding:'5%' }:{ width: '80%' }}>
                        <Typography style={{ color: '#282628', fontSize: AppConstants.FONTSIZE.FS20, fontFamily: 'Nunito Sans', fontWeight: 700,textAlign:'center' }}>{'Please Login'}</Typography>
                        <Typography style={{ color: '#282628', fontSize: AppConstants.FONTSIZE.FS20, fontFamily: 'Nunito Sans', fontWeight: 700,textAlign:'center' }}>{'Are You Sure?'}</Typography>
                    </Grid>:<Grid direction={'row'} container justify='center' style={{ width: '80%' }}>
                        <Typography><h1>{'Please Login'}</h1></Typography>
                    </Grid>}

                    <Grid direction={'row'} container justify='space-between' style={window.innerWidth <= 920 ? { width: '80%',marginBottom:'2%' }:{ width: '80%' }}>
                        <ButtonBase  title={'OK'}
                            variant="contained"
                            style={window.innerWidth <= 920 ? { width: '45%' }:{ width: '150px' }}
                            onClick={() => { props.history.push(AppConstants.SCREENS.LOGIN) }} />
                        <ButtonBase  title={'CANCEL'}
                            variant="contained"
                            style={window.innerWidth <= 920 ? { width: '45%' }:{ width: '150px' }}
                            onClick={onClick} />
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (ConfirmDialog);
