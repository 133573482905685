import { makeStyles } from '@material-ui/core/styles';

const filledTextFieldStyle = makeStyles(theme => ({
  textField: {
    // backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid #f1f1f5',
    color: '#262628',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    height:'50px',
    // lineHeight: 1.57
  },
  label: {
    color: '#B0B4BF',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
    // lineHeight: 1.57
  }
}));

export default filledTextFieldStyle;
