import React, { useState, useEffect } from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import OrderConfirmDialogStyle from './OrderConfirmDialogStyle';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import * as moment from 'moment';
import { Images } from '../../common';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';

const OrderConfirmDialog = props => {
    const classes = OrderConfirmDialogStyle();
    const { open, Amount, onClose, onBack, OrderId, PaymentId, Title, OnDetail, OrderReferenceNo, EstimateDelivery } = props;

    const wsCopyOrder = (OrderId) => {
        APIController.CopyOrder(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(),
            OrderId)
            .then((response) => {
                if (response.error == null) {
                    ToastsStore.warning(response.object.message)
                    props.history.push({
                        pathname: '/cart',
                    });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '700px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
                    <Grid container justify='space-between' alignItems='center' style={{ width: '98%' }}>
                        <Grid container justify='center' alignItems='center' direction='column' className={classes.Contentcardmainwithoutborder} style={{ width: '100%', height: '100%' }}>
                            <img src={Images.Successfulicon} alt="" />
                            <Typography className={classes.txtCongress}>Congratulations!</Typography>
                            <Typography className={classes.txtOrderSuccess}>{Title}</Typography>
                            {/* {PaymentId == '' ? <div /> : <Typography className={classes.txtPaymentSuccess}>#{PaymentId}</Typography>} */}
                            <Grid style={{ width: '100%',marginBottom:'10px' }} direction={'row'} container justify='center' alignItems='center'>
                            <Typography className={classes.txtSuccessAmount}>Order #{OrderReferenceNo}</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography className={classes.txtSuccessAmount}>₹ {Amount}</Typography>
                            </Grid>
                            {PaymentId == '' ? <Typography className={classes.txtOrderSuccessMsg}>Once our pharmacist verifies your order with the prescription, you will be notified for Payment. Normally, It takes up to 4 working hours</Typography> : <div />}
                        </Grid>
                        {/* <Grid container direction={'row'} style={{ width: '100%', marginTop: 15, marginBottom: '25px' }} alignItems='center' justify={'center'}>
                            <ButtonBase title={'Back To Home'} variant="contained"
                                style={{ width: '162px' }}
                                onClick={() => {
                                    props.history.push(AppConstants.SCREENS.HOMEPAGE)
                                }} />
                        </Grid> */}
                        <Grid container direction='column' className={classes.Contentcardmainwithoutborder} style={{ width: '100%', height: '100%',marginTop:'5px' }}>
                            <Grid container justify='center' alignItems='center' direction='column' style={{ width: '100%' }}>
                                {/* <Grid style={{ width: '80%', marginBottom: '5px' }} direction={'row'} container>
                                    <Typography className={classes.titleText}>Order #{OrderReferenceNo}</Typography>
                                </Grid> */}
                                <Grid style={{ width: '80%', marginBottom: '5px', }} direction={'row'} container alignItems='center' justify='center'>
                                    <Typography className={classes.txtOrderSuccess}>Expected Delivery : </Typography>
                                    <Typography className={classes.txtOrderSuccess}> {moment(EstimateDelivery).format("DD-MMM-YYYY")}</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginBottom: '15px' }} direction={'row'} container justify='space-around' alignItems='center'>
                                    {/* <ButtonBase type="submit" title={'Copy Order'} variant="contained"
                                        //  className={classes.btnCopyOrder}
                                        style={{ width: '40%' }}
                                        onClick={() => { wsCopyOrder(OrderId); }} /> */}
                                    <ButtonBase title={'Home'} variant="contained"
                                        style={{ width: '120px' }}
                                        onClick={() => {
                                            props.history.push(AppConstants.SCREENS.HOMEPAGE)
                                    }} />
                                    <ButtonBase type="submit" title={'Details'}
                                        variant="contained"
                                        style={{width: '120px' }}
                                        // className={classes.btnDetails}
                                        onClick={PaymentId != '' ? OnDetail : () => { props.history.push({ pathname: '/myorder', state: { FromDialog: true, orderConfomID: OrderId } }); }} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (OrderConfirmDialog);
