import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const AddressesStyle = theme => ({
    
      newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
      },
      inputMask_Field: {
        borderRadius: '5px',
        border: '1px solid #f1f1f5',
        color: '#262628',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        fontWeight: 400,
        height:'50px',
        width:'100%'
      },
      inputMask_label: {
        color: '#B0B4BF',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 16,
        // lineHeight: 1.57
      },
      ContentSelectcardmainAddress: {
        width:window.innerWidth<=420?'92%':'98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
      },
      ContentcardmainAddress: {
        width:window.innerWidth<=420?'92%':'98%',
        margin: '10px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
      },
});
const combinedStyles = combineStyles(commonStyle, AddressesStyle);
export default combinedStyles; 
