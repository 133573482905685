import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const OrderConfirmDialogStyle = makeStyles(theme => ({
    txtOrderSuccess: {
        fontFamily: 'Nunito Sans',
         fontWeight: 700,
        fontSize: '16px',
        lineHeight: '34px',
        color: '#b5bbc1',
        paddingBottom: '15px',
        color: '#262628',
    },
    txtOrderSuccessMsg: {
        // width: '50%',
        textAlign: 'center',
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: '16px',
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#9B9B9B',
    },
    txtPaymentSuccess: {
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        fontSize: 15,
        lineHeight: 1.5,
        color: '#39A749',
        paddingBottom: '15px',
    },
    txtSuccessAmount: {
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: 1.5,
        color: '#252320',
        // paddingBottom: '15px',
    },
    txtDeliveryDate: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        letterSpacing: -0.5,
        color: '#000000',
        lineHeight: 1.8,
    },
    btnCopyOrder: {
        width: '50%',
        borderRadius: '0px 0px 0px 5px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    },
    btnDetails: {
        width: '45%',
        borderRadius: '0px 0px 5px 0px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    },
    txtCongress:{
      fontFamily: 'Nunito Sans',
      fontSize: '36px',
      lineHeight: '48px',
      // letterSpacing: 0.1,
      fontWeight: 500,
      color:'#262628'
    }
    
}));

export default OrderConfirmDialogStyle;
