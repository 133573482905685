import React from 'react';
// import * as AppConstants from '../../common/Constants';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import PayAgreeDialogStyle from './PayAgreeDialogStyle';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";

const PayAgreeDialog = props => {
    const classes = PayAgreeDialogStyle();
    const { open, onOkPress, onClose, onClick } = props;

    
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '700px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center'
                    style={window.innerWidth<=920?{ height: '400px', width: '300px' }:{ height: '400px', width: '500px' }}>
                    <Grid direction={'row'} container style={{ width: '80%' }}>
                        {/* <Typography><h1>{'You Need to Read First'}</h1></Typography> */}
                        <Typography className={classes.txtModalDescription}>Return Policy will apply only if</Typography>
                        <Typography className={classes.txtModalDescription}>1. Product(s) delivered do not match your order</Typography>
                        <Typography className={classes.txtModalDescription}>2. Product(s) delivered are past or near to its expiry date (medicines with an expiry date of less than 03 months shall be considered as near expiry)</Typography>
                        <Typography className={classes.txtModalDescription}>3. Product(s) delivered were damaged in transit (do not to accept any product which has a tampered seal)</Typography>
                    </Grid>

                    <Grid direction={'row'} container justify={window.innerWidth<=920?'center':'space-between'} style={{ width: '80%', }}>
                        <ButtonBase title={'OK'}
                            variant="contained"
                            style={window.innerWidth<=920?{ width: '150px',marginBottom:'5%' }:{ width: '150px' }}
                            onClick={ onOkPress } />
                        <ButtonBase title={'CANCEL'}
                            variant="contained"
                            style={window.innerWidth<=920?{ width: '150px',marginBottom:'5%' }:{ width: '150px' }}
                            onClick={onClick} />
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (PayAgreeDialog);
