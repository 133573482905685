import React from 'react';
import * as AppConstants from '../../common/Constants';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import TopMenuStyle from './TopMenuStyle';
import PersonIcon from '@material-ui/icons/Person';
import AddressIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ChangePwdIcon from '@material-ui/icons/Lock';
const TopMenu = props => {
    const classes = TopMenuStyle();
    const { isShow } = props;
    return (
        <div>
            {isShow == false ? <div /> :
                <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
                    <Grid container justify='space-between' direction={'row'} alignItems='center' style={{ width: '75%' }}>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.EDITPROFILE) }}><AccountCircleOutlinedIcon className={classes.IconStyle} />MY PROFILE</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.CHANGEPASSWORD) }}><ChangePwdIcon className={classes.IconStyle} />CHANGE PASSWORD</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push({ pathname: '/addresses', state: { PatientIdfromPatient: '', Fromlistpage: '1' } }) }}><AddressIcon className={classes.IconStyle} />MY ADDRESSES</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push({ pathname: '/patient', state: { Fromlistpage: '1' } }) }}><PersonIcon className={classes.IconStyle} />MY PATIENTS</Button>
                        <Button className={classes.buttonstyle} onClick={() => { props.history.push(AppConstants.SCREENS.OLDPRESCRIPTION) }}><AssignmentIcon className={classes.IconStyle} />OLD PRESCRIPTIONS</Button>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default (TopMenu);
