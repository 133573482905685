/*eslint-disable eqeqeq*/
import React from 'react';
import * as AppConstants from '../../common/Constants';
import CancelIcon from '@material-ui/icons/Cancel';
import { Typography, Grid } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import MobileLoginDialogStyle from './MobileLoginDialogStyle';
import * as APIController from '../../api/APIController';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import FilledTextFieldUpdated from '../FilledTextFieldUpdated/FilledTextFieldUpdated';
import ButtonBase from '../ButtonBase/ButtonBase';

import OtpInput from 'react-otp-input';

const MobileLoginDialog = props => {
    const classes = MobileLoginDialogStyle();
    const { open, onClose, ImageDataLocal } = props;

    const [MobileNo, setMobileNo] = React.useState('');
    const [OTP, setOTP] = React.useState('');
    const [OTPView, setOTPView] = React.useState(false);
    const [OTPSuccess, setOTPSuccess] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(false);
    const [ValidateData, setValidateData] = React.useState([]);
    const [mediumBrowser, setMediumBrowser] = React.useState(window.innerWidth <= 420 ? true : false);

    React.useEffect(() => {
        // WsContactMe()
    }, [])

    const validateForm = (mobileno) => {
        if (mobileno === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        } else if (!mobileno.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDMOBILENO);
        } else if (mobileno.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        } else {
            wsValidateForPrescriptions(mobileno)
        }
    }

    const wsValidateForPrescriptions = (mobileno) => {
        setisLoading(true)
        APIController.ValidateForPrescriptions(mobileno)
            .then((response) => {
                if (response.object.status == 1) {
                    setOTPView(true)
                    setValidateData(response.object.data)
                    ToastsStore.success(response.object.message);
                } else {
                    ToastsStore.success(response.object.message);
                }
                setisLoading(false)
            });
    }

    const wsVerifyOTP = () => {
        setisLoading(true)
        APIController.verifyOtp(ValidateData[0].id, MobileNo, OTP).then((response) => {
            if (response.object.status == 1) {
                // let user = appUser()
                // user.id = this.props.history.location.state.data.id
                // user.mobileno = this.props.history.location.state.data.mobileno
                // user.fullname = this.props.history.location.state.data.fullname
                // user.writeToLocalStorage()
                // this.setState({ loadingVerify: false });
                // this.props.history.push(AppConstants.SCREENS.HOMEPAGE);

                ToastsStore.success(response.object.message);
                ConvertToBase64(MobileNo, ValidateData[0].id)
            } else if (response.object.status == 0) {
                ToastsStore.warning(response.object.message);
                // setisLoading(false)
            } else {
                ToastsStore.warning(response.error.message);
                setisLoading(false)
            }
        });
    }

    const ConvertToBase64 = (MobileNo, userId) => {
        const image2base64 = require('image-to-base64');
        ImageDataLocal.map((item) => {
            image2base64(item.imagePreview)
                .then((response) => {
                    wsAddPrescription(MobileNo, userId, item.imagename, response)
                }).catch((error) => {
                })
        })
    }

    const wsAddPrescription = (MobileNo, userId, ImageName, Image64) => {
        APIController.AddPrescription(
            1,//opration id
            userId,//userid
            MobileNo,//mobileno
            ImageName,//imagename,
            Image64,//imagepath,
            1,//getdata
            0,//is used
        ).then((responseJson) => {
            setOTPSuccess(true)
        })
        setisLoading(false)
    }

    return (
        <React.Fragment>
            <ToastsContainer store={ToastsStore} />
            <Alertdialog
                onClose={onClose}
                style={{ height: '100%' }}
                open={open}>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '2%', alignItems: 'center' }}>
                    <Typography style={{ marginLeft: '3%', fontWeight: 600 }}>Verify Mobile No</Typography>
                    <CancelIcon style={{ color: '#000', fontSize: AppConstants.FONTSIZE.FS30, alignSelf: 'self-end', cursor: 'pointer' }}
                        onClick={() => onClose(true)} />
                </div>
                {!OTPSuccess ?
                    <>
                        <Grid container style={{ width: '100%', padding: '5%' }} alignItems="center" justify={'flex-start'}>
                            <div style={mediumBrowser ? { display: 'grid', width: '600px' } : { display: 'flex', width: '600px' }}>
                                <FilledTextFieldUpdated
                                    rows={1}
                                    name="MobileNo"
                                    label='Enter your Mobile Number'
                                    onChange={(e) => setMobileNo(e.target.value)}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                                <ButtonBase type="submit" isLoading={isLoading} title={'Send OTP'} variant="contained" style={mediumBrowser ? { width: '100%', margin: '5% 0', } : { width: '50%', margin: '0 5%', }}
                                    onClick={() => validateForm(MobileNo)} />
                            </div>
                            {OTPView &&
                                <div style={mediumBrowser ? { display: 'grid', width: '600px' } : { display: 'flex', width: '600px' }}>
                                    <Grid container style={{ width: '100%', marginTop: '5%' }} alignItems="center">
                                        <OtpInput
                                            onChange={(text) => setOTP(text)}
                                            value={OTP}
                                            inputStyle={{ width: '50%', height: '100%', margin: '10%', borderRadius: '15%', color: '#262628', fontFamily: 'Nunito Sans', }}
                                            isInputNum={false}
                                            focusStyle={{ color: '#39A749' }}
                                            shouldAutoFocus={true} />
                                    </Grid>
                                    <ButtonBase type="submit" isLoading={isLoading} title={'Verify OTP'} variant="contained" style={mediumBrowser ? { width: '100%', margin: '5% 0', } : { width: '50%', margin: '5% 5% 0 5%', }}
                                        onClick={() => wsVerifyOTP()} />
                                </div>}
                        </Grid>
                    </>
                    : <div style={mediumBrowser ? { display: 'grid', textAlign: 'center', width: '275px' } : { display: 'grid', textAlign: 'center', width: '400px' }}>
                        <Typography style={{ margin: '5%', fontWeight: 600, fontSize: '23px' }}>Congratulations</Typography>
                        <Typography style={mediumBrowser ? { margin: '5% 10% 15%', fontWeight: 400 } : { margin: '5%', fontWeight: 400 }}>We have received your prescription. Our agent will call you as early as possible</Typography>
                    </div>}
            </Alertdialog>
        </React.Fragment >
    );
};


export default (MobileLoginDialog);
