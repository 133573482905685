import './App.css';
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';
import React, { useState }  from 'react';

function Cashfree() {
  const [orderToken, setOrderToken] = useState('7RyleyCOzRftapYCmSDb');
  const [isProd, setisProd] = useState(false);

  const cbs = (data) => {
    if (data.order && data.order.status === 'PAID') {
      alert('order is paid. Call api to verify');
    }
  };
  const cbf = (data) => {
    alert(data.order.errorText);
  };
  const renderDropin = () => {
    if (orderToken === '') {
      alert('Order Token is empty');
      return;
    }
  
    let parent = document.getElementById('drop_in_container');
    parent.innerHTML = '';
    let cashfree;
    if (isProd) {
      cashfree = new cashfreeProd.Cashfree();
    } else {
      cashfree = new cashfreeSandbox.Cashfree();
    }
    console.log('before Initialisation');
    cashfree.initialiseDropin(parent, {
      orderToken,
      onSuccess: cbs,
      onFailure: cbf,
      "components": [
        "order-details",
        "card",
        "netbanking",
        "app",
        "upi"
    ],
    "style": {
      //to be replaced by the desired values
      "backgroundColor": "#ffffff",
      "color": "#11385b",
      "fontFamily": "Lato",
      "fontSize": "14px",
      "errorColor": "#ff0000",
      "theme": "light", //(or dark)
  }
    });
    console.log('after Initialisation');
  };

  
  return (
    <div className="App">
      <button className="btn-render mt-2" onClick={renderDropin}>
        Pay now
      </button>

      
      <div
        className="dropin-parent"
        id="drop_in_container"
        style={{ height: '600px' }}
      >
       
      </div>
    </div>
  );
}

export default Cashfree;
