import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const refillStyle = theme => ({
    mainContainerLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    mainContainerRight: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    ContentcardmainCart: {
        width: window.innerWidth <= 420 ? '98%' : '98%',
        // margin: '10px',
        marginTop: '6px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentSelectcardmainRefill: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentcardmainRefill: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
});
const combinedStyles = combineStyles(commonStyle, refillStyle);
export default combinedStyles; 
