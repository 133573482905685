import { createMuiTheme } from '@material-ui/core';

const styles = createMuiTheme({
    palette: {
        primary: {
            // main: '#118810',
            main:'#39A749',
            redFaded: '#ffe6e6'
        },
        secondary: {
            main: '#e8f1ff'
        },
        textColor: '#252631',
        commonTitletxt:'#262628',
        inputBackground: '#f3f3f3',
        headerText: '#E1E1E5',
        darkgray: '#92929d',
        green:'green',
        red:'red',
        mobileBackground: '#f5f6f9',
        green:'green',
    },
    typography: {
        fontFamily: [
            'Inter',
            'Nunito Sans',
        ].join(','),
        button: {
            textTransform: 'none'
        }
    },
    spacing: 4
});

export default styles;
