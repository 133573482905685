import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TestimonialsStyle from './TestimonialsStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, CssBaseline, Typography, Avatar, ButtonBase, Button } from '@material-ui/core';
import { Images } from '../../common';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { autoPlay } from 'react-swipeable-views-utils';
import Compare from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart1 from "../WhyMedkart/WhyMedkart";
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import TestimonialsIcon from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import Title from "../../components/Header/Title";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

class Testimonials extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
            activeStep: 0,
            tutorialSteps: [
                { Name: '' },
            ]
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    handleNext = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };

    handleBack = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };

    handleStepChange = step => {

        this.setState({ activeStep: step })
    };
    renderForm() {
        const { classes } = this.props;
        const { activeStep, tutorialSteps, mediumBrowser } = this.state;

        return (

            <Grid style={{ width: '98%' }} container direction={'row'} justify="center" alignItems='center'>
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={this.handleStepChange}
                    enableMouseEvents >
                    {tutorialSteps.map((step, index) => (
                        <div key={step.label}>

                            {mediumBrowser ? <Grid container style={{ width: '100%' }} justify="space-between">
                                <Grid className={classes.Contentcard} style={{ width: '100%', marginTop: '5%' }} container>
                                    <Typography className={classes.title}>
                                        {'"I work at Medkart Pharmacy. The joy of a smiling customers after purchasing medicines at a much cheaper rate is really satisfying. I remember a Customer who had bought a cancer Medicine worth Rs 7000, his medicine was billed for 3250 at Medkart and we were both overwhelmed by the difference we created. This joy is indescribable."'}
                                    </Typography>
                                    <Grid container justify='flex-start' alignItems='flex-end' style={{ marginTop: '1%' }}>
                                        <Avatar alt="jigneshsolanki" src={Images.Testimonial1} className={classes.AvatarImg} />
                                        <Typography className={classes.NameStyle}>Jignesh Solanki</Typography>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.Contentcard} style={{ width: '100%', marginTop: '5%' }} container >
                                    <Typography className={classes.title}>
                                        {'"After purchasing from Medkart, I realized that we can buy quality generic medicines at much cheaper rates. I even got to learn a lot about generic medicines from my visit. "'}
                                    </Typography>

                                    <Grid container justify='flex-start' alignItems='flex-end' style={{ marginTop: '1%' }}>
                                        <Avatar alt="MegnaVyas" src={Images.Testimonial2} className={classes.AvatarImg} />
                                        <Typography className={classes.NameStyle}>Meghna Vyas</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> : <Grid container style={{ width: '100%' }} justify="space-between">
                                    <Grid className={classes.Contentcard} style={mediumBrowser ? { width: '90%', marginTop: '5%' } : { width: '48%' }} container  >
                                        <Typography className={classes.title}>
                                            {'"I work at Medkart Pharmacy. The joy of a smiling customers after purchasing medicines at a much cheaper rate is really satisfying. I remember a Customer who had bought a cancer Medicine worth Rs 7000, his medicine was billed for 3250 at Medkart and we were both overwhelmed by the difference we created. This joy is indescribable."'}
                                        </Typography>
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <Grid container justify='flex-start' alignItems='flex-end' style={mediumBrowser ? {} : { position: 'absolute', bottom: '10px' }}>
                                            <Avatar alt="jigneshsolanki" src={Images.Testimonial1} className={classes.AvatarImg} />
                                            <Typography className={classes.NameStyle}>Jignesh Solanki</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.Contentcard} style={mediumBrowser ? { width: '90%', marginTop: '5%' } : { width: '48%' }} container >
                                        <Typography className={classes.title}>
                                            {'"After purchasing from Medkart, I realized that we can buy quality generic medicines at much cheaper rates. I even got to learn a lot about generic medicines from my visit. "'}
                                        </Typography>
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <Grid container justify='flex-start' alignItems='flex-end' style={{ position: 'absolute', bottom: '10px' }}>
                                            <Avatar alt="MegnaVyas" src={Images.Testimonial2} className={classes.AvatarImg} />
                                            <Typography className={classes.NameStyle}>Meghna Vyas</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </div>
                    ))}
                </SwipeableViews>
            </Grid>

        );
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Checkout Medkart reviews by customers</title>
                    <meta name="description" content="Know what people think about Medkart. Checkout medkart reviews to know about customer experience. Here is the testimonials by Medkart customers." />
                    <meta name="keywords" content="Medkart Reviews | People think about Medkart | Customer Experience" />
                </Helmet>
                <CssBaseline />
                <Grid style={{ width: '100%' }}>
                    <CssBaseline />
                    {this.props.fromHomepage == true ? '' : <MedkartHeader dataFromsearch={this.dataFromsearch.bind(this)} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                    <main className={classes.main}>
                        <Title title={'Testimonials'} isBack={false} history={this.props.history} />
                        <Grid container justify='center' alignItems='center' style={{ width: '100%', marginTop: '10px' }}>

                            {this.renderForm()}
                        </Grid>
                        {/* <Grid container style={{ width: '99%', marginTop: '20px', }} justify="flex-end" >
                            <Grid container style={{ width: '110px' }} justify="space-between">
                                <ButtonBase onClick={() => this.handleBack()} variant="contained" style={{
                                    fontWeight: 'bold',
                                    backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px'
                                }}>
                                    <KeyboardArrowLeft style={{ color: '#fff' }} />
                                </ButtonBase>
                                <ButtonBase onClick={() => this.handleNext()} variant="contained" style={{
                                    fontWeight: 'bold',
                                    backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px'
                                }}>
                                    <KeyboardArrowRight style={{ color: '#fff' }} />

                                </ButtonBase>
                            </Grid>
                        </Grid> */}
                        {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />}
                    </main>
                    {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                </Grid>

            </React.Fragment>
        );
    }
}



export default withStyles(TestimonialsStyle)(Testimonials);
