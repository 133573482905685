import React, { Component, useState } from 'react';
import {
    Container, CssBaseline, Typography, Grid, InputAdornment,
    IconButton, Avatar, Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import loginStyle from './LoginStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { User } from '../../prototypes/User'
import { appUser } from '../../App'
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
// import FilledTextField from '../../components/FilledTextField/FilledTextField';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";
import FacebookLogin from 'react-facebook-login';
import LocationIcon from '@material-ui/icons/HighlightOff';
import ReactGA from 'react-ga';
import Title from "../../components/Header/Title";
//import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';
//const clientId = '50927884314-oevubkdn9vn3b94luhcqaeok3vinh7k8.apps.googleusercontent.com'
//const clientId = '903043039126-l2kib8flng5abmgagko4odf61ji6fgj8.apps.googleusercontent.com'
const clientId = '518409753847-jk4updqui7gnubfmvl2qvuhuunjpkljo.apps.googleusercontent.com'
const appId = '1354835421334285'
//const appId = '903043039126'

const success = response => {
}

const error = response => {
    console.log('error response:', response)
}

const loading = () => {
}

const logout = () => {
}

const responseFacebook = (response) => {
}


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            MobileNo: '',
            Password: '',
            showPassword: false,
            loading: false,
            FbData: '',
            facebookid: "",
            googleid: '',
            email: "",
            firstname: "",
            lastlatitude: '',
            lastlongitude: '',
            postal_Code: '',
            name: '',
        }
    }
    componentWillMount() {
        this.LoginCheck()
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        if (this.props.coords != null) {
            this.setState({ lastlatitude: this.props.coords.latitude, lastlongitude: this.props.coords.longitude })
            this.getLocationFromLatLong(this.props.coords.latitude, this.props.coords.longitude);
            // this.wsLogin();
        }
        else {
            localStorage.setItem('city', '');
            localStorage.setItem('pincode', '');
            // this.wsLogin();
        }

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsLogin()
        }

    }
    LoginCheck() {
        if (localStorage.getItem('user') != null) {
            this.props.history.push(AppConstants.SCREENS.HOMEPAGE)
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.MobileNo === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        }
        else if (!this.state.MobileNo.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDMOBILENO);
        }
        else if (this.state.MobileNo.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        }
        else if (this.state.Password === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOPASSWORD);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }
    wsLoginWithSocial(logintypeid, data) {
        APIController.SocialLogin(this.state.facebookid, this.state.googleid, this.state.email, '1', '', '1', logintypeid, this.state.firstname, '', this.state.name)
            .then((response) => {
                // this.setState({ loading: false })
                if (response.error == null) {
                    if (response.object.status == 1) {
                        this.props.history.push(AppConstants.SCREENS.HOMEPAGE)
                    } else if (response.object.status == 0) {
                        //navigate to signup screen
                        if (logintypeid == 3) {
                            this.props.history.push({
                                pathname: '/signup',
                                state: { detail: data, FromGoogle: true }
                            });
                        }
                        else {
                            this.props.history.push({
                                pathname: '/signup',
                                state: { detail: data, FromFacebook: true }
                            });
                        }
                    }
                    else {
                        ToastsStore.warning(response.error.message);
                    }
                }
            })

    }

    wsLogin() {
        this.setState({ loading: true })
        APIController.loginUser(this.state.MobileNo, '01/01/2019', this.state.Password, '1111111').then((response) => {
            this.setState({ loading: false })
            if (response.error == null) {
                if (response.object.status == 1) {
                    this.props.history.push(AppConstants.SCREENS.HOMEPAGE);
                }
                else if (response.object.status == 2) {
                    this.props.history.push({
                        pathname: '/verifyotp',
                        state: { data: response.object }
                    });
                }
                else if (response.object.status == 0) {
                    ToastsStore.error(response.object.message);
                }
            }
            else {
                ToastsStore.error(response.error.message);
            }
        })
    }
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    renderForm() {
        const { showPassword, loading, mediumBrowser } = this.state;
        const { classes } = this.props;

        const responseFacebook = (response) => {
            this.setState({
                facebookid: response.id,
                firstname: response.name,
                email: response.email,
                // profilepic: user.user.photo,
                // socail: "3",
                // user: user
            });
            this.wsLoginWithSocial(2, response);
        }

        const responseGoogle = (response) => {
            this.setState({
                googleid: response.profileObj.googleId,
                firstname: response.profileObj.name,
                email: response.profileObj.email,
                imageUrl: response.profileObj.imageUrl
            });
            this.wsLoginWithSocial(3, response);
        }

        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={mediumBrowser ? { width: '100%' } : { width: '100%', marginLeft: '7%', }} alignItems="center" justify={mediumBrowser ? 'center' : 'flex-start'}>
                    <Grid container style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', } : { width: '75.5%', height: '7.91%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Mobile No</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            name="MobileNo"
                            label='Enter your Mobile Number'
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 10,
                            }}
                        />
                    </Grid>
                    <Grid container style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', marginTop: '5%', } : { width: '75.5%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Password</Typography> */}
                        <FilledTextFieldUpdated type={showPassword ? 'text' : 'password'}
                            name="Password"
                            label='Enter your Password'
                            onChange={this.handleChange}
                            rows={1}
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid direction='row' container justify="flex-end" style={window.innerWidth <= 420 ? { marginTop: '5%', width: '80%' } : { marginTop: '5%', width: '75.5%' }}>
                        <Typography className={classes.txtForgotPwd} onClick={() => this.props.history.push(AppConstants.SCREENS.FORGOTPASSWORD)}>Forgot Password?</Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between' alignItems='center' style={window.innerWidth <= 420 ? { marginTop: '5%', width: '80%' } : { width: '75.5%', marginTop: '5%' }} >
                        <ButtonBase type="submit" isLoading={loading} title={'Login'} variant="contained" style={{ width: '30%', }} />
                        <Typography className={classes.txtSignup} onClick={() => this.props.history.push(AppConstants.SCREENS.SIGNUP)}>Sign Up</Typography>
                    </Grid>

                    {/* <Grid direction='row' alignItems='center' justify='space-around'
                        container style={window.innerWidth <= 420 ? { marginTop: '3%', marginBottom: '2%', width: '80%', } : { marginTop: '3%', marginBottom: '5%', height: '4%', width: '60%', }} >
                        <Typography className={classes.txtConnectUsing}>Connect Using</Typography>
                        <GoogleLogin
                            render={renderProps => (
                                <Avatar onClick={renderProps.onClick}
                                    src={Images.Google} className={classes.googleimg}></Avatar>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={error}
                            // isSignedIn={true}
                            clientId={clientId}
                        />
                        <FacebookLogin
                            render={renderProps => (
                                <Avatar onClick={renderProps.onClickFB}></Avatar>
                            )}
                            size='small'
                            onSuccess={success}
                            onFailure={error}
                            appId={appId}
                            cssClass={classes.facebook}
                            textButton=""
                            // autoLoad={true}
                            fields="name,email,picture"
                            //  onClick={responseFacebook}
                            callback={responseFacebook}
                            icon={<Avatar src={Images.Facebook} className={classes.facebookimg}></Avatar>}
                        />
                        {/* <Avatar src={Images.Facebook} className={classes.facebookimg} onClick={() => this.renderFacebookDesign}></Avatar> 
                    </Grid> */}
                </Grid>
            </form>
        );
    }

    handleStep(step) {
        this.setState({ open: step })
    }

    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (

            // <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
            <React.Fragment>
                {/* <Grid container style={window.innerWidth<=420?{ width: '100%',marginTop:'25%' }:window.innerWidth<=920?{ width: '100%',marginTop:'15%' }:{width: '100%',marginTop:'10%'}} justify="center" alignItems="center">  */}
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main} >
                    {/* <Title isBack={1} title={'Change Password'} history={this.props.history} /> */}
                    <Grid container style={window.innerWidth <= 920 ? { width: '100%', marginTop: '10%' } : { width: '100%', padding: '35px 12px 5px 12px' }} direction={'row'} alignItems='center' ></Grid>
                    <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%', marginTop: '10%' } : { width: '55%', marginTop: '2%' }} >
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', }} justify='center' alignItems='center'>
                                <Grid container justify={mediumBrowser ? 'center' : 'flex-start'} style={{ width: '100%', }} >
                                    <Typography className={classes.txtLogin}>Login</Typography>
                                </Grid>
                                <Grid container justify={mediumBrowser ? 'center' : 'flex-start'} style={{ width: '100%', }} >
                                    {this.renderForm()}
                                </Grid>
                            </Grid>
                            {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end' >
                                <img src={Images.FormBackground} className={classes.img} alt="" />
                                <LocationIcon style={{ color: '#39A749', position: 'absolute', }} onClick={() => { this.props.history.push(AppConstants.SCREENS.HOMEPAGE) }} />
                            </Grid>}
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
                {/* </Grid>  */}
            </React.Fragment>
        );
    }
}
export default withStyles(loginStyle)(Login);

