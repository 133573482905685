import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import paymentsStyle from './PaymentsStyle';
import { Scrollbars } from 'react-custom-scrollbars';
import { CssBaseline, Typography, Grid, CircularProgress, Divider, Card, } from '@material-ui/core';
import TopMenu from "../../components/Header/TopMenu";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import * as moment from 'moment';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import ReactGA from 'react-ga';

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            dataSource: [],
            dataSourceOrder: [],
            PatientName: '',
            IsLoading: true,
            orderdate: '',
            estimateddeliverydate: '',
            orderreferenceno: '',
            orderstatus: '',
            ordertotal: '',
            discount: '',
            deliverycharges: '',
            grosstotal: '',
            IdForEdit: 0,

        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsPayments();
    }

    wsPayments() {
        APIController.GetData('13', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSource: response.object, IsLoading: false })
                    if (response.object != '') { this.WsGetOrdersById(response.object[0].id) }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    WsGetOrdersById(ID) {
        this.setState({ IsLoadingEdit: true })
        APIController.GetOrdersById('12', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                if (response.error == null) {
                    // this.SetData(response.object.data[0])
                    this.setState({ dataSourceOrder: response.object.data.orderdetails, IsLoadingEdit: false, })
                    if (response.object.data.orders.length != 0) {
                        this.setState({
                            orderdate: response.object.data.orders[0].orderdate,
                            estimateddeliverydate: response.object.data.orders[0].estimateddeliverydate,
                            orderreferenceno: response.object.data.orders[0].orderreferenceno,
                            orderstatus: response.object.data.orders[0].orderstatus,
                            patientname: response.object.data.orders[0].patientname,
                            addressmobileno: response.object.data.orders[0].addressmobileno,
                            ordertotal: response.object.data.orders[0].ordertotal,
                            discount: response.object.data.orders[0].discount,
                            deliverycharges: response.object.data.orders[0].deliverycharges,
                            grosstotal: response.object.data.orders[0].grosstotal,
                            orderid: response.object.data.orders[0].id,
                            Orderstatusid: response.object.data.orders[0].orderstatusid,
                            Iscodallowed: response.object.data.orders[0].iscodallowed
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    renderPaymentdetails() {
        const { dataSourceOrder, IsLoadingEdit, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} container direction="row" style={mediumBrowser ? { width: '100%', height: '97%', padding: '4%', paddingBottom: '15px' } : { width: '100%', height: '97%', padding: '4%', }}>
                {IsLoadingEdit ? this.LoadingView() :
                    <Grid container direction='row' >
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Placed on </Typography>
                            <Typography style={{ backgroundColor: '#fff', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.orderdate} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between' >
                            <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Esti. Delivery Date </Typography>
                            <Typography style={{ backgroundColor: '#fff', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.estimateddeliverydate != '' ? moment(this.state.estimateddeliverydate).format("DD-MMM-YYYY") : ''} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order No. </Typography>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.orderreferenceno != '' ? '#' + this.state.orderreferenceno : ''} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order Status </Typography>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.orderstatus} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Typography style={{ color: '#9B9B9B', marginTop: '10px', marginBottom: '10px', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order Items </Typography>
                        {/* <Scrollbars autoHide autoHeight hideTracksWhenNotNeeded={true} autoHeightMax='300px'> */}
                        {
                            dataSourceOrder.map((item, index) =>
                                <Grid container direction='row'>
                                    <Grid container
                                        direction="row"
                                        justify='space-between'>
                                        <Grid container style={{ width: '80%' }}>
                                            <Typography className={classes.Medicinetitle} style={{ backgroundColor: '#fff', color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {item.medicinename} </Typography>
                                            {item.companyname != null && item.companyname != '' && <>
                                                &nbsp; <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                                &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                            </>}
                                        </Grid>
                                        <Grid container style={{ width: '20%' }} justify='flex-end'>
                                            <Typography style={{ color: '#39A749', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', }}> ₹ {item.totalsaleprice} </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        direction="row"
                                        justify='space-between'>
                                        <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Pack of {item.packsize}, Quantatiy: {item.quantity} </Typography>
                                        <Typography style={{ color: 'red', textDecorationLine: 'line-through', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> ₹ {item.totalmrp} </Typography>
                                    </Grid>
                                    <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                                </Grid>
                            )}
                        {/* </Scrollbars> */}

                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> MRP Total </Typography>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.ordertotal != '' ? '₹ ' + this.state.ordertotal : ''} </Typography>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Discount </Typography>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.discount != '' ? '-₹ ' + this.state.discount : ''} </Typography>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Delivery Charges </Typography>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {this.state.deliverycharges} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#39A749', height: '2px', marginTop: '10px', }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', marginTop: '1%', paddingBottom: '10px' }}> Gross Total </Typography>
                            <Typography style={{ color: '#262628', fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '700', marginTop: '1%' }}> {this.state.grosstotal != '' ? '₹ ' + this.state.grosstotal : ''} </Typography>
                        </Grid>
                    </Grid>
                }
            </Card>
        )
    }

    OnPressEditList(index, id) {
        this.setState({ IdForEdit: index })
        this.WsGetOrdersById(id)
    }

    renderPaymentsList() {
        const { dataSource, IdForEdit, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', height: '97%', padding: '2%', paddingBottom: '17px' } : { flex: 1, width: '100%', height: '97%', padding: '2%', }}>
                <Scrollbars autoHide autoHeight autoHeightMax='600px'>
                    {
                        dataSource.map((item, index) =>
                            <Grid container onClick={() => { this.OnPressEditList(index, item.id) }} style={{ cursor: 'pointer' }} direction={'row'}>
                                <Grid container direction='row' className={IdForEdit == index ? classes.ContentSelectcardmainPayment : classes.ContentcardmainPayment} >
                                    {/* <Grid container direction='column' justify='center' alignItems='stretch' style={{ backgroundColor: '#fff', width: '100%', }}> */}
                                    <Grid container
                                        directi on="row"
                                        // justify="center"
                                        alignItems="center"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px', marginRight: '10px', width: '100%', }}>
                                        <Typography style={{ backgroundColor: '#fff', width: '50%', color: '#39A749', fontSize: '18px', fontWeight: '700', fontFamily: 'Nunito Sans', }} > {item.patientname} </Typography>
                                        <Typography style={{ backgroundColor: '#fff', width: '50%', textAlign: 'right', color: '#39A749', fontSize: '18px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> ₹ {item.grosstotal} </Typography>
                                    </Grid>
                                    <Grid container
                                        direction="row"
                                        // justify="center"
                                        alignItems="center"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px', marginRight: '10px', width: '100%', }}>
                                        <Typography style={{ backgroundColor: '#fff', width: '60%', color: '#262628', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans', }} > {item.orderdate} </Typography>
                                        <Typography style={{ backgroundColor: '#fff', width: '40%', textAlign: 'right', color: '#B0B4BF', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> {item.paymentmethod} </Typography>
                                    </Grid>
                                    <Grid container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px', marginRight: '10px', width: '100%', }}>
                                        <Typography style={{ color: '#B0B4BF', width: '50%', fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}>  #{item.orderreferenceno} </Typography>
                                        <Grid justify='center' alignItems='center' className={classes.placeView}>
                                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, color: '#ffffff', fontSize: '14px', marginTop: '1%', textAlign: 'center' }} >{item.paymentstatus}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                        )
                    }
                </Scrollbars>
            </Card>
        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    renderCartEmpty() {
        const { classes } = this.props
        return (
            <Grid className={classes.ContentcardmainCart} style={{ width: '99%', marginTop: '15px' }} container direction={'row'} justify={'center'} alignItems="center">
                {/* <Typography className={classes.qntytxt} >{''}</Typography> */}
                <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> {'No Payments Found'}</Typography>
            </Grid>
        )
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, IsLoading, dataSource } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'My Payments'} history={this.props.history} />
                    {/* {mediumBrowser == false && <TopMenu history={this.props.history} />} */}
                    {IsLoading ? this.LoadingView() :
                        <Grid container justify='center' alignItems='center' alignSelf='center'>
                            <Grid container style={mediumBrowser ? { width: '100%', marginTop: '2%' } : { width: '98.5%', marginTop: '0.5%' }} direction="row" alignItems='center'>
                                <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {'(' + dataSource.length + ')'} Payments Found </Typography>
                            </Grid>
                            <Grid container justify='center' alignItems='center' alignSelf='center'>
                                {dataSource.length == 0 ? this.renderCartEmpty() :
                                    <Grid container direction="row" style={mediumBrowser ? { width: '100%', marginTop: '2%' } : { width: '98.5%', marginTop: '1%' }} justify="space-between">
                                        <Grid direction="row" container style={mediumBrowser ? { width: '100%', } : { width: '39%', }} >
                                            {this.renderPaymentsList()}
                                        </Grid>
                                        <Grid direction="row" container style={mediumBrowser ? { width: '100%', marginTop: '3%' } : { width: '60%', }} >
                                            {this.renderPaymentdetails()}
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(paymentsStyle)(withTranslation('translation')(Payments));
