import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const OrderReviewStyle = theme => ({
    
      newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
      },
      mainContainerLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    mainContainerRight: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    txtOrderSuccess: {
        fontFamily: 'Nunito Sans',
        // fontWeight: 400,
        fontSize: '20px',
        lineHeight: '34px',
        color: '#b5bbc1',
        paddingBottom: '2%',
        color: '#262628',

    },
    txtOrderSuccessMsg: {
        // width: '50%',
        textAlign: 'center',
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: 14,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#9B9B9B',
    },
    txtDeliveryDate: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: 14,
        letterSpacing: -0.5,
        color: '#000000',
        lineHeight: 1.8,
    },
    btnCopyOrder: {
        width: '50%',
        borderRadius: '0px 0px 0px 5px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    },
    btnDetails: {
        width: '45%',
        borderRadius: '0px 0px 5px 0px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    },
    txtCongress:{
      fontFamily: 'Nunito Sans',
      fontSize: '36px',
      lineHeight: '48px',
      // letterSpacing: 0.1,
      fontWeight: 500,
      color:'#262628'
    },
    Medicinetitle: {
        fontFamily: 'Nunito Sans', 
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.textColor,
    },
});
const combinedStyles = combineStyles(commonStyle, OrderReviewStyle);
export default combinedStyles; 
