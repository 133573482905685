/*eslint-disable eqeqeq*/
import React from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid, IconButton, } from '@material-ui/core';
import DownlodAppDialogStyle from './DownlodAppDialogStyle';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import { Images } from '../../common';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CancelIcon from '@material-ui/icons/Cancel';
const DownlodAppDialog = props => {
    // const classes = DownlodAppDialogStyle();
    const { open, onClose, onClick } = props;
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '100%', }}
                open={open}>
                <Grid container justify='center' alignItems='center'
                    style={window.innerWidth <= 420 ? { width: '300px', backgroundColor: '#39A749' } : window.innerWidth <= 920 ? { width: '350px', backgroundColor: '#39A749' } : { width: '500px', backgroundColor: '#39A749', }}>
                    <IconButton  onClick={onClose} style={{ position: 'absolute', right: -0, top: -0, }}>
                        <CancelIcon  style={{ position: 'absolute', right: -0, top: -0, color: '#fff', fontSize: AppConstants.FONTSIZE.FS30, }} />
                    </IconButton>
                    <Grid direction={'row'} container justify='center' style={{ width: '100%', padding: '5%', }}>
                        <Typography style={{ color: '#fff', fontSize: AppConstants.FONTSIZE.FS20, fontFamily: 'Nunito Sans', fontWeight: 700, textAlign: 'center' }}>{'For Better Experience Download Medkart Application'}</Typography>
                    </Grid>
                    <Grid direction={'column'} container justify='center' alignItems='center' style={{ width: '100%', padding: '5%', backgroundColor: '#fff' }}>
                        <IconButton  href="https://play.google.com/store/apps/details?id=com.vmobee.reactnative.medkart" target="_blank" style={{ borderRadius: '0', backgroundColor: '#fff' }}>  <img src={Images.PlayStore} style={{ width: '94%', }} /></IconButton>
                        <IconButton href="https://apps.apple.com/us/app/medkart-pharmacy/id1495957793" target="_blank" style={{ borderRadius: '0', backgroundColor: '#fff' }}>  <img src={Images.AppStore} style={{ width: '90%', }} /></IconButton>
                    </Grid>
                    <Grid container direction='column' justify='center' style={{ width: '100%', margin: '15px' }}>
                        {/* <Typography style={{ color: '#fff', fontSize: AppConstants.FONTSIZE.FS16, fontFamily: 'Nunito Sans', fontWeight: 500, textAlign: 'center' }}>{'www.medkart.in'}</Typography> */}
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};


export default (DownlodAppDialog);
