/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import {
    Container, CssBaseline, Typography, Grid, InputAdornment,
    IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChangePasswordStyle from './ChangePasswordStyle';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
// import FilledTextField from '../../components/FilledTextField/FilledTextField';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import { Images } from '../../common';
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import Title from "../../components/Header/Title";
import TopMenu from "../../components/Header/TopMenu";
import ReactGA from 'react-ga';
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
            showtxtPassword: false,
            showtxtNewPassword: false,
            showtxtNewConfirmPassword: false,
            loading: false,
            mediumBrowser: false,
            open: false,
        };
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    // localStorage.clear();
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsChangePassword()
        }
    }
    validateForm() {
        var IsValid = 0;
        if (this.state.OldPassword == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOPASSWORD);
        } else if (this.state.NewPassword == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NONEWPASSWORD);
        } else if (this.state.ConfirmPassword == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOCONFIRMPASSWORD);
        } else if (this.state.ConfirmPassword != this.state.NewPassword) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.PASSWORDSHOULDMATCH);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }
    handleClickshowtxtPassword = () => {
        this.setState({
            showtxtPassword: !this.state.showtxtPassword,
        });
    };
    handleClickshowtxtNewPassword = () => {
        this.setState({
            showtxtNewPassword: !this.state.showtxtNewPassword,
        });
    };
    handleClickshowtxtNewConfirmPassword = () => {
        this.setState({
            showtxtNewConfirmPassword: !this.state.showtxtNewConfirmPassword
        });
    };
    wsChangePassword() {
        this.setState({ loading: true })
        APIController.changePassword(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.OldPassword, this.state.NewPassword).then((response) => {
            this.setState({ loading: false })
            if (response.error == null) {
                ToastsStore.success(response.object.message)
                this.setState({ OldPassword: '', NewPassword: '', ConfirmPassword: '' })

            }
            else {
                ToastsStore.warning(response.error.code.message);
            }
        })
    }

    renderForm() {
        const { showtxtPassword, showtxtNewPassword, showtxtNewConfirmPassword, loading, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={{ width: '100%', }} alignItems="center" justify='center'>
                    <Grid container style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', } : { width: '75.5%', height: '7.91%', }} alignItems="center">
                        {/* <Typography className={classes.txtCurrentPwd}>Current Password</Typography> */}
                        <FilledTextFieldUpdated type={showtxtPassword ? 'text' : 'password'}
                            name="OldPassword"
                            value={this.state.OldPassword}
                            label='Enter Current Password'
                            onChange={this.handleChange}
                            rows={1}
                            style={{ marginTop: '5%' }}
                            InputProps={{
                                className: classes.textField,
                                // style: { backgroundColor: 'white'},
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={this.handleClickshowtxtPassword}>
                                            {showtxtPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                    <Grid container style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', } : { width: '75.5%', height: '7.91%' }} alignItems="center">
                        {/* <Typography className={classes.txtCurrentPwd}>New Password</Typography> */}
                        <FilledTextFieldUpdated type={showtxtNewPassword ? 'text' : 'password'}
                            name="NewPassword"
                            value={this.state.NewPassword}
                            label='Enter New Password'
                            onChange={this.handleChange}
                            rows={1}
                            style={{ marginTop: '5%' }}
                            InputProps={{
                                className: classes.textField,
                                // style: { backgroundColor: 'white'},
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={this.handleClickshowtxtNewPassword}
                                        >
                                            {showtxtNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                    <Grid container style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', } : { width: '75.5%', height: '7.91%' }} alignItems="center">
                        {/* <Typography className={classes.txtCurrentPwd}>Confirm Password</Typography> */}
                        <FilledTextFieldUpdated type={showtxtNewConfirmPassword ? 'text' : 'password'}
                            name="ConfirmPassword"
                            label='Enter Confirm Password'
                            value={this.state.ConfirmPassword}
                            onChange={this.handleChange}
                            rows={1}
                            style={{ marginTop: '5%' }}
                            InputProps={{
                                className: classes.textField,
                                // style: { backgroundColor: 'white'},
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            // aria-label="toggle password visibility"
                                            onClick={this.handleClickshowtxtNewConfirmPassword}>
                                            {showtxtNewConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid container justify='center' style={window.innerWidth <= 420 ? { width: '80%', height: '7.91%', marginTop: '5%', marginBottom: '5%' } : { width: '75.5%', marginTop: '5%', marginBottom: '5%' }}>
                        <ButtonBase type="submit" title={'Change Password'} isLoading={loading}
                            style={mediumBrowser ? { width: '70%', } : { width: '70%', }} variant="contained" />
                        {/* onClick={() => this.props.history.push(AppConstants.SCREENS.LOGIN)} */}
                    </Grid>

                </Grid>
            </form>
        );
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                {/* <Grid container style={{ width: '100%' }} justify="center" alignItems="center"> */}
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title isBack={1} title={'Change Password'} history={this.props.history} />
                    {/* {mediumBrowser == false && <TopMenu history={this.props.history} />} */}
                    <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container className={classes.mainContainer}
                            style={mediumBrowser ? { width: '100%', marginTop: '10%' } : { width: '55%', marginTop: '2%' }} >
                            <ToastsContainer store={ToastsStore} />

                            <Grid container justify='center' alignItems='center' style={mediumBrowser ? { width: '100%', } : { width: '50%' }} >
                                <Grid container justify='flex-start' style={{ width: '100%' }}>
                                    {this.renderForm()}
                                </Grid>
                            </Grid>

                            {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end'>
                                <img src={Images.FormBackground} className={classes.img} alt="" />
                            </Grid>}
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
                {/* </Grid> */}
            </React.Fragment>
        );
    }
}
export default withStyles(ChangePasswordStyle)(ChangePassword);
