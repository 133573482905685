import { API, HTTPMethod } from '../api/API';
import { URLs } from '../api/URLs';

export function loginUser(mobileno, lastsyncat, password, deviceid) {
    let params = {
        "mobileno": mobileno,
        "lastsyncat": lastsyncat,
        "password": password,
        "deviceid": deviceid,
        "devicetypeid": '3'
    }
    let url = URLs.base + URLs.login
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function SocialLogin(facebookid, googleid, emailaddress, devicetypeid, devicename, selectedlanguage, logintypeid, firstname, deviceid) {
    let params = {
        "facebookid": facebookid,
        "googleid": googleid,
        "emailaddress": emailaddress,
        "devicetypeid": '3',
        "devicename": devicename,
        "selectedlanguage": selectedlanguage,
        "logintypeid": logintypeid,
        "firstname": firstname,
        "deviceid": deviceid,
    }
    let url = URLs.base + URLs.sociallogin
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function registerUser(firstname, lastname, mobileno, emailaddress, password, profilepicname, profilepic,
    devicetypeid, facebookid, googleid, logintypeid, lastlocation, lastlatitude, lastlongitude) {
    let params = {
        "firstname": firstname,
        "lastname": lastname,
        "mobileno": mobileno,
        "emailaddress": emailaddress,
        "password": password,
        "profilepicname": profilepicname,
        "profilepic": profilepic,
        "devicetypeid": '3',
        "facebookid": facebookid,
        "googleid": googleid,
        "logintypeid": logintypeid,
        "lastlocation": lastlocation,
        "lastlatitude": lastlatitude,
        "lastlongitude": lastlongitude,
    }
    let url = URLs.base + URLs.signup
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function editProfile(userid, mobileno, firstname, lastname, emailaddress, profilepic, profilepicname) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "firstname": firstname,
        "lastname": lastname,
        "emailaddress": emailaddress,
        "profilepic": profilepic,
        "profilepicname": profilepicname,
    }
    let url = URLs.base + URLs.editprofile
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function searchMedicineNew(userid, mobileno, searchtext) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "searchtext": searchtext
    }
    let url = URLs.base + URLs.searchmedicine
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function resendOtp(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.resendotp
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function verifyOtp(userid, mobileno, otp) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "otp": otp
    }
    let url = URLs.base + URLs.verifyotp
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function changePassword(userid, mobileno, password, newpassword) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "password": password,
        "newpassword": newpassword
    }
    let url = URLs.base + URLs.changepassword
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function forgotPassword(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.forgotpassword
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function getMyProfile(userid, mobileno, lastsyncat) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "lastsyncat": lastsyncat
    }
    let url = URLs.base + URLs.getmyprofile
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function contactUs(userid, mobileno, message) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "message": message
    }
    let url = URLs.base + URLs.contactus
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function getStoreList(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.getstorelist
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function processCart(userid, mobileno, medicineid, quantity, lastsyncstatus, id) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "medicineid": medicineid,
        "quantity": quantity,
        "lastsyncstatus": lastsyncstatus,
        "id": id,
    }
    let url = URLs.base + URLs.processcart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}


export function AddEditAddress(userid, mobileno, id, appid, name, address1, address2, area, city, postcode, addresstypeid, isdefault) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "id": id,
        "appid": appid,
        "name": name,
        "address1": address1,
        "address2": address2,
        "area": area,
        "city": city,
        "postcode": postcode,
        "addresstypeid": addresstypeid,
        "isdefault": isdefault,
    }
    let url = URLs.base + URLs.addeditaddress
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function DeleteAddress(userid, mobileno, id) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "id": id,
    }
    let url = URLs.base + URLs.deleteaddress
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function AddEditPatient(userid, mobileno, id, appid, name, doctorname, isdefault, relationwithpatient, birthdate, height, weight, bloodgroup, medicalconditions, allergies, medications) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "id": id,
        "appid": appid,
        "name": name,
        "doctorname": doctorname,
        "isdefault": isdefault,
        "relationwithpatient": relationwithpatient,
        "birthdate": birthdate,
        "height": height,
        "weight": weight,
        "bloodgroup": bloodgroup,
        "medicalconditions": medicalconditions,
        "allergies": allergies,
        "medications": medications,
    }
    let url = URLs.base + URLs.addeditpatient
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function DeletePatient(userid, mobileno, id) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "id": id,
    }
    let url = URLs.base + URLs.deletepatient
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GetData(operationid, userid, mobileno) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function ManageRefills(userid, mobileno, orderid, repeatafterdays, nextrefilldate, statusid) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        "repeatafterdays": repeatafterdays,
        "nextrefilldate": nextrefilldate,
        "statusid": statusid,
    }
    let url = URLs.base + URLs.manageRefills
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function EditRefills(userid, mobileno, orderid, refillid, repeatafterdays, nextrefilldate, statusid) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        "refillid": refillid,
        "repeatafterdays": repeatafterdays,
        "nextrefilldate": nextrefilldate,
        "statusid": statusid,
    }
    let url = URLs.base + URLs.manageRefills
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function DeleteRefills(userid, mobileno, orderid, refillid, statusid) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        "refillid": refillid,
        "statusid": statusid,
    }
    let url = URLs.base + URLs.manageRefills
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function GetMyOrder(operationid, userid, mobileno, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "orderstatus": id,
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function AddPrescription(operationid, userid, mobileno, imagename, imagepath, getdata, isused) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "imagename": imagename,
        "imagepath": imagepath,
        "getdata": getdata,
        "isused": isused,

    }
    let url = URLs.base + URLs.addeditprescription
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GetPrescription(operationid, userid, mobileno, getdata, isused) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "getdata": getdata,
        "isused": isused,
    }
    let url = URLs.base + URLs.addeditprescription
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function AddPrescriptionToNew(userid, mobileno, id, operationid, getdata, isused) {
    let params = {
        "mobileno": mobileno,
        "userid": userid,
        "id": id,
        "operationid": operationid,
        "getdata": getdata,
        "isused": isused,

    }
    let url = URLs.base + URLs.addeditprescription
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GetOldPrescription(operationid, userid, mobileno, getdata, isused) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "getdata": getdata,
        "isused": isused,

    }
    let url = URLs.base + URLs.addeditprescription
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function removePrescription(userid, mobileno, operationid, getdata, id) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "operationid": operationid,
        "getdata": getdata,
        "id": id,
    }
    let url = URLs.base + URLs.addeditprescription
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}


export function GetCart(operationid, userid, mobileno, postcode, getdata) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "postcode": postcode,
        "getdata": getdata,
    }
    let url = URLs.base + URLs.processcart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function UpdateCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "postcode": postcode,
        "getdata": getdata,
        "quantity": quantity,
        "medicineid": medicineid,
        "cartid": cartid,
        "id": id
    }
    let url = URLs.base + URLs.processcart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function AddToCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "postcode": postcode,
        "getdata": getdata,
        "quantity": quantity,
        "medicineid": medicineid,
        "id": id
    }
    let url = URLs.base + URLs.processcart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function RemoveCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "postcode": postcode,
        "getdata": getdata,
        "quantity": quantity,
        "medicineid": medicineid,
        "cartid": cartid,
        "id": id
    }
    let url = URLs.base + URLs.processcart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function MedicineDetail(userid, mobileno, medicineid, compositionid) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "medicineid": medicineid,
        "compositionid": compositionid
    }
    let url = URLs.base + URLs.getmedicinedetails
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function ContactUs(userid, mobileno, message) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "message": message,
    }
    let url = URLs.base + URLs.contactUs
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function ContactMedkaer(name, location, emailaddress, phoneno, message, source) {
    let params = {
        "Name": name,
        "Location": location,
        "EmailAddress": emailaddress,
        "PhoneNo": phoneno,
        "Message": message,
        "Source": source,
    }
    // let url = 'https://medkart.in/WSServices.aspx/AddFranchiseRequest';
    let url = URLs.base + URLs.addfranchiserequest;
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GetStoreList(userid, mobileno, latitude, longitude, name) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "latitude": latitude,
        "longitude": longitude,
        "storename": name
    }
    let url = URLs.base + URLs.getstorelist
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function GetNotifications(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno
    }
    let url = URLs.base + URLs.notifications
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function GetPatientById(operationid, userid, mobileno, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "id": id
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GetAddressById(operationid, userid, mobileno, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "id": id
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function OrderReview(operationid, userid, mobileno, addressid, patientid, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "addressid": addressid,
        "patientid": patientid,
        "id": id
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function AddOrder(userid, mobileno, discount, deliverycharges, addressid, patientid, patientname, doctorname, additonalnotes, removeandcontinue) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "discount": discount,
        "deliverycharges": deliverycharges,
        "addressid": addressid,
        "patientid": patientid,
        "patientname": patientname,
        // "doctorname": doctorname,
        "additonalnotes": additonalnotes,
        "removeandcontinue": removeandcontinue,
        "devicetypeid": '2'
    }
    let url = URLs.base + URLs.addorder
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function GetOrdersById(operationid, userid, mobileno, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "id": id
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function OrderConfirmation(operationid, userid, mobileno, id) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "id": id
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function CopyOrder(userid, mobileno, id) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": id
    }
    let url = URLs.base + URLs.copyorder
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
//by nilesh 30-05-2020

export function GetRaiseandissueDetail(operationid, userid, mobileno, typeid) {
    let params = {
        "operationid": operationid,
        "userid": userid,
        "mobileno": mobileno,
        "typeid": typeid
    }
    let url = URLs.base + URLs.getData
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function CancelOrReturnOrder(userid, mobileno, orderid, issueid, additonalnotes, imagename, image, orderstatus) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        "issueid": issueid,
        "additionaldetails": additonalnotes,
        "imagename": imagename,
        "image": image,
        "orderstatus": orderstatus
    }
    let url = URLs.base + URLs.cancelOrReturnOrder
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function SendOrderOTP(userid, mobileno, orderid, orderref) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        "orderref": orderref
    }
    let url = URLs.base + URLs.sendorderotp
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function VerifyOrderOTP(userid, mobileno, orderid, orderotp) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid,
        orderotp: orderotp
    }
    let url = URLs.base + URLs.verifyorderotp
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function ResendOrderOTP(userid, mobileno, orderid) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "orderid": orderid
    }
    let url = URLs.base + URLs.resendorderotp
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function ApplyCouponCode(userid, mobileno, couponcode,postcode) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "OfferID": couponcode,
        'postcode':postcode
    }
    let url = URLs.base + URLs.ApplyCouponCode
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function RemoveCouponCode(userid, mobileno, postcode) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "postcode": postcode
    }
    let url = URLs.base + URLs.RemoveCouponCode
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function GeneratePayTMChecksum(orderid, mobileno, userid, amount) {
    let params = {
        "orderid": orderid,
        "mobileno": mobileno,
        "userid": userid,
        "amount": amount
    }
    let url = URLs.base + URLs.GeneratePayTMChecksum
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function ValidateForPrescriptions(mobileno) {
    let params = {
        "mobileno": mobileno
    }
    let url = URLs.base + URLs.ValidateForPrescriptions
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function SendCart(userid, mobileno, emailaddress) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
        "emailaddress": emailaddress
    }
    let url = URLs.base + URLs.SendCart
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}

export function Compare_GetMedicines(sessionid) {
    let params = {
        "sessionid": sessionid,
    }
    let url = URLs.base + URLs.Compare_GetMedicines
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function Compare_SearchMedicines(searchtext) {
    let params = {
        "searchtext": searchtext,
    }
    console.log('params:serch', params)
    let url = URLs.base + URLs.Compare_SearchMedicines
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function Compare_AddMedicine(sessionid, medicineid) {
    let params = {
        "sessionid": sessionid,
        "medicineid": medicineid,
    }
    console.log('Add medivineparams:', params)
    let url = URLs.base + URLs.Compare_AddMedicine
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function Compare_DeleteMedicines(sessionid, id) {
    let params = {
        "sessionid": sessionid,
        "id": id
    }
    let url = URLs.base + URLs.Compare_DeleteMedicines
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function Compare_EmailMedicines(emailaddress, sessionid, mobileno) {
    let params = {
        "emailaddress": emailaddress,
        "mobileno": mobileno,
        "sessionid": sessionid,
    }
    let url = URLs.base + URLs.Compare_EmailMedicines
    console.log('params:', params)
    console.log('url:', url)
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}


export function ContactMedkart(name, location, emailaddress, phoneno, message) {
    let params = {
        "name": name,
        "location": location,
        "email": emailaddress,
        "phone": phoneno,
        "message": message,
    }
    let url = URLs.base + URLs.ContactUsWeb;
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function GetCoupons(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.GetCoupons
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}
export function InitiateCashFreeTransaction(userid, mobileno) {
    let params = {
        "userid": userid,
        "mobileno": mobileno,
    }
    let url = URLs.base + URLs.GetCoupons
    let body = JSON.stringify(params)
    return API.shared.request(HTTPMethod.post, url, body)
}