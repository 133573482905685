import React, { useState, useEffect } from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid } from '@material-ui/core';
import CancelOrderDialogStyle from './CancelOrderDialogStyle';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Dropzone from 'react-dropzone';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FilledTextField from '../FilledTextField/FilledTextField';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

const CancelOrderDialog = props => {
    const classes = CancelOrderDialogStyle();
    const { open, onClose, Title, onClick, OrderStatusId, onCancelOrder, GetOrderdetail, OrderId, OrderStatus, OrderReferenceNo } = props;
    const [dropzone, setdropzone] = React.useState('');
    const [Name, setName] = React.useState('');
    const [image64, setimage64] = React.useState('');
    const [imagename, setimagename] = React.useState('');
    const [IssueId, setIssueId] = React.useState('');
    const [matchId, setmatchId] = React.useState(-1);
    // const image = dropzone.preview

    const handleChange = (e) => {
        setName(e.target.value)
    }
    const ConvertToBase64 = () => {
        const image2base64 = require('image-to-base64');
        image2base64(dropzone.preview)
            .then((response) => {
                setimage64(response)
            })
            .catch((error) => {

            })
    }



    const addFilesToDropzone = (files, dropzone) => {
        let file = files[0]
        file['preview'] = URL.createObjectURL(files[0]);
        // this.setState({ dropzone: files[0] }, () => {
        // });
        setdropzone(files[0])
        ConvertToBase64();
        setimagename(files[0].name)
        // this.setState({ imagename: files[0].name, imageSize: (files[0].size / 2048).toFixed(2) });
    }


    const _onPress = (index) => {
        setIssueId(props.data[index].id)
        setmatchId(index)
    }

    const wsRaiseAnIssue = () => {
        if (IssueId == '') {
            ToastsStore.warning('Please Select Issue ');
        }
        else if (Name == '') {
            ToastsStore.warning('Please Enter Addtional notes');
        }
        else {
            APIController.CancelOrReturnOrder(AppConstants.userIdFromLocal(),
                AppConstants.mobilenoFromLocal(), OrderId, IssueId, Name, imagename, image64, OrderStatusId <= '4' ? '6' : '7')
                .then((response) => {
                    if (response.error == null) {
                        if (response.object.status == 1) {
                            setIssueId('')
                            setName('')
                            setdropzone('')
                            setimage64('')
                            setimagename('')
                            setmatchId(-1)
                            onCancelOrder(response.object.message)
                        }
                        else {
                            ToastsStore.warning(response.object.message);
                        }
                    }
                    else if (response.error.message && response.error.message.length > 0) {
                        ToastsStore.warning(response.error.message);
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                // style={{ height: '750px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center' style={window.innerWidth <= 920 ? { width: '100%' } : { padding: '20px', width: '100%' }}>

                    <Grid container justify='center'
                        style={{ width: '95%' }}
                        className={classes.Imagecardmainwithoutborder}  >
                        <Typography className={classes.titletext}>{props.Title}</Typography>
                    </Grid>
                    <Typography className={classes.OrderIdtext}>#{OrderReferenceNo}</Typography>
                    <Grid container
                        style={{ width: '100%' }}
                        className={classes.Imagecardmainwithoutborder}  >
                        <Typography className={classes.issuetitletext}>{'What Did You Issue Face?'}</Typography>
                    </Grid>
                    {
                        props.data.map((item, index) =>
                            <Grid className={classes.Imagecardmainwithoutborder} onClick={() => _onPress(index)} container direction={'row'} justify='flex-start'
                                alignItems={'center'} style={{ width: '95%', cursor: 'pointer' }}>
                                {/* <Typography className={classes.Issuetext}>{item.name}</Typography> */}
                                {matchId == index ? <RadioButtonCheckedIcon style={window.innerWidth <= 920 ? { color: '#39A749', fontSize: '20px' } : { color: '#39A749', fontSize: '30px', marginRight: '1%' }} /> : <RadioButtonUncheckedIcon style={window.innerWidth <= 920 ? { color: 'grey', fontSize: '20px' } : { color: 'grey', fontSize: '30px', marginRight: '1%' }} />}
                                <Typography className={classes.Issuetext}>{item.name}</Typography>
                            </Grid>
                        )
                    }
                    <Grid container
                        style={{ width: '95%' }}
                        className={classes.Imagecardmainwithoutborder}  >
                        <Typography className={classes.imagetitletext}>{'Add Photo About Issue ?'}</Typography>
                    </Grid>
                    <Grid container direction={'row'} className={classes.Imagecardmain} justify='center'
                        alignItems={'center'} style={{ width: '95%' }}>
                        <Dropzone onDrop={files => { addFilesToDropzone(files, 'dropzone') }}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid alignItems="center">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Grid container alignItems='center' justify={'center'} style={{ width: '100%', height: '100px' }}>
                                            <Typography style={{ textAlign: 'center', color: '#BEBEBE', fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>
                                                {dropzone.preview == undefined ? <AddAPhotoIcon style={{ cursor: 'pointer', fontSize: '50px' }} />
                                                    : <img
                                                        className={classes.AvatarImg}
                                                        src={dropzone.preview == undefined ? '' : dropzone.preview}
                                                        alt="">
                                                    </img>}
                                            </Typography>
                                        </Grid>

                                    </div>
                                </Grid>
                            )}
                        </Dropzone>
                    </Grid>
                    <Grid container direction={'row'} className={classes.Imagecardmainwithoutborder} justify='center'
                        alignItems={'center'} style={{ width: '95%' }}>
                        <FilledTextField
                            rows={1}
                            value={Name}
                            name="Name"
                            label="Additional Details *"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid direction={window.innerWidth <= 920 ? 'column' : 'row'} container alignItems='center' justify={window.innerWidth <= 920 ? 'center' : 'space-between'} className={classes.Imagecardmainwithoutborder} style={{ width: '95%' }}>
                        <ButtonBase title={OrderStatusId <= '4' ? 'CANCEL ORDER' : 'RETURN ORDER'}
                            variant="contained"
                            style={window.innerWidth <= 920 ? { width: '70%' } : { width: '40%' }}
                            onClick={() => { wsRaiseAnIssue() }} />
                        <ButtonBase title={'CLOSE'}
                            variant="contained"
                            style={window.innerWidth <= 920 ? { width: '40%', marginTop: '5%' } : { width: '40%' }}
                            onClick={onClick} />
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (CancelOrderDialog);
