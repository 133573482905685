import * as AppConstants from '../common/Constants';
const commonStyle = theme => ({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    padding: '12px 12px',
    textAlign:'left',
    backgroundColor: '#fff',
    marginBottom: '50px',
    marginTop: '10px',
  },
  Contentcardmain: {
    margin: '10px',
    backgroundColor: '#fff',
    padding: '12px 12px ',
    border: '2px solid ',
    borderColor: theme.palette.headerText,
    borderRadius: '0px 0px 0px 0px',
  },
  Contentcardmainwithoutborder: {
    margin: '10px',
    backgroundColor: '#fff',
    padding: '12px 12px',
    borderRadius: '0px 0px 0px 0px',
  },
  ContentSelectcardmain: {
    margin: '10px',
    padding: '12px 12px',
    border: '2px solid ',
    borderColor: theme.palette.primary.main,
    borderRadius: '0px 0px 0px 0px',
  },
  ContentcardmainwithRadius: {
    margin: '10px',
    backgroundColor: '#fff',
    padding: '12px 12px',
    border: '1px solid ',
    borderColor: '#39A749',
    borderRadius: '5px 5px 5px 5px',
  },
  button: {
    borderRadius: 4,
    height: 48,
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: 0.1,
    fontWeight: 500,
    boxShadow: 'none'
  },
  title: {
    fontFamily: 'Nunito Sans', fontWeight: 800,
    fontSize: 48,
    lineHeight: 1.13,
    letterSpacing: -0.5,
    color: theme.palette.textColor,
  },
  subtitle: {
    fontFamily: 'Nunito Sans', fontWeight: 400,
    fontSize: AppConstants.FONTSIZE.FS16,
    lineHeight: 1.5,
    color: theme.palette.textColor,
    margin: '16px 0',
  },
  linkText: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    lineHeight: 1.43,
    fontWeight: 500,
    // color: theme.palette.primary.main,
  },
  Menulink: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    lineHeight: 1.43,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  orGrid: {
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'Nunito Sans', fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.13,
    letterSpacing: -0.5,
    color: theme.palette.primary.main,
    paddingBottom: '2%',
  },
  //AlertDialog for mobile View
  alerView:
  {
    padding: '5%',
    border: '1px solid #f1f1f5',
    borderTopWidth: '2%',
    borderRadius: '5% 5% 0% 0%',
    width: '100%',
    height: '375px',
    backgroundColor: '#fff',
    position: 'fixed',
    textAlign: 'center',
    bottom: '0',
    left: '0',
  },
  Screentitle: {
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: AppConstants.FONTSIZE.FS35,
    lineHeight: 1.13,
    letterSpacing: 0,
    color: theme.palette.textColor,
  },
  titletextGreen: {
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: AppConstants.FONTSIZE.FS35,
    lineHeight: 1.13,
    letterSpacing: -0.5,
    color: '#39A749',
  },
});

export default commonStyle;
