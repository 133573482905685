import React, { Component } from 'react';
import { CssBaseline, Grid, Typography, ButtonBase } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import HomePageStyle from "./HomePageStyle";
import Tour from 'reactour'
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import AddIcon from '@material-ui/icons/Add';
import PhotoCameraIcon from '@material-ui/icons/AddAPhoto';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";
import BottomMenu from "../../components/Footer/BottomMenu";
import Slider from "react-slick";
import ReactGA from 'react-ga';
import OfferDialog from "../../components/OfferDialog/OfferDialog";
import Helmet from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isLoading: true,
            mediumBrowser: false,
            Activeindex: 0,
            isMobile: false,
            offerDialog: false,
            Height: 100,
            activeStep: 0,
            isTourOpen: false,
            cart: '',
            OffersBanner: '',
            tutorialSteps: [
                { imgPath: Images.Banner0, },
                { imgPath: Images.Banner1, },
                { imgPath: Images.Banner2, },
                { imgPath: Images.Banner3, },
                { imgPath: Images.Banner4, },
                { imgPath: Images.Banner5 },
            ]
        }
    }
    componentWillMount() {
        const data = window.$offer
        if (data == undefined) { this.WsGetOffers() }
        if (localStorage.getItem('WalktTrough') == null) {
            setTimeout(
                function () {
                    this.setState({ isTourOpen: true })
                }
                    .bind(this),
                4000
            );

        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        if (localStorage.getItem('user') != null) {
            this.WsGetcart()
        }
        //Get Current Location Code-----------------------------
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords != null || position.coords != undefined) {
                Geocode.setApiKey("AIzaSyATV9qayPdPY8GHlyc0wPIr1r7AyW5UIwQ");
                Geocode.setRegion("in");
                localStorage.setItem('lat', position.coords.latitude);
                localStorage.setItem('long', position.coords.longitude);
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    response => {
                        console.log('response:', response)
                        const address = response.results[1].formatted_address;
                        console.log('address:', address)
                        const numb = address.match(/\d/g, '');
                        const pincode = numb.toString();
                        console.log('pincode:', pincode)
                        const zipcode = pincode.substr(pincode.length - 11);
                        console.log('zipcode:', zipcode)
                        const pin_code = zipcode.replace(/,/g, '');
                        const city_name = response.results[0].address_components[1].long_name;
                        localStorage.setItem('city', city_name);
                        localStorage.setItem('pincode', pin_code);
                    },
                    error => {
                        console.error("error ", error);
                    }
                );
            }
            else {
                localStorage.setItem('city', '');
                localStorage.setItem('pincode', '');
            }
            //---------------------------------------------------------------------
        });
    }

    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }
    resize() { this.setState({ mediumBrowser: window.innerWidth <= 920, isMobile: window.innerWidth <= 720, Height: window.innerHeight }) }

    handleStep(step) {
        this.setState({ open: step })
    }
    handleBack = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };
    handleNext = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };

    renderSlider() {
        const { cart, isLoading, mediumBrowser, isMobile } = this.state
        const tutorialSteps = [
            {
                imgPath: Images.Banner0,
                label: "EMERGING ICONS OF GUJARAT 2020" + '\n',
                sublabel: ''
            }, 
            // {
            //     imgPath: Images.Banner1,
            //     label: "Gujarat's most trusted Generic Medicine Store",
            //     sublabel: ''
            // },
             {
                imgPath: Images.Banner2,
                label: 'Largest Range of Generic Medicines',
                sublabel: ''
            }, {
                imgPath: Images.Banner3,
                label: 'Trained & Qualified Staff',
                sublabel: ''
            }, {
                imgPath: Images.Banner4,
                label: '2 Lac Customers 75cr Saved',
                sublabel: ''
            }, {
                imgPath: Images.Banner5,
                label: 'WHO GMP Approved Generic at Lowest Prices',
                sublabel: ''
            }
        ];
        const tutorialSteps_mobile = [
            { imgPath: Images.Banner0_mobile },
            // { imgPath: Images.Banner1_mobile },
            { imgPath: Images.Banner2_mobile },
            { imgPath: Images.Banner3_mobile },
            { imgPath: Images.Banner4_mobile },
            { imgPath: Images.Banner5_mobile }
        ];
        function SampleNextArrow(props) {
            const { onClick } = props;
            return (
                <ButtonBase onClick={onClick} variant="contained" style={{ position: 'absolute', right: 5, zIndex: 500, fontWeight: 'bold', top: 300, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', }}>
                    <KeyboardArrowRight style={{ color: '#fff' }} />
                </ButtonBase>
            );
        }
        function SamplePrevArrow(props) {
            const { onClick } = props;
            return (
                <ButtonBase onClick={onClick} variant="contained" style={{ position: 'absolute', left: 5, zIndex: 500, fontWeight: 'bold', top: 300, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', }}>
                    <KeyboardArrowLeft style={{ color: '#fff' }} />
                </ButtonBase>
            );
        }
        var settings = {
            dots: true,
            infinite: true,
            autoplaySpeed: 4000,
            accessibility: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            initialSlide: this.state.Activeindex,
            pauseOnHover: false,
            autoplay: true,
            speed: 1500,
            slidesToScroll: 1,
            centerPadding: '0px',
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            appendDots: dots =>
            (
                <Grid container style={{
                    flexDirection: 'row',
                    backgroundColor: 'none',
                    borderRadius: "0px",
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 20,
                    paddingLeft: 20,
                    justifyContent: 'flex-start',
                }}>
                    {dots.map((item) => item.props.className == 'slick-active'
                        ? <AddIcon style={{ color: '#39A749', }} />
                        : <Typography style={{ color: '#ddd', padding: "8px" }}>|</Typography>
                    )}
                </Grid>
            )
        };

        return (
            <Grid container direction='row' style={{ width: '100%' }}>
                <Slider {...settings} style={{ width: '100%', alignSelf: 'center' }} >
                    {window.innerWidth <= 420 ? tutorialSteps_mobile.map((step, index) =>
                        <Grid container style={{ width: '100%' }} direction='row'>
                            <img resizeMode={'contain'} src={step.imgPath} style={mediumBrowser ? { marginTop: '25px', width: '100%', height: this.state.Height - 150 } : { marginTop: '25px', width: '100%', height: this.state.Height - 150 }} />
                        </Grid>
                    ) : tutorialSteps.map((step, index) =>
                        <Grid container style={{ width: '100%' }} direction='row'>
                            <img resizeMode={'contain'} src={step.imgPath} style={mediumBrowser ? { marginTop: '35px', width: '100%', height: this.state.Height - 150 } : { marginTop: '35px', width: '100%', height: this.state.Height - 150 }} />
                            {/* <Typography style={mediumBrowser ?
                                { fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '600', color: '#262628', position: 'absolute', width: '40%', top: 50, left: 50 } : { fontSize: AppConstants.FONTSIZE.FS40, color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '600', position: 'absolute', width: '40%', top: 50, left: 50 }}>{step.label.toUpperCase()}</Typography> */}
                        </Grid>
                    )}
                </Slider>

            </Grid>
        )
    }
    renderfooterMenu() {
        const { classes } = this.props
        return (<main className={classes.main} >
            <BottomMenu history={this.props.history} />
        </main>)
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    WsGetcart() {
        APIController.GetCart('8', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), '380051', '0')
            .then((response) => {
                if (response.error == null) { this.setState({ cart: response.object[0].cartcount }) }
            })
    }
    WsGetOffers() {
        APIController.GetData(16, AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                const Banner = response.object.filter(data => data.shortcode === 'WebBanner');
                if (Banner.length == 1 && Banner[0].parametervalue != '') {
                    this.setState({ OffersBanner: Banner[0].parametervalue, offerDialog: true, })
                    window.$offer = '1' //global variable
                }
            })
    }

    medicineDetailFromsearch(data) { }

    handleOpenDialog() {
        this.setState({ offerDialog: true })
    }
    handleCloseDialog() {
        this.setState({ offerDialog: true })
    }
    closeTour = () => {
        this.setState({ isTourOpen: false })
        localStorage.setItem('WalktTrough', '1')
    }


    render() {
        const { cart, mediumBrowser } = this.state
        const steps = [
            {
                selector: '.first-step',
                content: 'Type your Medicine Name To  See Suggetions',
                style: {
                    fontSize: 18,
                },
            },
        ]
        return (
            <React.Fragment>
                <Helmet>
                    <title>Get WHO-GMP standard generic medicine at Medkart online pharmacy</title>
                    <meta name="description" content="Visit Medkart pharmacy online to buy WHO-GMP generic medicines at affordable prices. Also available at our 45+ stores in India across 4 states" />
                    <meta name="keywords" content="Medkart Pharmacy Online | WHO-GMP Generic Medicine | Affordable Medicines" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader closeTour={this.closeTour.bind(this)} CartCount={cart} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main>
                    {this.renderSlider()}
                    {/* <WebsiteHomePage browser={mediumBrowser} /> */}
                    <OfferDialog
                        history={this.props.history}
                        onClose={() => this.setState({ offerDialog: false })}
                        open={this.state.offerDialog}
                        url={this.state.OffersBanner}
                    // url={'https://picsum.photos/200/300'}
                    />
                    {/* <DialButton history={this.props.history} /> */}
                    <div style={{
                        display: 'flex', height: '50px', width: '170px', backgroundColor: '#FDD017', zIndex: 1000, position: 'fixed',
                        bottom: "70px", right: "10Px", fontWeight: 500, alignItems: 'center', borderRadius: '30px', cursor: 'pointer'
                    }} onClick={() => this.props.history.push('prescriptionUpload')}>
                        <PhotoCameraIcon style={{ color: '#39A749', marginLeft: '10px' }} />
                        <text style={{ margin: '20px 20px 20px 5px', textAlign: 'center' }}>Click to upload Prescription</text>
                    </div>
                    <Tour
                        rounded={5}
                        steps={steps}
                        isOpen={this.state.isTourOpen}
                        disableFocusLock={true}
                        onRequestClose={this.closeTour.bind(this)} />
                </main>
                {/* {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />} */}
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
            </React.Fragment >
        );
    }
}
const combined = withStyles(HomePageStyle)(HomePage);
export default geolocated({
    positionOptions: { enableHighAccuracy: true },
    watchPosition: true,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: false,
    userDecisionTimeout: 5000,
})(combined);