import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const OldPrescriptionStyle = theme => ({
    
    Medicinetitle: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 20,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
      },
      qntytxt: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
      },
      Dropimgtxt: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
      },
      Dropimgsubtxt:{
        fontFamily: 'Nunito Sans', fontWeight: 200,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
      },
      oldprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.red,
      },
      newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
      },
      mainContainer: {
        // marginTop: '100px',
        marginBottom: '8%',
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.09)',
      },
    Contentcardmainwithoutborder_OldPrescription: {
      // margin: '10px',
      backgroundColor: '#fff',
      padding: '12px 12px',
      borderRadius: '0px 0px 0px 0px',
  },
});
const combinedStyles = combineStyles(commonStyle, OldPrescriptionStyle);
export default combinedStyles; 
