import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FranchiseeMedkartStyle from './FranchiseeMedkartStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import { Grid, CssBaseline, Typography, Avatar } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { Images } from '../../common';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import AdjustIcon from '@material-ui/icons/Adjust';
import Title from "../../components/Header/Title";
import LocationIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import {ReCAPTCHA} from "react-google-recaptcha-v3";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import Slider from "react-slick";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import './FranchiseeStyle.css';

const recaptchaRef = React.createRef();

class FranchiseeMedkart extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
            Name: '',
            Location: '',
            Email: '',
            Phone: '',
            Message: '',
            ReCAPTCHA: '',
            isLoading: false,
            OpenThankYou: false
        }
    }

    componentDidMount() {
    
       
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    wsContactUs() {
        this.setState({ isLoading: true })
        APIController.ContactMedkaer(this.state.Name, this.state.Location, this.state.Email, this.state.Phone, this.state.Message, 'Website').then((response) => {
            if (response.error == null) {
                this.setState({
                    isLoading: false,
                    Name: '',
                    Location: '',
                    Email: '',
                    Phone: '',
                    Message: '',
                    ReCAPTCHA: '',
                })
                ToastsStore.success(response.object.message);
                // this.setState({OpenThankYou:true}) 
                this.props.history.push('/thankyou')
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
        })

    }
    handleStep(step) {
        this.setState({ open: step })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit() {
        if (this.validateForm()) {
            this.wsContactUs()
            // recaptchaRef.current.reset();
        }
    }
    // onChange() {
    //     this.setState({ ReCAPTCHA: true })
    // }

    validateForm() {
        var IsValid = 0;
        if (this.state.Name.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoName);
        }
        else if (this.state.Location.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoLocation);
        }
        else if (this.state.Email.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoEmail);
        }
        else if (!this.state.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDEMAILADDRESS);
        }
        else if (this.state.Phone.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoPhone);
        }
        else if (this.state.Phone.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOTPHONE);
        }
        else if (this.state.ReCAPTCHA.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.VALIDCAPTCHA);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    rendertitle() {
        const { mediumBrowser } = this.state
        const { classes } = this.props;
        return (
            <Grid container style={{ width: '100%' }} alignItems="center" justify={'center'} direction={'row'}>
                <Typography className={classes.Screentitle} >{'WANTS TO '}</Typography>&nbsp;&nbsp;
                <Typography className={classes.titletextGreen} >{' PARTNER US ?'}</Typography>
                {/* <Typography className={classes.titletextGreen} >{'Send us message'}</Typography> */}
            </Grid>
        )
    }

    renderForm() {
        const { isLoading, Name, Location, Email, Phone } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate >
                {/* <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', height: '97%',marginTop:'55px', padding: '2%', }}> */}
                <Grid container style={{ width: '100%', marginTop: 20 }} justify="space-between" alignItems="center" direction={'row'}>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            value={Name}
                            name="Name"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            label="Name *"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Email"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Email}
                            label="Email *"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Phone"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Phone}
                            label="Mobile *"
                            inputProps={{
                                maxLength: 10
                            }}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Location"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Location}
                            label="Location *"
                            onChange={this.handleChange}

                        />
                    </Grid>
                    {/* <Grid alignItems="center" direction={'row'} style={{ width: '100%' }} container className={classes.textFieldContent}>
                        <FilledTextField
                            multiline
                            rows={3}
                            className={classes.textField1}
                            name="Message"
                            InputProps={{
                                className: classes.textField1,
                                disableUnderline: true
                            }}
                            value={Message}
                            label="Message"
                            onChange={this.handleChange}
                        />
                    </Grid> */}
                    <Grid alignItems="center" style={{ width: '100%', postion: 'relative' }} direction={'row'} container className={classes.textFieldContent}>
                        <GoogleReCaptchaProvider reCaptchaKey="6LdR-6sZAAAAAHdPSumoT2053wjjds4c3vYXT4Hs" >
                            <GoogleReCaptcha onVerify={token =>
                                this.setState({ ReCAPTCHA: token })
                            } />
                        </GoogleReCaptchaProvider>
                    </Grid>
                    <div style={{ width: '100%', textAlign: '-webkit-center' }}>
                        <Grid alignItems="center" direction={'row'} style={window.innerWidth <= 420 ? { width: '60%', marginTop: '10px', marginBottom: '10px' } : window.innerWidth <= 920 ? { width: '40%', marginTop: '10px', marginBottom: '10px' } : { width: '30%', marginTop: '10px', marginBottom: '10px', alignItems: 'center' }} container >
                            <ButtonBase isLoading={isLoading} onClick={() => { this.handleSubmit() }} title={'Submit'} />
                            {/* <ButtonBase isLoading={isLoading} onClick={() => { this.props.history.push('/thankyou') }} title={'Submit'} /> */}
                        </Grid>
                    </div>
                </Grid>
                {/* </Card> */}
            </form>
        );
    }
    renderMainContent() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} justify={'center'} >
                <Grid container style={mediumBrowser ? { width: '100%' } : { width: '100%', margin: '0 10%', justifyContent: 'center' }} alignItems="center" direction={'row'}>
                    {this.renderForm()}
                </Grid>
            </Grid>
        )
    }
    renderWhyMedkart() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={window.innerWidth <= 420 ? { width: '100%', marginTop: 30, } : { width: '100%', marginTop: 30, marginLeft: '50px' }} container direction={'row'} alignItems='flex-start'>
                <Grid style={{ width: '100%', marginBottom: '10px', }} alignItems='center' container direction={'row'} >
                    <Typography className={classes.Screentitle} >{'WHY'}</Typography>&nbsp;&nbsp;
                    <Typography className={classes.titletextGreen} >{'MEDKART?'}</Typography>
                </Grid>
                {/* <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', }}> */}
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Proven Franchisee Model'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Robust Systems & Support'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Largest Range of Generic Medicines'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Trusted Brand'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Business with a Social Impact'}</Typography>
                </Grid>
                {/* </Card> */}
            </Grid>
        )
    }
    renderIphoneimg() {
        const { classes } = this.props
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} justify={'center'}>
                <img src={Images.Iphone}
                    className={classes.img} alt="" />
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }

    renderSlider() {
        const { mediumBrowser } = this.state
        function SampleNextArrow(props) {
            const { onClick } = props;
            return (
                <ButtonBase onClick={onClick} variant="contained" style={{ position: 'absolute', right: 5, zIndex: 500, fontWeight: 'bold', top: 300, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', }}>
                    <KeyboardArrowRight style={{ color: '#fff' }} />
                </ButtonBase>
            );
        }
        function SamplePrevArrow(props) {
            const { onClick } = props;
            return (
                <ButtonBase onClick={onClick} variant="contained" style={{ position: 'absolute', left: 5, zIndex: 500, fontWeight: 'bold', top: 300, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', }}>
                    <KeyboardArrowLeft style={{ color: '#fff' }} />
                </ButtonBase>
            );
        }

        var settings = {
            dots: true,
            infinite: true,
            autoplaySpeed: 4000,
            accessibility: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            initialSlide: 0,
            pauseOnHover: false,
            autoplay: false,
            speed: 1500,
            slidesToScroll: 1,
            centerPadding: '0px',
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            appendDots: dots =>
            (
                <Grid container style={{
                    flexDirection: 'row',
                    backgroundColor: 'none',
                    borderRadius: "0px",
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 20,
                    paddingLeft: 20,
                    justifyContent: 'flex-start',
                }}>
                    {dots.map((item) => item.props.className == 'slick-active'
                        ? <AddIcon style={{ color: '#39A749', }} />
                        : <Typography style={{ color: '#ddd', padding: "8px" }}>|</Typography>
                    )}
                </Grid>
            )
        };

        return (
            <div style={mediumBrowser ? { display: 'grid', width: '100%', justifyItems: 'center' } : { display: 'flex', width: '100%' }}>
                {/* <Slider {...settings} style={{ width: '100%', alignSelf: 'center' }} > */}
                <div className='App'>
                    <img src={Images.TestimonialDummy1} className='Timg' />
                    <div className='myCarousel'>
                        <lable style={{ fontSize: '20px', color: '#FFF', marginTop: "15px" }}>Nihit Kshatriya</lable>
                        <lable style={{ fontSize: '14px' }}>Baroda Franchise Owner</lable>
                        <img src={Images.FiveStar} style={{ height: '50px' }} />
                        <lable style={{ fontSize: '17px' }}>Medkart has enabled us to create an ecosystem that is centred around the benefit of the patients. This ecosystem eventually takes care of the sustainability of the business and hence creates a win-win situation for the entrepreneur and customers.</lable>
                    </div>
                </div>
                <div className='App'>
                    <img src={Images.TestimonialDummy2} className='Timg' />
                    <div className='myCarousel1'>
                        <lable style={{ fontSize: '20px', marginTop: "15px" }}>Lopa Shah</lable>
                        <lable style={{ fontSize: '14px' }}>Vapi/Valsad Franchise Owner</lable>
                        <img src={Images.FiveStar} style={{ height: '50px' }} />
                        <lable style={{ fontSize: '17px' }}>We always knew that; it's a challenging option as compare to a normal medical store. But we took it as a challenge and started our 1st shop in Valsad in January 2018 and 2nd and 3rd store in Vapi next year. Now with all our 3 outlets, we are reasonably placed in terms of business volumes.</lable>
                    </div>
                </div>
                {/* </Slider> */}
            </div>
        );
    }

    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { cart, mediumBrowser } = this.state
        return (
            <Grid style={{ width: '100%' }}>
                <Helmet>
                    <title>Contact us for Medkart Franchisee and start selling</title>
                    <meta name="description" content="Open generic medicine store with medkart franchisee at low investment with high return business. Start selling generic medicines in your Medkart store." />
                    <meta name="keywords" content="Medkart Franchisee | Low Investment High Return Business | Open Generic Medicine Store" />
                </Helmet>
                <CssBaseline />
                {this.props.fromHomepage == true ? '' : <MedkartHeader dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    {/* {this.props.fromHomepage ? '' : <Title isBack={false} title={'WANT TO PARTNER US ?'} history={this.props.history} />} */}
                    <div style={{ marginTop: '35px' }}>

                        <div style={{ padding: '0 12px 5px 12px', textAlign: 'center' }}>
                            <label className={classes.titletext}>Be our</label> <label className={classes.titletextcolor}>Partner</label><label className={classes.titletext}> of Growth! </label>
                        </div>
                        <Grid container style={{ width: '100%', alignSelf: 'flex-start', alignItems: 'flex-start', marginTop: '20px' }} direction='row' justify='space-around'>
                            {mediumBrowser && <Grid container style={{ width: '80%', padding: '5px' }} alignItems='center' justify='center'>
                                <img src={Images.MedkartShop} style={{ width: '100%', height: 300 }} />
                            </Grid>}
                            <Grid container style={mediumBrowser ? { width: '95%', paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%', marginTop: '2%' } : { width: '50%', padding: '5px', paddingTop: '0px', backgroundColor: '#ffffff', }} alignItems='flex-start' justify='center'>
                                <p className={classes.ParagraphTxt}>
                                    {'We are glad that you are searching for an opportunity to start your own business.'}<br />
                                    {'Medkart Pharmacy has come a long way since its humble beginnings in the year 2014. It is spreading its wings in the field of generic medicines retail pharmacy, helping patients understand WHO GMP certified generics and accept them. Our core principle is of touching each individual`s life meaningfully by educating and aiding in maximum savings.'}<br />
                                    {'We are present across major cities in Gujarat and expanding our presence in the western regions of India, with over 1004- exclusive Medkart Pharmacy stores. '}
                                </p>
                            </Grid>
                            {mediumBrowser ? <Grid /> : <Grid container style={{ width: '40%', padding: '5px', }} alignItems='center' justify='center'>
                                <img src={Images.MedkartShop} style={{ width: '100%', height: 350 }} />
                            </Grid>}
                        </Grid>

                        <label className={classes.titletext}>Why you should choose </label> <label className={classes.titletextcolor}>Medkart Pharmacy? </label>
                        <Grid container style={{ width: '100%', alignSelf: 'flex-start', alignItems: 'flex-start', marginTop: '20px' }} direction='row' justify='space-around'>
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', padding: '5px', paddingTop: '0px', backgroundColor: '#ffffff', }} alignItems='flex-start' justify='center'>
                                <ul>
                                    <li className={classes.ParagraphTxt}>
                                        WHO - GMP certified Medicines
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Largest Range of Generic Medicines and also stocking Branded Medicines
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Opportunity to tap never ending demand
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Operationally Profitable in just 3-6 months
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Quick Return on Investment in approx. 18 months
                                    </li>
                                </ul>
                            </Grid>
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', padding: '5px', paddingTop: '0px', backgroundColor: '#ffffff', }} alignItems='flex-start' justify='center'>
                                <ul>
                                    <li className={classes.ParagraphTxt}>
                                        Started by an IIM Alumnus
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Trusted Brand and business with a Social Impact
                                    </li>
                                    <li className={classes.ParagraphTxt}>
                                        Opportunity for Master Franchise also available
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                        <div style={{ display: 'grid', textAlign: 'center' }}>
                            <label className={classes.titletext}>Want To Be A</label> <label className={classes.titletextcolor}>Franchise Owner?</label>
                            <label className={classes.ParagraphTxtTitle}>Just fill out this form, We'll get in touch shortly!</label>
                        </div>

                        {this.renderMainContent()}

                        <div style={{ padding: '40px 12px 5px 12px', textAlign: 'center' }}>
                            <label className={classes.titletext}>WHAT OUR</label> <label className={classes.titletextcolor}>FRANCHISEES</label><label className={classes.titletext}> SAY </label>
                        </div>

                        {this.renderSlider()}

                    </div>

                    {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />}
                </main>
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                {/* <Alertdialog
                    paperFullScreen
                    onClose={() => this.setState({ OpenThankYou: false })}
                    style={{ height: '100%', borderRadius: '100px' }}
                    open={this.state.OpenThankYou}>
                    <Grid container className={mediumBrowser ? classes.mainMobileView : classes.mainView} style={{ width: '100%', }} direction="column" justify="center" alignItems="center">
                        <LocationIcon style={{ color: '#888', resize: 'cover', position: 'absolute', cursor: 'pointer', top: '1%', right: '0.8%' }} onClick={()=>this.setState({ OpenThankYou: false })} />
                        <CheckCircleIcon className={classes.thankslogo} />
                        <div style={{ display: 'grid', textAlign: 'center' }}>
                            <label className={classes.titletextcolor}>Thank You!</label>
                            <label className={classes.subtxt}>Your response has been recorded</label>
                        </div>
                    </Grid>
                </Alertdialog> */}
            </Grid>

        );
    }
}
export default withStyles(FranchiseeMedkartStyle)(FranchiseeMedkart);
