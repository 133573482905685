import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import myOrderStyle from './MyOrderStyle';
import { CssBaseline, Typography, Grid, Divider, CircularProgress, MenuItem, Tabs, Tab, AppBar, Box, Button, Card, TextField, } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import * as moment from 'moment';
import FilledDatePicker from "../../components/FilledDatePicker/FilledDatePicker";
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";

import { Scrollbars } from 'react-custom-scrollbars';
import OrderConfirmDialog from "../../components/OrderConfirmDialog/OrderConfirmDialog";
import CODDialog from "../../components/CODDialog/CODDialog";
import PayAgreeDialog from "../../components/PayAgreeDialog/PayAgreeDialog";
import CancelOrderDialog from "../../components/CancelOrderDialog/CancelOrderDialog";
import CashFreeDialog from "../../components/CashFreeDialog/CashFreeDialog.jsx";
import TrackOrderDialog from "../../components/TrackOrderDialog/TrackOrderDialog";
import * as AppConstants from '../../common/Constants';
import ReactGA from 'react-ga';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';
import { URLs } from '../../api/URLs';

const theme1 = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    background: "transparent",
                    backgroundColor: 'transparent',
                }
            }
        }
    }
});

const apiKey = AppConstants.apiKey
const apiSecret = AppConstants.apiSecret
const isProd = 'TEST'
class MyOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            PaymentConfirm: false,
            TrakingUrl: '',
            CodConfirm: false,
            OpenCancelOrderDialog: false,
            OpenReturnOrderDialog: false,
            OpenTrackOrderDialog: false,
            OpenCODDialog: false,
            mediumBrowser: false,
            IsLoading: true,
            IdForEdit: 0,
            Amount: '',
            orderConfomID: '',
            orderIDNoti: '',
            orderdate: '',
            estimateddeliverydate: '',
            orderreferenceno: '',
            orderstatus: '',
            patientname: '',
            FromDialog: '',
            addressmobileno: '',
            ordertotal: '',
            discount: '',
            deliverycharges: '',
            grosstotal: '',
            dataSource: [],
            dataSourceRefill: [],
            dataSourceOrder: [],
            Raiseissuedatasource: [],
            loading: false,
            nextDeliveryDate: '',
            IsLoadingEdit: true,
            PatientBirthDate: '',
            CouponDiscount: 0,
            Interval: '',
            fromPageRefill: '',
            edit: '',
            orderid: '',
            refillid: '',
            refillInterval: '',
            refillNextDeliveryDate: '',
            idOrder: '',
            refillOrderID: '',
            razorpay_payment_id: '',
            tabvalue: '4',
            Iscodallowed: '',
            Orderstatusid: '',
            emailaddress: '',
            OpenCashfreeDialog: false,
            CashfreeOrderToken: '',
        };
    }
    componentWillMount() {
        if (this.props.history.location.state != null) { this.setState({ FromDialog: this.props.history.location.state.FromDialog }) }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentDidMount() {
        //code for Razore pay 
        ReactGA.pageview(window.location.pathname + window.location.search);
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        this.wsMyOrders('0');

        if (this.props.history.location.state != null) {
            this.setState({
                fromPageRefill: this.props.history.location.state.fromPageRefill,
                edit: this.props.history.location.state.edit,
                orderid: this.props.history.location.state.orderid,
                refillid: this.props.history.location.refillid,
                refillInterval: this.props.history.location.refillInterval,
                refillNextDeliveryDate: this.props.history.location.refillNextDeliveryDate,
            });
            this.WsGetOrdersById(this.props.history.location.state.orderid)
        }
        if (this.props.history.location.state.orderConfomID != "") {
            this.setState({ orderConfomID: this.props.history.location.state.orderConfomID })
            this.WsGetOrdersById(this.props.history.location.state.orderConfomID)
        }

        if (this.props.history.location.state.orderIDNoti != "") {
            this.setState({ orderIDNoti: this.props.history.location.state.orderIDNoti })
            this.WsGetOrdersById(this.props.history.location.state.orderIDNoti)
        }
        this.wsGetListRifills();
    }


    OnPressEditList(index, id) {
        this.setState({ IdForEdit: index })
        this.WsGetOrdersById(id)
    }

    CallApiForOrderToken() {
        let tokenUrl = URLs.base + URLs.OrderToken;
        let headers = {
            'Content-Type': 'application/json',
            'x-api-version': '2022-01-01',
            'x-client-id': apiKey,
            'x-client-secret': apiSecret,
        };
        let method = 'POST';
        let data = {
            "mobileno": AppConstants.mobilenoFromLocal(),
            "body": {
                "order_id": this.state.orderid,
                "order_amount": this.state.grosstotal,
                "order_currency": "INR",
                "order_note": this.state.orderreferenceno,
                "order_meta": {
                    "notify_url": URLs.base + URLs.NotifyUrl
                },
                "customer_details": {
                    "customer_id": AppConstants.userIdFromLocal(),
                    "customer_email": this.state.emailaddress,
                    "customer_phone": this.state.addressmobileno,
                }
            }
        }
        let body = JSON.stringify(data);
        this.request(method, tokenUrl, body, headers)
    }

    request(method, url, body, headers) {
        return new Promise((resolve, reject) => {
            fetch(url, { method: method, headers: headers, body: body })
                .then((response) => {
                    return response.json()
                })
                .then((responseJSON) => {
                    console.log('responseJSON:', responseJSON)
                    if (responseJSON.data != '') {
                        this.setState({ CashfreeOrderToken: responseJSON.data })
                        this.renderDropin(responseJSON.data)
                    }

                    resolve(responseJSON)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }


    renderDropin = (orderToken) => {
        this.setState({ OpenCashfreeDialog: true })
        const cbs = (data) => {
            console.log('data: success', data)
            if (data.order && data.order.status === 'PAID') {
                this.PaymentSuccess(data.transaction.transactionId)
            }
        };
        const cbf = (data) => {
            console.log('data errr:', data)
            alert(data.transaction.txMsg);
            this.setState({ OpenCashfreeDialog: false })
        };
        if (orderToken === '') {
            alert('Order Token is empty');
            return;
        }

        let parent = document.getElementById('drop_in_container');
        parent.innerHTML = '';
        let cashfree;
        if (isProd == 'TEST') {
            cashfree = new cashfreeSandbox.Cashfree();
        } else {
            cashfree = new cashfreeProd.Cashfree();
        }
        cashfree.initialiseDropin(parent, {
            orderToken,
            onSuccess: cbs,
            onFailure: cbf,
            "components": [
                "order-details",
                "card",
                "netbanking",
                "app",
                "upi"
            ],
            "order_meta": {
                "notify_url": URLs.base + URLs.NotifyUrl
            },
            "style": {
                //to be replaced by the desired values
                "backgroundColor": "#ffffff",
                "color": "#39A749",
                "fontFamily": "Lato",
                "fontSize": "14px",
                "errorColor": "#ff0000",
                "theme": "light", //(or dark)
            }
        });

    };


    WsGetOrdersById(ID) {
        this.setState({ IsLoadingEdit: true })
        APIController.GetOrdersById('12', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                if (response.error == null) {
                    // this.SetData(response.object.data[0])
                    this.setState({ dataSourceOrder: response.object.data.orderdetails, IsLoadingEdit: false, })
                    if (response.object.data.orders.length != 0) {
                        let MyOrders = response.object.data.orders[0]
                        let CouponDiscount = MyOrders.coupondiscount
                        // let CouponDiscount = MyOrders.ordertotal * MyOrders.discountpercentage / 100 > MyOrders.maximumdiscount ? MyOrders.maximumdiscount : MyOrders.ordertotal * MyOrders.discountpercentage / 100
                        this.setState({
                            orderdate: response.object.data.orders[0].orderdate,
                            estimateddeliverydate: response.object.data.orders[0].estimateddeliverydate,
                            orderreferenceno: response.object.data.orders[0].orderreferenceno,
                            orderstatus: response.object.data.orders[0].orderstatus,
                            patientname: response.object.data.orders[0].patientname,
                            addressmobileno: response.object.data.orders[0].addressmobileno,
                            ordertotal: response.object.data.orders[0].ordertotal,
                            discount: response.object.data.orders[0].discount,
                            deliverycharges: response.object.data.orders[0].deliverycharges,
                            // grosstotal: response.object.data.orders[0].grosstotal - CouponDiscount,
                            grosstotal: response.object.data.orders[0].grosstotal,
                            orderid: response.object.data.orders[0].id,
                            Orderstatusid: response.object.data.orders[0].orderstatusid,
                            Iscodallowed: response.object.data.orders[0].iscodallowed,
                            emailaddress: response.object.data.orders[0].emailaddress,
                            TrakingUrl: response.object.data.orders[0].trackingurl,
                            CouponDiscount: CouponDiscount,

                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 });
    }

    WsGetRaiseandissueDetail(Status) {
        APIController.GetRaiseandissueDetail('10', AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(), Status)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ Raiseissuedatasource: response.object })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }
    wsSendOrderOTP() {
        APIController.SendOrderOTP(AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(), this.state.orderid, this.state.orderreferenceno)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        this.setState({ OpenCODDialog: true })
                        ToastsStore.warning(response.object.message)
                    }
                    else {
                        ToastsStore.warning(response.object.message)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    wsMyOrders(orderstatus) {
        this.setState({ IsLoading: true })
        APIController.GetMyOrder('11', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), orderstatus)
            // APIController.GetMyOrder('11', '8445', '7698498403', orderstatus)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSource: response.object, IsLoading: false })
                    if (response.object != '') {
                        this.WsGetOrdersById(this.state.orderIDNoti != undefined ? this.state.orderIDNoti : response.object[0].id)
                    }
                    else if (response.object.length == 0) {
                        this.setState({
                            dataSourceOrder: [],
                            orderdate: '',
                            Orderstatusid: '',
                            estimateddeliverydate: '',
                            orderreferenceno: '',
                            orderstatus: '',
                            patientname: '',
                            addressmobileno: '',
                            ordertotal: '',
                            discount: '',
                            deliverycharges: '',
                            grosstotal: '',
                            orderid: '',
                            Orderstatusid: '',
                            Iscodallowed: ''

                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    validationForRefill(id) {
        // this.setState({ idOrder: id });
        if (this.state.Interval == '' && this.state.nextDeliveryDate == '') {
            // ToastsStore.warning(AppConstants.ValidationErrorMessages.NODATFORREFILL);
        }
        else {
            this.state.edit == true ?
                this.wsEditRefills()
                : this.wsAddRefills()
        }
    }

    wsGetListRifills() {
        APIController.GetData('15', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.refillid)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSourceRefill: response.object });
                    // this.setState({ Interval: response.object[0].daystogo,nextDeliveryDate:response.object[0].nextrefilldate });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    wsAddRefills() {
        APIController.ManageRefills(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.orderid, this.state.Interval, moment(this.state.nextDeliveryDate).format("DD-MMM-YYYY"), '1')
            .then((response) => {
                if (response.error == null) {
                    // this.setState({ dataSourceAddRefill: response.object });
                    // this.props.history.push({ pathname: '/refill', state: { dataSourceAddRefill_MyOrder: response.object, fromEditRefill: false } })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    wsEditRefills() {
        APIController.EditRefills(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.orderid, this.state.refillid, this.state.refillInterval, moment(this.state.refillNextDeliveryDate).format("DD-MMM-YYYY"), '2')
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSourceEditRefill: response.object });
                    this.props.history.push({ pathname: '/refill', state: { dataSourceEditRefill_MyOrder: response.object, fromEditRefill: true } })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }


    renderMyOrderList() {
        const { classes } = this.props;
        const { dataSource, IsLoading, IdForEdit, tabvalue, mediumBrowser } = this.state;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { textAlign: 'left', flex: 1, width: '100%', height: '97%', padding: '2%', paddingBottom: '23px' } : { textAlign: 'left', flex: 1, width: '100%', height: '97%', padding: '2%', paddingBottom: '23px' }}>
                {/* <Grid container
                    direction="row"
                    alignItems='center'
                    justify='space-between'
                    style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
                    <ButtonBase style={{ width: '26%' }} title={'Approved'}
                        onClick={() => {
                            this.wsMyOrders('2');
                            this.setState({ tabvalue: '1', IsLoading: true, IdForEdit: 0 })
                        }}
                        disabled={this.state.tabvalue == '1' ? true : false} />
                    <ButtonBase style={{ width: '26%' }} title={'Delivered'}
                        onClick={() => {
                            this.wsMyOrders('5');
                            this.setState({ tabvalue: '2', IsLoading: true, IdForEdit: 0 })
                        }}
                        disabled={this.state.tabvalue == '2' ? true : false} />
                    <ButtonBase style={{ width: '26%' }} title={'All'}
                        onClick={() => {
                            this.wsMyOrders('0');
                            this.setState({ tabvalue: '3', IsLoading: true, IdForEdit: 0 })
                        }}
                        disabled={this.state.tabvalue == '3' ? true : false} />
                </Grid> */}

                {/* <Grid container direction='row' style={{ width: '100%', height: '50px' }} justify={'space-between'} alignItems='center' >
                    <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700',fontFamily: 'Nunito Sans', }}> {'(' + dataSource.length + ')' + ' ' + 'Orders Found'} </Typography>
                </Grid> */}
                {IsLoading ? this.LoadingView() :
                    <Scrollbars autoHide autoHeight autoHeightMax='1000px'>
                        {
                            dataSource.map((item, index) =>
                                <Grid container onClick={() => { this.OnPressEditList(index, item.id) }} style={{ cursor: 'pointer', marginBottom: index == dataSource.length - 1 ? '13px' : '0px' }} direction={'row'}>
                                    <Grid container direction='row' className={IdForEdit == index ? classes.ContentSelectcardmainMyOrder : classes.ContentcardmainMyOrder}>
                                        <Grid container direction='row' justify={'space-between'}
                                            style={{ marginTop: '5px' }} >
                                            <Typography style={{ marginLeft: '10px', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', }}> {item.patientname} </Typography>
                                            <Grid justify='center' alignItems='center'
                                                className={
                                                    item.orderstatusid == '1'
                                                        ? classes.placeView :
                                                        item.orderstatusid == '2'
                                                            ? classes.aprovedView :
                                                            item.orderstatusid == '3'
                                                                ? classes.codView :
                                                                item.orderstatusid == '4'
                                                                    ? classes.despatchView :
                                                                    item.orderstatusid == '5'
                                                                        ? classes.deliverView :
                                                                        item.orderstatusid == '6'
                                                                            ? classes.cancelView :
                                                                            item.orderstatusid == '7'
                                                                                ? classes.returnView :
                                                                                classes.rejectView
                                                }>
                                                <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: 400, color: '#ffffff', fontSize: '14px', marginTop: '1%', textAlign: 'center' }}> {item.orderstatus} </Typography>
                                            </Grid>
                                        </Grid>
                                        {item.orderstatusid == '2' ? <Grid container direction="row" style={{ marginLeft: '10px' }} alignItems='center'>

                                            {/* <Grid containder justify='space-between' style={{width:'70%',backgroundColor:'red'}}> */}
                                            <Typography style={{ width: '30%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order No. </Typography>
                                            <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> #{item.orderreferenceno} </Typography>
                                            {/* </Grid> */}
                                            {/* <Grid containder direction="row" style={{width:'30%',backgroundColor:'green'}}> */}
                                            <Typography style={{ width: '40%', fontFamily: 'Nunito Sans', fontWeight: 700, color: 'red', fontSize: '15px', textAlign: 'end' }}> Pay Now&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                            {/* </Grid> */}

                                        </Grid> : <Grid container direction="row"
                                            style={{ marginLeft: '10px' }} >
                                            <Typography style={{ width: '30%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order No. </Typography>
                                            <Typography style={item.orderstatusid == '2' ? { width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', } : { width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> #{item.orderreferenceno} </Typography>
                                        </Grid>}
                                        <Grid container direction="row"
                                            style={{ marginLeft: '10px' }} >
                                            <Typography style={{ width: '30%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Order On </Typography>
                                            <Typography style={{ width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {item.orderdate} </Typography>
                                        </Grid>
                                        <Grid container direction="row"
                                            style={{ marginLeft: '10px', marginBottom: '10px' }} >
                                            <Typography style={{ width: '30%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> Expected Delivery </Typography>
                                            <Typography style={{ width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', }}> {moment(item.estimateddeliverydate).format("DD-MMM-YYYY")} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Scrollbars>
                }
            </Card>
        )
    }

    handleChange = (e) => {
        // this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    CancelOrder() {
        this.setState({ OpenCancelOrderDialog: true })
        this.WsGetRaiseandissueDetail(1)
    }
    ReturnOrder() {
        this.setState({ OpenReturnOrderDialog: true })
        this.WsGetRaiseandissueDetail(2)
    }
    renderaddMyOrder() {
        const { dataSourceOrder, IsLoadingEdit, dataSourceRefill, nextDeliveryDate, Interval, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} container direction="row" style={mediumBrowser ? { width: '100%', height: '97%', padding: '4%', paddingBottom: '17px' } : { width: '100%', height: '97%', padding: '4%', }}>
                {IsLoadingEdit ? this.LoadingView() :
                    <Grid container>
                        {this.state.fromPageRefill == true ?
                            <Grid container direction={window.innerWidth <= 420 ? 'column' : 'row'} justify={window.innerWidth <= 420 ? 'center' : 'space-between'} style={{ border: '1px solid ', borderColor: '#39A749', borderRadius: '0px 0px 0px 0px', }} >
                                <FilledDatePicker
                                    // disableFuture={true}
                                    disablePast={true}
                                    label={'Next Delivery Date'}
                                    InputAdornmentProps={{ position: "end", style: { margin: '-5%' } }}
                                    style={window.innerWidth <= 420 ? { width: '90%', marginTop: '10px', marginLeft: '5%' } : { width: '45%', marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}
                                    value={nextDeliveryDate === '' ? new Date() : nextDeliveryDate}
                                    name='nextDeliveryDate'
                                    onChange={(e) => this.setState({ nextDeliveryDate: e })} />
                                <MuiThemeProvider theme={theme1}>
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        label='Interval'
                                        rows={1}
                                        value={Interval}
                                        name={'Interval'}
                                        keyboardEnabled={true}
                                        keyboardIcon={<ArrowDropDownIcon fontStretch='20px' />}
                                        InputAdornmentProps={{ position: "end" }}
                                        InputProps={{
                                            className: classes.textField,
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{
                                            className: classes.label,
                                        }}
                                        style={window.innerWidth <= 420 ? { width: '90%', marginTop: '10px', marginBottom: '10px', marginLeft: '5%', } : { width: '45%', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
                                        className={classes.textField}
                                        onChange={this.handleChange}
                                        select>
                                        {['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'].map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </TextField>
                                </MuiThemeProvider>
                            </Grid> : <Grid />}

                        <Grid container
                            direction="row"
                            justify='space-between'
                            style={{ marginTop: '10px', }}>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', backgroundColor: '#fff', color: '#9B9B9B' }}> Placed on </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', backgroundColor: '#fff', color: '#262628' }}> {this.state.orderdate} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between' >
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', backgroundColor: '#fff', color: '#9B9B9B' }}> Esti. Delivery Date </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', backgroundColor: '#fff', color: '#262628' }}> {this.state.estimateddeliverydate != '' ? moment(this.state.estimateddeliverydate).format("DD-MMM-YYYY") : ''} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> Order No. </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#262628' }}> {this.state.orderreferenceno != '' ? '#' + this.state.orderreferenceno : ''} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> Order Status </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9262628B9B9B' }}> {this.state.orderstatus} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B', marginTop: '10px', marginBottom: '10px' }}> Order Items </Typography>
                        {/* <Scrollbars autoHide autoHeight hideTracksWhenNotNeeded={true} autoHeightMax='300px'> */}
                        {
                            dataSourceOrder.map((item, index) =>
                                <Grid container direction='row'>
                                    <Grid container
                                        direction="row"
                                        justify='space-between'>
                                        {/* <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', }}> {item.medicinename} </Typography> */}
                                        {item.companyname != null && item.companyname != '' ?
                                            <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', }}>{item.medicinename + '( By: ' + item.companyname + ' )'}</Typography>
                                            : <Typography style={{ color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', }}>{item.medicinename}</Typography>
                                        }
                                        <Typography style={{ color: '#39A749', fontFamily: 'Nunito Sans', fontWeight: '700', fontSize: '18px', }}> ₹ {item.totalsaleprice} </Typography>
                                    </Grid>
                                    <Grid container
                                        direction="row"
                                        justify='space-between'>
                                        <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> Pack of {item.packsize}, Quantatiy: {item.quantity} </Typography>
                                        <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: 'red', textDecorationLine: 'line-through', }}> ₹ {item.totalmrp} </Typography>
                                    </Grid>
                                    {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                                        <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                        &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                    </Grid> */}
                                    <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />


                                </Grid>
                            )}
                        {/* </Scrollbars> */}

                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> MRP Total </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#262628' }}> {this.state.ordertotal != '' ? '₹ ' + this.state.ordertotal : ''} </Typography>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> Discount </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#262628' }}> {this.state.discount != '' ? '-₹ ' + this.state.discount : ''} </Typography>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> CouponDiscount </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#262628' }}> {this.state.CouponDiscount != '' ? '-₹ ' + this.state.CouponDiscount : ''} </Typography>
                        </Grid>
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#9B9B9B' }}> Delivery Charges </Typography>
                            <Typography style={{ fontFamily: 'Nunito Sans', fontWeight: '400', fontSize: '16px', color: '#262628' }}> ₹ {this.state.deliverycharges} </Typography>
                        </Grid>
                        <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#39A749', height: '2px', marginBottom: '1%', marginTop: '1%', }} />
                        <Grid container
                            direction="row"
                            justify='space-between'>
                            <Typography style={{ color: '#262628', fontSize: '18px', fontWeight: '700', fontFamily: 'Nunito Sans', paddingBottom: '15px' }}> Gross Total </Typography>
                            <Typography style={{ color: '#262628', fontSize: '18px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {this.state.grosstotal != '' ? '₹ ' + this.state.grosstotal : ''} </Typography>
                        </Grid>

                        {this.state.fromPageRefill == true ? <Grid container
                            direction="row"
                            justify='flex-end'
                            style={{ marginTop: '10px', }}>
                            {this.state.edit == true ?
                                <ButtonBase title='EDIT REFILL' onClick={() => this.validationForRefill()}></ButtonBase> :
                                <ButtonBase title='ADD REFILL' onClick={() => this.validationForRefill()} ></ButtonBase>}
                        </Grid> : <Grid></Grid>}
                        {
                            this.state.Orderstatusid == '6' || this.state.Orderstatusid == '' || this.state.Orderstatusid == '8' ?
                                <Grid container style={{ marginBottom: '25px' }}></Grid> :
                                <Grid container
                                    direction="row"
                                    alignItems='center'
                                    justify='space-between'
                                    style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}>
                                    {/* button for cancel order or return order */}
                                    {
                                        <>
                                            <ButtonBase style={mediumBrowser ? { width: '100%' } : { width: '30%', }} title={this.state.Orderstatusid <= '4' ? "CANCEL ORDER" : "RETURN ORDER"} onClick={() => this.state.Orderstatusid <= '4' ? this.CancelOrder() : this.ReturnOrder()} />
                                            {/* {this.state.Orderstatusid == '2' && <ButtonBase style={mediumBrowser ? { width: '100%', marginLeft: '10%' } : { width: '30%', marginLeft: '40%' }} title={'PAY NOW'} onClick={() => this.CheckReadorNotTerms()} />} */}
                                        </>
                                    }

                                    {/* Button for Payment pay Online or Cod */}
                                    {this.state.Orderstatusid == '2' ?
                                        this.state.Iscodallowed == 0 ?
                                            <div className="App">
                                                <div
                                                    className="dropin-parent"
                                                    id="drop_in_container"
                                                    style={{ height: '600px' }} >

                                                    <ButtonBase className="btn-render mt-2" style={mediumBrowser ? { width: '100%', marginLeft: '10%' } : { width: '30%', marginLeft: '40%' }} title={'PAY NOW'} onClick={() => this.CheckReadorNotTerms()} />

                                                </div>
                                            </div>

                                            :
                                            <Grid container
                                                direction="row"
                                                alignItems='center'
                                                justify='space-between'
                                                style={mediumBrowser ? { width: '100%', marginTop: '15px', marginBottom: '15px', } : { width: '70%', marginTop: '15px', marginBottom: '15px', paddingLeft: '12%' }}>
                                                <ButtonBase onClick={() => this.CheckReadorNotTerms()} style={{ width: mediumBrowser ? '100%' : '40%', }} title={'PAY NOW'} />
                                                <ButtonBase style={mediumBrowser ? { width: '100%', marginTop: '15px' } : { width: '40%' }} title={'Cash On Delivery'} onClick={() => this.wsSendOrderOTP()} />
                                            </Grid>
                                        // <Grid container
                                        //     direction="row"
                                        //     alignItems='center'
                                        //     justify='space-between'
                                        //     style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
                                        //     <ButtonBase style={window.innerWidth<=420?{ width: '40%' }:{ width: '23%' }} title={'PAY NOW'} onClick={() => this.CheckReadorNotTerms()} />
                                        //     <ButtonBase style={{ width: '23%' }} title={'COD'} onClick={() => this.wsSendOrderOTP()} />
                                        // </Grid> 
                                        : <div />
                                    }
                                    {/* Button for Track order when order are despatched */}
                                    {
                                        this.state.TrakingUrl != '' ?
                                            <ButtonBase style={mediumBrowser ? { width: '100%', marginTop: '15px' } : { width: '30%' }} title={"TRACK ORDER"}
                                                // onClick={() => this.setState({ OpenTrackOrderDialog: true })} 
                                                href={this.state.TrakingUrl} target="_blank"
                                            />
                                            : <div />
                                    }

                                </Grid>
                        }


                    </Grid>
                }
            </Card>
        )
    }

    renderCartEmpty() {
        const { classes } = this.props
        return (
            <Grid className={classes.ContentcardmainCart} style={{ width: '98.5%', marginLeft: '0.8%' }} container direction={'row'} justify={'center'} alignItems="center">
                <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> {'No Orders Found'}</Typography>
            </Grid>
        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    PaymentSuccess(id) {
        return (
            this.setState({
                PaymentId: id,
                PaymentConfirm: true,
                OpenCashfreeDialog: false
            })
        )
    }




    openPaymentModal() {

        const PaymentSuccess = (id) => {
            return (
                this.setState({
                    PaymentId: id,
                    PaymentConfirm: true
                })
            )
        }
        const options = {
            //key: 'rzp_test_hasWCtfMnnZprM', //this is demo key
            key: 'rzp_live_1tyJr3v1Twq7E0',    //this is live key
            amount: this.state.grosstotal * 100,//  = INR 1/
            name: this.state.patientname,
            description: this.state.orderreferenceno,
            method: { netbanking: true, card: false, wallet: true, upi: true },
            reference_no: this.state.orderid,
            image: 'https://medkart.in/icon1.png',
            handler: function (response) {
                if (response.razorpay_payment_id != null) {
                    PaymentSuccess(response.razorpay_payment_id)
                }
            },
            prefill: {
                name: this.state.patientname,
                contact: this.state.addressmobileno,
                email: this.state.emailaddress
            },
            notes: {
                reference_no: this.state.orderid,
            },
            theme: {
                color: '#39A749',
                hide_topbar: false
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    medicineDetailFromsearch(data) { }

    CheckReadorNotTerms() {
        localStorage.getItem('Terms') == '1' ?
            // this.renderDropin(this.state.CashfreeOrderToken)
            this.CallApiForOrderToken()
            : this.setState({ CodConfirm: true })
    }


    OnAgree() {
        localStorage.setItem('Terms', '1');
        this.setState({ CodConfirm: false })
        this.CallApiForOrderToken()
    }

    OnCODReload(data) {
        this.setState({
            PaymentId: data.paymentid,
            OpenCODDialog: false,
            Amount: data.OrderTotal,
            PaymentConfirm: true,
            tabvalue: '1'
        })
        this.wsMyOrders('2')
    }
    OnCancelReload(data) {
        ToastsStore.warning(data);
        this.wsMyOrders('0')
        this.setState({ OpenCancelOrderDialog: false, tabvalue: '4' })
    }
    OnReturnReload(data) {
        ToastsStore.warning(data);
        this.wsMyOrders('0')
        this.setState({ OpenReturnOrderDialog: false, tabvalue: '4' })
    }
    OnPaymentReload() {
        this.setState({ PaymentConfirm: false, tabvalue: '1' })
        this.wsMyOrders('2')
    }
    render() {
        const { classes } = this.props;
        const { dataSource, OpenCashfreeDialog, IsLoading, IdForEdit, tabvalue, mediumBrowser, Amount, Raiseissuedatasource, OpenCODDialog, CodConfirm, OpenReturnOrderDialog, OpenCancelOrderDialog, PaymentConfirm, PaymentId, OrderId } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title isBack={this.state.FromDialog == true ? 1 : 2} title={'My Orders'} history={this.props.history} />
                    {IsLoading ? this.LoadingView() :
                        <Grid container justify='flex-start' alignItems='center'>
                            <ToastsContainer store={ToastsStore} />
                            <Grid container
                                direction="row"
                                alignItems='center'
                                justify='space-between'
                                style={mediumBrowser ? { width: '100%', marginTop: '2%', } : { width: '38.3%', marginLeft: '0.8%', marginTop: '1%' }}>
                                <ButtonBase style={{ width: '23%' }} title={'Approved'}
                                    onClick={() => {
                                        this.wsMyOrders('2');
                                        this.setState({ tabvalue: '1', IsLoading: true, IdForEdit: 0 })
                                    }}
                                    disabled={this.state.tabvalue == '1' ? true : false} />
                                <ButtonBase style={{ width: '23%' }} title={'Paid'}
                                    onClick={() => {
                                        this.wsMyOrders('3');
                                        this.setState({ tabvalue: '3', IsLoading: true, IdForEdit: 0 })
                                    }}
                                    disabled={this.state.tabvalue == '3' ? true : false} />
                                <ButtonBase style={{ width: '23%' }} title={'Delivered'}
                                    onClick={() => {
                                        this.wsMyOrders('5');
                                        this.setState({ tabvalue: '2', IsLoading: true, IdForEdit: 0 })
                                    }}
                                    disabled={this.state.tabvalue == '2' ? true : false} />
                                <ButtonBase style={{ width: '23%' }} title={'All'}
                                    onClick={() => {
                                        this.wsMyOrders('0');
                                        this.setState({ tabvalue: '4', IsLoading: true, IdForEdit: 0 })
                                    }}
                                    disabled={this.state.tabvalue == '4' ? true : false} />

                            </Grid>
                            {
                                this.state.dataSource.length == 0 ? this.renderCartEmpty() : <Grid container justify='center' alignItems='center' alignSelf='center'>
                                    <Grid container direction='row' style={mediumBrowser ? { width: '100%', marginTop: '2%' } : { width: '98.5%', marginTop: '1%' }} justify={'space-between'} alignItems='center' >
                                        <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}>{'(' + dataSource.length + ')' + ' ' + 'Orders Found'} </Typography>
                                    </Grid>
                                    <Grid container direction="row" style={mediumBrowser ? { width: '100%', marginTop: '2%' } : { width: '98.5%', marginTop: '1%' }} justify="space-between">
                                        <Grid alignItems='cetner' container style={mediumBrowser ? { width: '100%', } : { width: '39%', }} >
                                            {this.renderMyOrderList()}
                                        </Grid>
                                        <Grid alignItems='cetner' container style={mediumBrowser ? { width: '100%', marginTop: '0px' } : { width: '60%', }} >
                                            {this.renderaddMyOrder()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    <OrderConfirmDialog
                        open={PaymentConfirm}
                        OrderId={OrderId}
                        PaymentId={PaymentId}
                        Amount={this.state.grosstotal}
                        OnDetail={() => this.OnPaymentReload()}
                        history={this.props.history}
                        OrderReferenceNo={this.state.orderreferenceno}
                        EstimateDelivery={this.state.estimateddeliverydate}
                        Title={'Payment Pay Successfully!'}
                    />
                    <PayAgreeDialog
                        history={this.props.history}
                        onClose={() => this.setState({ CodConfirm: false })}
                        open={CodConfirm}
                        onOkPress={() => this.OnAgree()}
                        onClick={() => this.setState({ CodConfirm: false })}
                    />
                    <CancelOrderDialog
                        history={this.props.history}
                        onClose={() => this.setState({ OpenCancelOrderDialog: false })}
                        open={OpenCancelOrderDialog}
                        data={Raiseissuedatasource}
                        OrderId={this.state.orderid}
                        Title={'Cancel Order'}
                        OrderStatus={this.state.orderstatus}
                        onCancelOrder={this.OnCancelReload.bind(this)}
                        OrderStatusId={this.state.Orderstatusid}
                        OrderReferenceNo={this.state.orderreferenceno}
                        onClick={() => this.setState({ OpenCancelOrderDialog: false })}
                    />
                    <CancelOrderDialog
                        history={this.props.history}
                        onClose={() => this.setState({ OpenReturnOrderDialog: false })}
                        open={OpenReturnOrderDialog}
                        data={Raiseissuedatasource}
                        OrderId={this.state.orderid}
                        Title={'Return Order'}
                        OrderStatus={this.state.orderstatus}
                        onCancelOrder={this.OnReturnReload.bind(this)}
                        OrderStatusId={this.state.Orderstatusid}
                        OrderReferenceNo={this.state.orderreferenceno}
                        onClick={() => this.setState({ OpenReturnOrderDialog: false })}
                    />

                    <CODDialog
                        history={this.props.history}
                        onClose={() => this.setState({ OpenCODDialog: false })}
                        open={OpenCODDialog}
                        OrderId={this.state.orderid}
                        onVerify={this.OnCODReload.bind(this)}
                        onCancel={() => this.setState({ OpenCODDialog: false })}
                    />
                    {/* <TrackOrderDialog
                        history={this.props.history}
                        onClose={() => this.setState({ OpenTrackOrderDialog: false })}
                        open={this.state.OpenTrackOrderDialog}
                        Title={'Track Order'}
                        OrderId={this.state.orderreferenceno}
                        onCancel={() => this.setState({ OpenTrackOrderDialog: false })}
                    /> */}

                </main>

                <Alertdialog
                    onClose={() => this.setState({ OpenCashfreeDialog: false })}
                    // style={{ height: '750px', overflow: 'hidden', borderRadius: '100px' }}
                    open={OpenCashfreeDialog}  >
                    <div

                        id="drop_in_container"
                        style={{ overflowY: 'overlay' }}
                    >
                    </div>

                </Alertdialog>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(myOrderStyle)(withTranslation('translation')(MyOrder));
