import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TermsConditionsStyle from './TermsConditionsStyle';
import {
    CssBaseline, Grid, Button,
} from '@material-ui/core';
import Iframe from 'react-iframe';
import Footer from '../../components/Header/Footer';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Title from "../../components/Header/Title";
import CompareIcon from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUsIcon from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";
class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: true,
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Medkart Terms & Conditions for data usage</title>
                    <meta name="description" content="Medkart privacy policy for data usage" />
                    <meta name="keywords" content="Medkart Privacy Policy" />
                </Helmet>
                <CssBaseline />
                <Grid style={{ width: '100%' }}>
                    <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                    <main className={classes.main}>
                        <Title title={'Terms & Conditions'} isBack={false} history={this.props.history} />
                        <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">
                            <Grid style={mediumBrowser ? { width: '100%' } : { width: '98.5%' }} container frameBorder='0'>
                                <Grid style={{ marginRight: 3, marginLeft: 3, marginTop: '13px', fontFamily: 'Nunito Sans' }}>

                                    <p>Terms of use</p>
                                    <p>Please read these terms of use carefully by accessing or using this internet based platform, you agree to be bound by the terms described herein and all terms incorporated by reference. if you do not agree to all of these terms, do not use this internet based platform.</p>
                                    <p>What is Medkart</p>
                                    <ol>
                                        <ol>
                                            <li>The domain name www.medkart.in, an internet based portal and Medkart a mobile application, is owned and operated by MedkartPharmacy Private Limited, a company duly incorporated under the provisions of the Companies Act, 2013 (hereinafter referred to as &ldquo;<strong>Medkart</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo; or &ldquo;<strong>Our</strong>&rdquo; or &ldquo;<strong>Us</strong>&rdquo; or &ldquo;<strong>Company</strong>&rdquo;). The domain name and the mobile application are collectively referred to as the &ldquo;<strong>Website</strong>&rdquo;.</li>
                                            <li>Your access or use of the Website, transaction on the Website and use of Services (as defined herein below) hosted or managed remotely through the Website, are governed by the following terms and conditions (hereinafter referred to as the&nbsp;<strong>Terms of Use</strong>&rdquo;), including the applicable policies which are incorporated herein by way of reference. These Terms of Use constitutes a legal and binding contract between you (hereinafter referred to as &ldquo;<strong>You</strong>&rdquo; or &ldquo;<strong>Your</strong>&rdquo; or the &ldquo;<strong>User</strong>&rdquo;) on one part and Medkart on the other Part.</li>
                                            <li>By accessing, browsing or in any way transacting on the Website, or availing any Services, You signify Your agreement to be bound by these Terms of Use. Further, by impliedly or expressly accepting these Terms of Use, you also accept and agree to be bound by Our policies, including the Privacy Policy (as set out in Part B herein below), and such other rules, guidelines, policies, terms and conditions as are relevant under the applicable law(s) in India and other jurisdictions for the purposes of accessing, browsing or transacting on the Website, or availing any of the Services, and such rules, guidelines, policies, terms and conditions shall be deemed to be incorporated into, and considered as part and parcel of these Terms of Use. However, if You navigate away from the Website to a third party website, You may be subject to alternative terms and conditions of use and privacy policy, as may be specified on such website. In such event, the terms and conditions of use and privacy policy applicable to that website will govern Your use of that website.</li>
                                            <li>&nbsp;The Website is a platform that facilitates (i) online purchase of pharmaceutical products sold by various third party pharmacies and manufactures (&ldquo;<strong>Third Party Pharmacies</strong>&rdquo;); (ii) diagnostic services being offered by various third party diagnostic centers (&ldquo;<strong>Third Party Labs</strong>&rdquo;); (iii) online medical consultancy services/ second opinion being offered by third party independent doctors (&ldquo;<strong>Medical Experts</strong>&rdquo;); and (iv) online advertisements of various sponsors advertising and marketing their own good and services (&ldquo;<strong>Third Party Advertisers</strong>&rdquo;). The Third Party Pharmacies, Third Party Labs, Medical Experts and the Third Party Advertisers are collectively referred to as the &ldquo;<strong>Third Party Service Providers</strong>&rdquo;. Further the Website also serves as an information platform providing health and wellness related information to the Users accessing the Website (The services of Third Party Services Provider and the information services is collectively referred to as the &ldquo;<strong>Services</strong>&rdquo;).</li>
                                            <li>The arrangement between the Third Party Service Providers, You and Us shall be governed in accordance with these Terms of Use. The Services would be made available to such natural persons who have agreed to use the Website after obtaining due registration, in accordance with the procedure as determined by Us, from time to time, (referred to as &ldquo;<strong>You</strong>&rdquo; or &ldquo;<strong>Your</strong>&rdquo; or &ldquo;<strong>Yourself</strong>&rdquo; or &ldquo;<strong>User</strong>&rdquo;, which terms shall also include natural persons who are accessing the Website merely as visitors). The Services are offered to You through various modes which shall include issue of discount coupons and vouchers that can be redeemed for various goods/ services offered for sale by relevant Third Party Service Providers. To facilitate the relation between You and the Third Party Service Providers through the Website, Medkart shall send to You (promotional content including but not limited to emailers, notifications and messages).</li>
                                            <li>You agree and acknowledge that the Website is a platform that You and Third Party Service Providers utilize to meet and interact with another for their transactions. Medkart is not and cannot be a party to or save as except as may be provided in these Terms of Use, control in any manner, any transaction between You and the Third Party Service Providers.</li>
                                            <li>Medkart reserves the right to change or modify these Terms of Use or any policy or guideline of the Website including the Privacy Policy, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions on the Website and You waive any right You may have to receive specific notice of such changes or modifications. Your continued use of the Website will confirm Your acceptance of such changes or modifications; therefore, You should frequently review these Terms of Use and applicable policies to understand the terms and conditions that apply to Your use of the Website.</li>
                                            <li>As a condition to Your use of the Website, You must be 18 (eighteen) years of age or older to use or visit the Website in any manner. By visiting the Website or accepting these Terms of Use, You represent and warrant to Medkart that You are 18 (eighteen) years of age or older, and that You have the right, authority and capacity to use the Website and agree to and abide by these Terms of Use.</li>
                                            <li>These Terms of Use is published in compliance of, and is governed by the provisions of Indian laws, including but limited to:</li>
                                            <ul>
                                                <li>the Indian Contract Act, 1872 (&ldquo;<strong>Contract Act</strong>&rdquo;);</li>
                                                <li>the (Indian) Information Technology Act, 2000 (&ldquo;<strong>IT Act</strong>&rdquo;) and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 (&ldquo;<strong>IG Guidelines</strong>&rdquo;);</li>
                                                <li>the Drugs and Cosmetic Act, 1940 (&ldquo;<strong>Drugs Act</strong>&rdquo;), read with the Drugs and Cosmetics Rules, 1945 (&ldquo;<strong>Drugs Rules</strong>&rdquo;);</li>
                                                <li>the Drugs and Magic Remedies (Objectionable Advertisements) Act, 1954 (&ldquo;<strong>Drugs and Magic Act</strong>&rdquo;);</li>
                                                <li>The Indian Medical Council Act, 1956 read with the Indian Medical Council Rules, 1957;</li>
                                                <li>Pharmacy Act, 1948 (&ldquo;<strong>Pharmacy Act</strong>&rdquo;) and</li>
                                                <li>the Consumer Protection Act, 1986.</li>
                                            </ul>
                                            <li>Medkart authorizes You to view and access the content available on the Website solely for the purposes of availing the Services, such as visiting, using, ordering, receiving, delivering and communicating only as per these Terms of Use. The contents on the Website including information, text, graphics, images, logos, button icons, software code, design, and the collection, arrangement and assembly of content, contains Third Party Service Providers&rsquo; content (&ldquo;<strong>Third Party Content</strong>&rdquo;) as well as in-house content provided by Medkart including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals (&ldquo;<strong>Medkart Content</strong>&rdquo;) (collectively, &ldquo;<strong>Content</strong>&rdquo;). The Medkart Content is the property of Medkart and is protected under copyright, trademark and other applicable law(s). You shall not modify the Medkart Content or reproduce, display, publicly perform, distribute, or otherwise use the Medkart Content in any way for any public or commercial purpose or for personal gain.</li>
                                            <li>Compliance with these Terms of Use would entitle You to a personal, non-exclusive, non-transferable, limited privilege to access and transact on the Website.</li>
                                            <li>These Terms of Use constitute an electronic record in terms of the IT Act and rules framed there under, as applicable and amended from time to time. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                                        </ol>
                                    </ol>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>ELIGIBILITY</p>
                                    <ol>
                                        <ol>
                                            <li>For the purposes of availing the Services and/or transacting with the Third Party Service Providers through the Website, You are required to obtain registration, in accordance with the procedure established by Medkart in this regard. As part of the registration process, Medkart may collect the following personal information from You:</li>
                                            <ul>
                                                <li>Name;</li>
                                                <li>User ID;</li>
                                                <li>Email address;</li>
                                                <li>Address (including country and ZIP/ postal code);</li>
                                                <li>Gender;</li>
                                                <li>Age;</li>
                                                <li>Phone number;</li>
                                                <li>Password chosen by the User;</li>
                                                <li>Valid financial account information; and</li>
                                                <li>Other details as You may volunteer.</li>
                                            </ul>
                                            <li>The registration on or use/ access of the Website is only available to natural persons, other than those who are &lsquo;incompetent to contract&rsquo; under the Contract Act. That is, persons including minors, un-discharged insolvents etc. are not eligible to register on, or use/ access the Website. By registering, accessing or using the Website, You accept the terms of these Terms of Use and represent and warrant to Medkart that you are &lsquo;competent to contract&rsquo; under the Contract Act and have the right, authority and capacity to use the Website and agree to and abide by these Terms of Use.</li>
                                            <li>A registered id can only be utilized by the person whose details have been provided and Medkart does not permit multiple persons to share a single log in/ registration id. However, a registered user, being also a parent or legal guardian of a person &lsquo;incompetent to contract&rsquo; such as minors or persons with unsound mind, would be permitted to access and use the Website for the purposes of procuring the Services, on behalf of such persons.</li>
                                            <li>Organizations, companies, and businesses may not become registered members on the Website or use the Website through individual members.</li>
                                            <li>You agree and acknowledge that You would (i) create only 1 (one) account; (ii) provide accurate, truthful, current and complete information when creating Your account and in all Your dealings through the Website; (iii) maintain and promptly update Your account information; (iv) maintain the security of Your account by not sharing Your password with others and restricting access to Your account and Your computer; (v) promptly notify Medkart if You discover or otherwise suspect any security breaches relating to the Website; and (vi) take responsibility for all the activities that occur under Your account and accept all risk of unauthorized access.</li>
                                            <li>The Website uses temporary cookies to store certain data (that is not sensitive personal data or information) that is used by Medkart for the technical administration of the Website, research and development, and for User administration. In the course of serving advertisements or optimizing services to You, Medkart may allow authorized third parties to place or recognize a unique cookie on the Your browser. Medkart does not store personally identifiable information in the cookies.</li>
                                            <li>Medkart, at its sole discretion, reserves the right to permanently or temporarily suspend Users, to bar their use and access of the Website and App, at any time while Medkart investigates complaints or alleged violations of these Terms of Use or any Services, or for any other reason</li>
                                        </ol>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <p>OTHER TERMS</p>
                                    <ul>
                                        <li><u>Your Profile, Collection, Use, Storage and Transfer of Personal Information:</u>
                                            <ol>
                                                <li>Your Medkart profile is created to store record of Your Consultations and Your personal health information online, including history, health conditions, allergies and medications.</li>
                                                <li>Any information provided as part of a web Consultation or obtained from use of the Services by You becomes part of Your Medkart record. You agree to provide accurate information to help Us serve You best to Our knowledge, to periodically review such information and to update such information as and when necessary. Medkart reserves the right to maintain, delete or destroy all communications and materials posted or uploaded to the Website according to its internal record retention and/or destruction policies. You might be contacted via email to review the information provided by You for Medkart&rsquo;s record or for the Services. Please make sure You provide a valid email-id and You update it as and when needed.</li>
                                                <li>For additional information regarding use of information about You, please refer to the Privacy Policy.</li>
                                                <li>The terms &ldquo;personal information&rdquo; and &ldquo;sensitive personal data or information&rdquo; are defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the &ldquo;<strong>SPI Rules</strong>&rdquo;), and are reproduced in the Privacy Policy.
<ol>
                                                        <li>The Privacy Policy sets out:
<ol>
                                                                <li>the type of information collected from Users, including sensitive personal data or information;</li>
                                                                <li>the purpose, means and modes of usage of such information; and</li>
                                                                <li>how and to whom Medkart will disclose such information.</li>
                                                            </ol>
                                                        </li>
                                                        <li>The Users are expected to read and understand the Privacy Policy, so as to ensure that he or she has the knowledge of:
<ol>
                                                                <li>the fact that the information is being collected;</li>
                                                                <li>the purpose for which the information is being collected;</li>
                                                                <li>the intended recipients of the information;</li>
                                                                <li>the name and address of the agency that is collecting the information and the agency that will retain the information; and</li>
                                                                <li>the various rights available to such Users in respect of such information.</li>
                                                            </ol>
                                                        </li>
                                                        <li>Medkart shall not be responsible in any manner for the authenticity of the personal information or sensitive personal data or information supplied by the Users to Medkart or any other person acting on behalf of Medkart.</li>
                                                        <li>The use of the Website involves every Users&rsquo; registration information and browsing history being stored and submitted to the appropriate authorities. The consent and procedure for such collection and submission is provided in the Privacy Policy. The other information collected by Medkart from Users as part of the registration process is described in the Privacy Policy. The consent and revocation procedures in relation to the same are set out in the Privacy Policy.</li>
                                                        <li>The Users are responsible for maintaining the confidentiality of the Users&rsquo; account access information and password. The Users shall be responsible for all uses of the Users&rsquo; account and password, whether or not authorized by the Users. The Users shall immediately notify Medkart of any actual or suspected unauthorized use of the Users&rsquo; account or password.</li>
                                                        <li>If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Medkart has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Medkart shall have the right to suspend or terminate such account at its sole discretion.</li>
                                                        <li>Medkart may disclose or transfer User Information (as defined in the Privacy Policy) to its affiliates in other countries, and You hereby consent to such transfer. The SPI Rules only permit Medkart to transfer sensitive personal data or information including any information, to any other body corporate or a person in India, or located in any other country, that ensures the same level of data protection that is adhered to by Medkart as provided for under the SPI Rules, only if such transfer is necessary for the performance of the lawful contract between Medkart or any person on its behalf and the user or where the User has consented to data transfer.</li>
                                                        <li>By accepting these Terms of Use and by registering on the Website, You consent to be contacted by Us and by the Third Party Service Providers and You further consent to receive emails and messages (SMS) notifications and information at any time from Us and from Third Party Service Providers.</li>
                                                        <li>Hereby I give my consent to be contacted by Medkart and by the Third Party Service Providers and I further consent to receive emails and messages (SMS) notifications, calls and information at any time from Medkart and from Third Party Service Providers.</li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><u>Payment, Fees and Taxes:</u>
                                            <ol>
                                                <li>Registration on the Website and the access to the information provided on the Website is free. Medkart does not charge any fee for accessing, browsing and buying through the Website. You agree to make all payments directly to the respective Third Party Pharmacies for purchase of Pharmaceutical Goods and Services from such Third Party Pharmacies. The Third Party Pharmacies may choose to either personally collect such payment from You or may use the services of collection agents duly appointed in this regard. You agree and acknowledge that You shall not hold Medkart responsible for any loss or damage caused to You during the process, due to any acts or omission on the part of third parties viz. the Third Party Pharmacies or the collection agents or for any actions/ omissions which are beyond the control of Medkart.</li>
                                                <li>In relation to the diagnostic services being availed from the Website, Third Party Labs agree to pay all package fees, consulting fees and other fees applicable to the Third Party Labs use of such Services and the Third Party Labs shall not circumvent the fee structure. The fee is dependent on the package that the Third Party Labs purchase and not on actual usage of the Services. In relation to the Users using the diagnostic Services, the Users agree to make all payments directly to the respective Third Party Labs for use of the diagnostic Services from the Website. You agree and acknowledge that You shall not hold Medkart responsible for any loss or damage caused to You during the process, due to any acts or omission on the part of the Third Party Labs&rsquo; any actions/ omissions which are beyond the control of Medkart.</li>
                                                <li>Each User / Third Party Service Providers are solely responsible for payment of all taxes, legal compliances, statutory registrations and reporting. Medkart is in no way responsible for any of the taxes except for its own income tax.</li>
                                                <li>The subscription fees for the Services, if any charged by Medkart, could be paid online through the facility made on the Website. Third parties support and services are required to process online fee payment. Medkart is not responsible for any loss or damage caused to User/ Third Party Service Providers during this process as these third parties are beyond the control of Medkart. The fees could also be paid offline and be either collected personally from the User/ Third Party Service Providers or required to be mailed to Medkart at the following address: MedkartPharmacy Private Limited, A404, Safal Pegasus, Prahalad Nagar, Ahmedabad &ndash; 380051, India</li>
                                                <li>All fees are exclusive of applicable taxes.</li>
                                                <li>Medkart reserves the right to modify the fee structure by providing on the Website which shall be considered as valid and agreed communication.</li>
                                                <li>In order to process the payments, Medkart might require details of User&rsquo;s/ Third Party Service Providers&rsquo; bank account, credit card number etc. Please check Our Privacy Policy on how Medkart uses the confidential information provided by Users.</li>
                                                <li>Available payment methods: Wallets: Paytm, AmazonPay, Mobikwik, AirtelMoney, Freecharge, OlaMoney, JIO Money, PhonePe, MPESA UPI &ndash; BHIM and GooglePay. All debit and credit cards. Cash on delivery for offline payments.</li>
                                                <li>Applicable payment charges: No charges levied.</li>
                                                <li>Security details in relation to payment methods: API integrated.</li>
                                                <li>Charge-back options: No.</li>
                                            </ol>
                                        </li>
                                        <li><u>Return, Refund, Cancellation and Shipping charges:</u><br /> We offer return and refund on the products and Services ordered by You on the Website which are subject to further terms and conditions as detailed in the return, refund, cancellation and shipping charges policy (&ldquo;Return and Refund Policy&rdquo;). The&nbsp;<a href="https://medkart.in/ReturnPolicy">Return and Refund Policy</a>forms an integral part of these Terms of Use and the Users are requested to carefully read the same.</li>
                                        <li><u>Covenants: (Covenanters for the purposes of these Terms of Use shall include the Users and the Third Party Service Providers)</u>
                                            <ol>
                                                <li>Each Covenanter undertakes that it shall not do any act or post, display, upload, modify, publish, transmit, update or share any information that -
<ol>
                                                        <li>belongs to another person and to which the such Covenanter does not have any right;</li>
                                                        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                                                        <li>infringes any patent, trademark, copyright or other intellectual proprietary rights of any third party;</li>
                                                        <li>violates any law for the time being in force;</li>
                                                        <li>impersonates another person;</li>
                                                        <li>harms minors in any way;</li>
                                                        <li>deceives or misleads the addressee about the origin of such messages or communicates any information that is grossly offensive and menacing in nature;</li>
                                                        <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                                                        <li>is prohibited under applicable law(s) for the time being in force including Drugs Act read with the Drugs Rules, the Drugs and Magic Act, the Indian Penal Code, 1860, as amended from time to time and rules made there under; and</li>
                                                        <li>threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                                                    </ol>
                                                </li>
                                                <li>You are also prohibited from:
<ol>
                                                        <li>violating or attempting to violate the integrity or security of the Website or any Medkart Content;</li>
                                                        <li>transmitting any information (including job posts, messages and hyperlinks) on or through the Website that is disruptive or competitive to the provision of Services by Medkart;</li>
                                                        <li>intentionally submitting on the Website any incomplete, false or inaccurate information;</li>
                                                        <li>making any unsolicited communications to other Covenanters;</li>
                                                        <li>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Website;</li>
                                                        <li>attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;</li>
                                                        <li>copying or duplicating in any manner any of the Medkart Content or other information available from the Website; and</li>
                                                        <li>framing or hotlinking or deeplinking any Medkart Content.</li>
                                                    </ol>
                                                </li>
                                                <li>Medkart, upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information as mentioned in this Clause 4, shall be entitled to disable such information that is in contravention of this Clause 4 or any provisions of these Terms of Use. Medkart shall be entitled to preserve such information and associated records for at least 90 (ninety) days for production to governmental authorities for investigation purposes.</li>
                                                <li>In case of non-compliance with any applicable law(s), rules or regulations, or these Terms of Use or the Privacy Policy by a Covenanter, Medkart has the right to immediately terminate the access or usage rights of the Covenanter to the Services and to remove noncompliant information.</li>
                                            </ol>
                                        </li>
                                        <li><u>Liability</u>
                                            <ol>
                                                <li>Medkart shall not be responsible or liable in any manner to the Users or any Third Party Service Providers (collectively referred to as the &ldquo;<strong>Other Parties</strong>&rdquo;) for any losses, damage, injuries or expenses incurred by Other Parties as a result of any disclosures made by Medkart, where Other Parties have consented to the making of such disclosures by Medkart. If the Other Parties had revoked such consent under the terms of the Privacy Policy, then Medkart shall not be responsible or liable in any manner to the Other Parties for any losses, damage, injuries or expenses incurred by the Other Parties as a result of any disclosures made by Medkart prior to its actual receipt of such revocation.</li>
                                                <li>The Other Parties shall not hold Medkart responsible or liable in any way for any disclosures by Medkart under Regulation 6 of the SPI Rules.</li>
                                                <li>The Services provided by Medkart or any of its licensors or providers or Third Party Service Providers are provided &lsquo;as is&rsquo;, as available, and without any warranties or conditions (express or implied, including the implied warranties of merchantability, accuracy, fitness for a particular purpose, title and non-infringement, arising by statute or otherwise in law or from a course of dealing or usage or trade). Medkart does not provide or make any representations, warranties or guarantees, express or implied about the Website or the Services. Medkart does not verify any content or information provided by the Other Parties on the Website and to the fullest extent permitted by applicable law(s), disclaims all liability arising out of the Other Parties&rsquo; use or reliance upon the Website, the Services, the Medkart Content, Third Party Contents, representations and warranties made by the Other Parties on the Website or any loss arising out of the manner in which the Services have been rendered.</li>
                                                <li>The Website may be linked to the website of third parties, affiliates and business partners. Medkart has no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such websites or made available by/through the Website. Inclusion of any link on the Website does not imply that Medkart endorses the linked website. Other Parties may use the links and these services at their own risk.</li>
                                                <li>Medkart shall not be responsible for the mishaps/missed services due to no service/no show from the Other Parties; Medkart shall not be responsible for any error in any of the services being provided by the Third Party Service Providers.</li>
                                                <li>Users accept and acknowledge that Medkart does not provide any representation or give any guarantee or warranty (whether express or implied, or whether arising by virtue of a statue or otherwise in law or from a course of dealing or usage or trade) in relation to the goods/ products and services made available on its Website by Third Party Service Providers, including any guarantee or warrantee that such goods/ products (i) are merchantable; (ii) fit for the purpose of which they are to be (or have been) purchased;(iii) have accurate description; (iv) do not cause any infringement; and (v) that the Third Party Service Providers have legal title over the goods/products being offered for sale by them on the Website. Medkart also does not provide any representation or give any guarantee or warranty (whether express or implied) about the Website or any of the Services offered or services offered or provided by the Third Party Service Providers.</li>
                                                <li>The Other Parties further accept and acknowledge that Medkart does not verify any content or information provided by either the Users or the Third Party Services/ or obtained from the Users or the Third Party Service Providers, and to fullest extent permitted by applicable law(s), disclaims all liability arising out of the Other Parties&rsquo; use or reliance upon the Website, the Services, the Medkart Content, Third Party Content, representations and warranties made by the Other Parties on the Website or any opinion or suggestion given or expressed by Medkart or any Third Party Service Providers in relation to any Services provided by Medkart.</li>
                                                <li>Medkart assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect Other Parties&rsquo; equipment on account of the Other Parties&rsquo; access to, use of, or browsing the Website or the downloading of any material, data, text, images, video content, or audio content from the Website. If any of the Other Party is dissatisfied with the Website, the sole remedy of such Other Party(s) is to discontinue using the Website.</li>
                                                <li>The listing of Third Party Service Providers on the Website is based on numerous factors including Users comments and feedbacks. In no event shall the Protected Entities (as defined herein below) be liable or responsible for the listing order of Third Party Service Providers on the Website.</li>
                                                <li>To the maximum extent permitted by applicable law(s), Medkart, its affiliates, independent contractors, service providers, consultants, licensors, agents, and representatives, and each of their respective directors, officers or employees (&ldquo;<strong>Protected Entities</strong>&rdquo;), shall not be liable for any direct, indirect, special, incidental, punitive, exemplary or consequential damages, or any other damages of any kind, arising from, or directly or indirectly related to, (i) the use of, or the inability to use, the Website or the content, materials and functions related thereto; (ii) User's provision of information via the Website; even if such Protected Entity has been advised of the possibility of such damages.</li>
                                                <li>In no event shall the Protected Entities be liable for, or in connection with, (i) the provision of, or failure to provide, all or any products or service by a Third Party Service Provider to any User; or (ii) any comments or feedback given by any of the Users in relation to the goods or services provided by any Third Party Service Providers; or (ii) any content posted, transmitted, exchanged or received by or on behalf of any User, Third Party Service Providers or other person on or through the Website.</li>
                                                <li>Medkart disclaims any liability in relation to the validity of the medical advice provided by the Medical Experts and the validity and legality of the e-prescription for dispensation of medicines and conduct of diagnostic tests. All liabilities arising out of any wrong diagnosis of medical condition by the Medical Experts and/ or arising from the e-prescription will be of the concerned Medical Expert. Further, all liabilities arising out of any wrong diagnosis report by the Third Party Labs and/ or arising from the wrong dispensation of the Pharmaceutical Goods and Services will be of the concerned Third Party Labs or the Third Party Pharmacies as the case may be.</li>
                                                <li>The Users may share their previous medical history during interaction with the Medical Experts. The Users undertake to share such information at their own risk. Medkart reserves the right to retain such information for the purpose of providing Services to the Users.</li>
                                                <li>With respect to the Consultation Services, after selection of the type of treatment viz. Homeopathy, Allopathy or Ayurveda along with the specification of the disease by the patient, Medkart will decide the Medical Expert to whom the query should be directed based on the information shared by the User. However, in no event the Protected Entities shall be held liable for the losses attributable to such decision making and in no event shall the Protected Entities be liable for any Consultation provided and/or e-prescription issued by the Medical Expert by using the interface of online medical consultancy.</li>
                                                <li>The Users acknowledge that the Protected Entities merely act in the capacity of facilitators between the Other Parties by providing a platform for them to interact and transact. In no event shall the Protected Entities be held liable for any of the losses attributable to Services offered through the Website.</li>
                                                <li>In no event shall the total aggregate liability of the Protected Entities to any Other Parties for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to negligence, strict liability, product liability or otherwise) arising from these Terms of Use or any Other Parties&rsquo; use of the Website exceed an aggregate amount of INR 1000/- (Indian Rupees One Thousand only). Medkart accepts no liability for any errors or omissions on behalf of the Other Parties.</li>
                                                <li>In no event shall the Protected Entities be liable for failure on the part of the Users or Third Party Service Providers to provide agreed services or to make himself/herself available at the appointed time, cancellation or rescheduling of appointments. In no event shall the Protected Entities be liable for any comments or feedback given by any of the Users in relation to the services provided by a Third Party Service Providers.</li>
                                            </ol>
                                        </li>
                                        <li><u>Indemnity</u><br /> The Covenanters agree to defend, indemnify and hold harmless Medkart, the Protected Entities, independent contractors, service providers, consultants, licensors, agents, and representatives, and each of their respective directors, officers and employees, from and against any and all claims, losses, liability, damages, and/or costs (including, but not limited to, reasonable attorney fees and costs) arising from or related to (a) Covenanters access to or use of Website; (b) Covenanters violation of these Terms of Use or any applicable law(s); (c) Covenanters violation of any rights of another person/ entity, including infringement of their intellectual property rights; or (d) Covenanters conduct in connection with the Website.</li>
                                        <li><u>Modification of Website</u><br /> Medkart reserves the right to modify or discontinue, temporarily or permanently, the Website or any features or portions thereof without prior notice. Other Parties agree that Medkart will not be liable for any modification, suspension or discontinuance of the Website or any other part thereof.</li>
                                        <li><u>Intellectual property rights</u><br /> All the intellectual property used on the Website except those which have been identified as the intellectual properties of the Other Parties shall remain the exclusive property of the Company. The Other Parties agree not to circumvent, disable or otherwise interfere with security related features of the Website or features that prevent or restrict use or copying of any materials or enforce limitations on use of the Website or the materials therein. The materials on the Website or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means.</li>
                                        <li><u>Compliance of Applicable Law</u>
                                            <ol>
                                                <li>While communicating/ transacting with each other through the Website, the Other Parties shall at all times ensure full compliance with the applicable provisions of the Contract Act, IT Act, IG Guidelines, Drugs Act read with the Drug Rules, Drugs and Magic Act, The Indian Medical Council Act, 1956 read with the Indian Medical Council Rules, 1957, Pharmacy Act, Consumer Protection Act, 1986, SPI Rules, etc (&ldquo;<strong>Captioned Laws</strong>&rdquo;). as well as all other laws for the time being in force, and ensure due payment of applicable taxes. They must specifically ensure that they are in no way purchasing Pharmaceutical Good and Services or Prescription Drugs without a valid prescription, which are prohibited under the Drugs Act (read with the Drugs Rules) as well as the other applicable laws for the time being in force.</li>
                                                <li>The Users must also ensure that the prescription uploaded on the Website or emailed to Medkart for processing the order for Prescription Drugs is a valid prescription duly obtained from a registered medical practitioner. The Users acknowledge and accept that they shall bear all costs/ liability/ damages, caused to the Third Party Service Providers or to Medkart, as a result of any dispensation of Prescription Drugs by the Third Party Service Providers owing to the non-compliance by the User in this regard.</li>
                                            </ol>
                                        </li>
                                        <li>Termination (Parties for the Purpose of these Terms of Use shall collectively mean the Other Parties and Medkart)
<ol>
                                                <li>The provisions of these Terms of Use shall continue to apply until terminated by either of the Party as set for below:
<ol>
                                                        <li>In case of Other Parties wanting to terminate these Terms of Use, Other Parties may do so by:
<ol>
                                                                <li>not accessing the Website; or</li>
                                                                <li>closing their accounts for all of the Services that they use.</li>
                                                            </ol>
                                                        </li>
                                                        <li>Medkart reserves the right to, at any time, and with or without notice, terminate these Terms of Use against each of the Users or the Third Party Service Providers or the Other Parties as a whole, if there is:
<ol>
                                                                <li>breach any of applicable law(s), including but not limited to the Captioned Laws or the provisions of these Terms of Use or the terms of the Privacy Policy or any other terms, conditions, or policies that may be applicable to the Other Parties from time to time (or have acted in a manner that clearly shows that Other Party(s) do not intend to, or are unable to, comply with the same); or</li>
                                                                <li>Medkart is unable to verify or authenticate any information provided to Medkart by Other Party(s); or</li>
                                                                <li>Medkart believes, in its sole discretion, that Other Party(s) actions may cause legal liability for Medkart (or any of its affiliates, independent contractors, service providers, consultants, licensors, agents, and representatives) or are contrary to the interests of the Website; or</li>
                                                                <li>Medkart is required to do so by law; or</li>
                                                                <li>if Other Party(s) fail to provide (or after providing such consent, later revoke) the consents necessary or desirable for Medkart to provide the Services to the Other Party(s); or</li>
                                                                <li>The provision of the Services to the Other Party(s), or to the general public, is in Medkart&rsquo;s opinion, no longer commercially viable; or</li>
                                                                <li>Medkart has elected to discontinue, with or without reason, access to the Website or the Services (or any part thereof).</li>
                                                            </ol>
                                                        </li>
                                                        <li>Medkart may also terminate or suspend (temporarily or permanently) all or a portion of Other Party(s) account or access to the Services, with or without reason. Except as may be set forth in any of the terms applicable to a particular Service, termination of Other Party(s) account may include: (i) removal of access to all offerings within the Website or with respect to the Services; and (ii) barring Other Party(s) from further use or access of the Website or of any of the Services.</li>
                                                        <li>Once terminated or suspended (temporarily or permanently), Other Party(s) may not continue to use the Website under the same account, a different account or re-register under a new account.</li>
                                                        <li>Upon termination of these Terms of Use, Medkart shall have no obligation to maintain or provide any of Other Party(s) data and may thereafter, unless legally prohibited, delete all of Other Party(s) data in its systems or otherwise in its possession or under its control, including but not limited to Other Party(s) personal information, log-in ID and password, order details (including any prescriptions uploaded) and all related information, files and materials associated with or inside Other Party(s) account (or any part thereof).</li>
                                                        <li>Medkart reserves the right, at its sole discretion, to pursue all of its legal remedies, including but not limited to deletion of the Other Party(s) content from the Website with or without ability to access the Website and the other Services, upon any breach by the Other Party(s) of these Terms of Use or if Medkart is unable to verify or authenticate any information the Other Party(s) submits to Medkart, or if the Other Party(s) fail to provide (or after providing such consent, later revokes) the consents necessary or desirable for Medkart to provide the Services to the Other Party(s).</li>
                                                        <li>The right to terminate/ suspend the account is in addition to, and without prejudice to, Medkart&rsquo;s right to initiate action against the Other Party(s), in accordance with applicable law.</li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><u>Force Majeure</u><br /> Other Parties accept and acknowledge that Medkart shall not be liable for any loss or damage caused to the User as a result of delay or default or deficiency or failure in the Services as a result of any natural disasters, fire, riots, civil disturbances, actions or decrees of governmental bodies, communication line failures (which are not caused due to the fault of Medkart or the Third Party Service Providers), or any other delay or default or deficiency or failure which arises from causes beyond Medkart&rsquo;s reasonable control (&ldquo;<strong>Force Majeure Event</strong>&rdquo;). In the event of any Force Majeure Event arising, Medkart, depending on whose performance has been impacted under the Terms of Use, shall immediately give notice to the Other Party(s) of the facts which constitute the Force Majeure Event.</li>
                                        <li><u>Governing Law and Dispute Resolution</u><br /> These Terms of Use and any contractual obligation between the Parties will be governed by the laws of India, without reference to the conflict of laws principles. Any legal action or proceeding related to Other Party(s) access to, or use of, the Website or these Terms of Use shall be subject to the exclusive jurisdiction of the courts at New Delhi. All disputes will be subject to arbitration at New Delhi in English by a sole arbitrator appointed by Medkart under the Arbitration and Conciliation Act, 1996.</li>
                                        <li><u>Survival</u><br /> Even after termination, certain obligations mentioned under Covenants, Liability, Indemnity, Intellectual Property, Dispute Resolution will continue and survive termination.</li>
                                        <li><u>Severability</u><br /> If any provision of these Terms of Use is deemed invalid, unlawful, void or for any other reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any of the remaining provisions.</li>
                                        <li><u>Waiver</u><br /> No provision of these Terms of Use shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by Medkart. Any consent by Medkart to, or a waiver by Medkart of any breach by Other Parties, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</li>
                                        <li><u>Headings</u><br /> The headings and subheadings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of these Terms of Use.</li>
                                        <li><u>Contact Information</u><br /> If any Other Party(s) has any grievance, comment, question or suggestion regarding any of our Services, please contact our customer service at&nbsp;<a href="mailto:info@medkart.in">info@medkart.in</a>. If any Other Party(s) has any questions concerning Medkart, the Website, these Terms of Use, or anything related to any of the foregoing, Medkart can be reached at the following email address -&nbsp;<a href="mailto:info@medkart.in">info@medkart.in</a>.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <h4>Contact us</h4>

                                    You may contact us with any questions relating to this Privacy Policy by submitting your request to <a href="mailto:info@medkart.in">info@medkart.in</a> or by postal mail at: Registered Address: Medkart Pharmacy Private Limited. Ground Floor, 1, Sangini Complex, Ahmedabad, Gujarat 380006, India. Phone Number - 7600001634. </Grid>
                            </Grid>
                        </Grid>
                        {/* {window.innerWidth <= 920 ? <Grid/>:<BottomMenu history={this.props.history} />} */}
                    </main>
                    {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                </Grid>
            </React.Fragment>
        );
    }
}
export default withStyles(TermsConditionsStyle)(TermsConditions);
