import React from 'react';
import FilledDatePickerStyle from './FilledDatePickerStyle';
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const FilledDatePicker = props => {
  const classes = FilledDatePickerStyle();
  const { label, value, name, onChange } = props;
  return (
    <MuiPickersUtilsProvider locale="en" utils={MomentUtils}>
      <KeyboardDatePicker
        inputVariant="filled"
        // className={classes.textField}
        label={label}
        fullWidth
        format="DD/MM/YYYY"
        error={''}
        helperText={''}
        value={value}
        name={name}
        keyboardEnabled={true}
        keyboardIcon={<ArrowDropDownIcon/>}
        InputAdornmentProps={{ position: "end" }}
        InputProps={{
          disableUnderline: true,
          className: classes.textField,
        }}
        InputLabelProps={{
          className: classes.label
        }}
        onChange={onChange}
        {...props}
      >
        {props.children}
      </KeyboardDatePicker>
    </MuiPickersUtilsProvider>
  )
};

export default FilledDatePicker;
