import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';

const filledTextFieldStyle = makeStyles(theme => ({
    textField: {
        backgroundColor: '#fff',
        borderRadius: 4,
        border: '1px solid #f1f1f5',
        color: '#32373c',
        fontFamily:'Nunito Sans' ,
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: 600,
        height:'62px',
        lineHeight: 1.57
    },
    label: {
        color: '#b5bbc1',
        fontFamily:'Nunito Sans' ,
        fontWeight: 400,
        fontSize: 14,
    }
}));
export default filledTextFieldStyle;