import React from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid } from '@material-ui/core';
import CODDialogStyle from './CODDialogStyle';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import * as APIController from '../../api/APIController';
import { withStyles } from '@material-ui/core/styles';
import { Images } from '../../common';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import OtpInput from 'react-otp-input';

const CODDialog = props => {
    const classes = CODDialogStyle();
    const { open, onClose, onCancel, onVerify, OrderId
    } = props;
    const [OTP, setOTP] = React.useState('');

    // const handleChange = (e) => {
    //     this.setState({ [e.target.name]: e.target.value });
    // }

    const handleSubmit = () => {
        if (validateForm()) {
            wsVerifyOrderOTP();
        }
    }

    const validateForm = () => {
        var IsValid = 0;
        if (OTP == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOOTP);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    const wsResendOTP = () => {
        APIController.ResendOrderOTP(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), OrderId).then((response) => {
            if (response.object.status == 1) {
                ToastsStore.warning(response.object.message);
            }
            else {
                ToastsStore.warning(response.object.message);
            }
        });
    }

    const wsVerifyOrderOTP = () => {
        APIController.VerifyOrderOTP(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), OrderId, OTP).then((response) => {
            if (response.object.status == 1) {
                onVerify(response.object.data[0])
                ToastsStore.warning(response.object.message);

            }
            else {
                ToastsStore.warning(response.object.message);
            }

        });
    }


    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '700px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center' style={{ padding: '20px', width: '100%' }}>

                    <Grid container justify='center'
                        style={{ width: '95%' }}
                        className={classes.Imagecardmainwithoutborder}  >
                        <Typography className={classes.titletext}>{'Cash On Delivery'}</Typography>
                    </Grid>

                    <Grid container direction='column' alignItems='center' justify='center' style={{ width: '100%',marginBottom: '30px', marginTop: '20px',}}>
                        <Grid container direction='column' justify="center" alignItems='center' style={{ width: '75.5%', height: '7.91%', }}>
                            <Typography className={classes.txtEnterOTP}>OTP sent to:&nbsp;  {AppConstants.mobilenoFromLocal()}</Typography>
                            <OtpInput
                                onChange={(text) => setOTP(text)}
                                value={OTP}
                                inputStyle={{ width: '100%', height: '100%', margin: 10, borderRadius: '15%', color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}
                                isInputNum={true}
                                focusStyle={{ color: '#39A749' }}
                                shouldAutoFocus={true}
                            />
                            <Typography className={classes.txtResendPwd} onClick={() => wsResendOTP()}>Resend Order OTP</Typography>
                        </Grid>
                    </Grid>
                    
                    <Grid direction={'row'} container justify='space-between' style={{ marginTop: '30px', width: '100%' }}>
                        <ButtonBase title={'PLACE ORDER'}
                            variant="contained"
                            style={window.innerWidth<=420?{width:'60%'}:{ width: '33%' }}
                            onClick={() => handleSubmit()} />
                        {/* <ButtonBase title={'RESEND ORDER OTP'}
                            variant="contained"
                            style={{ width: '33%' }}
                            onClick={() => wsResendOTP()} /> */}
                        <ButtonBase title={'CANCEL'}
                            variant="contained"
                            style={{ width: '32%' }}
                            onClick={onCancel} />
                    </Grid>


                </Grid>

            </Alertdialog>
        </React.Fragment>
    );
};

export default (CODDialog);
