import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const WhyMedkartStyle = theme => ({
    WhyTxt: {
        color: '#FFFF',
        fontSize: AppConstants.FONTSIZE.FS30,
        fontFamily:'Nunito Sans',
    },

});
const combinedStyles = combineStyles(commonStyle, WhyMedkartStyle);
export default combinedStyles; 
