import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, ButtonBase, CssBaseline, Grid, } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Pagination from './Pagination';
import * as AppConstants from '../../common/Constants';
import { Images } from '../../common';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [
    {

        imgPath: Images.Banner6,
        label: "EMERGING ICONS OF GUJARAT 2020" + '\n',
        sublabel: ' - Times of India'
    },
    {

        imgPath: Images.Banner1,
        label: "Gujarat's most trusted Generic Medicine Store",
        sublabel: ''
    },
    {

        imgPath: Images.Banner2,
        label: 'Largest Range of Generic Medicines',
        sublabel: ''
    },
    {

        imgPath: Images.Banner3,
        label: 'Trained & Qualified Staff',
        sublabel: ''
    },
    {

        imgPath: Images.Banner4,
        label: '2 Lac Customers 75cr Saved',
        sublabel: ''
    },
    {

        imgPath: Images.Banner5,
        label: 'WHO GMP Approved Generic at Lowest Prices',
        sublabel: ''

    },
];
const tutorialSteps_mobile = [
    {

        imgPath: Images.Banner6_mobile,
    },
    {

        imgPath: Images.Banner1_mobile,
    },
    {

        imgPath: Images.Banner2_mobile,
    },
    {

        imgPath: Images.Banner3_mobile,
    },
    {

        imgPath: Images.Banner4_mobile,
    },
    {

        imgPath: Images.Banner5_mobile,
    },
];

function WebsiteHomePage(props) {
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep > 4 ? 0 : prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep == 0 ? 5 : prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    return (
        <Grid container justify='flex-end'>
            <CssBaseline />
            {/* <Slider
                style={{
                    width: '100%',
                }}
                autoplay={1000}
            >
                {tutorialSteps.map((slide, index) => <div key={index}>
                    <h2> <img src={slide.imgPath} style={props.browser ? { width: '100%', height: 250 } : { width: '100%', height: 500 }} /></h2>
                    <div> <Typography style={props.browser ?
                        { fontSize: 20, fontFamily: 'Nunito Sans', fontWeight: '300', color: '#262628', position: 'absolute', width: '40%', top: 50, left: 50 } : { fontSize: '64px', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '300', position: 'absolute', width: '40%', top: 50, left: 100 }}>{slide.label.toUpperCase()}</Typography>

                        <Pagination dots={5} index={index} onChangeIndex={handleStepChange} /></div>
                </div>)}
            </Slider> */}
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                animateTransitions={true}
                disableLazyLoading={true}
                style={{
                    width: '100%',
                    marginTop: '2%'
                }}>
                {window.innerWidth <= 920 ? tutorialSteps_mobile.map((step, index) => (
                    <Grid style={{ width: '100%', position: 'relative' }} direction='row'>
                        <img src={step.imgPath} style={{ width: '100%', height: 610 }} />
                        <ButtonBase onClick={() => handleBack()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: 250, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', left: 5 }}>
                            <KeyboardArrowLeft style={{ color: '#fff' }} />
                        </ButtonBase>
                        {/* <Typography style={props.browser?{ fontSize: 20,  fontFamily:'Nunito Sans', fontWeight: '300', color: '#262628', position: 'absolute', width: '40%', top: 50, left: 50 }:{ fontSize: '64px', color: '#262628', fontFamily:'Nunito Sans', fontWeight: '300', position: 'absolute', width: '40%', top: 50, left: 100 }}>{step.label.toUpperCase()}</Typography> */}
                        <ButtonBase onClick={() => handleNext()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: 250, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', right: 5 }}>
                            <KeyboardArrowRight style={{ color: '#fff' }} />
                        </ButtonBase>
                        <Pagination dots={6} index={index} onChangeIndex={handleStepChange} />
                    </Grid>
                )) : window.innerWidth <= 420 ? tutorialSteps_mobile.map((step, index) => (
                    <Grid style={{ width: '100%', position: 'relative' }} direction='row' justify='center'>
                        <img src={step.imgPath} style={{ width: '100%', height: 500 }} />
                        <ButtonBase onClick={() => handleBack()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: 1000, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', left: 5 }}>
                            <KeyboardArrowLeft style={{ color: '#fff' }} />
                        </ButtonBase>
                        {/* <Typography style={{ fontSize: '25px', color: '#262628', fontFamily:'Nunito Sans', fontWeight: '300', position: 'absolute', width: '40%', top: 50, left: 50 }}>
                            {step.label.toUpperCase()}
                        </Typography> */}
                        <ButtonBase onClick={() => handleNext()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: 1000, backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', right: 5 }}>
                            <KeyboardArrowRight style={{ color: '#fff' }} />
                        </ButtonBase>
                        <Pagination dots={6} index={index} onChangeIndex={handleStepChange} />
                    </Grid>
                )) : tutorialSteps.map((step, index) => (
                    <Grid style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }} direction='row'>
                        <img src={step.imgPath} style={{ height: '100%', width: '100%' }} />
                        <ButtonBase onClick={() => handleBack()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: '45%', backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', left: 5 }}>
                            <KeyboardArrowLeft style={{ color: '#fff' }} />
                        </ButtonBase>
                        {/* <Typography style={{ fontSize: '50px', color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '300', position: 'absolute', width: '40%', top: 150, left: 100 }}>{step.label.toUpperCase()} <br /> {step.sublabel}</Typography> */}
                        <ButtonBase onClick={() => handleNext()} variant="contained" style={{ position: 'absolute', fontWeight: 'bold', top: '45%', backgroundColor: '#c3c3c3', borderRadius: '3px', width: '46px', height: '46px', right: 5 }}>
                            <KeyboardArrowRight style={{ color: '#fff' }} />
                        </ButtonBase>
                        <Pagination dots={6} index={index} onChangeIndex={handleStepChange} />
                    </Grid>
                ))
                }
            </AutoPlaySwipeableViews>

        </Grid>
    );
}


export default WebsiteHomePage;