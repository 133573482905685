import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const TopMenuStyle = makeStyles(theme => ({
    IconStyle: {
        color: '#39A749',
        fontSize: '30px',
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        marginRight: '15px',
    },
    buttonstyle: {
        color: '#262628',
        // width:'180px',
        fontSize: '16px',
        fontFamily: 'Nunito Sans',
        fontWeight: 700,

    },

}));

export default TopMenuStyle;
