import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import patientStyle from './PatientStyle';
import Title from "../../components/Header/Title";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { CssBaseline, Typography, Grid, RadioGroup, Radio, CircularProgress, MenuItem, FormControlLabel, InputAdornment, Button, Card, } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import FilledDatePicker from "../../components/FilledDatePicker/FilledDatePicker";
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as moment from 'moment';
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import PatientRelationDialog from "../../components/PatientRelationDialog/PatientRelationDialog";
import uuid from 'react-uuid';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactGA from 'react-ga';

class Patient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            IsLoadingEdit: false,
            SelectItem: -1,
            IdForEdit: -1,
            dataSource: [],
            PatientId: '',
            PatientIdOnAddress: '',
            PatientName: '',
            PatientDocotorName: '',
            PatientRelation: '',
            PatientBirthDate: '',
            PatientBloodGroup: '',
            PatientHeight: '',
            PatientWeight: '',
            PatientMedicalcCnditions: '',
            PatientAllergies: '',
            PatientMedications: '',
            PatientIsDefault: '1',
            IsLoading: false,
            Fromlistpage: '',
            MenuOption: [
                {
                    id: 0,
                    value: 'MySelf'
                },
                {
                    id: 1,
                    value: 'Father'
                },
                {
                    id: 2,
                    value: 'Mother'
                }

            ],
            PatientIds: '',
            ValidPatient: false,
            selectedPatientItem: -1,
            isrelationverified: 1,
            PatientRelationPopup: false,
        };
    }


    wsPatient() {
        this.setState({ IsLoading: true })
        APIController.GetData('5', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    let Temp = []
                    response.object.map((item, index) => {
                        Temp.push({ ...item, 'Selected': false })
                    })
                    this.setState({ dataSource: Temp, IsLoading: false })
                    let indexxx = -1;
                    response.object.filter(function (item, index) {
                        if (item.isdefault == 1) {
                            indexxx = index
                            return indexxx
                        }
                    })

                    this.setState({ dataSource: this.state.dataSource.map((item, idx) => idx !== indexxx ? item : { ...item, Selected: true }), selectedPatientItem: indexxx })
                    if (response.object.length != 0) {//order flow
                        if (this.state.Fromlistpage == 0) {
                            this.WsGetPatientById(response.object[0].id, 0)
                        }
                        else if (this.state.Fromlistpage == 1) { //without orderflow
                            this.WsGetPatientById(response.object[0].id, 0)
                        }

                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    WsGetPatientById(ID, index) {
        this.setState({ IsLoadingEdit: true })
        APIController.GetPatientById('5', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                if (response.error == null) {
                    this.SetData(response.object.data[0])
                    this.setState({ IsLoadingEdit: false, PatientIdOnAddress: ID, IdForEdit: index })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }
    SetData(data) {
        this.setState({
            PatientDocotorName: data.doctorname,
            PatientId: data.id,
            PatientName: data.name,
            PatientRelation: data.relationwithpatient,
            PatientBirthDate: data.birthdate,
            PatientBloodGroup: data.bloodgroup,
            PatientHeight: data.height,
            PatientWeight: data.height,
            PatientMedicalcCnditions: data.medicalconditions,
            PatientMedications: data.medications,
            PatientIsDefault: data.isdefault.toString(),
            PatientAllergies: data.allergies,
            isrelationverified: data.isrelationverified,
            // PatientRelationPopup: data.isrelationverified == 0 ? true : false,
        })
    }
    OnPressEditList(index, id) {
        this.setState({ IdForEdit: index, })
        this.WsGetPatientById(id, index)
    }
    componentWillMount() {
        this.setState({ Fromlistpage: this.props.history.location.state.Fromlistpage })
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsPatient();
    }

    resize() { this.setState({ mediumBrowser: window.innerWidth <= 920 }) }

    handleSubmit() { if (this.validateForm()) { this.wsAddPatient() } }

    async onPressList(index, id) {
        this.setState({ dataSource: this.state.dataSource.map((item, idx) => idx !== index ? item : { ...item, Selected: !item.Selected }) })
        // this.WsGetPatientById(id, index)
        this.state.dataSource.map((item, idx) => { item.Selected && item.isrelationverified == 0 && this.setState({ PatientRelationPopup: true }) })
    }

    handlePatientRelation(PatientRelationData) {
        this.setState({ PatientRelation: PatientRelationData })
        this.wsAddPatient(PatientRelationData)
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.PatientName.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.PATIENTNAME);
        } else if (this.state.PatientDocotorName.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.DOCTORNAME);
        } else if (this.state.PatientRelation.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.RELATIONWITHPATIENT);
        } else if (this.state.PatientBirthDate === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoDateofBirth);
        } else if (this.state.PatientIsDefault === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.ISDEFAULTPATIENT);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsAddPatient(PatientRelationData) {
        this.setState({ loading: true })
        APIController.AddEditPatient(AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            this.state.IdForEdit == -1 ? '0' : this.state.PatientId,
            uuid(),
            this.state.PatientName,
            this.state.PatientDocotorName,
            this.state.PatientIsDefault,
            PatientRelationData == "" || PatientRelationData == undefined ? this.state.PatientRelation : PatientRelationData,
            moment(this.state.PatientBirthDate).format("MM/DD/YYYY"),
            this.state.PatientHeight, this.state.PatientWeight,
            this.state.PatientBloodGroup,
            this.state.PatientMedicalcCnditions,
            this.state.PatientAllergies,
            this.state.PatientMedications)
            .then((response) => {
                this.setState({ loading: false, })
                if (response.error == null) {
                    this.wsPatient();
                    this.blankState();
                    ToastsStore.warning(response.object.message);
                    this.setState({ PatientRelationPopup: false })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    wsDeletePatient(id) {
        APIController.DeletePatient(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), id)
            .then((response) => {
                // this.setState({ loading: false })
                if (response.error == null) {
                    this.wsPatient();
                    this.blankState();
                    ToastsStore.warning(response.object.message);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    blankState() {
        this.setState(
            {
                PatientName: '',
                PatientDocotorName: '',
                PatientRelation: '',
                PatientBirthDate: '',
                PatientAllergies: '',
                PatientBloodGroup: '',
                PatientHeight: '',
                PatientWeight: '',
                PatientMedicalcCnditions: '',
                PatientMedications: '',
                PatientIsDefault: '1',
                IdForEdit: -1,

            }
        )
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    GotoAddressScreen() {
        let PatientIds = [];
        this.state.dataSource.map((item) => {
            if (item.Selected)
                PatientIds.push(item.id)
        })
        // this.setState({ PatientIds: PatientIds.toString() })
        if (PatientIds != '') {
            this.props.history.push({
                pathname: '/addresses',
                state: {
                    PatientIdfromPatient: PatientIds.toString(),
                    Fromlistpage: '0'
                }
            });
        }
        else {
            ToastsStore.warning('Please Select Atleast One Patient')
        }
    }
    renderPatientList() {
        const { dataSource, IdForEdit, IsLoading, mediumBrowser, Fromlistpage } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { textAlign:'left', flex: 1, width: '100%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', height: '97%', padding: '2%', }}>
                {IsLoading ? this.LoadingView() :
                    <Scrollbars autoHide autoHeight autoHeightMax='500px'>
                        {
                            dataSource.map((item, index) =>
                                <Grid container onClick={() => this.OnPressEditList(index, item.id)} direction={'row'}>
                                    <Grid container direction='row' justify='flex-start' alignItem='center' className={IdForEdit == index ? classes.ContentSelectcardmainPatient : classes.ContentcardmainPatient} style={{textAlign:'left', cursor: 'pointer', }}>
                                        <Grid onClick={() => this.onPressList(index, item.id)} container justify={window.innerWidth <= 420 ? 'flex-start' : 'center'} alignItems='flex-start' style={window.innerWidth <= 420 ? { width: '10%', marginTop: '2%' } : window.innerWidth <= 920 ? { width: '8%', marginTop: '1.3%' } : { width: '5%', marginTop: '0.8%', }}>
                                            {item.Selected ? <CheckBoxIcon style={mediumBrowser ? { color: '#39A749', } : { color: '#39A749', }} /> : <CheckBoxOutlineBlankIcon style={mediumBrowser ? { color: '#9B9B9B', } : { color: '#9B9B9B', }} />}
                                        </Grid>
                                        <Grid container direction='column' style={mediumBrowser ? { width: '90%', } : { width: '95%', }}>
                                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '24px', fontFamily: 'Nunito Sans', }}>{item.name} </Typography>
                                            <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{item.relationwithpatient} </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Scrollbars>
                }
                <Grid container direction='row' style={mediumBrowser ? { width: '94%', marginLeft: '10px', marginBottom: '20px' } : { width: '100%', marginBottom: '20px' }} alignItems='flex-start' justify='flex-start'>
                    {Fromlistpage == '1' ? <div /> : <ButtonBase
                        title='SELECT ADDRESS'
                        // disabled={this.state.selectedPatientItem == -1 ? true : false}
                        onClick={() => { this.GotoAddressScreen() }}
                        style={mediumBrowser ? { width: '100%', marginTop: '10px', padding: '2%' } : { width: '40%', marginLeft: '10px', marginTop: '10px' }} />
                    }
                </Grid>
            </Card>
        )
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    renderaddPatient() {
        const { classes } = this.props;
        const { loading, PatientName, PatientDocotorName,
            PatientRelation, PatientBirthDate, PatientAllergies, PatientBloodGroup, PatientHeight, PatientWeight, PatientMedicalcCnditions, PatientMedications, IsLoadingEdit, PatientIsDefault, mediumBrowser } = this.state;
        return (
            <Grid container direction="row" style={{ width: '100%', }}  >
                {IsLoadingEdit && <Grid container direction='row' style={{ width: '100%', height: '60px', }}>
                    <CircularProgress />
                </Grid>}
                <form noValidate style={{ width: '100%' }}>
                    <Card raised={true} container direction="row" style={mediumBrowser ? { width: '100%', height: '97%', padding: '4%', marginTop: '3%' } : { width: '100%', height: '97%', padding: '4%', }} >
                        <FilledTextFieldUpdated
                            defaultValue={this.state.PatientName}
                            label='* Name'
                            rows={1}
                            value={PatientName}
                            name="PatientName"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='* Doctor Name'
                            rows={1}
                            value={PatientDocotorName}
                            name="PatientDocotorName"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='Patient Relation'
                            rows={1}
                            value={PatientRelation}
                            name={'PatientRelation'}
                            style={{ marginBottom: '10px' }}
                            onChange={this.handleChange}
                            select>

                            {['Myself', 'Spouse', 'Mother', 'Father', 'Brother', 'Sister', 'Friend', 'Son', 'Daughter', 'Grandfather', 'Grandmother', 'Other'].map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </FilledTextFieldUpdated>

                        <Grid container direction='row' justify='space-between'>

                            <FilledDatePicker
                                disableFuture={true}
                                label={'Date of Birth'}
                                style={mediumBrowser ? { width: '100%', marginBottom: '10px', } : { width: '49%', marginBottom: '10px', }}
                                value={PatientBirthDate == '' ? new Date() : PatientBirthDate}
                                name='PatientBirthDate'
                                onChange={(e) => this.setState({ PatientBirthDate: e })} />

                            <FilledTextFieldUpdated
                                label='Patient Blood Group'
                                rows={1}
                                value={PatientBloodGroup}
                                name={'PatientBloodGroup'}
                                style={mediumBrowser ? { width: '100%', marginBottom: '10px', } : { width: '49%', marginBottom: '10px', }}
                                onChange={this.handleChange}
                                select>
                                {['O-', 'O+', 'A-', 'A+', 'B-', 'B+', 'AB-', 'AB+'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </FilledTextFieldUpdated>
                        </Grid>
                        <Grid container direction='row' justify='space-between'>
                            <FilledTextFieldUpdated
                                label='Height'
                                name="PatientHeight"
                                value={PatientHeight}
                                onChange={this.handleChange}
                                InputProps={{
                                    className: classes.inputMask_Field,
                                    disableUnderline: true,
                                    endAdornment: (<InputAdornment position="end">cm</InputAdornment>),
                                }}
                                style={mediumBrowser ? { width: '100%', marginBottom: '10px', } : { width: '49%', marginBottom: '10px', }} >
                            </FilledTextFieldUpdated>

                            <FilledTextFieldUpdated
                                label='Weight'
                                name="PatientWeight"
                                value={PatientWeight}
                                onChange={this.handleChange}
                                InputProps={{
                                    className: classes.inputMask_Field,
                                    disableUnderline: true,
                                    endAdornment: (<InputAdornment position="end">kg</InputAdornment>),
                                }}
                                style={mediumBrowser ? { width: '100%', marginBottom: '10px', } : { width: '49%', marginBottom: '10px', }} >
                            </FilledTextFieldUpdated>
                        </Grid>

                        <FilledTextFieldUpdated
                            label='Medical Conditions'
                            rows={1}
                            value={PatientMedicalcCnditions}
                            name="PatientMedicalcCnditions"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='Allergies'
                            value={PatientAllergies}
                            rows={1}
                            name="PatientAllergies"
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <FilledTextFieldUpdated
                            label='Medications'
                            rows={1}
                            name="PatientMedications"
                            value={PatientMedications}
                            onChange={this.handleChange}
                            style={{ marginBottom: '10px' }} />
                        <Grid container direction='column' style={{ textAlign:'left',backgroundColor: '#fff', marginLeft: '1%', }}>
                            <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Is Default </Typography>
                            <RadioGroup row value={this.state.PatientIsDefault}>
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value='0'
                                        name='PatientIsDefault'
                                        onChange={this.handleChange} />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    control={<Radio
                                        color="primary"
                                        value='1'
                                        name='PatientIsDefault'
                                        onChange={this.handleChange} />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Grid>
                        <Grid container style={{ marginBottom: '5%', marginTop: '5%', width: '100%', marginLeft: '1%', }} direction='row' justify={this.state.IdForEdit == -1 ? 'flex-start' : 'space-between'}>
                            <ButtonBase title={this.state.IdForEdit == -1 ? 'Add Patient' : 'Save Changes'}
                                isLoading={loading}
                                onClick={() => { this.handleSubmit() }}
                                style={window.innerWidth <= 920 ? { width: '60%', } : { width: '40%', }} />
                            {this.state.IdForEdit == -1 ? <div /> : <ButtonBase title='Delete'
                                isLoading={loading}
                                onClick={() => { this.wsDeletePatient(this.state.PatientId) }}
                                style={{ width: '30%', border: '1px solid #E1E4EB', marginRight: '2%', backgroundColor: '#FFFFFF', color: 'red', }} />
                            }
                        </Grid>
                        {/* </Grid> */}
                    </Card>
                </form>
            </Grid>
        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, Fromlistpage, dataSource, } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    {/* isBack={Fromlistpage == '1' ? false : true} */}
                    <Title isBack={Fromlistpage == '1' ? 1 : 2} title={Fromlistpage == '1' ? 'Patients' : 'Select Patient'} history={this.props.history} />
                    {/* {Fromlistpage == '0' || mediumBrowser ? <div /> : <TopMenu history={this.props.history} />} */}
                    <Grid container justify='center' alignItems='center'>
                        <Grid container direction='row' style={mediumBrowser ? { width: '100%', marginBottom: '0.5%' } : { width: '98.5%', marginBottom: '0.5%' }} justify='space-between' alignItems='center'>
                            <Grid container style={mediumBrowser ? { width: '50%', } : { width: '50%', }} >
                                <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{'(' + dataSource.length + ')' + ' ' + 'Patients Found'} </Typography>
                            </Grid>
                            <Grid container justify='flex-end' style={mediumBrowser ? { width: '50%', } : { width: '50%', }} >
                                <Button onClick={() => this.blankState()}><Typography style={{ color: '#39A749', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {'Add New'} </Typography></Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={mediumBrowser ? { width: '100%' } : Fromlistpage == '1' ? { width: '98.5%', } : { width: '98.5%', }} justify={"space-between"}>
                            <Grid direction="row" container style={mediumBrowser ? { width: '100%' } : { width: '39%', }} >
                                {this.renderPatientList()}
                            </Grid>
                            <Grid direction="row" container style={mediumBrowser ? { width: '100%' } : { width: '60%', }} >
                                {this.renderaddPatient()}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
                <MedkartFooter {...this.props} />
                <PatientRelationDialog
                    history={this.props.history}
                    onClose={() => this.setState({ PatientRelationPopup: false })}
                    open={this.state.PatientRelationPopup}
                    // index={-1}
                    // wsAddToCart={this.wsAddToCart.bind(this)}
                    handlePatientRelation={this.handlePatientRelation.bind(this)}
                    onClick={() => this.setState({ PatientRelationPopup: false })} />

            </React.Fragment>
        );
    }
}

export default withStyles(patientStyle)(withTranslation('translation')(Patient));
