import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core';
import i18n from './i18n';
import App from './App';
import './index.css';
import styles from './common/styles';
import * as serviceWorker from './serviceWorker';
import { initializeFirebase, askForPermissioToReceiveNotifications ,onMessageListener} from './push-notification';
import ReactGA from 'react-ga';

const trackingId = "UA-54941934-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

ReactDOM.render(
    <ThemeProvider theme={styles}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </ThemeProvider>,
    document.getElementById('root')
);
initializeFirebase();
askForPermissioToReceiveNotifications();
// onMessageListener()  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
