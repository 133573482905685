/**
 * This object represents the user of the app
 * @param {JSON} raw json that is received from server or local storage
 */
export function User(json) {
    this.id = json.UserId || json.id || ""
    this.mobileno=json.MobileNo || json.mobileno || ""
    this.fullname=json.FullName || json.fullname || ""
    this.firstName = json.FirstName || json.firstName || ""
    this.lastName = json.LastName || json.lastName || ""
    this.profilepic = json.ProfilePicUrl || json.profilepic || ""
    // this.type = json.UserType || json.type || 0
    // this.shortName = this.firstName.substring(0,1) + this.lastName.substring(0,1) || ''
}

/**
 * Returns a name for the user that is suitable for display on profile etc.
 */
User.prototype.displayName = function () {
    // TODO: Fix this for locales where lastName may appear before firstName
    return this.firstName + " " + this.lastName
}

/**
 * Writes the current values stored into the user object to localStorage
 */
let userStorageKey = "user"
User.prototype.writeToLocalStorage = function () {
    localStorage.setItem(userStorageKey, JSON.stringify(this))  
}

/**
 * Reads the values stored into localStorage and populates into the caller instance of User
 */
User.prototype.readFromLocalStorage = function () {
    let stored = localStorage.getItem(userStorageKey) || "{}"
    let json = JSON.parse(stored)
    let storedUser = new User(json)
    Object.assign(this, storedUser)
}

