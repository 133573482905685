import React from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid } from '@material-ui/core';
import OfferDialogStyles from './OfferDialogStyles';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import Iframe from 'react-iframe';
import { Scrollbars } from 'react-custom-scrollbars';

import LocationIcon from '@material-ui/icons/HighlightOff';
const OfferDialog = props => {
    const classes = OfferDialogStyles();
    const { open, onClose, url } = props;
    return (
        <React.Fragment>
            <Alertdialog
            paperFullScreen
                onClose={onClose}
                style={{ height: '100%', borderRadius: '100px' }}
                open={open}>
                <Grid container className={classes.mainView} style={{ width: '100%', }} direction="row" justify="center" alignItems="center">
                    <LocationIcon style={{ color: '#fff', resize: 'cover', position: 'absolute', cursor: 'pointer', top: '1%', right: '0.8%' }} onClick={onClose} />
                    <img src={url} style={{ width: '100%', height: '450px' }} />
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (OfferDialog);
