import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AboutUsStyle from './AboutUsStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, CssBaseline, Typography, Button, Toolbar } from '@material-ui/core';
import { Images } from '../../common';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

const Background = Images.Pills_bg;

class AboutUs extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleStep(step) {
        this.setState({ open: step })
    }

    renderForm() {
        const { mediumBrowser } = this.state
        const { classes } = this.props;
        return (
            <Grid container style={{ marginTop: '5%', width: '100%' }} direction='row' justify='center' alignItems='center'>
                <Grid container style={{ width: '100%', alignSelf: 'flex-start', alignItems: 'flex-start' }} direction='row' justify='space-around'>
                    {mediumBrowser && <Grid container style={{ width: '80%', padding: '5px', backgroundColor: '#ffffff', borderRadius: '2%', boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)', }} alignItems='center' justify='center'>
                        <img src={Images.Who_we_are} style={{ width: '100%', height: 300 }} />
                    </Grid>}
                    <Grid container style={mediumBrowser ? { width: '95%', paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%', marginTop: '2%' } : { width: '40%', padding: '5px', paddingTop: '0px', backgroundColor: '#ffffff', }} alignItems='flex-start' justify='center'>
                        <Typography className={classes.AboutTxt}>
                            Who We Are?
                        </Typography>
                        <p className={classes.ParagraphTxt}>
                            {/* {'Our genesis is deep-rooted in addressing a significant issue in the pharmacy market – the easy availability of WHO-GMP approved generics. Medkart was started by Ankur Agarwal in 2014, who was once surprised to know that generic medicines were way cheaper than the branded ones.'}
                            <br /><br />
                            {'Unfortunately, there was no one-stop-shop for generics. This pushed him to dive deeper into the industry and act as a catalyst for change in the pharmacy market. He, along with Parasharan Chari spreads the reach of Medkart from Ahmedabad headquarters to make quality and affordable medicine easily accessible.'} */}
                            {'We, at Medkart Pharmacy, believe that medicines are affordable but awareness about affordable medicines, availability of the same, acceptance of the same due to lack of education make the customer pay dearly. The reason for our existance is to address this significant issue and create a deep impact for the patients - needy and otherwise.'}<br /><br />
                            {'Vision of Medkart is to redefine the positioning of medicines for India through the AAA philosophy that focuses on Awareness, Availability, and Acceptance. Unfortunately, there was no one-stop-shop for generics.'}
                        </p>
                    </Grid>
                    {mediumBrowser ? <Grid /> : <Grid container style={{ width: '40%', padding: '5px', backgroundColor: '#ffffff', borderRadius: '2%', boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)', }} alignItems='center' justify='center'>
                        <img src={Images.Who_we_are} style={{ width: '100%', height: 300 }} />
                    </Grid>}
                </Grid>

                <Grid container style={mediumBrowser ? { width: '80%', marginTop: '0px', left: 0, right: 0, backgroundImage: "url(" + Background + ")", boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)', } : { width: '100%', marginTop: '5%', left: 0, right: 0, backgroundImage: "url(" + Background + ")", boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)', }} direction='row' justify='center'>
                    <Grid container direction='column' justify='center' alignItems='center' style={{ padding: '3%', width: '100%', height: '100%', backgroundColor: '#000000', opacity: 0.7, }}>
                        <p className={classes.ParagraphTxt} style={mediumBrowser ? { textAlign: 'center', color: '#ffffff', width: '80%', } : { textAlign: 'center', color: '#ffffff', width: '60%', }}>
                            {/* {'We aim to become the largest generic medicine brand of Gujarat and making quality, affordable medicines reach deeper into Indian households. Our vision to ascertain the broader availability of WHO-GMP approved medicines, we are expanding in western India while also diving deep into MP, North Mumbai, Rajasthan, and Gujarat.'} <br />
                            {'We are quite successful in this mission by operating on a franchisee model and successfully running 20+ stores across multiple states.'} */}
                            {'Medkart was founded in 2014 by Ankur Agarwal, an IIM alumnus, and as soon as the mission started catching the imagination of a typically cornered patient, scaling up was the obvious outcome. With the addition of Parthiv Shah and Parasharan Chari, the able leadership started translating the larger vision, of becoming the most trusted player in the generic medicine space, into reality. Medkart currently has a presence across 22+ cities spanning 80+ stores.'}
                        </p>
                    </Grid>
                </Grid>
                <Grid container style={{ width: '100%', }} direction={mediumBrowser ? 'column' : 'row'} justify={mediumBrowser ? 'center' : 'space-around'} alignItems='center'>
                    <Grid container style={mediumBrowser ? { width: '80%', backgroundColor: '#f1f1f1', padding: '5%', marginTop: '5%' } : { width: '32%', backgroundColor: '#f1f1f1', padding: '5%', }} alignItems={mediumBrowser ? 'center' : 'flex-start'} justify={mediumBrowser ? 'center' : 'flex-start'}>
                        <img src={Images.Rocket_launch} style={{ width: '50%', height: 150, left: 0, }} justify='flex-start' />
                        <Typography className={classes.AboutTxt}>
                            Growth that matters
                        </Typography>
                        <p className={classes.ParagraphTxt}>
                            {/* {'We have come far from being a single, dedicated generic medicine shop to a chain of generic medicine stores in Gujarat, Madhya Pradesh, Mumbai, and Rajasthan. We are rapidly expanding in Ahmedabad with 16 stores and Baroda with 10 stores and 35+ across various cities in Gujarat, including Surat, Bhavnagar, Jamnagar, and the growth story extends to Jaipur and Mumbai as well.'} */}
                            {'We aim to become the largest & the most trusted generic medicine brand of Gujarat and making quality, affordable medicines reach deeper into Indian households. Our vision to ascertain the broader availability of WHO-GMP approved medicines, we are expanding in western India while also diving deep into MP, North Mumbai, Rajasthan, and Gujarat.'}
                            <br />
                            {'We are quite successful in this mission by operating on a franchisee model as well and successfully running 80+ stores across multiple states.'}
                        </p>
                    </Grid>
                    <Grid container direction='column' style={mediumBrowser ? { width: '80%', backgroundColor: '#ffffff', marginTop: '5%' } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                        <Grid container direction={mediumBrowser ? 'column' : 'row'} style={{ width: '100%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Customers Served
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', fontSize: '36px' }}>
                                    3Lakhs+
                                </Typography>
                            </Grid>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', marginTop: '2%' } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Present in
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', fontSize: '36px' }}>
                                    22+ Cities
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction={mediumBrowser ? 'column' : 'row'} style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', marginTop: '2%' } : { width: '100%', backgroundColor: '#ffffff', marginTop: '5%' }} alignItems='center' justify='center'>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Recommended by
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', fontSize: '36px' }}>
                                    1600+ Doctors
                                </Typography>
                            </Grid>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', marginTop: '2%' } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Total Stores
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', marginTop: 0, fontSize: '36px' }}>
                                    80+
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction={mediumBrowser ? 'column' : 'row'} style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', marginTop: '2%' } : { width: '100%', backgroundColor: '#ffffff', marginTop: '5%' }} alignItems='center' justify='center'>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Savings of
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', marginTop: 0, fontSize: '36px' }}>
                                    Rs. 1100Cr.+
                                </Typography>
                            </Grid>
                            <Grid container direction='column' style={mediumBrowser ? { width: '100%', backgroundColor: '#ffffff', marginTop: '2%' } : { width: '50%', backgroundColor: '#ffffff', }} alignItems='center' justify='center'>
                                <Typography className={classes.AboutTxt} style={{ fontSize: '26px' }}>
                                    Customer Retention
                                </Typography>
                                <Typography className={classes.AboutTxt} style={{ color: '#39A749', marginTop: 0, fontSize: '36px' }}>
                                    85%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction='row' style={mediumBrowser ? { width: '100%', backgroundColor: '#39A749', marginBottom: '5%', marginTop: '15px' } : { width: '100%', backgroundColor: '#39A749', }} justify='center' alignItems='center'>
                    <Grid container direction='row' style={mediumBrowser ? { width: '80%', padding: '3%', } : { width: '60%', padding: '3%', }} justify={mediumBrowser ? 'center' : 'flex-end'} alignItems='center'>
                        <Typography className={classes.AboutTxt} style={mediumBrowser ? { color: '#ffffff', fontSize: '30px', textAlign: 'center' } : { color: '#ffffff', fontSize: '36px', textAlign: 'end' }}>
                            Inquire about Franchisee
                        </Typography>
                    </Grid>
                    <Grid container direction='row' style={mediumBrowser ? { width: '80%', padding: '3%', marginBottom: '15px' } : { width: '40%', }} justify={mediumBrowser ? 'center' : 'flex-start'} alignItems='center'>
                        <ButtonBase
                            onClick={() => this.props.history.push(AppConstants.SCREENS.FRANCHISEEMEDKART)}
                            title={'Lets Get In Touch'}
                            style={mediumBrowser ? { backgroundColor: '#FFFFFF', color: '#282628', width: '80%', } : { backgroundColor: '#FFFFFF', color: '#282628', width: '40%', }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <Grid container style={{ width: '100%' }} justify="center" alignItems="center">
                <Helmet>
                    <title>About Medkart Online Store for Generic Medicines</title>
                    <meta name="description" content="Know more about Medkart stores, medkart owner, medkart funding and the mission. Get familiar with us before you chose to buy standard generics online at Medkart" />
                    <meta name="keywords" content="Medkart Online | Medkart Owner | Medkart Funding" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader  medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'About Us'} isBack={false} history={this.props.history} />
                    <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">
                        <Grid style={mediumBrowser ? { width: '100%' } : { width: '98.5%' }} container frameBorder='0'>
                            {this.renderForm()}
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
            </Grid>
        );
    }
}



export default withStyles(AboutUsStyle)(AboutUs);
