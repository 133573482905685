import { makeStyles } from '@material-ui/core/styles';

const headerStyle = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#FFFFFF',
        height: 100,
        // boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.36)'
    },
    footerBar: {
        zIndex: theme.zIndex.drawer + 1,
        bottom: 0,
        height: '50px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        position: 'fixed'
        // boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.36)'
    },
    appBar1: {
        zIndex: theme.zIndex.tooltip + 10,
        marginTop: 200,
        backgroundColor: '#FFFFFF',
        // boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.36)'
    },
    appBar2: {
        zIndex: theme.zIndex.tooltip + 10,
        backgroundColor: '#FFFFFF',
    },
    toolBar: {
        padding: 0,
        height: '100px'
    },
    iconMain: {
        height: '50%',
        width: '100%',
        marginLeft: '10px',
        // position: 'relative'
    },
    listRoot: {
        marginTop: '3%',
        width: '100%',
        maxWidth: '25%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto', 
        maxHeight: 400,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    cardManu: {
        flex: 1,
        width: '50%',
        height: '100%',
        textAlign: 'center',
        marginTop: '1%',
        marginLeft: '5%',
        marginRight: '5%',

    },
    mainButton: {
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: '#f8f8f8',
    },
    cardMedia: {
        width: '50%',
        height: '50%',
    },
    mediaGrid: {
        padding: '3%',
    },
    gridcardManu: {
        marginTop: '9%',
        flex: 1,
    },
    textFillSearch: {
        borderRadius: 8,
        fontFamily: 'Nunito Sans',
        fontSize: 14,
        marginLeft: theme.spacing(8),
        fontWeight: 500,
        width: '60%',
        // alignSelf: 'flex-start',
        textAlign: 'left',
        padding: '2px 4px',
        display: 'flex',
        // alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,        
    },
    textSingin: {
        color: "#000"
    },
    link: {
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.21,
        color: '#000'
    },
    cartCounttxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.21,
        color: '#000'
    },
}));

export default headerStyle;
