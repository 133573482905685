const jap = {
  common: {
    authTitle: '継続的なスキルアッププラットフォーム',
    authSubTitle:
      'Techgigsは、DevOps作業のギグマーケットプレイスであり、企業が技術的な課題とタスクを通じて絶え間なく向上するものの、常に向上している幅広い人材プールにアクセスするためのプラットフォームです。',
    email: 'Eメール',
    password: 'パスワード'
  },
  login: {
    forgotPassword: 'パスワードを忘れましたか？',
    register: 'アカウントを持っていないのですか？ 登録',
    signIn: 'サインイン',
    signInWithLinkdin: 'Linkedinでサインインする',
    signInWithGoogle: 'Googleでサインインする'
  }
};
export default jap;
