import { makeStyles } from '@material-ui/core/styles';
const AlertdialogStyle = makeStyles(theme => ({
    //   alerView:
    //   {
    //     padding: '5%',
    //     border: '1px solid #f1f1f5',
    //     borderTopWidth: '2%',
    //     borderRadius: '5% 5% 0% 0%',
    //     width: '100%',
    //     height: '375px',
    //     backgroundColor: '#fff',
    //     position: 'fixed',
    //     textAlign: 'center',
    //     // alignItems: 'center',
    //     bottom: '0',
    //     left: '0',
    //   },
    
}));
export default AlertdialogStyle;
