import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EditProfileStyle from './EditProfileStyle';
import {
    CssBaseline, Typography, Grid, Button,
} from '@material-ui/core';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { Images } from '../../common';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import Dropzone from 'react-dropzone';
import Avatar from '@material-ui/core/Avatar';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import TopMenu from "../../components/Header/TopMenu";
import Footer from '../../components/Header/Footer';
import images from '../../common/Images';
import ReactGA from 'react-ga';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: '',
            LastName: '',
            DataSource: [],
            EmailAddress: '',
            MobileNo: '',
            dropzone: [],
            image64: '',
            imagename: '',
            imageSize: '',
            ProfileBase64: '',
            ProfileImageName: '',
            profilepic: '',
            profilepicname: '',
            loading: false,
            mediumBrowser: false,
        }
    }

    renderTopMenu() {
        const { classes } = this.props
        const { cart, isLoading } = this.state
        return (
            <Grid container justify='center' alignItems='center' style={{
                width: '100%',
                // padding: '10px',
                // backgroundColor: 'green',
            }}>
                <TopMenu />
            </Grid>

        )
    }
    ConvertToBase64() {
        const image2base64 = require('image-to-base64');
        image2base64(this.state.dropzone.preview)
            .then((response) => { this.setState({ image64: response }) })
            .catch((error) => {
            })
    }
    addFilesToDropzone(files, dropzone) {
        // let files_with_preview = [];
        // files.map(file => {
        //     file['preview'] = URL.createObjectURL(file);
        //     files_with_preview.push(file);
        // });
        let file = files[0]
        file['preview'] = URL.createObjectURL(files[0]);
        this.setState({ dropzone: files[0] }, () => {

        });
        this.ConvertToBase64();
        this.setState({ imagename: files[0].name, imageSize: (files[0].size / 2048).toFixed(2) });
    }
    wsEditProfile() {
        this.setState({ loading: true })
        APIController.editProfile(AppConstants.userIdFromLocal(), this.state.MobileNo, this.state.FirstName, this.state.LastName, this.state.EmailAddress, this.state.image64, this.state.imagename).then((response) => {
            this.setState({ loading: false })
            if (response.error == null) {
                this.SetData(response.object)
                ToastsStore.success(response.object.message)
                // this.props.history.push(AppConstants.SCREENS.EDITPROFILE)
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
            else {

                ToastsStore.warning(response.error.message)
            }
        })
    }
    wsGetMyProfile() {
        APIController.getMyProfile(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), '01/01/2019').then((response) => {
            if (response.error == null) {
                this.SetData(response.object)
            }
            else {
                ToastsStore.warning(response.error.message)
            }
        })
    }
    SetData(data) {
        this.setState({
            FirstName: data.firstname,
            LastName: data.lastname,
            EmailAddress: data.emailaddress,
            MobileNo: data.mobileno,
            profilepic: data.profilepicurl

        })
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetMyProfile();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsEditProfile()
        }

    }
    validateForm() {
        var IsValid = 0;
        if (this.state.FirstName.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOFIRSTNAME);
        }
        else if (this.state.LastName.trim() == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOLASTNAME);
        }
        else if (this.state.EmailAddress === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOEMAILADDRESS);
        }

        else if (this.state.MobileNo === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        }

        else if (this.state.MobileNo.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    renderForm() {
        const { classes } = this.props;
        const { dropzone, DataSource, loading } = this.state;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={{ width: '100%', marginTop:'5%',marginBottom:'5%' }} alignItems="center" justify="center">
                    <Grid container style={window.innerWidth<=420?{ width: '80%', height: '7.91%', }:{ width: '75.5%', height: '7.91%', }} alignItems='center'>
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>First Name</Typography> */}
                        <FilledTextFieldUpdated
                            value={this.state.FirstName}
                            rows={1}
                            name="FirstName"
                            label='Enter Your First Name'
                            onChange={this.handleChange}
                        />
                    </Grid>

                    <Grid container style={window.innerWidth<=420?{ width: '80%', height: '7.91%',marginTop: '5%', }:{ width: '75.5%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Last Name</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            value={this.state.LastName}
                            name="LastName"
                            label='Enter Your Last Name'
                            onChange={this.handleChange}
                        />
                    </Grid>

                    <Grid container style={window.innerWidth<=420?{ width: '80%', height: '7.91%',marginTop: '5%', }:{ width: '75.5%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Email Address</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            value={this.state.EmailAddress}
                            name="EmailAddress"
                            label='Enter Your Email Address'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid container style={window.innerWidth<=420?{ width: '80%', height: '7.91%',marginTop: '5%', }:{ width: '75.5%', height: '7.91%', marginTop: '5%', }} alignItems="center">
                        {/* <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Mobile Number</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            disabled
                            value={this.state.MobileNo}
                            name="MobileNo"
                            label='Enter Your Mobile Number'
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 10
                            }} />
                    </Grid>
                    <Grid container justify='center' style={window.innerWidth<=420?{ width: '80%', height: '7.91%',marginTop: '5%', }:{ width: '75.5%', marginTop: '7%' }} >
                        <ButtonBase type="submit" title={'Update Profile'} isLoading={loading}
                            // onClick={() => this.props.history.push(AppConstants.SCREENS.HOMEPAGE)}
                            style={{ width: '60%',  }}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
    renderDropZone() {
        const { classes } = this.props;
        const { dropzone, profilepic } = this.state;
        // const image = dropzone.preview;
        const image = dropzone.preview != undefined ? dropzone.preview : profilepic != '' ? profilepic : Images.Placeholder;
        return (
            <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                {({ getRootProps, getInputProps }) => (
                    <Grid style={{ marginTop: '7%', marginBottom: '7%' }} alignItems='center' justify='center'>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Typography style={{ textAlign: 'center', color: '#BEBEBE', fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>
                                {AppConstants.profilepicFromLocal() == null || AppConstants.userIdFromLocal() == null ?
                                    <Avatar
                                        className={classes.AvatarImg}
                                        src={AppConstants.profilepicFromLocal()}
                                        alt="profile"
                                    />
                                    : <Avatar src={image}
                                        className={classes.AvatarImg}> {AppConstants.fullnameFromLocal().charAt(0)}</Avatar>}
                            Change Photo
                            </Typography>
                        </div>
                    </Grid>
                )}
            </Dropzone>
        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { dropzone } = this.state;
        const image = dropzone.preview;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    <Title isBack={1} title={'My Profile'} history={this.props.history} />
                    {/* {mediumBrowser == false && <TopMenu history={this.props.history} />} */}
                    <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container justify='center' alignItems='center' className={classes.mainContainer} style={mediumBrowser ? { width: '100%',marginTop:'10%' } : { width: '55%',marginTop:'2%' }}>
                            {/* <ToastsContainer store={ToastsStore} />
                            <Grid container justify='center' alignItems='center' direction={'row'} style={mediumBrowser ? { width: '100%' } : { width: '100%' }}> */}
                                {/* <Grid container direction={'row'} justify='center' alignItems='center'> */}
                                {/* {this.renderDropZone()} */}
                                <Grid container justify='center' alignItems='center' style={mediumBrowser ? { width: '100%' } : { width: '50%' }} >
                                    <Grid container justify='flex-start' style={{ width: '100%' }}>
                                        {this.renderForm()}
                                    </Grid>
                                </Grid>
                                {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end'>
                                    <img src={Images.FormBackground} className={classes.img} alt="" />
                                </Grid>}
                            </Grid>
                        </Grid>
                    {<Footer history={this.props.history} />}
                </main>

            </React.Fragment>
        );
    }
}
export default withStyles(EditProfileStyle)(EditProfile);
