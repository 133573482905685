import React, { Component } from 'react';
import {
    Container, CssBaseline, Button, Grid, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CampaignStyle from "./CampaignStyle";
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import { autoPlay } from 'react-swipeable-views-utils'
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import WebsiteHomePage from "../WebsiteHomePage/WebsiteHomePage";
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";
import ContactMedkart from "../ContactMedkart/ContactMedkart";
import Testimonials1 from "../Testimonials/Testimonials";
import scrollToComponent from 'react-scroll-to-component';
import CallToOrder from "../CallToOrder/CallToOrder";
import BottomMenu from "../../components/Footer/BottomMenu";
// import Pagination from './Pagination';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import ReactGA from 'react-ga';
import OfferDialog from "../../components/OfferDialog/OfferDialog";
import Helmet from "react-helmet";
import DownlodAppDialog from "./DownlodAppDialog";

class Campaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isLoading: true,
            AppPopup: true,
            mediumBrowser: false,
            isMobile: false,
            offerDialog: false,
            activeStep: 0,
            cart: '',
            OffersBanner: '',
            tutorialSteps: [
                {
                    imgPath: Images.Banner1,
                },
                {

                    imgPath: Images.Banner2,
                },
                {

                    imgPath: Images.Banner3,
                },
                {

                    imgPath: Images.Banner4,
                },
                {

                    imgPath: Images.Banner5

                },
            ]

        }
    }
    componentWillMount() {
        const data = window.$offer
        if (data == undefined) { this.WsGetOffers() }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        if (localStorage.getItem('user') != null) {
            this.WsGetcart()

        }
        //Get Current Location Code-----------------------------
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords != null || position.coords != undefined) {
                Geocode.setApiKey("AIzaSyATV9qayPdPY8GHlyc0wPIr1r7AyW5UIwQ");
                Geocode.setRegion("in");
                localStorage.setItem('lat', position.coords.latitude);
                localStorage.setItem('long', position.coords.longitude);
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    response => {
                        const address = response.results[0].formatted_address;
                        const numb = address.match(/\d/g, '');
                        const pincode = numb.toString();
                        const zipcode = pincode.substr(pincode.length - 11);
                        const pin_code = zipcode.replace(/,/g, '');
                        const city_name = response.results[0].address_components[1].long_name;
                        localStorage.setItem('city', city_name);
                        localStorage.setItem('pincode', pin_code);
                    },
                    error => {
                        console.error("error ", error);
                    }
                );
            }
            else {
                localStorage.setItem('city', '');
                localStorage.setItem('pincode', '');
            }
            //---------------------------------------------------------------------
        });
    }


    componentWillUnmount() {

        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920, isMobile: window.innerWidth <= 720 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    handleBack = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };
    handleNext = () => {
        this.state.tutorialSteps.push("Name");
        this.setState({ activeStep: this.state.activeStep + 1 })
    };

    renderSlider() {
        const { cart, isLoading, mediumBrowser, isMobile } = this.state
        const tutorialSteps = [
            {

                imgPath: Images.Banner1,
                label: "Gujarat's most trusted Generic Medicine Store"
            },
            {

                imgPath: Images.Banner2,
                label: 'Largest Range of Generic Medicines'
            },
            {

                imgPath: Images.Banner3,
                label: 'Trained & Qualified Staff'
            },
            {

                imgPath: Images.Banner4,
                label: '2 Lac Customers 75cr Saved'
            },
            {

                imgPath: Images.Banner5,
                label: 'WHO GMP Approved Generic at Lowest Prices'

            },
        ];
        // const handleStepChange = step => {
        //     this.setState({ activeStep: this.state.activeStep + 1 })
        // };
        const { classes, } = this.props;
        return (
            <Grid container direction='column' style={{ width: '100%', backgroundColor: 'yellow' }}>
                <Slider
                    style={{ width: '100%', height: '100%', }}
                    slideIndex={1}
                    autoplay={3000}
                    onSlideChange={event => this.setState({ activeStep: event.slideIndex })}
                    duration={3000}
                    classNames={classes.slidermain}
                >
                    {tutorialSteps.map((step, index) =>
                        <Grid container style={{ width: '100%' }} direction='row'>
                            <img src={step.imgPath} style={mediumBrowser ? { width: '100%', height: 250 } : { width: '100%', height: 500 }} />
                            <Typography style={mediumBrowser ?
                                { fontSize: '18px', fontFamily: 'Nunito Sans', fontWeight: '600', color: '#262628', position: 'absolute', width: '40%', top: 50, left: 50 } : { fontSize: AppConstants.FONTSIZE.FS40, color: '#262628', fontFamily: 'Nunito Sans', fontWeight: '600', position: 'absolute', width: '40%', top: 50, left: 50 }}>{step.label.toUpperCase()}</Typography>
                            {/* <Pagination dots={5} index={index} onChangeIndex={handleStepChange} style={{position:'fixed',bottom:'-20px',backgroundColor:'red' }}/> */}
                        </Grid>
                    )}
                </Slider>
                {/* <Pagination dots={5} index={this.state.activeStep} onChangeIndex={this.state.activeStep + 1}/> */}
            </Grid>
        )
    }
    renderfooterMenu() {
        const { classes } = this.props
        const { cart, isLoading } = this.state
        return (
            <main className={classes.main} >
                <BottomMenu history={this.props.history} />
            </main>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    WsGetcart() {
        APIController.GetCart('8', AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            '380051', '0')
            .then((response) => {
                if (response.error == null) {
                    this.setState({ cart: response.object[0].cartcount })
                }
            })
    }
    WsGetOffers() {

        APIController.GetData(16, AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                const Banner = response.object.filter(data => data.shortcode === 'WebBanner');
                if (Banner.length == 1 && Banner[0].parametervalue != '') {
                    this.setState({
                        OffersBanner: Banner[0].parametervalue, offerDialog: true,
                    })
                    window.$offer = '1' //global variable
                }
                // if (response.object[7].parametervalue != '' || response.object[7].parametervalue != null) {

                // }
            })
    }

    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { cart, isLoading, mediumBrowser, AppPopup } = this.state
        return (
            <React.Fragment>
                <Helmet>
                    <title>Get WHO-GMP standard generic medicine at Medkart online pharmacy</title>
                    <meta name="description" content="Visit Medkart pharmacy online to buy WHO-GMP generic medicines at affordable prices. Also available at our 45+ stores in India across 4 states" />
                    <meta name="keywords" content="Medkart Pharmacy Online | WHO-GMP Generic Medicine | Affordable Medicines" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader CartCount={cart} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main>
                    <WebsiteHomePage browser={mediumBrowser} />
                    <OfferDialog
                        history={this.props.history}
                        onClose={() => this.setState({ offerDialog: false })}
                        open={this.state.offerDialog}
                        url={this.state.OffersBanner}
                    />
                </main>
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                <DownlodAppDialog
                    history={this.props.history}
                    onClose={() => this.setState({ AppPopup: false })}
                    open={AppPopup}
                    onClick={() => this.setState({ AppPopup: false })}
                />
            </React.Fragment>
        );
    }
}
const combined = withStyles(CampaignStyle)(Campaign);
export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    watchPosition: true,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: false,
    userDecisionTimeout: 5000,
})(combined);