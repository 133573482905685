// leave off @2x/@3x
const images = {
    Banner0: require('../assets/Web/home-1-banner-0.jpg'),
    Banner1: require('../assets/Web/home-1-banner-1.jpg'),
    Banner2: require('../assets/Web/home-1-banner-2.jpg'),
    Banner3: require('../assets/Web/home-1-banner-3.jpg'),
    Banner4: require('../assets/Web/home-1-banner-4.jpg'),
    Banner5: require('../assets/Web/home-1-banner-5.jpg'),
    Banner6: require('../assets/Web/Website_Banner2.jpeg'),
    Banner0_mobile: require('../assets/Web/home-1-banner-0-mobile.jpg'),
    Banner1_mobile: require('../assets/Web/home-1-banner-1-mobile.jpg'),
    Banner2_mobile: require('../assets/Web/home-1-banner-2-mobile.jpg'),
    Banner3_mobile: require('../assets/Web/home-1-banner-3-mobile.jpg'),
    Banner4_mobile: require('../assets/Web/home-1-banner-4-mobile.jpg'),
    Banner5_mobile: require('../assets/Web/home-1-banner-5-mobile.jpg'),
    Banner6_mobile: require('../assets/Web/Website_Banner2.jpeg'),
    Vision: require('../assets/Web/vision_img.jpg'),
    Who_we_are: require('../assets/Web/who_we_are.jpg'),
    CheckMark: require('../assets/Web/checkmark.png'),
    Pills_bg: require('../assets/Web/pills_bg.jpg'),
    Rocket_launch: require('../assets/Web/rocket_launch.svg'),
    Testimonial1: require('../assets/Web/jigneshsolanki.png'),
    Testimonial2: require('../assets/Web/meghna.png'),
    FiveStar: require('../assets/Web/rating.png'),
    TestimonialDummy1: require('../assets/Web/TestimonialDummy1.jpeg'),
    TestimonialDummy2: require('../assets/Web/TestimonialDummy2.jpeg'),
    MedkartShop: require('../assets/Web/medkart_shop.png'),
    FormBackground: require('../assets/Web/login_img.png'),
    SignupBackground: require('../assets/Web/SignUpBackground.png'),
    SignupSideImage: require('../assets/Web/signup_side_image.png'),
    EcommerceCard: require('../assets/Web/ecommerce.png'),
    EcommerceCard1: require('../assets/Web/ecommerce1.png'),
    EcommerceCard2: require('../assets/Web/ecommerce2.png'),
    iconmain: require('../assets/Web/icon_main.png'),
    logoImage: require('../assets/Web/logoImage.jpg'),
    logo: require('../assets/Web/logo.png'),
    ordermedicine: require('../assets/Web/ordermedicine.png'),
    calltoorder: require('../assets/Web/calltoorder.png'),
    myorders: require('../assets/Web/myorders.png'),
    refills: require('../assets/Web/refills.png'),
    storelocator: require('../assets/Web/storelocator.png'),
    whygeneric: require('../assets/Web/whygeneric.png'),
    Google: require('../assets/Web/google_icon.png'),
    Facebook: require('../assets/Web/fb_icon.png'),
    Placeholder: require('../assets/Web/placeholder.png'),
    linkarrow: require('../assets/ic_linkarrow.svg'),
    email: require('../assets/ic_Email.svg'),
    success: require('../assets/Web/success.png'),
    Iphone: require('../assets/Web/iphone.png'),
    Successfulicon: require('../assets/Web/successfulicon.png'),
    Icon_tablet: require('../assets/Web/icon_tablet.png'),
    Icon_injection: require('../assets/Web/icon_injection.png'),
    Icon_pill: require('../assets/Web/icon_pill.png'),
    Icon_syrup: require('../assets/Web/icon_syrup.png'),
    Icon_drop: require('../assets/Web/icon_drop.png'),
    Icon_tube: require('../assets/Web/icon_tube.png'),
    AppStore: require('../assets/Web/appstore.png'),
    coupon:require('../assets/Web/coupon.jpg'),
    PlayStore: require('../assets/Web/playstore.png'),
    NewIcon: require('../assets/logo.png'),
    Men: require('../assets/men.png'),
};

export default images;
