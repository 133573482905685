import React from 'react';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import buttonBaseStyle from './ButtonBaseStyle';

const ButtonBase = props => {
  const classes = buttonBaseStyle();
  const { title, customClass, isLoading } = props;
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={`${classes.button} ${customClass}`}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <CircularProgress color="secondary" size={20} />
      ) : (
          <Typography>{title}</Typography>
        )}
    </Button>
  );
};

export default ButtonBase;
