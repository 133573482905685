/*eslint-disable eqeqeq*/
import React from 'react';
import * as AppConstants from '../../common/Constants';
import CallIcon from '@material-ui/icons/Call';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import MobilenoDialogStyle from './MobilenoDialogStyle';
import * as APIController from '../../api/APIController';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
const MobilenoDialog = props => {
    const classes = MobilenoDialogStyle();
    const { open, onClose, onClick } = props;
    const [data, setdata] = React.useState([]);
    const [sample, setsample] = React.useState('');

    // React.useEffect(() => {// Pass in a callback function!
    //     WsContactMe()
    // })

    React.useEffect(() => {
        WsContactMe()
    }, [sample])

    const WsContactMe = () => {
        APIController.GetData('16', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    setdata(response.object.filter((e) => e.shortcode == 'ContactNo1' || e.shortcode == 'ContactNo2'))
                }
                else if (response.error.message && response.error.message.length > 0) {

                }
            });
    }
    // const [open, setopen] = React.useState(open);
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '100%' }}
                open={open}>

                <Grid container justify='flex-start' alignItems='center'
                    style={window.innerWidth <= 420 ? { width: '300px', backgroundColor: '#39A749' } : window.innerWidth <= 920 ? { width: '350px', backgroundColor: '#39A749' } : { width: '500px', backgroundColor: '#39A749' }}>
                    <Grid direction={'row'} container justify='center' style={{ width: '100%', padding: '5%', }}>
                        <Typography style={{ color: '#fff', fontSize: AppConstants.FONTSIZE.FS20, fontFamily: 'Nunito Sans', fontWeight: 700, textAlign: 'center' }}>{'Contact Medkart Pharmacy'}</Typography>
                    </Grid>
                    <Grid container direction='column' justify='center' style={{ width: '100%', marginBottom: '10px', borderBottom: '2px solid #fff' }}></Grid>

                    {data.map((item, index) =>
                        <Grid direction='row' justify={'center'} container style={{ width: '100%' }}>
                            {index < 3 &&
                                <Grid direction='row' container style={window.innerWidth <= 420 ? { width: '80%', paddingTop: '2%', marginBottom: '2%' } : { width: '50%', paddingTop: '2%', marginBottom: '2%' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<CallIcon style={{ color: '#fff', fontSize: AppConstants.FONTSIZE.FS30, }} />&nbsp;&nbsp;
                                    <a href={"tel:" + item.parametervalue} target="_blank" style={{ color: '#fff', fontSize: AppConstants.FONTSIZE.FS20, fontFamily: 'Nunito Sans', fontWeight: 400, textDecorationLine: 'none' }}>{item.parametervalue}</a>
                                </Grid>
                            }
                        </Grid>
                    )}
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};


export default (MobilenoDialog);
