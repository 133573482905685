import React from 'react';
import { withTranslation } from 'react-i18next';
import Compare from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import * as AppConstants from '../../common/Constants';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import { Typography, Grid, IconButton, Divider, Button,ClickAwayListener,MenuItem, ListItem, } from '@material-ui/core';
import BottomMenuStyle from './BottomMenuStyle';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const BottomMenu = props => {
    const classes = BottomMenuStyle();
    const [openmenuScreen, setOpenmenuScreen,] = React.useState(false);
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { isShow } = props;
    return (
        <div>
           {/* <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} /> */}
                {props.browser == false && <Grid container direction='row' justify='center' alignItems='center' style={{ width:'100%',padding:'0.1%',}}>
                        <Button className={classes.buttonstyleMenu} onClick={() => { AppConstants.userIdFromLocal() != '' ? props.history.push(AppConstants.SCREENS.ECOMMERCEHOMEPAGE) : setLoginDialog(true) }} ><Buy className={classes.IconStyleMenu} />ABOUT US</Button>&nbsp;&nbsp;
                        <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.COMPARE) }}><Compare className={classes.IconStyleMenu} />COMPARE MEDICINES</Button>&nbsp;&nbsp;
                        <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.STORES) }}><StoreLocator className={classes.IconStyleMenu} />STORE LOCATOR</Button>&nbsp;&nbsp;
                        <Button className={classes.buttonstyleMenu} onClick={() => { props.history.push(AppConstants.SCREENS.TESTIMONIALS) }} ><Testimonials className={classes.IconStyleMenu} />TESTIMONIALS</Button>
                        <Grid container style={props.browser == true ? { width: '50%', } : {  width: '12%', }} justify={'flex-start'} direction={'row'} alignItems='flex-start'>
                        <ClickAwayListener onClickAway={() => setOpenmenuScreen(false)}>
                            <Grid container justify='center' alignItems='center' onClick={() => setOpenmenuScreen(openmenuScreen => !openmenuScreen)} style={{  position: 'relative',width:'100%',cursor: 'pointer', }} direction={'row'}>
                                {/* <Avatar style={{backgroundColor:'#f1f1f1'}}> */}
                                <IconButton
                                    aria-label="open drawer"
                                    edge="start"
                                    className={clsx(classes.menuButton, open && classes.hide)}>
                                    <MenuIcon fontSize='large'/>
                                </IconButton>
                                {openmenuScreen ? (
                                    <Grid
                                        container
                                        direction='column'
                                        justify={'center'}
                                        style={{
                                            position: 'absolute',
                                            top: 40,
                                            right: 10,
                                            left: '0px',
                                            border: '1px solid #008000',
                                            backgroundColor: '#008000'
                                    }} >
                                        <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.JOINUS) }} style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}><ListItemIcon><JoinUs className={classes.IconStyleMenu} /></ListItemIcon>CAREER</MenuItem>
                                        <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.CONTACTMEDKART) }} style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}><ListItemIcon><Partnerwithus className={classes.IconStyleMenu} /></ListItemIcon>FRANCHISEE</MenuItem>
                                        {/* <MenuItem onClick={() => { props.history.push(AppConstants.SCREENS.HOMEPAGE) }} style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', }}>HOME </MenuItem> */}
                                    </Grid>
                                ) : null} 
                            </Grid>
                        </ClickAwayListener> 
                    </Grid>
                </Grid>}
                {/* {props.browser == false && <Divider style={{ width: '100%', height: '2px',borderRadius: '100px', backgroundColor: '#D9DCE3' }} />} */}

            <ConfirmDialog
                history={props.history}
                onClose={() => setLoginDialog(false)}
                open={LoginDialog}
                onClick={() => setLoginDialog(false)}
            />

        </div>
    );
};

export default (BottomMenu);
