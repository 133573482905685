import React, { useState, useEffect } from 'react';
import * as AppConstants from '../../common/Constants';
import { Typography, Grid } from '@material-ui/core';
import CancelOrderDialogStyle from './CashFreeDialogStyle';
import ButtonBase from '../ButtonBase/ButtonBase';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Dropzone from 'react-dropzone';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FilledTextField from '../FilledTextField/FilledTextField';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';


const apiKey = AppConstants.apiKey
const apiSecret = AppConstants.apiSecret

const CancelOrderDialog = props => {
    const classes = CancelOrderDialogStyle();
    const { open, onClose, Title, onClick, OrderStatusId, onCancelOrder, GetOrderdetail, OrderId, OrderStatus, OrderReferenceNo } = props;
    const [dropzone, setdropzone] = React.useState('');
    const [Name, setName] = React.useState('');
    const [image64, setimage64] = React.useState('');
    const [imagename, setimagename] = React.useState('');
    const [IssueId, setIssueId] = React.useState('');
    const [matchId, setmatchId] = React.useState(-1);
    // const image = dropzone.preview

    React.useEffect(() => {
        // renderDropin()
        // _createOrderWithToken('1510', '10.2', 'MK1510')
    }, [])

    const [orderToken, setOrderToken] = useState('7RyleyCOzRftapYCmSDb');
    const [isProd, setisProd] = useState('TEST');

    const cbs = (data) => {
        if (data.order && data.order.status === 'PAID') {
            alert('order is paid. Call api to verify');
        }
    };
    const cbf = (data) => {
        alert(data.order.errorText);
    };
    const renderDropin = () => {
        if (orderToken === '') {
            alert('Order Token is empty');
            return;
        }

        let parent = document.getElementById('drop_in_container');
        parent.innerHTML = '';
        let cashfree;
        if (!isProd == 'TEST') {
            cashfree = new cashfreeProd.Cashfree();
        } else {
            cashfree = new cashfreeSandbox.Cashfree();
        }
        console.log('before Initialisation');
        cashfree.initialiseDropin(parent, {
            orderToken,
            onSuccess: cbs,
            onFailure: cbf,
            "components": [
                "order-details",
                "card",
                "netbanking",
                "app",
                "upi"
            ],
            "style": {
                //to be replaced by the desired values
                "backgroundColor": "#ffffff",
                "color": "#11385b",
                "fontFamily": "Lato",
                "fontSize": "14px",
                "errorColor": "#ff0000",
                "theme": "light", //(or dark)
            }
        });
        console.log('after Initialisation');
    };

    const _createOrderWithToken = (OrderID, Amount, OrderRefNo) => {
        let tokenUrl;
        if (isProd === 'TEST') {
            tokenUrl = 'https://sandbox.cashfree.com/pg/orders'; //for TEST
        } else {
            tokenUrl = 'https://live.cashfree.com/pg/orders'; //for PROD
        }
        let orderApiMap = {
            order_id: OrderID,
            order_amount: Amount,
            order_currency: 'INR',
            order_note: OrderRefNo,
            customer_details: {
                customer_id: "12345",
                customer_email: "techsupport@cashfree.com",
                customer_phone: "9816512345"
            }

        };
        let data = {
            "order_id": "order_1626945143520",
            "order_amount": 10.12,
            "order_currency": "INR",
            "order_note": "Additional order info",
            "customer_details": {
                "customer_id": "12345",
                "customer_email": "techsupport@cashfree.com",
                "customer_phone": "9816512345"
            },
        }
        // console.log(JSON.stringify(orderApiMap));
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-version': '2022-01-01',
                'x-client-id': apiKey,
                'x-client-secret': apiSecret,
            },
            body: JSON.stringify(data),
        };
        return new Promise((resolve, reject) => {
            let cfToken;
            fetch(tokenUrl, postParams)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log('data:', data)
                    if (data.status === 'ERROR') {
                        console.log(
                            `Error (code: ${data.subCode}, message: ${data.message})`,
                        );
                        console.log(
                            'Please check the apiKey and apiSecret credentials and the environment',
                        );
                        return;
                    }
                    try {
                        cfToken = data.cftoken;
                        // let map = {
                        //     orderId: OrderID,
                        //     orderAmount: Amount,
                        //     appId: apiKey,
                        //     tokenData: cfToken,
                        //     orderCurrency: 'INR',
                        //     orderNote: OrderRefNo,
                        //     notifyUrl: 'https://medkart.api.thestaging.cc/API/WebHook/WebHook_Live_CashFree',
                        //     customerName: 'Nilesh patel',
                        //     customerPhone: '7698498403',
                        //     customerEmail: 'test@test.com',
                        //     color1: AppConstants.COLORS.GREEN,
                        //     color2: AppConstants.COLORS.WHITE,

                        // };
                        // return resolve(map);
                    } catch (error) {
                        console.log('THE ERROR IS ' + data);
                        return reject(data);
                    }
                });
        });
    }
    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                // style={{ height: '750px', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center' style={window.innerWidth <= 920 ? { width: '100%' } : { padding: '20px', width: '100%' }}>
                    <div className="App">
                        <div
                            className="dropin-parent"
                            id="drop_in_container"
                            style={{ height: '600px' }} >
                            <button className="btn-render mt-2" onClick={_createOrderWithToken}>
                                Pay now
                            </button>

                        </div>
                    </div>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (CancelOrderDialog);
