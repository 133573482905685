import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, Grid, IconButton, Divider, } from '@material-ui/core';
import TitleStyle from './TitleStyle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as AppConstants from '../../common/Constants';

const CommonTitle = props => {
    const classes = TitleStyle();
    const { title, isBack } = props;
    return (
        <div>
            <Grid container style={window.innerWidth <= 920 ?{ width: '100%', marginTop:'10%' }:{ width: '100%', padding: '35px 12px 5px 12px' }} direction={'row'} alignItems='center' >
                {/* <Grid container style={{ width: '100%' }} direction={'row'} alignItems='center' >
                    <IconButton style={{ borderRadius: 0 }} onClick={() => props.history.goBack()} >
                        <ArrowBackIosIcon style={{ fontSize: '20px', fontWeight: '700', marginLeft: '-10px', marginRight: '2px', color: '#262628' }} />
                        <Typography className={classes.backytxt}>{'Back'}</Typography>
                    </IconButton>
                </Grid> */}
                <Grid container style={{ width: '100%', marginBottom: '15px' }} direction={'row'} alignItems='center' >
                    {/* {isBack == false ? <div /> : <IconButton style={{ borderRadius: 0 }} onClick={() => props.history.goBack()} > */}
                    {/*isBack == false ? <div /> :
                        isBack == 1 ?
                            <IconButton style={{ borderRadius: 0 }} onClick={() => props.history.push(AppConstants.SCREENS.ECOMMERCEHOMEPAGE)} >
                                <ArrowBackIosIcon style={{ fontSize: '25px', fontWeight: '900', marginLeft: '-10px', color: '#262628' }} />
                                {/* <Typography className={classes.backytxt}>{'Back'}</Typography> }
                            </IconButton>
                            : <IconButton style={{ borderRadius: 0 }} onClick={() => props.history.goBack()} >
                                <ArrowBackIosIcon style={{ fontSize: '25px', fontWeight: '900', marginLeft: '-10px', color: '#262628' }} />
                                {/* <Typography className={classes.backytxt}>{'Back'}</Typography> }
                            </IconButton>
                    */}
                    <Typography className={classes.titletext}>{title}</Typography>
                </Grid>
                <Grid container style={{ width: '100%' }} alignItems='center' >
                    <Divider style={{ width: '100%', height: '2px', borderRadius: '100px', backgroundColor: '#D9DCE3' }} />
                    <Divider style={{ width: '60px', height: '5px', borderRadius: '100px', backgroundColor: '#39A749', color: '#D9DCE3', marginTop: -3 }} />
                </Grid>
            </Grid>
        </div>
    );
};

export default withTranslation('translation')(CommonTitle);
