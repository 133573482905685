import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AlertdialogStyle from "./AlertdialogStyle";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="center" ref={ref} {...props} />;
});
const Alertdialog = props => {
    const classes = AlertdialogStyle();
    return (
        <Dialog
            {...props}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            {props.children}
        </Dialog>

    );
}
export default Alertdialog;