
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import orderReviewStyle from './OrderReviewStyle';
import Button from '@material-ui/core/Button';
import { CssBaseline, Typography, Grid, Divider, CircularProgress, Card, } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import * as moment from 'moment';
import { Images } from '../../common';
import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import { Scrollbars } from 'react-custom-scrollbars';
import Alertdialog from "../../components/Mobile/Alertdialog/Alertdialog";
import OrderConfirmDialog from "../../components/OrderConfirmDialog/OrderConfirmDialog";
import ReactGA from 'react-ga';
class OrderReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            IsLoading: false,
            mediumBrowser: false,
            OrderId: '',
            orderreferenceno: '',
            OrderReferenceNo: '',
            // EstaimateDeliveryDate:'',
            patientName: '',
            patientDoctor: '',
            estimateddeliverydate: '',
            addressname: '',
            address1: '',
            address2: '',
            area: '',
            city: '',
            postcode: '',
            addressmobileno: '',
            addresstypeid: '',

            CartTotal: '',
            MedKartDiscount: '',
            CouponDiscount: 0,
            AddressIDStateOrderReview: '',
            PatientIDStateOrderReview: '',
            FlatlistData: '',
            GrossTotal: '',
            DeliveryCharges: 0,
            dataSourceCart: [],
            ConfirmPopup: false,
            PatientDetails: []
        };
    }
    componentWillMount() {
        this.setState({
            PatientIDStateOrderReview: this.props.history.location.state.PatientIdfromAddress,
            AddressIDStateOrderReview: this.props.history.location.state.AddressIdfromAddress
        })
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        //this.setState({ DeliveryCharges: parseInt(localStorage.getItem('deliverycharges')) })
        this.resize();
        this.wsOrderReview();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    wsOrderReview() {
        this.setState({ IsLoading: true })
        const PinCode = localStorage.getItem('pincode');
        APIController.OrderReview('14', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.AddressIDStateOrderReview, this.state.PatientIDStateOrderReview, PinCode).then((response) => {
            if (response.error == null) {
                this.setState({
                    dataSourceCart: response.object.data.cart, IsLoading: false,
                    estimateddeliverydate: response.object.data.cartcount[0].estimateddeliverydate,
                    DeliveryCharges: response.object.data.cartcount[0].deliverycharges,
                    patientName: response.object.data.patients[0].name,
                    patientDoctor: response.object.data.patients[0].doctorname,
                    PatientDetails: response.object.data.patients,
                    addressname: response.object.data.addresses[0].name, address1: response.object.data.addresses[0].address1,
                    address2: response.object.data.addresses[0].address2, area: response.object.data.addresses[0].area,
                    city: response.object.data.addresses[0].city, postcode: response.object.data.addresses[0].postcode,
                    addressmobileno: response.object.data.addresses[0].mobileno, addresstypeid: response.object.data.addresses[0].addresstypeid
                })
                this.CalculateCartTotal(this.state.dataSourceCart);
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
        })
    }
    CalculateCartTotal(Data) {
        var SubTotal = 0;
        var Discount = 0;
        var CouponDiscount = 0;
        Data.map(function (item) {
            SubTotal = SubTotal + (item.quantity * item.mrp);
            Discount = Discount + (item.quantity * (item.mrp - item.saleprice));
            CouponDiscount = SubTotal * item.discountpercentage / 100 > item.maximumdiscount ? item.maximumdiscount : SubTotal * item.discountpercentage / 100
        });
        // (SubTotal - Discount) < global.deliverychargesafter ? this.setState({ DeliveryCharges: global.deliverycharges }) : this.setState({ DeliveryCharges: 0, })
        this.setState({
            CartTotal: SubTotal.toFixed(2),
            MedKartDiscount: (Discount).toFixed(2),
            CouponDiscount: CouponDiscount,
            FlatlistData: Data,
            GrossTotal: Math.round((SubTotal - Discount - CouponDiscount + this.state.DeliveryCharges).toFixed(2)),
            isLoading: false
        });
    }

    wsOrderConfirmation() {
        APIController.OrderConfirmation('11', AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            this.state.OrderId)
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        orderID: response.object.data[0].id,
                        orderreferenceno: response.object.data[0].orderreferenceno,
                        estimateddeliverydate: response.object.data[0].estimateddeliverydate, IsLoading: false
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }
    wsCopyOrder() {
        APIController.CopyOrder(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(),
            this.state.OrderId)
            .then((response) => {
                if (response.error == null) {
                    ToastsStore.warning(response.object.message)
                    this.props.history.push({
                        pathname: '/cart',
                    });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }
    async wsAddOrder() {
        let TempDataIDs = [];
        let TempDataNames = [];
        await this.state.PatientDetails.map((item) => {
            TempDataIDs.push(item.id)
            TempDataNames.push(item.name)
        })
        APIController.AddOrder(
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            this.state.MedKartDiscount,
            this.state.DeliveryCharges,
            this.state.AddressIDStateOrderReview,
            TempDataIDs.toString(),
            TempDataNames.toString(),
            // this.state.patientDoctor,
            this.state.additionalnotes,
            '0').then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        localStorage.setItem('cartvalue', JSON.stringify(0))
                        this.setState({
                            OrderId: response.object.data[0].id,
                            ConfirmPopup: true
                        })
                        this.wsOrderConfirmation()
                    }
                    else if (response.object.status == 0) {
                        alert(response.object.message);
                        this.props.history.push(AppConstants.SCREENS.CART)

                    }
                    else {
                        ToastsStore.warning(response.object.message);
                    }
                    // this.wsOrderConfirmation()
                    // this.props.history.push({
                    //     pathname: '/orderconfirmation',
                    //     state: { OrderIDfromOrderReview: response.object.data[0].id }
                    // });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            })
    }

    renderReviewOrder() {
        const { PatientDetails } = this.state;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={{ textAlign: 'left', flex: 1, width: '100%', height: '97%', padding: '2%', paddingTop: this.state.mediumBrowser ? '5%' : '2%', paddingRight: this.state.mediumBrowser ? '5%' : '4%', paddingLeft: this.state.mediumBrowser ? '4%' : '4%' }}>
                <Grid container direction='row' >
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '18px', fontWeight: '700', color: '#262628', marginBottom: '2%' }}>Deliver to...</Typography>
                    <Grid container direction='column'>
                        {PatientDetails.map((item, index) => {
                            return (
                                <>

                                    <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '20px', fontFamily: 'Nunito Sans', }}> {item.name}</Typography>
                                    <Typography style={{ color: '#262628', fontSize: '16px', }}> {item.doctorname} </Typography>
                                </>)
                        })}
                    </Grid>
                </Grid>
                <Divider style={{ height: '1px', width: '100%', marginBottom: '20px', marginTop: '20px', }} orientation="horizontal" />
                <Grid container direction='row'>
                    {/* <Typography style={{ color: '#262628', fontWeight: 'bold', fontSize: '24px', marginBottom: '5px', }}>Delivery Details</Typography>
                    <Divider style={{ height: '1px', width: '100%', marginBottom: '5px', marginTop: '5px', }} orientation="horizontal" /> */}
                    <Grid container direction='row'>
                        <Typography style={{ width: '70%', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}>Expected Delivery Date</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', textAlign: 'right', }}>{this.state.estimateddeliverydate}</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ height: '1px', width: '100%', marginBottom: '20px', marginTop: '20px', }} orientation="horizontal" />
                <Grid container direction='row' >
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', marginBottom: '15px', }}>Delivery Address</Typography>
                    {/* <Divider style={{ height: '1px', width: '100%', marginBottom: '5px', marginTop: '5px', }} orientation="horizontal" /> */}
                    <Grid container direction='row'
                        style={{ backgroundColor: '#fff', width: '100%', }} >
                        <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '20px', fontFamily: 'Nunito Sans', }}> {this.state.addressname} </Typography>
                        <Typography style={this.state.addresstypeid == 1 ?
                            {
                                color: '#E1E4EB',
                                fontSize: '12px',
                                backgroundColor: '#4A90E2',
                                fontFamily: 'Nunito Sans',
                                width: '45px',
                                height: '17px',
                                marginLeft: '30px',
                                borderRadius: '50px 50px 50px 50px',
                                textAlign: 'center',
                                justifyContent: 'flex-end',
                                marginTop: '1%',
                            } : this.state.addresstypeid == 2 ? {
                                color: '#E1E4EB',
                                fontSize: '12px',
                                backgroundColor: '#E2634A',
                                fontFamily: 'Nunito Sans',
                                width: '45px',
                                height: '17px',
                                marginTop: '1%',
                                marginLeft: '30px',
                                borderRadius: '50px 50px 50px 50px',
                                textAlign: 'center',
                                justifyContent: 'flex-end',
                            } : {
                                color: '#E1E4EB',
                                fontSize: '12px',
                                backgroundColor: '#ECB32F',
                                fontFamily: 'Nunito Sans',
                                width: '45px',
                                height: '17px',
                                marginTop: '1%',
                                marginLeft: '30px',
                                borderRadius: '50px 50px 50px 50px',
                                textAlign: 'center',
                                justifyContent: 'flex-end',
                            }
                        }> {this.state.addresstypeid == 1 ? 'Home' : this.state.addresstypeid == 2 ? "Work" : "Other"} </Typography>
                    </Grid>
                    <Grid container direction='column' style={{ marginTop: '20px', }} >
                        <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.address1}, {this.state.address2},  </Typography>
                        <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.area}, {this.state.city}, {this.state.postcode} </Typography>
                        <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.addressmobileno} </Typography>
                    </Grid>
                </Grid>
                {/* <Divider style={{ height: '2px', width: '100%', backgroundColor: 'green', marginBottom: '10px', marginTop: '10px', }} orientation="horizontal" /> */}
                <Grid container direction='row' style={{ marginTop: '20px', }}>
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', marginBottom: '5px', }}>Additional Notes</Typography>
                    <FilledTextField
                        // defaultValue={this.state.AddressPatientName}
                        label='Additional Notes'
                        rows={5}
                        name="additionalnotes"
                        onChange={this.additionalnotes}
                        style={{ marginBottom: '2%', }} />
                </Grid>
                <Grid container direction='row' style={{ width: '100%', marginBottom: '20px' }} alignItems='flex-start' justify='flex-start'>
                    <ButtonBase title='PLACE ORDER'
                        justify='center'
                        // onClick={() => { this.setState({ ConfirmPopup: true }) }}
                        onClick={() => { this.wsAddOrder() }}
                        style={this.state.mediumBrowser ? { width: '100%', marginBottom: '25px' } : { width: '35%' }} />
                </Grid>

            </Card>
        )
    }
    medicineDetailFromsearch(data) {

    }
    renderOrderConfirm() {
        const { classes } = this.props;
        const { IsLoading } = this.state;
        return (
            <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
                <Grid container justify='space-between' alignItems='center' style={{ width: '98%' }}>
                    <Grid container justify='center' alignItems='center' direction='column' className={classes.Contentcardmainwithoutborder} style={{ width: '100%', height: '100%' }}>
                        <img src={Images.Successfulicon} alt="" />
                        <Typography className={classes.txtCongress}>Congratulations!</Typography>
                        <Typography className={classes.txtOrderSuccess}>Order Placed successfully!</Typography>
                        <Typography className={classes.txtOrderSuccessMsg}>Once our pharmacist verifies your order with the prescription, you will be notified for Payment. Normally, It takes up to 4 working hours</Typography>
                    </Grid>
                    <Grid container direction={'row'} style={{ width: '100%', marginTop: 15, marginBottom: '25px' }} alignItems='center' justify={'center'}>
                        <ButtonBase title={'Back To Home'} variant="contained"

                            style={{ width: '162px' }}
                            onClick={() => {
                                // window.history.replaceState(null, null, "/"),
                                this.props.history.push(AppConstants.SCREENS.HOMEPAGE)
                            }} />
                    </Grid>
                    <Grid container direction='column' className={classes.Contentcardmainwithoutborder} style={{ width: '100%', height: '100%' }}>
                        <Typography className={classes.titleText}>Order #{this.state.orderreferenceno}</Typography>
                        <Grid container justify='flex-end' style={{ bottom: '0%' }} direction='column' style={{ width: '100%' }}>
                            <Grid container>
                                <Typography className={classes.txtOrderSuccess}>Expected Delivery : </Typography>
                                <Typography className={classes.txtDeliveryDate}> {moment(this.state.estimateddeliverydate).format("DD-MMM-YYYY")}</Typography>
                            </Grid>
                            <Grid container justify='flex-end'>
                                <ButtonBase type="submit" title={'COPY ORDER'} variant="contained" className={classes.btnCopyOrder}
                                    onClick={() => {
                                        this.wsCopyOrder(this.state.OrderId);
                                    }} />
                                <ButtonBase type="submit" title={'DETAILS'} variant="contained" className={classes.btnDetails}
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/myorder',
                                            state: { orderConfomID: this.state.orderID }
                                        });
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        )
    }
    renderReviewOrderList() {
        const { dataSourceCart, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { textAlign: 'left', flex: 1, width: '100%', height: '97%', padding: '5%', paddingBottom: '35px' } : { textAlign: 'left', flex: 1, width: '100%', height: '97%', padding: '2%', paddingBottom: '4%' }}>
                <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '18px', fontWeight: '700', color: '#262628', }}>Order Items</Typography>
                <Scrollbars autoHide autoHeight hideTracksWhenNotNeeded={true} autoHeightMax='1000px' style={{ marginTop: '30px' }}>
                    {
                        dataSourceCart.map((item, index) =>
                            <Grid container
                                direction='row'
                            // justify='flex-start'
                            >
                                <Grid container direction='row' alignContent='center'>
                                    {/* <Grid alignItems={'center'} container xl={12} md={12} sm={12} xs={12} >
                                        <Typography className={classes.Medicinetitle}>{item.medicinename}</Typography>
                                        &nbsp; <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                        &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                    </Grid> */}
                                    {item.companyname != null && item.companyname != '' ?
                                        <Typography style={{ width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', }}>{item.medicinename + '( By: ' + item.companyname + ' )'}</Typography>
                                        :
                                        <Typography style={{ width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', }}>{item.medicinename}</Typography>
                                    }
                                    {/* &nbsp; <Typography style={{ width: '30%', color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                    &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography> */}
                                    <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', textAlign: 'right', }}>₹ {item.saleprice}</Typography>
                                </Grid>
                                <Typography style={{ width: '50%', color: '#9B9B9B', fontSize: '16px', fontFamily: 'Nunito Sans', fontWeight: '400', }}>Quantity: {item.quantity}</Typography>
                                {/* <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                                    <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'Company: '}</Typography>
                                    &nbsp;&nbsp;&nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname}</Typography>
                                </Grid> */}
                                {dataSourceCart.length - 1 == index ? null : <Divider style={{ height: '1px', width: '100%', marginBottom: '1%' }} orientation="horizontal" />}
                            </Grid>
                        )}
                </Scrollbars>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#39A749', height: '2px', marginBottom: '1%', marginTop: '1%', }} />
                <Grid container direction='row' style={{}}>
                    <Grid container direction='row' >
                        <Typography style={{ width: '70%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', }}>MRP Total</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '600', textAlign: 'right', }}>₹ {this.state.CartTotal}</Typography>
                    </Grid>
                    <Grid container direction='row' >
                        <Typography style={{ width: '70%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', }}>Discount</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', textAlign: 'right', }}>-₹ {this.state.MedKartDiscount}</Typography>
                    </Grid>
                    <Grid container direction='row' >
                        <Typography style={{ width: '70%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', }}>CouponDiscount</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', textAlign: 'right', }}>-₹ {this.state.CouponDiscount.toFixed(2)}</Typography>
                    </Grid>
                    <Grid container direction='row'>
                        <Typography style={{ width: '70%', color: '#9B9B9B', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', }}>Delivery Charges</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: '400', textAlign: 'right', }}>₹ {this.state.DeliveryCharges}</Typography>
                    </Grid>
                    <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#39A749', height: '2px', marginBottom: '1%', marginTop: '1%', }} />
                    <Grid container direction='row'>
                        <Typography style={{ width: '70%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '18px', fontWeight: '700', }}>Gross Total</Typography>
                        <Typography style={{ width: '30%', color: '#262628', fontFamily: 'Nunito Sans', fontSize: '18px', fontWeight: '700', textAlign: 'right', }}>₹ {this.state.GrossTotal}</Typography>
                    </Grid>
                </Grid>

            </Card>
        )
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, IsLoading, ConfirmPopup } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Review Order'} history={this.props.history} />
                    {IsLoading ? this.LoadingView() :
                        <Grid container direction="row" style={mediumBrowser ? { width: '100%', paddingTop: '4%' } : { width: '99%', marginLeft: '10px', marginTop: '10px' }} justify={'space-around'}>
                            <ToastsContainer store={ToastsStore} />
                            <Grid item style={mediumBrowser ? { width: '100%' } : { width: '39%', }}>
                                {this.renderReviewOrder()}
                            </Grid>
                            <Grid item style={mediumBrowser ? { width: '100%' } : { width: '60%', marginLeft: '10px' }} >
                                {this.renderReviewOrderList()}
                            </Grid>
                        </Grid>
                    }
                    <OrderConfirmDialog
                        open={ConfirmPopup}
                        OrderId={this.state.OrderId}
                        PaymentId={''}
                        OnDetail={''}
                        Amount={this.state.GrossTotal}
                        history={this.props.history}
                        OrderReferenceNo={this.state.orderreferenceno}
                        EstimateDelivery={this.state.estimateddeliverydate}
                        Title={'Order Placed Successfully!'}
                    />


                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(orderReviewStyle)(withTranslation('translation')(OrderReview))