import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import uploadPrescriptionStyle from './UploadPrescriptionStyle';
import MedkartHeader from "../../components/Header/MedkartHeader";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Footer from "../../components/Header/Footer";
import * as AppConstants from '../../common/Constants';
import Title from "../../components/Header/Title";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as APIController from '../../api/APIController';
import Dropzone from 'react-dropzone';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, Typography, IconButton, CircularProgress, CssBaseline, Button } from '@material-ui/core';
import OldPrescriptionModal from "./OldPrescriptionModal";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReactGA from 'react-ga';
class UploadPrescription extends Component {
    constructor() {
        super();
        this.state = {
            dropzone: {},
            open: false,
            image64: '',
            imagename: '',
            isLoading: true,
            isLoadingChoose: false,
            isRemoveLoading: false,
            IsSelectPress: -1,
            IsSelectPressed: 0,
            IsRemovePress: -1,
            imageSize: '',
            IsPageLoad:true,
            dataSource: [],
            OlddataSource: [],
            openPrescriptionDialog: false,
        }
    }
    ConvertToBase64() {
        const image2base64 = require('image-to-base64');
        image2base64(this.state.dropzone.preview)
            .then((response) => {
                this.setState({ image64: response })
                this.wsAddPrescription()

            })
            .catch((error) => {

            })
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetPrescription([])
        // this.wsGetOldPrescription()

    }
    addDummyImage() {
        var dataSource = [...this.state.dataSource];
        dataSource.push({ id: '0', prescreptionpic: '', lastsyncstatus: 1 });
        this.setState({ dataSource, isLoading: false });
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    handleUserPopup(step) {
        this.setState({ openPrescriptionDialog: step })
    }

    wsGetPrescription(DS) {
        if (DS.length == 0) {
            APIController.GetPrescription(
                0,//oprationid
                AppConstants.userIdFromLocal(),//userid
                AppConstants.MobileNoValidation(),//mobileno
                1,//getdata
                0,//is used
            ).then((responseJson) => {
                console.log('responseJson:......', responseJson)
                // this.setState({ dataSource: responseJson.object });
                this.setState({
                    dataSource: responseJson.object,
                   
                }, () => {
                    this.addDummyImage()
                });
            })
            this.wsGetOldPrescription()

        } else {
            if (DS.data != null) {
                this.setState({ dataSource: DS.data });
            }
        }
    }
    wsGetOldPrescription = () => {
        APIController.GetOldPrescription(
            0,//oprationid
            AppConstants.userIdFromLocal(),//userid
            AppConstants.mobilenoFromLocal(),//mobilenumber
            1,//getdata
            1,//is used
        ).then((responseJson) => {
            if (responseJson.error == null) {
                this.setState({ OlddataSource: responseJson.object, isLoading: false,IsPageLoad:false });
            }
            else {
                ToastsStore.warning(responseJson.error.message)
            }
            // this.addDummyImage();// remove by nilesh when select patient issue
        })
    }
    addDummyPhoto() {
        const { classes } = this.props
        const { DeliveryCharges, CartTotal, MedKartDiscount, GrossTotal, dropzone, dataSource, isLoadingChoose } = this.state
        const image = dropzone.preview;

        return (

            <Dropzone onDrop={files => { this.addFilesToDropzone(files, 'dropzone') }}>
                {({ getRootProps, getInputProps }) => (
                    <Grid container direction={'row'} justify={'center'} alignItems="center"  >
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button style={{ width: '200px', height: '200px', border: '1px dashed #D9DCE3', borderRadius: 0 }} isLoading={isLoadingChoose} >
                                {isLoadingChoose ? <CircularProgress /> :
                                    <Grid container direction={'column'} justify='center' alignItems='center'>
                                        <CloudUploadIcon style={{ color: '#D9DCE3', fontSize: 50 }} />
                                        <Typography className={classes.Dropimgtxt}> {'Drop your image '}</Typography>
                                        <Typography style={{ marginTop: 10 }} className={classes.Dropimgsubtxt}>{'.jpg, .pdf, .png allowed'}</Typography>
                                    </Grid>}
                            </Button>
                        </div>
                    </Grid>
                )}
            </Dropzone>
        )
    }
    bindPrescriptions(DS) {
        this.setState({ dataSource: DS });
        this.addDummyImage()
    }
    removeimage(id) {
        this.setState({ isRemoveLoading: true, IsRemovePress: id })
        APIController.removePrescription(
            AppConstants.userIdFromLocal(),//userid
            AppConstants.MobileNoValidation(),//mobileno
            3,//oprationid
            1,//getdata
            id//id for remove
        ).then((responseJson) => {
            if (responseJson.object != null) {
                this.setState({ dataSource: responseJson.object, isRemoveLoading: false, });
                this.addDummyImage();
            }
            else {
                this.setState({ dataSource: [], isRemoveLoading: false });
                this.addDummyImage();
            }
        })
    }
    wsAddPrescriptionToNew = (id, index) => {
        this.setState({ IsSelectPress: index, IsSelectPressed: 1 })
        APIController.AddPrescriptionToNew(
            AppConstants.userIdFromLocal(),//userid
            AppConstants.mobilenoFromLocal(),//mobileno
            id,//prescription id
            2,//oprationid
            1,//getdata
            0,//is used
        ).then((responseJson) => {
            this.setState({ IsSelectPressed: id })
            window.scrollTo(0, 0);
            this.bindPrescriptions(responseJson.object)
        })
    }
    wsAddPrescription() {
        this.setState({ isLoadingChoose: true });
        APIController.AddPrescription(
            1,//opration id
            AppConstants.userIdFromLocal(),//userid
            AppConstants.MobileNoValidation(),//mobileno
            this.state.imagename,//imagename,
            this.state.image64,//imagepath,
            1,//getdata
            0,//is used
        ).then((responseJson) => {
            this.setState({ dataSource: responseJson.object, isLoadingChoose: false });
            this.addDummyImage()
        })
    }

    addFilesToDropzone(files, dropzone) {
        let file = files[0]
        file['preview'] = URL.createObjectURL(files[0]);
        this.setState({ dropzone: files[0] }, () => { });
        this.setState({ imagename: files[0].name, imageSize: (files[0].size / 2048).toFixed(2) });
        this.ConvertToBase64();
    }

    renderUpload() {
        const { classes } = this.props
        const { DeliveryCharges, CartTotal, IsRemovePress, MedKartDiscount, GrossTotal, isRemoveLoading, dropzone, dataSource, isLoadingChoose } = this.state
        const image = dropzone.preview;
        return (
            <Grid style={{ width: '100%' }} className={classes.Contentcardmainwithoutborder} container direction={'row'} justify={'flex-start'} alignItems="center">
                <Grid justify={'space-between'} direction={'row'} style={{ width: '100%' }} container alignItems='center'>
                    <Typography className={classes.qntytxt} >{'Uploaded Prescription'}</Typography>
                    <ButtonBase style={window.innerWidth <= 420 ? { width: '100%', marginTop: '5%' } : { width: '15%' }} disabled={dataSource == null || dataSource.length == 1 ? true : false} onClick={() => this.props.history.push({ pathname: '/patient', state: { Fromlistpage: '0' } })} title={'SELECT PATIENT'}></ButtonBase>
                </Grid>
                <Grid container style={{ width: '100%', marginTop: '10px' }} direction={'row'} alignItems="center"  >
                    {dataSource != null && dataSource.map((item, index) =>
                        <Grid className={item.id != 0 && classes.ContentSelectcardmain}
                            style={{ padding: 0, position: 'relative', height: '200px', width: '200px', margin: '15px' }} container direction={'row'}  >
                            {item.id != 0 && <IconButton onClick={() => this.removeimage(item.id)} style={{ color: '#39A749', top: -25, right: -25, position: 'absolute' }}>
                                {isRemoveLoading && item.id == IsRemovePress ? <CircularProgress size={30} /> : <CancelIcon
                                    style={{ color: 'red', fontSize: 30, backgroundColor: '#fff', borderRadius: 50 }}
                                />}</IconButton>}
                            {item.id == '0' ? this.addDummyPhoto() : <img
                                style={{ height: '100%', width: '100%' }}
                                src={item.imagepath}
                                alt="name"
                            />}
                        </Grid>
                    )}
                </Grid>


            </Grid>
        )
    }
    renderNodataFound() {
        const { classes } = this.props
        return (
            <Grid className={classes.Contentcardmain} style={{ width: '100%', }} container direction={'row'} justify={'center'} alignItems="center">
                <Typography className={classes.qntytxt} >{'Old Prescriptions Not Found'}</Typography>
            </Grid>
        )
    }
    renderOldPrescription() {
        const { classes } = this.props
        const { DeliveryCharges, IsSelectPress, IsSelectPressed, CartTotal, MedKartDiscount, GrossTotal, dropzone, dataSource, OlddataSource, isLoadingChoose } = this.state
        const image = dropzone.preview;
        return (
            <Grid style={{ width: '100%' }} className={classes.Contentcardmainwithoutborder} container direction={'row'} justify={'center'} alignItems="center">
                <Grid style={{ width: '100%', }} container direction={'row'} alignItems="center">
                    <Typography className={classes.qntytxt} >{'Old Prescriptions'}</Typography>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '20px' }} container direction={'row'}>
                    {OlddataSource == 0 ? this.renderNodataFound() : OlddataSource.map((item, index) =>
                        <Grid style={{ height: '200px', width: '200px', margin: '15px' }} container direction={'row'}  >
                            <IconButton edge='end' onClick={() => this.wsAddPrescriptionToNew(item.id, index)} style={{ position: 'absolute', }}>
                                {IsSelectPress == index && IsSelectPressed == 1 ? <AssignmentTurnedInIcon /> : <CheckBoxOutlineBlankIcon />}
                            </IconButton>
                            <img
                                style={{ height: '100%', width: '100%' }}
                                src={item.imagepath}
                                alt="name"
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )

    }
    renderSelectPatientButton() {
        const { classes } = this.props
        const { DeliveryCharges, CartTotal, MedKartDiscount, GrossTotal, dropzone, dataSource, isLoadingChoose } = this.state
        const image = dropzone.preview;
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} alignItems="center">
                <Grid style={{ width: '25%' }} container direction={'row'} justify={'center'} alignItems="center">
                    <ButtonBase disabled={dataSource == null || dataSource.length == 0 ? true : false} onClick={() => this.props.history.push({ pathname: '/patient', state: { Fromlistpage: '0' } })} title={'SELECT PATIENT'}></ButtonBase>
                </Grid>
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })

    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { IsPageLoad } = this.state
        return (
            <Grid style={{ width: '100%' }}>
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Upload Prescription'} history={this.props.history} />
                    {IsPageLoad ? this.LoadingView() :

                        <Grid container direction="row" style={{ width: '100%', }} justify={'center'}>
                            {this.renderUpload()}
                            {this.renderOldPrescription()}

                        </Grid>
                    }
                </main>
                <Footer history={this.props.history} />
            </Grid>
        );
    }
}
export default withStyles(uploadPrescriptionStyle)(withTranslation('translation')(UploadPrescription));
