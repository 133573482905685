/*eslint-disable eqeqeq*/
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SchemeStyle from './SchemeStyle';
import { Select, CssBaseline, Grid, Typography, CircularProgress } from '@material-ui/core';
import Iframe from 'react-iframe';
import Footer from '../../components/Header/Footer';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Title from "../../components/Header/Title";
import CompareIcon from '@material-ui/icons/AutorenewOutlined';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import * as APIController from '../../api/APIController';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUsIcon from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ShoppingCart from '@material-ui/icons/AddShoppingCart';
import PincodeDialog from "../../components/PincodeDialog/PincodeDialog";
import BottomMenu from "../../components/Footer/BottomMenu";
import images from '../../common/Images';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import ReactGA from 'react-ga';
import Helmet from "react-helmet";


class Scheme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: true,
            cart: parseInt(localStorage.getItem('cartvalue')),
            selectItem: -1,
            dataSource: [],
            CheckSum: '',
            Token: '',
            OrderId: '106006',
            Amount: '100.00',
            LoginDialog: false,
            Index: '',
            OpenPincodeDialog: false,
            IsPageLoad: true,
            medicinequantity: []
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsSearchMedicine()
        this.medicinequantity()
        this.WsGetcart()
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }

    medicinequantity() {
        const quantitycount = [];
        let i = 1;
        for (i = 1; i <= 100; i++) {
            quantitycount.push({ "medicinequantity": i });
        }
        this.setState({ medicinequantity: quantitycount })

    }
    wsSearchMedicine() {
        {
            APIController.searchMedicineNew(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), 'Scheme').then((response) => {
                if (response.error == null) {
                    this.setState({ dataSource: response.object })
                }
                else if (response.error.message.message && response.error.message.message.length > 0) {
                }
            })

        }
    }
    BuyNow = (index, quantitydefoult) => {
        this.setState({ Index: index })
        AppConstants.userIdFromLocal() == '' ? this.setState({ LoginDialog: true }) :
            localStorage.getItem('pincode') == '' ?
                this.setState({ OpenPincodeDialog: true }) :
                this.wsAddToCart(index, quantitydefoult)

    }
    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }
    WsGetcart = () => {
        const PinCode = localStorage.getItem('pincode');
        APIController.GetCart('8', AppConstants.userIdFromLocal(),
            '380051', '0')
            .then((response) => {
                if (response.error == null) {

                    localStorage.setItem('cartvalue', JSON.stringify(response.object[0].cartcount))
                    this.setState({ cart: response.object[0].cartcount, IsPageLoad: false })
                }
                else {

                }
            })

    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }
    navigatetoDetail(item) {
        this.props.history.push({
            pathname: '/medicinedetail',
            state: {
                id: item.id,
                compositionid: item.compositionid,
                quantityOption: this.state.medicinequantity,
                medicinequantity: item.quantity
            }
        })
    }

    wsAddToCart = (index, quantitydefoult) => {
        const PinCode = localStorage.getItem('pincode');
        let flatListData = [...this.state.dataSource];
        flatListData[index].quantity = quantitydefoult;
        APIController.AddToCart(
            quantitydefoult != 0 ? 1 : 2,//opraion Id 1 for add 2 for delete
            AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            PinCode,
            1,//get data
            quantitydefoult,
            flatListData[index].id,
            flatListData[index].cartid,//id
        ).then((response) => {
            if (response.error == null) {
                this.setState({ cart: response.object.data.length })
                ToastsStore.warning(response.object.message)

            }
            else if (response.error.message.message && response.error.message.message.length > 0) {
            }
            this.setState({ OpenPincodeDialog: false, })
        })

    }
    renderNote() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container direction="row" style={{ width: '99%' }} >
                <Typography style={{ color: 'red', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans' }}>{'* Offer applicable to min order of Rs. 250/-'}</Typography>
            </Grid>
        )
    }
    render() {
        const { mediumBrowser, IsPageLoad } = this.state;
        const { classes } = this.props;
        return (
            <Grid style={{ width: '100%' }} >
                <Helmet>
                    <title>Know about Medkart Schemes</title>
                    <meta name="description" content="Read about Medkart return policy for medicines purchased online. Know when can you cancel or return the orders for medicines purchased from our online store." />
                    <meta name="keywords" content="Medicine Return Policy | Medkart Return Policy" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Offers'} isBack={false} history={this.props.history} />
                    {IsPageLoad ? this.LoadingView() : <Grid container style={{ width: '100%', alignItems: 'center' }} justify={'center'}  >

                        <Grid container style={mediumBrowser ? { width: '100%', marginTop: '15px' } : { width: '60%', marginLeft: '1%', marginTop: '10px' }} direction="row" justify={'center'} alignItems='center'>

                            {
                                this.state.dataSource != null && this.state.dataSource.map((item, index) =>
                                    <Grid container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        wrap="nowrap"
                                        style={{
                                            backgroundColor: '#fff',
                                            width: '100%',
                                            paddingLeft: '5px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            paddingRight: '15px',
                                            border: '1px solid #39A749',
                                            overflow: 'hidden',
                                        }}>
                                        <Grid container onClick={() => this.navigatetoDetail(item)}
                                            style={window.innerWidth <= 920 ? {
                                                backgroundColor: '#fff',
                                                width: '80%',
                                                margin: '5px', marginTop: '1.5%'
                                            } : {
                                                backgroundColor: '#fff',
                                                width: '80%',
                                                margin: '5px',
                                            }}>

                                            <img src={item.medicinetypename == "TABLET" ?
                                                images.Icon_tablet : item.medicinetypename == "INJECTION" ?
                                                    images.Icon_injection : item.medicinetypename == "SYRUP" ?
                                                        images.Icon_syrup : item.medicinetypename == "DROPS" ?
                                                            images.Icon_drop : item.medicinetypename == "CREAM" ?
                                                                images.Icon_tube : images.Icon_pill}
                                                style={{ height: '5%', width: '5%' }} />
                                            <Grid container direction="row" style={{ cursor: 'pointer', paddingLeft: '5px', width: '90%', backgroundColor: '#fff' }}>
                                                <Typography className={classes.Medicinetitle}>{item.name}</Typography>
                                                {item.companyname != null && item.companyname != '' && <>
                                                    &nbsp; <Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{'( By: '}</Typography>
                                                    &nbsp;<Typography style={{ color: '#BEBEBE' }} className={classes.Medicinetitle}>{item.companyname + ' )'}</Typography>
                                                </>}
                                                <Grid container direction="row">
                                                    <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>Price:&nbsp;</Typography>
                                                    <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>₹ {item.saleprice}&nbsp;&nbsp;</Typography>
                                                    <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans', textDecorationLine: 'line-through', }}>₹ {item.mrp}</Typography>
                                                    <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;&nbsp;Pack of:</Typography>
                                                    <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito Sans' }}>&nbsp;{item.packsize}</Typography>
                                                    {item.category == 'C' ? (
                                                        <Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>
                                                            Not For Online Sale
                                                        </Typography>) : <div />}
                                                    {item.medicineclass == 'DIS' ? (
                                                        <Typography style={{ color: 'red', backgroundColor: '#fff', fontSize: '16px', fontWeight: '600', fontFamily: 'Nunito Sans', }}>
                                                            This Medicine is Discontinued
                                                        </Typography>) : <div />}
                                                </Grid>

                                                {/* <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.medicineclass} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.category} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.quantity} </Typography>
                                                <Typography noWrap style={{ color: '#262628', fontSize: '16px', fontWeight: 'bold', }}> {item.medicinetypename} </Typography> */}
                                            </Grid>
                                        </Grid>

                                        <Grid container
                                            direction="row"
                                            justify='flex-end'
                                            alignItems="center"
                                            style={{
                                                backgroundColor: '#fff',
                                                width: '20%',
                                                margin: '5px'
                                            }}>
                                            {
                                                this.state.selectItem == index || this.state.dataSource[index].quantity != 0 ?

                                                    <div>
                                                        <Select native
                                                            onChange={(text) => this.BuyNow(index, text.target.value)}
                                                            style={{
                                                                width: '68px',
                                                                height: '20%',
                                                                backgroundColor: '#fff',
                                                                border: '1px solid ',
                                                                borderColor: '#39A749',
                                                                // paddingLeft:'15px'
                                                            }} >
                                                            {/* <option value={''} style={{ color: '#fff', backgroundColor: 'green' }}>{item.quantity}</option> */}
                                                            {
                                                                this.state.medicinequantity.map((item1, index) => <option style={{ marginLeft: '20px' }} selected={item1.medicinequantity == item.quantity ? true : false} value={item1.medicinequantity} >
                                                                    &nbsp;&nbsp;{item1.medicinequantity}</option>)
                                                            }
                                                        </Select>
                                                    </div>

                                                    : <div>

                                                        {
                                                            item.medicineclass == 'DIS'
                                                                ? <ButtonBase
                                                                    style={{
                                                                        width: '70px',
                                                                        height: '20%', color: 'red'
                                                                    }}
                                                                    size="small"
                                                                    variant="contained"
                                                                    disabled
                                                                    title='DIS' />
                                                                : <Grid> {item.category == 'A' ||
                                                                    item.category == 'B' ||
                                                                    item.category == 'D' ? (
                                                                    <ButtonBase
                                                                        style={{
                                                                            width: '70px',
                                                                            height: '20%',
                                                                            fontFamily: 'Nunito Sans',
                                                                            fontSize: '14px'
                                                                        }}
                                                                        size="small"
                                                                        variant="contained"
                                                                        // disabled={index == selectItem ? false : true}
                                                                        onClick={() => this.BuyNow(index, '1')}
                                                                        title='+ Add' />
                                                                    // <ArrowForwardIcon disabled={index == selectItem ? false : true} style={{ backgroundColor: '#fff' }} onClick={() => wsAddToCart(item, index + 1)}/>
                                                                ) : item.category == 'F' ||
                                                                    item.category == 'E' ? (
                                                                    <ButtonBase
                                                                        style={{
                                                                            width: '70px',
                                                                            height: '20%',
                                                                            fontFamily: 'Nunito Sans',
                                                                            fontSize: '14px'
                                                                        }}
                                                                        size="small"
                                                                        variant="contained"
                                                                        onClick={() => { this.BuyNow(index, '1'); ToastsStore.warning("Price Needs to be Confirmed.") }}
                                                                        title='+ Add' />
                                                                ) : (
                                                                    <ButtonBase
                                                                        style={{
                                                                            width: '70px',
                                                                            height: '20%', color: 'red',
                                                                            fontFamily: 'Nunito Sans',
                                                                            fontSize: '14px'
                                                                        }}
                                                                        size="small"
                                                                        variant="contained"
                                                                        disabled
                                                                        title='N/A' />
                                                                )
                                                                }
                                                                </Grid>
                                                        }

                                                    </div>
                                            }
                                        </Grid>

                                    </Grid>

                                )
                            }
                            {this.renderNote()}
                        </Grid>

                    </Grid>}
                    <ConfirmDialog
                        history={this.props.history}
                        onClose={() => this.setState({ LoginDialog: false })}
                        open={this.state.LoginDialog}
                        onClick={() => this.setState({ LoginDialog: false })}
                    />
                    <PincodeDialog
                        history={this.props.history}
                        onClose={() => this.setState({ OpenPincodeDialog: false })}
                        open={this.state.OpenPincodeDialog}
                        index={-1}
                        wsAddToCart={this.wsAddToCart.bind(this)}
                        onClick={() => this.setState({ OpenPincodeDialog: false })}
                    />
                </main>
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}

            </Grid>
        );
    }
}
export default withStyles(SchemeStyle)(Scheme);
