import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import whyGenericStyle from './WhyGenericStyle';
import { CssBaseline, Typography, Grid, Paper, FormControlLabel, Divider } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import ReactPlayer from 'react-player'
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import Title from "../../components/Header/Title";
import ReactGA from 'react-ga';
class WhyGeneric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            dataSource: [],
            WhyGenericName: '',
            WhyGenericDescription: '',
            loading: false,
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsWhyGeneric();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    wsWhyGeneric() {
        // this.setState({ loading: true })
        APIController.GetData('1', '9306', '9601260067')
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSource: response.object })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    renderWhyGenericList() {
        const { dataSource, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container direction="row" justify='center' alignItems='center' alignSelf='center'>
                <Grid container style={mediumBrowser ? { width: '100%', marginTop: '2%' } : { width: '98.5%', marginTop: '0.5%' }} direction="row" alignItems='center'>
                    <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}> {'(' + dataSource.length + ')'} Videos Found </Typography>
                </Grid>
                {
                    dataSource.map((item, index) =>
                        <Grid container direction='row'
                            style={mediumBrowser ? { width: '100%', height: '400px', marginTop: '2%' } : { width: '31.8%', height: '400px', marginTop: '1%' }}
                            className={mediumBrowser ? classes.Contentcardmain_WhyGeneric : classes.Contentcardmain}
                        >
                            <ReactPlayer width={'100%'} url={item.description} />
                        </Grid>

                    )
                }
            </Grid>
        )
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Why Generic'} history={this.props.history} />
                    {this.renderWhyGenericList()}
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(whyGenericStyle)(withTranslation('translation')(WhyGeneric));
