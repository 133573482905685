import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const ForgotPasswordStyle = theme => ({
  mainContainer: {
    // marginTop: '150px',
    borderRadius: '10px',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)',
  },
  innerContainerLeft: {
    width: '50%',
  },
  innerContainerRight: {
    width: '50%',
  },
  txtOtp: {
    marginTop: '5%',
    // marginBottom: '5%',
    color: '#262628',
    fontFamily:'Nunito Sans',
    fontSize: '14px',
  },

  txtForgotPwd: {
    marginTop: '5%',
    marginBottom: '5%',
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: '22px',
    marginLeft: window.innerWidth<=920?'':'7%',
  },
  textField: {
    borderRadius: '5px',
    border: '1px solid #f1f1f5',
    color: '#262628',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    height:'50px',
    width:'100%'
  },
  img: {
    width: '100%',
    hight: 450,

  },
});
const combinedStyles = combineStyles(commonStyle, ForgotPasswordStyle);
export default combinedStyles; 
