import React, { Component } from 'react';
import VerifyOTPStyle from './VerifyOTPStyle';
import * as APIController from '../../api/APIController';
import { withStyles } from '@material-ui/core/styles';
import { User } from '../../prototypes/User'
import { appUser } from '../../App'

import { Images } from '../../common';
import {
    CssBaseline,
    Typography,
    Grid,
} from '@material-ui/core';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import OtpInput from 'react-otp-input';
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import LocationIcon from '@material-ui/icons/HighlightOff';
import ReactGA from 'react-ga';
class VerifyOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOTP: '',
            OTP: '',
            loadingVerify: false,
            loadingResend: false,
            mediumBrowser: false,
        };
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsVerifyOTP();
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.OTP == '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOOTP);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsResendOTP() {
        this.setState({ loadingResend: true });
        APIController.resendOtp(this.props.history.location.state.data.id, this.props.history.location.state.data.mobileno).then((response) => {
            this.setState({ loadingResend: false });
            if (response.error != null) {
                ToastsStore.warning(response.object.message);
            }
            else {
                ToastsStore.warning(response.object.message);
            }
        });
    }

    wsVerifyOTP() {
        this.setState({ loadingVerify: true });
        APIController.verifyOtp(this.props.history.location.state.data.id, this.props.history.location.state.data.mobileno, this.state.OTP).then((response) => {
            if (response.object.status == 1) {
                let user = appUser()
                user.id = this.props.history.location.state.data.id
                user.mobileno = this.props.history.location.state.data.mobileno
                user.fullname = this.props.history.location.state.data.fullname
                user.writeToLocalStorage()
                this.setState({ loadingVerify: false });
                this.props.history.push(AppConstants.SCREENS.HOMEPAGE);
            }
            else if (response.object.status == 0) {
                this.setState({ loadingVerify: false });
                ToastsStore.warning(response.object.message);

            }
            else {
                this.setState({ loadingVerify: false });
                ToastsStore.warning(response.error.message);
            }


        });
    }


    renderForm() {
        const { loadingResend, loadingVerify, OTP, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={mediumBrowser ? { width: '100%' } : { width: '100%', marginLeft: '7%', marginBottom: '30%' }} alignItems='center' justify={mediumBrowser ? 'center' : 'flex-start'}>
                    <Grid container alignItems='center' style={mediumBrowser ? { width: '80%', height: '7.91%' } : { width: '75.5%', height: '7.91%', }}>
                        <Typography className={classes.txtEnterOTP}>OTP sent to: {this.props.history.location.state.data.mobileno}</Typography>
                        <OtpInput
                            onChange={(text) => this.setState({ OTP: text })}
                            value={OTP}
                            inputStyle={{ width: '100%', height: '100%', margin: '10%', borderRadius: '15%', color: '#262628', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}
                            isInputNum={false}
                            focusStyle={{ color: '#39A749' }}
                            shouldAutoFocus={true}
                        />
                    </Grid>
                    <Grid container style={mediumBrowser ? { width: '80%', marginTop: '10%', marginBottom: '5%', } : { marginTop: '10%', marginBottom: '5%', width: '75.5%' }} direction='row' justify='space-between' alignItems='center'>
                        <ButtonBase type="submit" onClick={() => this.wsVerifyOTP()} isLoading={loadingVerify} title={'Verify OTP'}
                            style={{ width: '60%', }} />
                        <ButtonBase onClick={() => this.wsResendOTP()} title={'Resend'} isLoading={loadingResend}
                            style={{ width: '35%', border: '1px solid #E1E4EB', backgroundColor: '#FFFFFF', color: '#39A749', }} />
                    </Grid>
                </Grid>
            </form>
        );
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main} >
                    <Grid container style={window.innerWidth <= 920 ? { width: '100%', marginTop: '10%' } : { width: '100%', padding: '35px 12px 5px 12px' }} direction={'row'} alignItems='center' ></Grid>
                    <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%', marginTop: '10%' } : { width: '55%', marginTop: '2%' }} >
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', }} justify='center' alignItems='center'>
                                <Grid container justify={mediumBrowser ? 'center' : 'flex-start'} style={{ width: '100%', }} >
                                    <Typography className={classes.txtVerifyOTP}>Verify Account</Typography>
                                </Grid>
                                <Grid container justify={mediumBrowser ? 'center' : 'flex-start'} style={{ width: '100%', }} >
                                    {this.renderForm()}
                                </Grid>
                            </Grid>
                            {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end' >
                                <img src={Images.FormBackground} className={classes.img} alt="" />
                                <LocationIcon style={{ color: '#39A749', position: 'absolute', }} onClick={() => { this.props.history.push(AppConstants.SCREENS.HOMEPAGE) }} />
                            </Grid>}
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(VerifyOTPStyle)(VerifyOTP);