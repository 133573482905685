import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import WhyMedkartStyle from './WhyMedkartStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Grid, CssBaseline, Typography, Button, Toolbar } from '@material-ui/core';
import { Images } from '../../common';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import Compare from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart1 from "../WhyMedkart/WhyMedkart";
import WhyMedkartIcon from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import Title from "../../components/Header/Title";
import WebsiteHomePage from "../../containers/WebsiteHomePage/WebsiteHomePage";
import BottomMenu from "../../components/Footer/BottomMenu";
class WhyMedkart extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleStep(step) {
        this.setState({ open: step })
    }

    renderForm() {
        const { classes } = this.props;
        return (
            <main className={classes.main} >
                <BottomMenu history={this.props.history} />
            </main>
        );
    }
    medicineDetailFromsearch(data) {}
    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state
        return (
            <React.Fragment>
                <CssBaseline />
                <Grid style={{ width: '100%' }}>
                    <CssBaseline />
                    {this.props.fromHomepage == true ? '' : <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                    <Title isBack={false} title={'Why Medkart'} history={this.props.history} />
                    <main className={classes.main}>
                        {/* <Grid container style={{ width: '100%', height: 40,}} direction='row' alignItems='center'>
                            <Typography className={classes.Screentitle}>WHY</Typography>&nbsp;&nbsp;
                        <Typography className={classes.titletextGreen}>MEDKART</Typography>
                        </Grid> */}
                        <WebsiteHomePage />
                        {window.innerWidth <= 920 ? <Grid/>:this.renderForm()}
                    </main>
                    {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                </Grid>

            </React.Fragment>
        );
    }
}



export default withStyles(WhyMedkartStyle)(WhyMedkart);
