import { makeStyles } from '@material-ui/core/styles';

const FooterStyle = makeStyles(theme => ({
    Downloadtxt: {
        color:'#262628',
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
    },
    IconStyle: {
        color: '#262628',
        fontSize: '24px',
        position:'fixed'
    }
}));

export default FooterStyle;
