import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const SignupStyle = theme => ({
  mainContainer: {
    // width: '50%',
    // marginTop: '100px',
    // marginBottom: '8%',
    borderRadius: '10px',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)',
  },
  textField: {
    borderRadius: '5px',
    border: '1px solid #f1f1f5',
    color: '#262628',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    height:'50px',
    width:'100%'
  },
  innerContainerRight: {
    width: '30%',
  },
  innerContainerLeft: {
    width: '50%',
  },
  txtSignup: {
    marginTop: '5%',
    marginBottom: '5%',
    fontFamily: 'Nunito Sans', fontWeight: 700,
    fontSize: '22px',
    // marginLeft: '7%',
  },
  img: {
    width: '350px',
    height: '450px',
  },
  AvatarImg: {
    height: '120px',
    width: '120px',
    borderRadius: '75px',
    marginBottom:'15%'
    // height: '100%',
    // width: '100%',
    // borderRadius: 100 / 2,
    // marginBottom:'15%'
  },
});
const combinedStyles = combineStyles(commonStyle, SignupStyle);
export default combinedStyles;
