import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContactMedkartStyle from './ContactMedkartStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import { Grid, CssBaseline, Typography, CircularProgress, Avatar, } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { Images } from '../../common';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import AdjustIcon from '@material-ui/icons/Adjust';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import Title from "../../components/Header/Title";
// import {ReCAPTCHA} from "react-google-recaptcha-v3";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

const recaptchaRef = React.createRef();

class ContactMedkart extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
            Name: '',
            Location: '',
            Email: '',
            Phone: '',
            Message: '',
            ReCAPTCHA: '',
            isLoading: false,
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    wsContactUs() {
        this.setState({ isLoading: true })
        APIController.ContactMedkart(this.state.Name, this.state.Location, this.state.Email, this.state.Phone, this.state.Message, ).then((response) => {
            if (response.error == null) {
                this.setState({
                    isLoading: false,
                    Name: '',
                    Location: '',
                    Email: '',
                    Phone: '',
                    Message: '',
                    ReCAPTCHA: '',
                })
                ToastsStore.success(response.object.message);
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
        })

    }
    handleStep(step) {
        this.setState({ open: step })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit() {
        if (this.validateForm()) {
            this.wsContactUs()
            // recaptchaRef.current.reset();
        }
    }
    // onChange() {
    //     this.setState({ ReCAPTCHA: true })
    // }

    validateForm() {
        var IsValid = 0;
        if (this.state.Name.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoName);
        }
        else if (this.state.Location.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoLocation);
        }
        else if (this.state.Email.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoEmail);
        }
        else if (!this.state.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDEMAILADDRESS);
        }
        else if (this.state.Phone.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoPhone);
        }
        else if (this.state.Phone.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOTPHONE);
        }
        else if (this.state.ReCAPTCHA.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.VALIDCAPTCHA);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    rendertitle() {
        const { mediumBrowser } = this.state
        const { classes } = this.props;
        return (
            <Grid container style={{ width: '100%' }} alignItems="center" justify={'center'} direction={'row'}>
                <Typography className={classes.Screentitle} >{'WANTS TO '}</Typography>&nbsp;&nbsp;&nbsp;
                <Typography className={classes.titletextGreen} >{' CONTACT US ?'}</Typography>
                {/* <Typography className={classes.titletextGreen} >{'Send us message'}</Typography> */}
            </Grid>
        )
    }

    renderForm() {
        const { showPassword, loading, isLoading, Name, mediumBrowser, Location, Email, Message, Phone } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate >
                {/* <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', height: '97%',marginTop:'55px', padding: '2%', }}> */}
                <Grid container style={{ width: '100%', marginTop: 20 }} justify="space-between" alignItems="center" direction={'row'}>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            value={Name}
                            name="Name"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            label="Name *"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Location"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Location}
                            label="Location *"
                            onChange={this.handleChange}

                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Email"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Email}
                            label="Email *"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} container className={classes.textFieldContent}>
                        <FilledTextField
                            rows={1}
                            name="Phone"
                            InputProps={{
                                className: classes.textField,
                                disableUnderline: true
                            }}
                            value={Phone}
                            label="Phone *"
                            inputProps={{
                                maxLength: 10
                            }}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" direction={'row'} style={{ width: '100%' }} container className={classes.textFieldContent}>
                        <FilledTextField
                            multiline
                            rows={3}
                            className={classes.textField1}
                            name="Message"
                            InputProps={{
                                className: classes.textField1,
                                disableUnderline: true
                            }}
                            value={Message}
                            label="Message"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid alignItems="center" style={{ width: '100%', postion: 'relative' }} direction={'row'} container className={classes.textFieldContent}>
                        <GoogleReCaptchaProvider reCaptchaKey="6LdR-6sZAAAAAHdPSumoT2053wjjds4c3vYXT4Hs" >
                            <GoogleReCaptcha onVerify={token =>
                                this.setState({ ReCAPTCHA: token })
                            } />
                        </GoogleReCaptchaProvider>
                    </Grid>
                    <Grid alignItems="center" direction={'row'} style={window.innerWidth <= 420 ? { width: '60%', marginTop: '10px', marginBottom: '10px' } : window.innerWidth <= 920 ? { width: '40%', marginTop: '10px', marginBottom: '10px' } : { width: '30%', marginTop: '10px', marginBottom: '10px' }} container >
                        <ButtonBase isLoading={isLoading} onClick={() => { this.handleSubmit() }} title={'Submit'} />
                    </Grid>
                </Grid>
                {/* </Card> */}
            </form>
        );
    }
    renderMapView() {
        const { dataSource, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={mediumBrowser ? { width: '100%', height: 500, position: 'relative', marginTop: '5%' } : { width: '100%', height: window.innerHeight - (0.35 * window.innerHeight), position: 'relative' }} direction={'row'}>
                <Map
                    google={this.props.google}
                    zoom={12}
                    initialCenter={{ lat: 23.026207, lng: 72.5472083 }}
                    style={{ width: mediumBrowser ? '100%' : '99%' }}
                >
                    <Marker
                        // style={{ backgroundColor: 'green' }}
                        icon={{ url: "https://medkart.in/assets/image/icons/map-marker.png" }}
                        key={1}
                        id={1}
                        position={{
                            lat:23.02043,
                            lng: 72.55888
                        }}
                        // onClick={this.onMarkerClick}
                    >
                    </Marker>
                    {/* {this.displayMarkers()}
                    {this.displayInfoWindow()} */}

                </Map>
            </Grid>
        )
    }
    renderMainContent() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} justify={'center'} >
                {/* {this.rendertitle()} */}
                <Grid style={{ width: '95%' }} alignItems="flex-start" justify={'space-between'} direction={'row'} container>
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '40%' }} alignItems="center" direction={'row'}>
                        {this.renderWhyMedkart()}
                    </Grid>
                    <Grid container style={mediumBrowser ? { width: '100%' } : { width: '57%' }} alignItems="flex-start" direction={'row'}>
                        {this.renderForm()}
                    </Grid>

                </Grid>
            </Grid>
        )
    }
    renderWhyMedkart() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={window.innerWidth <= 420 ? { width: '100%', marginTop: 30, } : { width: '100%', marginTop: 30, marginLeft: '50px' }} container direction={'row'} alignItems='flex-start'>
                {/* <Grid style={{ width: '100%', marginBottom: '10px', }} alignItems='center' container direction={'row'} >
                    <Typography className={classes.Screentitle} >{'WHY'}</Typography>&nbsp;&nbsp;&nbsp
                    <Typography className={classes.titletextGreen} >{'MEDKART?'}</Typography>
                </Grid> */}
                {/* <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', }}> */}
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '100%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <Avatar style={{ backgroundColor: '#39A749', border: '1px solid #000', height: 50, width: 50, alignItems: 'center', justify: 'center' }}>
                    <LocationOnIcon/>
                    </Avatar>&nbsp;&nbsp;&nbsp;
                    <Grid >
                        <Typography className={classes.maintxt} >{'Address'}</Typography>
                        <Typography className={classes.subtxt} >{'GF/1 , SANGINI COMPLEX ,'}</Typography>
                        <Typography className={classes.subtxt} >{'NEAR DOCTOR HOUSE ABOVE ,'}</Typography>
                        <Typography className={classes.subtxt} >{'PARIMAL UNDERPASS , ELISS BRIDGE ,'}</Typography>
                        <Typography className={classes.subtxt} >{'AHMEDABAD ,GJ 380006'}</Typography>
                    </Grid>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <Avatar style={{ backgroundColor: '#39A749', border: '1px solid #000', height: 50, width: 50, alignItems: 'center', justify: 'center' }}>
                    <PhoneAndroidIcon/>
                    </Avatar>&nbsp;&nbsp;&nbsp;
                    <Grid >
                        <Typography className={classes.maintxt} >{'Phone'}</Typography>
                        <Typography className={classes.subtxt} >{'+91 7949137600'}</Typography>
                        <Typography className={classes.subtxt} >{'+91 7949137600'}</Typography>
                    </Grid>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <Avatar style={{ backgroundColor: '#39A749', border: '1px solid #000', height: 50, width: 50, alignItems: 'center', justify: 'center' }}>
                    <EmailIcon/>
                   </Avatar>&nbsp;&nbsp;&nbsp;
                    <Grid >
                        <Typography className={classes.maintxt} >{'Email'}</Typography>
                        <Typography className={classes.subtxt} >{'info@medkart.in'}</Typography>
                    </Grid>
                </Grid>



                {/* <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Robust Systems & Support'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Largest Range of Generic Medicines'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Trusted Brand'}</Typography>
                </Grid>
                <Grid style={window.innerWidth <= 420 ? { width: '100%', padding: '1px', marginTop: '10px', marginBottom: '10px' } : { width: '95%', padding: '10px', borderRadius: 8 }} container direction={'row'} alignItems='flex-start'>
                    <AdjustIcon className={classes.subtxt} />&nbsp;
                    <Typography className={classes.subtxt} >{'Business with a Social Impact'}</Typography>
                </Grid> */}
                {/* </Card> */}
            </Grid>
        )
    }
    renderIphoneimg() {
        const { classes } = this.props
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} justify={'center'}>
                <img src={Images.Iphone}
                    className={classes.img} alt="" />
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { cart, isLoading } = this.state
        return (
            <Grid style={{ width: '100%' }}>
                <Helmet>
                    <title>Contact us for Medkart </title>
                    <meta name="description" content="Open generic medicine store with medkart franchisee at low investment with high return business. Start selling generic medicines in your Medkart store." />
                    <meta name="keywords" content="Medkart Franchisee | Low Investment High Return Business | Open Generic Medicine Store" />
                </Helmet>
                <CssBaseline />
                {this.props.fromHomepage == true ? '' : <MedkartHeader dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    {this.props.fromHomepage ? '' : <Title isBack={false} title={'Contact Us '} history={this.props.history} />}
                    {this.renderMainContent()}
                    {this.renderMapView()}
                    {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />}
                </main>
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
            </Grid>

        );
    }
} const LoadingContainer = (props) => <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
    <CircularProgress />
</Grid>;
// export default withStyles(ContactMedkartStyle)(ContactMedkart);
export default GoogleApiWrapper({ apiKey: 'AIzaSyCvfqmZbO-O0S8IALP-qT9VfhK_Oq75KVw', LoadingContainer: LoadingContainer })(withStyles(ContactMedkartStyle)(ContactMedkart));
