import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const OrderConfirmationStyle = theme => ({
    mainContainerLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    mainContainerRight: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    txtOrderSuccess: {
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.5,
        color: '#b5bbc1',
        paddingBottom: '15px',
    },
    txtPaymentSuccess: {
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.5,
        color: '#39A749',
        paddingBottom: '15px',
    },
    txtOrderSuccessMsg: {
        width: '50%',
        textAlign: 'center',
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: 14,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#d60d6e',
    },
    txtDeliveryDate: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: 14,
        letterSpacing: -0.5,
        color: '#000000',
        lineHeight: 1.8,
    },
    btnCopyOrder: {
        width: '50%',
        borderRadius: '0px 0px 0px 5px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    },
    btnDetails: {
        width: '50%',
        borderRadius: '0px 0px 5px 0px',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: 0.1,
        fontWeight: 500,
        boxShadow: 'none',
        border: '1px solid ',
        borderColor: '#FFFFFF',
    }
});
const combinedStyles = combineStyles(commonStyle, OrderConfirmationStyle);
export default combinedStyles; 
