import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const MedicineDetailStyle = theme => ({
      ContentSelectcardmainMedicineDetails: {
        width:'98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
      },
      Medicinetitle: {
        fontFamily: 'Nunito Sans',
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.textColor,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '80%',
        display: 'block'
    },
      ContentcardmainMedicineDetails: {
        width:'98%',
        margin: '10px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
      },
     

});
const combinedStyles = combineStyles(commonStyle, MedicineDetailStyle);
export default combinedStyles; 
