import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const ContactMedkartStyle = theme => ({
    Screentitle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS35,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.primary.main,
    },
    img: {
        width: '100%',
        height: 550,
    },
    textFieldContent: {
        width: '48%',
        marginTop: '20px'
    },
    subtxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: AppConstants.FONTSIZE.FS14,
        lineHeight: 1.5,
        letterSpacing: -0.5,
        // marginLeft: '15px',
        textAlign:'left',
        color: '#888',
    },
    maintxt: {
        fontFamily: 'Nunito Sans',
        fontWeight: 500,
        fontSize: AppConstants.FONTSIZE.FS17,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        // marginLeft: '15px',
        textAlign:'left',
        color: '#000',
    },
    titletextGreen: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: 'normal',
        color: '#262628',
    },
    textField: {
        backgroundColor:'#f1f1f1',
        borderRadius: 4,
        border: '1px solid #f1f1f5',
        color: '#32373c',
        fontFamily:'Nunito Sans' ,
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: 600,
        height:'62px',
        lineHeight: 1.57

    },
    textField1: {
        backgroundColor:'#f1f1f1',
        borderRadius: 4,
        border: '1px solid #f1f1f5',
        color: '#32373c',
        fontFamily:'Nunito Sans' ,
        fontSize: AppConstants.FONTSIZE.FS16,
        fontWeight: 600,
        height:'125px',
        lineHeight: 1.57

    },
});
const combinedStyles = combineStyles(commonStyle, ContactMedkartStyle);
export default combinedStyles; 
