
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import JoinUsStyle from './JoinUsStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import { Grid, CssBaseline, Typography, Card } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Footer from "../../components/Header/Footer";
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { Images } from '../../common';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import AdjustIcon from '@material-ui/icons/Adjust';
import Title from "../../components/Header/Title";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordians from './Accordians';
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

const recaptchaRef = React.createRef();
function createData(name, calories,) {
    return { name, calories, };
}

const rows = [
    createData('1', 'Accountant'),
    createData('2', 'Accountant'),
    createData('3', 'Accountant'),
    createData('4', 'Accountant'),
];

class JoinUs extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            mediumBrowser: false,
            Name: '',
            Location: '',
            Email: '',
            Phone: '',
            Message: '',
            ReCAPTCHA: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    wsContactUs() {
        this.setState({ isLoading: true })
        APIController.ContactMedkaer(this.state.Name, this.state.Location, this.state.Email, this.state.Phone, this.state.Message, 'Website').then((response) => {
            if (response.error == null) {
                this.setState({
                    isLoading: false,
                    Name: '',
                    Location: '',
                    Email: '',
                    Phone: '',
                    Message: '',
                    ReCAPTCHA: false,
                })
                ToastsStore.success(response.object.message);
            }
            else if (response.error.message && response.error.message.length > 0) {
                ToastsStore.warning(response.error.message)
            }
        })

    }
    handleStep(step) {
        this.setState({ open: step })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit() {
        if (this.validateForm()) {
            this.wsContactUs()
            recaptchaRef.current.reset();
        }
    }
    onChange() {
        this.setState({ ReCAPTCHA: true })
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.Name.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoName);
        }
        else if (this.state.Location.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoLocation);
        }
        else if (this.state.Email.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoEmail);
        }
        else if (!this.state.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDEMAILADDRESS);
        }
        else if (this.state.Phone.trim() === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NoPhone);
        }
        else if (this.state.Phone.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOTPHONE);
        }
        else if (this.state.ReCAPTCHA == false) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.VALIDCAPTCHA);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    renderForm() {
        const { showPassword, loading, isLoading, Name, Location, Email, Message, Phone, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            // <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', marginTop: '1%', height: '97%', padding: '2%', }}>
            <Grid container style={{ width: '100%', }} justify="flex-start" alignItems="center" direction={'row'}>
                <Grid style={mediumBrowser ? { width: '100%', marginTop: '10px', } : { width: '100%', postion: 'absolute' }} container direction={'row'} >
                    <Typography className={classes.titletextGreen} >{''}</Typography>
                </Grid>
                <Grid container style={{ backgroundColor: '#39A749', padding: 50, width: '100%', marginBottom: '2%' }} direction='row' alignItems='flex-start'>
                    <Grid container style={{ width: '100%' }} direction='column'>
                        <Typography className={classes.JoinTxt}>JOIN THE REVOLUTION!</Typography>
                        <Typography className={classes.mailtxt}>Drop in a mail to&nbsp;
                      <a href="mailto:careers@medkart.in" target="_blank" className={classes.mailtxtLink}>careers@medkart.in</a>
                            &nbsp; with your cv attached</Typography>
                    </Grid>

                    <ButtonBase title='Apply Now' variant="contained" style={mediumBrowser ? {
                        fontWeight: '600', fontSize: '16px', fontFamily: 'Nunito Sans',
                        backgroundColor: '#FFFF', color: '#000', width: '70%', marginTop: '5%', marginBottom: '3%',
                    } : {
                            fontWeight: '600', fontSize: '16px', fontFamily: 'Nunito Sans',
                            backgroundColor: '#FFFF', color: '#000', width: '35%', marginTop: '5%', marginBottom: '3%',
                        }} href="mailto:careers@medkart.in" target="_blank" >
                    </ButtonBase>
                </Grid>
            </Grid>
            //</Card>



        );
    }
    renderMainContent() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={{ width: '100%', marginTop: 10 }} container direction={'row'} justify={'center'}>
                {/* {this.rendertitle()} */}
                <Grid style={{ width: '95%' }} justify={'space-between'} direction={'row'} container>
                    <Grid container style={mediumBrowser ? { width: '100%', } : { width: '48%', }} direction={'row'}>
                        {this.renderWhyMedkart()}
                    </Grid>
                    <Grid container style={mediumBrowser ? { width: '100%', marginTop: '13px', marginBottom: '10px' } : { width: '48%', position: 'fixed', right: '2%', marginTop: '43px' }} direction={'row'}>
                        {this.renderForm()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderWhyMedkart() {
        const { classes } = this.props
        const { mediumBrowser } = this.state
        return (
            <Grid style={{ width: '100%' }} container direction={'row'}>
                <Grid style={{ width: '100%', }} container direction={'row'} >
                    <Typography className={classes.titletextGreen} >{'Open Positions'}</Typography>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '10px' }}>
                    <Accordians></Accordians>
                </Grid>
            </Grid>
        )
    }
    renderIphoneimg() {
        const { classes } = this.props
        return (
            <Grid style={{ width: '100%' }} container direction={'row'} justify={'center'}>
                <img src={Images.Iphone}
                    className={classes.img} alt="" />
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props
        const { cart, isLoading, } = this.state
        return (
            <React.Fragment>
                <Helmet>
                    <title>Join medkart for a career in pharma field. Checkout openings</title>
                    <meta name="description" content="Openings at Medart Career and join the revolution of promoting generic medicines in India. Join us to up your career game in pharma field" />
                    <meta name="keywords" content="Join Medkart | Medkart Career" />
                </Helmet>
                <CssBaseline />
                <Grid style={{ width: '100%' }}>
                    {this.props.fromHomepage == true ? '' : <MedkartHeader dataFromsearch={this.dataFromsearch.bind(this)} medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                    <ToastsContainer store={ToastsStore} />
                    <main className={classes.main}>
                        <Title isBack={false} title={'CAREER'} history={this.props.history} />
                        {this.renderMainContent()}
                        {/* {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />} */}
                    </main>
                    {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                </Grid>
            </React.Fragment>
        );
    }
}
export default withStyles(JoinUsStyle)(JoinUs);

