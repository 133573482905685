import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import notificationsStyle from './NotificationsStyle';

import { CssBaseline, Typography, Grid, CircularProgress } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';

import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';

import Title from "../../components/Header/Title";
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import ReactGA from 'react-ga';

class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            dataSource: [],
            IsLoading: true,
        };
    }

    wsNotifications() {
        APIController.GetNotifications(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            // APIController.GetNotifications('8445', '7698498403')
            .then((response) => {
                if (response.error == null) {
                    // this.setState({  })
                    this.setState({ dataSource: response.object, IsLoading: false })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message)
                }
            })
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    renderNotificationsList() {
        const { dataSource,mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container>
                {dataSource == 0 ?
                    <Grid container style={{ width: '100%', height: '50px', }} direction="row" justify='center' alignItems='center'>
                        <Typography style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> No Notification Found </Typography>
                    </Grid> : <Grid container justify='center' alignItems='center' alignSelf='center'>
                        <Grid container style={mediumBrowser?{ width: '100%',marginTop:'2%' }:{ width: '98.5%',marginTop:'0.5%' }} direction="row" alignItems='center'>
                            <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700',fontFamily: 'Nunito Sans', }}>{'(' + dataSource.length + ')'} Notifications Found </Typography>
                        </Grid>
                        {
                            dataSource.map((item, index) =>
                                <Grid container direction='column' className={classes.Contentcardmain_Notification}
                                    style={mediumBrowser?{ width: '100%',marginTop:'2%' }:{ width: '98.5%',marginTop:'1%' }}
                                    justify='center'
                                    onClick={() =>
                                        item.notificationtypeid == 6 ?
                                            this.props.history.push({
                                                pathname: '/myorder',
                                                state: { orderIDNoti: item.orderid }
                                            }) : null
                                    }>
                                    <Typography style={{ color: '#39A749', fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito Sans', }}> {item.notificationtype} </Typography>
                                    <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> {item.description}, </Typography>
                                    <Typography style={{ fontSize: '14px', fontFamily: 'Nunito Sans', fontWeight: 400, color: '#B0B4BF', }}> {item.createdate} </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                }
            </Grid>
        )
    }
    handleStep(step) {
        this.setState({ open: step })
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsNotifications();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { IsLoading } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)}
                    browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Notifications'} history={this.props.history} />
                    {IsLoading ? this.LoadingView() :
                        <Grid container >
                            {this.renderNotificationsList()}
                        </Grid>
                    }
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(notificationsStyle)(withTranslation('translation')(Notifications));