
import React, { Component } from 'react'
import { Grid, Typography, IconButton, TextField, CircularProgress, Card } from '@material-ui/core';
import cartStyle from './CartStyle';
import { withStyles } from '@material-ui/core/styles';
import images from '../../common/Images';
import { ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import RemoveIcon from '@material-ui/icons/Close';
import * as APIController from '../../api/APIController';

class Promocode extends Component {
    constructor() {
        super()
        this.state = {
            promoCodeLoding: false,
            promoDeleteLoading: false,
            PromocodeIsValid: false,
            Iscodeloading: true,
            PromocodeModal: false,
            PressedIndex: -1,
            Promocode: '',
            Isdisabled: true,
            mediumBrowser: false,
            PromoCodeflatListData: [],
        }
    }
    componentDidMount() {
        this.wsGetCoupons(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(),)
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 750 })
    }
    handleChangePromocode = (e) => {
        if (e.target.value != '') {
            this.setState({ [e.target.name]: e.target.value, Isdisabled: false });
        }
        else {
            this.setState({ [e.target.name]: e.target.value, Isdisabled: true });
        }

    }

    wsGetCoupons(userid, mobileno) {
        this.setState({ Iscodeloading: true });
        APIController.GetCoupons(userid, mobileno)
            .then((responseJson) => {
                console.log('responseJson:', responseJson.object.data)
                this.setState({ Iscodeloading: false });
                if (responseJson.object.status == '1') {
                    this.setState({ PromoCodeflatListData: responseJson.object.data, });
                } else {
                    this.setState({ Promocode: '', Isdisabled: true });
                    responseJson.object.message && ToastsStore.warning(responseJson.object.message)
                }
            })
    }
    handlePromoCode(codeID, code, index) {
        this.setState({ promoCodeLoding: true, PressedIndex: index, Isdisabled: true });
        this.props.WsPromocodeoffer(codeID, code)
    }

    // WsPromocodeoffer() {
    //     this.setState({ promoCodeLoding: true });
    //     APIController.ApplyCouponCode(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.Promocode)
    //         .then((responseJson) => {
    //             console.log('responseJson:', responseJson)
    //             this.setState({ promoCodeLoding: false });
    //             if (responseJson.object.status == '1') {
    //                 this.wsGetCartInner(responseJson.object.data);
    //                 this.setState({ PromocodeIsValid: true, coupanCodemessage: responseJson.object.message, Isdisabled: true });
    //                 ToastsStore.warning(responseJson.object.message)
    //             } else {
    //                 this.setState({ Promocode: '', Isdisabled: true });
    //                 ToastsStore.warning(responseJson.object.message)
    //             }
    //         })
    // }
    render() {
        const { classes } = this.props
        const { mediumBrowser, PressedIndex } = this.state
        return (
            <Grid container justify='center' alignItems='center' style={mediumBrowser ? { padding: '20px', width: '100%', minWidth: '100%' } : { padding: '20px', width: '100%', minWidth: '400px' }}>
                <Grid container justify={'space-between'} style={{ width: '100%', marginBottom: '15px' }} direction="row" alignItems='center' >
                    <Typography className={classes.txtPromocodetitle} >Apply Coupon</Typography>
                    <IconButton onClick={() => this.props.onClose()}
                        style={{ borderRadius: 0, padding: "5px", position: 'absolute', right: 10, top: 10 }}>
                        <RemoveIcon style={{ color: '#000' }} />
                    </IconButton>
                </Grid>
                {/* <Card elevation={0} raised={true} justify={'center'} direction="row" alignItems={'center'}
                    style={{ margin: '10px', width: '100%', padding: 4, border: '2px solid #d4d4d9' }}  >
                    <Grid container justify={'space-between'} style={{ width: '100%' }} direction="row" alignItems='center' >
                        <Grid container direction="row" alignItems='center' style={{ width: '70%', paddingLeft: '2%' }} >
                            <TextField
                                defaultValue={this.state.Promocode}
                                InputProps={{ maxLength: 20, disableUnderline: true, }}
                                placeholder='Enter Promocode'
                                rows={1}
                                editable={this.state.promoCodeLoding == true || this.state.PromocodeIsValid == true ? false : true}
                                style={{ fontWeight: 'bold' }}
                                value={(this.state.Promocode).toUpperCase()}
                                name="Promocode"
                                onChange={(e) => this.handleChangePromocode(e)}
                            />
                        </Grid>
                        {this.state.promoCodeLoding ? <CircularProgress size={22} style={{ color: '#39A749' }} /> :
                            <IconButton onClick={() => this.WsPromocodeoffer()} disabled={this.state.Isdisabled == true ? true : false}
                                style={{ borderRadius: 0, padding: "5px" }}>
                                <Typography style={{ textTransform: 'uppercase' }} className={this.state.Isdisabled ? classes.disableoldprice : classes.oldprice} > {'Apply'} </Typography>
                            </IconButton>
                        }
                    </Grid>
                </Card> */}
                <Grid container justify={'flex-start'} style={{ width: '100%' }} direction="row" alignItems='center' >
                    {/* <Typography className={classes.txtPromocodetitle}>COUPONS</Typography> */}
                </Grid>
                {this.state.Iscodeloading ? <CircularProgress /> :
                    this.state.PromoCodeflatListData.length == 0 ?
                        <Card elevation={0} raised={true} justify={'center'} direction="row" alignItems={'center'}
                            style={{ margin: '10px', width: '100%', padding: 4, border: '2px solid #d4d4d9' }}  >
                            <Typography style={{ textAlign: 'center' }} className={classes.txtPromocodetitle}>! Sorry No Coupons Available</Typography>
                        </Card> :
                        this.state.PromoCodeflatListData.map((item, index) => {
                            return (
                                <Card raised={true} alignItems={'center'} justify={'center'} direction="row" style={{ position: 'relative', width: '100%', padding: ' 10px', marginBottom: '10px' }} >
                                    <Grid container direction={'row'} justify='flex-end'>
                                        <Grid justify={'flex-end'} style={{ paddingRight: '5px', position: 'absolute', width: '150px', top: 0, marginBottom: 5, right: 0, borderTopRightRadius: '5px', borderBottomLeftRadius: '5px', border: '1px solid #fafafa', backgroundColor: '#f2dede' }} container direction={'row'} >
                                            <Typography className={classes.txtExpirepromo} >{'Expire After ' + item.ExpireAfter + ' Days'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ marginTop: '5px' }} container direction={'row'} justify={'space-between'} alignItems={'flex-start'}>
                                        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} style={{ width: '20%', cursor: 'pointer' }}>
                                            <img src={images.coupon} style={mediumBrowser ? { height: '30px', width: '40px', border: '0px solid #000' } : { height: '60px', width: '80px', border: '0px solid #000' }} />
                                        </Grid>
                                        <Grid container direction={'column'} alignItems={'flex-start'} justify='flex-start' style={{ width: '70%', cursor: 'pointer' }}>
                                            <Typography className={classes.txtPromocodetitle}>{item.Name}</Typography>
                                            <Typography className={classes.txtPromocodeContent}>{item.Description}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '5px', border: '1px dashed #000', margin: '10px 0px 0px 0px' }} direction={'row'} justify={'space-between'} alignItems={'center'}>
                                        <Typography className={classes.txtPromocodetitle} > {'Code : ' + item.Code} </Typography>
                                        {this.state.promoCodeLoding && index == PressedIndex ? <CircularProgress size={22} style={{ color: '#39A749' }} /> :
                                            <IconButton onClick={() => this.handlePromoCode(item.ID, item.Code, index)}
                                                style={{ borderRadius: 0, padding: "5px" }}>
                                                <Typography className={classes.newprice} > {'Apply'} </Typography>
                                            </IconButton>}
                                    </Grid>
                                </Card>
                            )
                        })
                }

            </Grid>
        )
    }
}
export default withStyles(cartStyle)(Promocode);