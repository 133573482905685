import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ForgotPasswordStyle from './ForgotPasswordStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import { CssBaseline, Typography, Grid, Container } from '@material-ui/core';
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import FilledTextFieldUpdated from '../../components/FilledTextFieldUpdated/FilledTextFieldUpdated';
import LocationIcon from '@material-ui/icons/HighlightOff';
import ReactGA from 'react-ga';
        
class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            MobileNo: '',
            loadingReset: false,
            mediumBrowser: false,
        };
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsForgotPassword();
        }

    }
    validateForm() {
        var IsValid = 0;
        if (this.state.MobileNo === '') {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.NOMOBILE);
        }
        else if (!this.state.MobileNo.match(/^[0-9]+$/)) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.INVALIDMOBILENO);
        }
        else if (this.state.MobileNo.length < 10) {
            ToastsStore.warning(AppConstants.ValidationErrorMessages.MOBILENOMINLENGTH);
        }
        else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsForgotPassword() {
        this.setState({ loadingReset: true })
        APIController.forgotPassword('', this.state.MobileNo).then((response) => {
            this.setState({ loadingReset: false });
            if (response.error == null) {
                ToastsStore.success(response.object.message)
                this.setState({ MobileNo: '' })
            }
            else {
                ToastsStore.warning(response.error.message);
            }
        })
    }
    renderForm() {
        const { classes } = this.props;
        const { loadingReset,mediumBrowser } = this.state;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container style={mediumBrowser?{width:'100%'}:{ width: '100%', marginLeft: '7%',marginBottom:'30%' }} alignItems="center" justify={mediumBrowser?'center':'flex-start'}>
                    <Grid container style={window.innerWidth<=420?{ width: '80%', height: '7.91%', }:{ width: '75.5%', height: '7.91%', }} alignItems="center">
                        {/* <Typography style={{color:'#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans',marginBottom: '3%',}}>Enter Registered Mobile No</Typography> */}
                        <FilledTextFieldUpdated
                            rows={1}
                            name="MobileNo"
                            label='Enter Registered Mobile No'
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 10
                            }}
                        />
                    </Grid>
                    <Grid container style={window.innerWidth<=420?{width:'80%',marginTop:'5%'}:{ width: '75.5%', marginTop: '10%' }} direction={'row'} justify={"space-between"} alignItems='center'>
                        <ButtonBase type="submit" title={'Reset Password'} isLoading={loadingReset}
                            style={window.innerWidth<=420?{width:'65%',}:{ width: '60%', }} />
                        <ButtonBase type="submit" title={'Login'}
                            onClick={() => this.props.history.push(AppConstants.SCREENS.LOGIN)}
                            style={window.innerWidth<=420?{
                                width: '25%',
                                border: '1px solid #E1E4EB', backgroundColor: '#FFFFFF', color: '#39A749',
                            }:{
                                width: '25%',
                                border: '1px solid #E1E4EB', backgroundColor: '#FFFFFF', color: '#39A749',
                            }} />
                    </Grid>
                    <Grid container style={window.innerWidth<=420?{width:'80%',marginTop:'3%',marginBottom:'5%'}:{ width: '75.5%', marginTop: '5%',marginBottom:'5%' }} direction={'row'} justify={mediumBrowser?"center":'flex-start'} alignItems='center'>
                        <Typography className={classes.txtOtp}>We'll send new Password to your Mobile Number</Typography>
                    </Grid>
                </Grid>
            </form>
        );
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }

    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main} >
                <Grid container style={window.innerWidth <= 920 ?{ width: '100%', marginTop:'10%' }:{ width: '100%', padding: '35px 12px 5px 12px' }} direction={'row'} alignItems='center' ></Grid>
                    <Grid container style={{ width: '100%' }} justify='center' alignItems='center'>
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%',marginTop:'10%' } : { width: '55%',marginTop:'2%' }} >
                            <Grid container style={mediumBrowser ? { width: '100%', } : { width: '50%', }} justify='center' alignItems='center'>
                                <Grid container justify={mediumBrowser?'center':'flex-start'} style={{ width: '100%', }} >
                                    <Typography className={classes.txtForgotPwd}>Forgot Password</Typography>
                                </Grid>
                                <Grid container justify={mediumBrowser?'center':'flex-start'} style={{ width: '100%', }} >
                                    {this.renderForm()}
                                </Grid>
                            </Grid>
                            {mediumBrowser == false && <Grid container style={{ width: '50%' }} justify='flex-end' >
                                <img src={Images.FormBackground} className={classes.img} alt="" />
                                <LocationIcon style={{ color: '#39A749', position: 'absolute', }} onClick={() => { this.props.history.push(AppConstants.SCREENS.HOMEPAGE) }} />
                            </Grid>}
                        </Grid>
                    </Grid>
                    {<Footer history={this.props.history} />}
                </main>
            </React.Fragment>
            
        );
    }
}
export default withStyles(ForgotPasswordStyle)(ForgotPassword);