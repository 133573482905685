/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Button,
    Avatar,
    Paper
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import headerStyle from './HeaderStyle';
import { Images } from '../../common';
import * as AppConstants from '../../common/Constants';
import CardMedia from '@material-ui/core/CardMedia';
import LocationIcon from '@material-ui/icons/LocationOn';
import ShoppingCart from '@material-ui/icons/AddShoppingCart';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import SearchMedicineModel from "../../containers/SearchMedicine/Model/SearchMedicineModel";

const Header = props => {
    const classes = headerStyle();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(open == false ? true : false);
    };

    const wsSearchMedicine = (text) => {
        if (text.length > 2 || text == "") {
        }
    }

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <Grid container style={{ width: '100%' }} direction={'row'} alignItems='center' justify={'space-between'}>
                        <Grid container style={{ width: '20%' }} direction={'row'} alignItems='center' >
                            <img src={Images.logoImage} className={classes.iconMain} />
                        </Grid>
                        <Grid container onClick={()=>handleClickAway(true)} style={{ width: '60%' }} justify={'center'} direction={'row'} alignItems='center' >
                            <Paper component="form" className={classes.textFillSearch}>
                                <IconButton className={classes.iconButton} aria-label="menu">
                                    <LocationIcon />
                                </IconButton>
                                <Grid>
                                    <Typography style={{color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans',}}> {localStorage.getItem('city')},</Typography>
                                    <Typography style={{color: '#262628', fontWeight: '400', fontSize: '12px', fontFamily: 'Nunito Sans',textAlign:'end'}}> {localStorage.getItem('pincode')} </Typography>
                                </Grid>
                                <Divider className={classes.divider} orientation="vertical" />
                                <InputBase
                                    className={classes.input}
                                    placeholder="Search Medicines"
                                    type="search"
                                    onChange={(text) => wsSearchMedicine(text)}
                                />
                                <Divider className={classes.divider} orientation="vertical" />
                                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                {/* <SearchMedicineModel openModel={open} /> */}
                            </Paper>
                        </Grid>
                        <Grid container style={{ width: '20%' }} direction={'row'} justify={'flex-end'} alignItems='center' >
                            <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.CART) }}>
                                <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                                <Avatar
                                    style={{ height: 25, width: 25, backgroundColor: 'red', position: 'absolute', right: 0, top: 0 }}
                                >
                                    <Typography className={classes.cartCounttxt}>
                                        {props.cartvalue != null ? props.cartvalue :
                                            localStorage.getItem('cartvalue')}
                                    </Typography>
                                </Avatar>
                            </IconButton>
                            <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.SIGNUP) }} className={classes.link}>{'SignUp'}</IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {/*<AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <img src={Images.logoImage} className={classes.iconMain} />
                    <Grid item sm={12} >
                        <Paper component="form" className={classes.textFillSearch}>
                            <IconButton className={classes.iconButton} aria-label="menu">
                                <LocationIcon />
                            </IconButton>
                            <Typography className={classes.iconButton}> Ahmedabad </Typography>
                            <Divider className={classes.divider} orientation="vertical" />
                            <InputBase
                                className={classes.input}
                                placeholder="Search Medicines"
                                type="search"
                                onClick={() => handleClickAway()}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <SearchMedicineModel open={open} />
                        </Paper>
                    </Grid>
                    <Grid container >
                        <ShoppingCartOutlinedIcon style={{ color: '#39A749' }} />
                        <Avatar
                            style={{ height: 20, width: 20, position: 'absolute', right: 0, top: 0 }}
                            onClick={() => { props.history.push(AppConstants.SCREENS.CART) }}>
                            {props.cartvalue != null ? props.cartvalue :
                                localStorage.getItem('cartvalue')}
                        </Avatar>
                    </Grid>
                    <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.LOGIN) }} className={classes.link}>{'SignIn'}</IconButton>
                    <Divider className={classes.divider} orientation="vertical" />

                    <IconButton onClick={() => { props.history.push(AppConstants.SCREENS.SIGNUP) }} className={classes.link}>{'SignUp'}</IconButton>
                </Toolbar>
            </AppBar>*/}

        </div>
    );
};

export default withTranslation('translation')(Header);
