import * as APICaller from '../api/APICaller'
import { User } from '../prototypes/User'
import { appUser } from '../App'

/**
 * This object represents the error that occurred in any api call that APIController has to offer
 * 
 * @param {String|Number} code Status code
 * @param {String} message Error message
 */
export function APIControllerError(code, message) {
    this.code = code
    this.message = message
}

/**
 * This object represents the output format for all api calls that APIController has to offer
 * 
 * @param {JSON} object - JSON representation of the object
 * @param {JSON|APIControllerError} error - Representation of the error
 */
export function APIControllerResponse(object, error) {
    this.object = object
    this.error = error
}

/**
 * Logs the user into the platform
 * 
 * @param {String} email The user's email
 * @param {String} password The user's password
 */
export function loginUser(mobileno, lastsyncat, password, deviceid) {
    return new Promise((resolve, reject) => {
        APICaller.loginUser(mobileno, lastsyncat, password, deviceid).then((response) => {
            // resolve(new APIControllerResponse(response.data[0], null))
            if (response.status == '1') {
                let user = appUser()
                user.id = response.data[0].id
                user.mobileno = response.data[0].mobileno
                user.fullname = response.data[0].fullname
                user.writeToLocalStorage()
                resolve(new APIControllerResponse(response.data[0], null))
            }
            else if (response.status == '2') {
                resolve(new APIControllerResponse(response.data[0], null))
            }
            else if (response.status == '0') {
                resolve(new APIControllerResponse(response.data[0], null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function SocialLogin(facebookid, googleid, emailaddress, devicetypeid, devicename, selectedlanguage, logintypeid, firstname, deviceid) {
    return new Promise((resolve, reject) => {
        APICaller.SocialLogin(facebookid, googleid, emailaddress, devicetypeid, devicename, selectedlanguage, logintypeid, firstname, deviceid).then((response) => {
            resolve(new APIControllerResponse(response, null))
            if (response && response.data[0]) {
                let user = appUser()
                user.id = response.data[0].id
                user.mobileno = response.data[0].mobileno
                user.fullname = response.data[0].fullname
                user.writeToLocalStorage()
                resolve(new APIControllerResponse(response.data[0], null))
            }

        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}


export function registerUser(
    firstname, lastname, mobileno, emailaddress, password, profilepicname,
    profilepic, deviceid, devicetypeid,
    fcmtoken, facebookid, googleid,
    logintypeid, lastlocation, lastlatitude, lastlongitude, selectedlanguage
) {
    return new Promise((resolve, reject) => {
        APICaller.registerUser(firstname, lastname, mobileno, emailaddress, password, profilepicname,
            profilepic, devicetypeid,
            facebookid, googleid,
            logintypeid, lastlocation, lastlatitude, lastlongitude, selectedlanguage).then((response) => {
                if (response && response.data[0]) {
                    resolve(new APIControllerResponse(response.data[0], null))
                }
                else {
                    let error = new APIControllerError("REGISTER_USER_FAILED", response.Message)
                    resolve(new APIControllerResponse(null, error))
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error))
            })
    })
}
export function editProfile(userid, mobileno, firstname, lastname, emailaddress, profilepic, profilepicname) {
    return new Promise((resolve, reject) => {
        APICaller.editProfile(userid, mobileno, firstname, lastname, emailaddress, profilepic, profilepicname).then((response) => {
            if (response && response.data[0].status == 1) {
                resolve(new APIControllerResponse(response.data[0], null))
            }
            else {
                let error = new APIControllerError(response.Message)
                resolve(new APIControllerResponse(null, error))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function searchMedicineNew(userid, mobileno, searchtext) {
    return new Promise((resolve, reject) => {
        APICaller.searchMedicineNew(userid, mobileno, searchtext).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function resendOtp(userid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.resendOtp(userid, mobileno).then((response) => {

            if (response) {
                resolve(new APIControllerResponse(response.data[0], null))
            }
            else {
                let error = new APIControllerError("REGISTER_USER_FAILED", response.Message)
                resolve(new APIControllerResponse(null, error))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function verifyOtp(userid, mobileno, otp) {
    return new Promise((resolve, reject) => {
        APICaller.verifyOtp(userid, mobileno, otp).then((response) => {
            resolve(new APIControllerResponse(response, null));
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function changePassword(userid, mobileno, password, newpassword) {
    return new Promise((resolve, reject) => {
        APICaller.changePassword(userid, mobileno, password, newpassword).then((response) => {
            if (response && response.data[0].status == 1) {

                resolve(new APIControllerResponse(response.data[0], null))
            }
            else {
                let error = new APIControllerError(response.data[0])
                resolve(new APIControllerResponse(null, error))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function getMyProfile(userid, mobileno, lastsyncat) {
    return new Promise((resolve, reject) => {
        APICaller.getMyProfile(userid, mobileno, lastsyncat).then((response) => {
            resolve(new APIControllerResponse(response.data[0], null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function forgotPassword(userid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.forgotPassword(userid, mobileno).then((response) => {
            if (response && response.data[0].status == 1) {
                resolve(new APIControllerResponse(response.data[0], null))
            }
            else if (response.status == '0') {
                resolve(new APIControllerResponse(null, response.data[0]))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function processCart(userid, mobileno, medicineid, quantity, lastsyncstatus, id) {
    return new Promise((resolve, reject) => {
        APICaller.processCart(userid, mobileno, medicineid, quantity, lastsyncstatus, id).then((response) => {
            if (response && response.data[0].status == 0) {
                // let user = appUser()
                // user.id = response.Data.UserId
                // user.writeToLocalStorage()
                // resolve(new APIControllerResponse(user, null))
            }
            else {
                // let error = new APIControllerError("REGISTER_USER_FAILED", response.Message)
                // resolve(new APIControllerResponse(null, error))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddEditAddress(userid, mobileno, id, appid, name, address1, address2, area, city, postcode, addresstypeid, isdefault) {
    return new Promise((resolve, reject) => {
        APICaller.AddEditAddress(userid, mobileno, id, appid, name, address1, address2, area, city, postcode, addresstypeid, isdefault).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function DeleteAddress(userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.DeleteAddress(userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddEditPatient(userid, mobileno, id, appid, name, doctorname, isdefault, relationwithpatient, birthdate, height, weight, bloodgroup, medicalconditions, allergies, medications) {
    return new Promise((resolve, reject) => {
        APICaller.AddEditPatient(userid, mobileno, id, appid, name, doctorname, isdefault, relationwithpatient, birthdate, height, weight, bloodgroup, medicalconditions, allergies, medications).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function DeletePatient(userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.DeletePatient(userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetData(operationid, userid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.GetData(operationid, userid, mobileno).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function ManageRefills(userid, mobileno, orderid, repeatafterdays, nextrefilldate, statusid) {
    return new Promise((resolve, reject) => {
        APICaller.ManageRefills(userid, mobileno, orderid, repeatafterdays, nextrefilldate, statusid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function EditRefills(userid, mobileno, orderid, refillid, repeatafterdays, nextrefilldate, statusid) {
    return new Promise((resolve, reject) => {
        APICaller.EditRefills(userid, mobileno, orderid, refillid, repeatafterdays, nextrefilldate, statusid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function DeleteRefills(userid, mobileno, orderid, refillid, statusid) {
    return new Promise((resolve, reject) => {
        APICaller.DeleteRefills(userid, mobileno, orderid, refillid, statusid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetMyOrder(operationid, userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.GetMyOrder(operationid, userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddPrescriptionToNew(userid, mobileno, id, operationid, getdata, isused) {
    return new Promise((resolve, reject) => {
        APICaller.AddPrescriptionToNew(userid, mobileno, id, operationid, getdata, isused).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function AddPrescription(operationid, userid, mobileno, imagename, imagepath, getdata, isused) {
    return new Promise((resolve, reject) => {
        APICaller.AddPrescription(operationid, userid, mobileno, imagename, imagepath, getdata, isused).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetPrescription(operationid, userid, mobileno, getdata, isused) {
    return new Promise((resolve, reject) => {
        APICaller.GetPrescription(operationid, userid, mobileno, getdata, isused).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetOldPrescription(operationid, userid, mobileno, getdata, isused) {
    return new Promise((resolve, reject) => {
        APICaller.GetOldPrescription(operationid, userid, mobileno, getdata, isused).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function removePrescription(userid, mobileno, operationid, getdata, id) {
    return new Promise((resolve, reject) => {
        APICaller.removePrescription(userid, mobileno, operationid, getdata, id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetCart(operationid, userid, mobileno, postcode, getdata) {
    return new Promise((resolve, reject) => {
        APICaller.GetCart(operationid, userid, mobileno, postcode, getdata).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function UpdateCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id) {
    return new Promise((resolve, reject) => {
        APICaller.UpdateCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function AddToCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, id) {
    return new Promise((resolve, reject) => {
        APICaller.AddToCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function RemoveCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id) {
    return new Promise((resolve, reject) => {
        APICaller.RemoveCart(operationid, userid, mobileno, postcode, getdata, quantity, medicineid, cartid, id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function MedicineDetail(userid, mobileno, medicineid, compositionid) {
    return new Promise((resolve, reject) => {
        APICaller.MedicineDetail(userid, mobileno, medicineid, compositionid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ContactUs(userid, mobileno, message) {
    return new Promise((resolve, reject) => {
        APICaller.ContactUs(userid, mobileno, message).then((response) => {
            resolve(new APIControllerResponse(response.data[0], null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ContactMedkaer(name, location, emailaddress, phoneno, message, source) {
    return new Promise((resolve, reject) => {
        APICaller.ContactMedkaer(name, location, emailaddress, phoneno, message, source).then((response) => {
            resolve(new APIControllerResponse(response.data[0], null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetStoreList(userid, mobileno, latitude, longitude, name) {
    return new Promise((resolve, reject) => {
        APICaller.GetStoreList(userid, mobileno, latitude, longitude, name).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetNotifications(userid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.GetNotifications(userid, mobileno).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetPatientById(operationid, userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.GetPatientById(operationid, userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetAddressById(operationid, userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.GetAddressById(operationid, userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function OrderReview(operationid, userid, mobileno, addressid, patientid, id) {
    return new Promise((resolve, reject) => {
        APICaller.OrderReview(operationid, userid, mobileno, addressid, patientid, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function AddOrder(userid, mobileno, discount, deliverycharges, addressid, patientid, patientname, doctorname, additonalnotes, removeandcontinue) {
    return new Promise((resolve, reject) => {
        APICaller.AddOrder(userid, mobileno, discount, deliverycharges, addressid, patientid, patientname, doctorname, additonalnotes, removeandcontinue).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetOrdersById(operationid, userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.GetOrdersById(operationid, userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function OrderConfirmation(operationid, userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.OrderConfirmation(operationid, userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function CopyOrder(userid, mobileno, id) {
    return new Promise((resolve, reject) => {
        APICaller.CopyOrder(userid, mobileno, id).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetRaiseandissueDetail(operationid, userid, mobileno, typeid) {
    return new Promise((resolve, reject) => {
        APICaller.GetRaiseandissueDetail(operationid, userid, mobileno, typeid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function CancelOrReturnOrder(userid, mobileno, orderid, issueid, additonalnotes, imagename, image, orderstatus) {
    return new Promise((resolve, reject) => {
        APICaller.CancelOrReturnOrder(userid, mobileno, orderid, issueid, additonalnotes, imagename, image, orderstatus).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function SendOrderOTP(userid, mobileno, orderid, orderref) {
    return new Promise((resolve, reject) => {
        APICaller.SendOrderOTP(userid, mobileno, orderid, orderref).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function ResendOrderOTP(userid, mobileno, orderid) {
    return new Promise((resolve, reject) => {
        APICaller.ResendOrderOTP(userid, mobileno, orderid).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function VerifyOrderOTP(userid, mobileno, orderid, orderotp) {
    return new Promise((resolve, reject) => {
        APICaller.VerifyOrderOTP(userid, mobileno, orderid, orderotp).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function ApplyCouponCode(userid, mobileno, couponcode,postcode) {
    return new Promise((resolve, reject) => {
        APICaller.ApplyCouponCode(userid, mobileno, couponcode,postcode).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function RemoveCouponCode(userid, mobileno, postcode) {
    return new Promise((resolve, reject) => {
        APICaller.RemoveCouponCode(userid, mobileno, postcode).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GeneratePayTMChecksum(orderid, mobileno, userid, amount) {
    return new Promise((resolve, reject) => {
        APICaller.GeneratePayTMChecksum(orderid, mobileno, userid, amount).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ValidateForPrescriptions(mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.ValidateForPrescriptions(mobileno).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function SendCart(userid, mobileno, emailaddress) {
    return new Promise((resolve, reject) => {
        APICaller.SendCart(userid, mobileno, emailaddress).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function Compare_GetMedicines(sessionid) {
    return new Promise((resolve, reject) => {
        APICaller.Compare_GetMedicines(sessionid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function Compare_DeleteMedicines(sessionid,id) {
    return new Promise((resolve, reject) => {
        APICaller.Compare_DeleteMedicines(sessionid,id).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function Compare_AddMedicine(sessionid,medicineid) {
    return new Promise((resolve, reject) => {
        APICaller.Compare_AddMedicine(sessionid,medicineid).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function Compare_EmailMedicines(emailaddress, sessionid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.Compare_EmailMedicines(emailaddress, sessionid, mobileno).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function Compare_SearchMedicines(searchtext) {
    return new Promise((resolve, reject) => {
        APICaller.Compare_SearchMedicines(searchtext).then((response) => {
            resolve(new APIControllerResponse(response.data, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function ContactMedkart(name, location, emailaddress, phoneno, message) {
    return new Promise((resolve, reject) => {
        APICaller.ContactMedkart(name, location, emailaddress, phoneno, message).then((response) => {
            resolve(new APIControllerResponse(response.data[0], null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetCoupons(userid, mobileno) {
    return new Promise((resolve, reject) => {
        APICaller.GetCoupons(userid, mobileno).then((response) => {
            resolve(new APIControllerResponse(response, null))
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}