import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import refillStyle from './RefillStyle';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Typography, Grid, Button, Divider, Card, } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Title from "../../components/Header/Title";
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import MedkartHeader from "../../components/Header/MedkartHeader";
import MedkartFooter from "../../components/Header/Footer";
import LocationIcon from '@material-ui/icons/LocationOn';
import { Scrollbars } from 'react-custom-scrollbars';
import * as moment from 'moment';
import ReactGA from 'react-ga';

class Refill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: false,
            dataSourceAddRefill: [],
            dataSourceOrderDetail: [],
            // cartitem:'',
            IsLoading: false,
            IdForEdit: -1,
            orderdate: '',
            estimateddeliverydate: '',
            orderreferenceno: '',
            orderstatus: '',
            ordertotal: '',
            discount: '',
            deliverycharges: '',
            grosstotal: '',
            RefillID: '',
            OrderID: '',
            RefillInterval: '',
            RefillNextDeliveryDate: '',
            dataSourceAddRefill_MyOrder: '',
            dataSourceEditRefill_MyOrder: '',
            fromEditRefill: '',
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetListRifills();
        this.wsGetCart();
    }

    OnPressEditList(orderid, id, index) {
        this.setState({ IdForEdit: index })
        this.wsGetListRefillDetails(orderid);
    }

    onPressList(index) {
        this.setState({ selectedItem: index });
        global.appidPatient = this.state.datasource1[index].id;
    }

    wsGetListRifills() {
        APIController.GetData('15', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSourceAddRefill: response.object });
                    if(response.object.length > 0){
                        this.OnPressEditList(response.object[0].orderid,response.object[0].id,0);
                    }
                }
                else if (response.error == null || this.props.history.location.state.fromEditRefill == true) {
                    this.setState({ dataSourceAddRefill: this.props.history.location.state.dataSourceEditRefill_MyOrder });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    wsGetListRefillDetails(ID) {
        APIController.GetOrdersById('12', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ dataSourceOrderDetail: response.object.data.orderdetails });
                    this.setState({
                        orderdate: response.object.data.orders[0].orderdate,
                        estimateddeliverydate: response.object.data.orders[0].estimateddeliverydate,
                        orderreferenceno: response.object.data.orders[0].orderreferenceno,
                        orderstatus: response.object.data.orders[0].orderstatus,
                        ordertotal: response.object.data.orders[0].ordertotal,
                        discount: response.object.data.orders[0].discount,
                        deliverycharges: response.object.data.orders[0].deliverycharges,
                        grosstotal: response.object.data.orders[0].grosstotal
                    });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }

    wsDeleteRefills() {
        APIController.DeleteRefills(AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal(), this.state.OrderID, this.state.RefillID, '3')
            .then((response) => {
                if (response.status == "1") {
                    ToastsStore.warning(response.message);
                    this.wsGetListRifills();
                }
                else if (response.message && response.message.length > 0) {
                    ToastsStore.warning(response.message);
                }
            });
    }

    wsGetCart() {
        APIController.GetData('8', AppConstants.userIdFromLocal(), AppConstants.mobilenoFromLocal())
            .then((response) => {
                if (response.error == null) {
                    this.setState({ cartitem: response.object.cart.length });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    ToastsStore.warning(response.error.message);
                }
            });
    }
    renderCartEmpty() {
        const { classes } = this.props
        return (
            <Grid className={classes.ContentcardmainCart} style={{ width: '99%' }} container direction={'row'} justify={'center'} alignItems="center">
                {/* <Typography className={classes.qntytxt} >{''}</Typography> */}
                <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Nunito Sans', }}> {'No Refills Found'}</Typography>
            </Grid>
        )
    }
    renderMyRefillList() {
        const { classes } = this.props;
        const { dataSourceAddRefill, IsLoading, mediumBrowser, IdForEdit } = this.state;
        return (
            // <Card raised={true} container direction="row" justify="center" alignItems="baseline" style={{ width: '100%', height: '97%', marginTop: '1%', padding: '2%' }}>
            <Card raised={true} direction="row" justify='center' alignItems='center' style={mediumBrowser ? { flex: 1, width: '100%', height: '97%', padding: '2%', } : { flex: 1, width: '100%', height: '97%', padding: '2%', }}>
                <Scrollbars autoHide autoHeight autoHeightMax='600px'>
                    {
                        dataSourceAddRefill.map((item, index) =>
                            <Grid container style={{ cursor: 'pointer' }} onClick={() => { this.OnPressEditList(item.orderid, item.id, index); this.setState({ RefillID: item.id, OrderID: item.orderid, RefillInterval: item.daystogo, RefillNextDeliveryDate: item.nextrefilldate }); }}>
                                <Grid container direction='row' className={IdForEdit == index ? classes.ContentSelectcardmainRefill : classes.ContentcardmainRefill}>
                                    <Grid container direction='row'
                                        style={{ backgroundColor: '#fff', marginTop: '10px', marginLeft: '10px' }} >
                                        <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '24px', fontFamily: 'Nunito Sans', }}> {item.patientname} </Typography>
                                    </Grid>
                                    <Grid container direction="row"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px' }} >
                                        <Typography style={{ backgroundColor: '#fff', width: '70%', color: '#FF6133', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {item.daystogo} Days Left </Typography>
                                    </Grid>
                                    <Grid container direction="row"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px', marginTop: '10px' }} >
                                        <Typography style={{ backgroundColor: '#fff', width: '32%', color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Order No. </Typography>
                                        <Typography style={{ backgroundColor: '#fff', width: '68%', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> #{item.orderid} </Typography>
                                    </Grid>
                                    <Grid container direction="row"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px' }} >
                                        <Typography style={{ backgroundColor: '#fff', width: '32%', color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Order On </Typography>
                                        <Typography style={{ backgroundColor: '#fff', width: '68%', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {item.createdat} </Typography>
                                    </Grid>
                                    <Grid container direction="row"
                                        style={{ backgroundColor: '#fff', marginLeft: '10px', marginBottom: '10px' }} >
                                        <Typography style={{ backgroundColor: '#fff', width: '32%', color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Expected Delivery </Typography>
                                        <Typography style={{ backgroundColor: '#fff', width: '68%', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {item.estimateddeliverydate} </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                </Scrollbars>
            </Card>
        )
    }

    renderaddMyRefill() {
        const { dataSourceAddRefill, dataSourceOrderDetail, IsLoading, mediumBrowser } = this.state;
        return (
            <Card raised={true} container direction="row" style={mediumBrowser?{ width: '100%', height: '97%', padding: '4%', }:{ width: '100%', height: '97%', padding: '4%', }}>
                <Grid container
                    direction="row"
                    justify='space-between'>
                    <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Placed on </Typography>
                    <Typography style={{ backgroundColor: '#fff', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.orderdate} </Typography>
                </Grid>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                <Grid container
                    direction="row"
                    justify='space-between' >
                    <Typography style={{ backgroundColor: '#fff', color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Esti. Delivery Date </Typography>
                    <Typography style={{ backgroundColor: '#fff', color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.estimateddeliverydate} </Typography>
                </Grid>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                <Grid container
                    direction="row"
                    justify='space-between'>
                    <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Order No. </Typography>
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.orderreferenceno} </Typography>
                </Grid>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                <Grid container direction="row" justify='space-between'>
                    <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Order Status </Typography>
                    {this.state.orderstatus == 'Placed' ? <Typography style={{ color: '#39A749', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.orderstatus} </Typography> : <Typography style={{ color: '#FF6133', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> {this.state.orderstatus} </Typography>}
                </Grid>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                <Typography style={{ color: '#9B9B9B', marginTop: '10px', marginBottom: '10px', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Order Items </Typography>
                <Scrollbars autoHide autoHeight hideTracksWhenNotNeeded={true} autoHeightMax='800px'>
                    {dataSourceOrderDetail.map((item, index) =>
                        <Grid container direction='row'>
                            <Grid container direction="row" justify='space-between'>
                                <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '18px', fontFamily: 'Nunito Sans', }}> {item.medicinename} </Typography>
                                <Typography style={{ color: '#39A749', fontWeight: '700', fontSize: '18px', fontFamily: 'Nunito Sans', }}> ₹ {item.totalsaleprice} </Typography>
                            </Grid>
                            <Grid container direction="row" justify='space-between'>
                                <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Pack of {item.packsize}, Quantatiy: {item.quantity} </Typography>
                                <Typography style={{ color: 'red', textDecorationLine: 'line-through', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> ₹ {item.totalmrp} </Typography>
                            </Grid>
                            <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#D9DCE3', height: '1px', marginBottom: '10px', marginTop: '10px' }} />
                        </Grid>)}
                </Scrollbars>
                <Grid container direction="row" justify='space-between'>
                    <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> MRP Total </Typography>
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> ₹ {this.state.ordertotal} </Typography>
                </Grid>
                <Grid container
                    direction="row"
                    justify='space-between'>
                    <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Discount </Typography>
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> -₹ {this.state.discount} </Typography>
                </Grid>
                <Grid container direction="row" justify='space-between'>
                    <Typography style={{ color: '#9B9B9B', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> Delivery Charges </Typography>
                    <Typography style={{ color: '#262628', fontWeight: '400', fontSize: '16px', fontFamily: 'Nunito Sans', }}> ₹ {this.state.deliverycharges} </Typography>
                </Grid>
                <Divider orientation="vertical" style={{ width: '100%', backgroundColor: '#39A749', height: '2px', marginBottom: '10px', marginTop: '10px', }} />
                <Grid container direction="row" justify='space-between'>
                    <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '18px', fontFamily: 'Nunito Sans', }}> Gross Total </Typography>
                    <Typography style={{ color: '#262628', fontWeight: '700', fontSize: '18px', fontFamily: 'Nunito Sans', }}> ₹ {this.state.grosstotal} </Typography>
                </Grid>
                <Grid container direction="row" justify='space-between' style={{ marginTop: '5%',marginBottom:'5%' }}>
                    <ButtonBase title='Deliver'
                        isLoading={IsLoading}
                        style={{ width: '30%', }} />
                    <ButtonBase title='Edit' isLoading={IsLoading}
                        onClick={() => this.props.history.push({ pathname: '/myorder', state: { fromPageRefill: true, edit: true, orderid: this.state.OrderID, refillid: this.state.RefillID, refillInterval: this.state.RefillInterval, refillNextDeliveryDate: this.state.RefillNextDeliveryDate } })}
                        style={{ width: '30%', backgroundColor: '#fff', color: '#39A749', border: '1px solid #39A749', }} />
                    <ButtonBase title='Delete' isLoading={IsLoading} onClick={() => { this.wsDeleteRefills() }}
                        style={{ width: '30%', backgroundColor: '#fff', color: 'red', border: '1px solid red', }} />
                </Grid>
            </Card>
        )
    }

    handleStep(step) {
        this.setState({ open: step });
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 });
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { mediumBrowser, dataSourceAddRefill } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <ToastsContainer store={ToastsStore} />
                    <Title title={'My Refills'} history={this.props.history} />
                    <Grid container justify='center' alignItems='flex-end' alignSelf='flex-end'>
                        <Grid container direction='row' style={mediumBrowser?{ width: '100%',marginBottom:'0.5%' }:{ width: '98.5%' }} justify='space-between' alignItems='center'>
                            <Typography style={{ color: '#262628', fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito Sans', }}>{'(' + dataSourceAddRefill.length + ')' + ' ' + 'Refills Found'}</Typography>
                            <Button onClick={() => this.props.history.push({ pathname: '/myorder', state: { fromPageRefill: true, orderid: this.state.OrderID, refillid: this.state.RefillID } })}>
                                <Typography style={{ color: '#39A749', fontWeight: '700', fontSize: '16px', fontFamily: 'Nunito Sans', }}>{'Create Refill'} </Typography>
                            </Button>
                        </Grid>
                        {dataSourceAddRefill.length == 0 ?
                            this.renderCartEmpty()
                            // : <Grid container
                            //     direction="row"
                            //     justify='space-around'
                            //     style={{ width: '100%', marginBottom: '5%', backgroundColor: '#fff' }}>
                                
                                :<Grid container direction="row" style={mediumBrowser ? {width:'100%'} : { width: '98.5%', }} justify="space-between">
                                <Grid container style={mediumBrowser ? { width: '100%', } : { width: '39%', marginTop:'8px'}}  >
                                    {this.renderMyRefillList()}
                                </Grid>
                                <Grid container style={mediumBrowser ? { width: '100%', marginTop:'3%' } : { width: '60%', marginTop:'8px'}}  >
                                    {this.renderaddMyRefill()}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </main>
                <MedkartFooter {...this.props} />
            </React.Fragment>
        );
    }
}
export default withStyles(refillStyle)(withTranslation('translation')(Refill));
