import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const ChangePasswordStyle = theme => ({
    txtCurrentPwd: {
        marginTop: '5%',
        color: '#262628',
        fontFamily:'Nunito Sans',
        fontSize: AppConstants.FONTSIZE.FS16,
        letterSpacing: 0,
        fontWeight: '400',  
    },
    mainContainer: {
        // marginTop: '100px',
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)',
    },
    innerContainerLeft: {
        width: '50%',
    },
    txtChangePwd: {
        marginTop: '5%',
        // marginBottom: '5%',
        fontWeight: 700,
        marginLeft: '7%',
        color: '#262628',
        fontSize: AppConstants.FONTSIZE.FS22,
        fontFamily:'Nunito Sans',
        color: '#262628',
    },
    textField: {
        borderRadius: '5px',
        border: '1px solid #f1f1f5',
        color: '#262628',
        fontFamily: 'Nunito Sans',
        fontSize: 16,
        fontWeight: 400,
        height:'50px',
        width:'100%'
      },
    innerContainerRight: {
        width: '50%',
    },
    img: {
        width: '100%',
        height: 450,
    },

});
const combinedStyles = combineStyles(commonStyle, ChangePasswordStyle);
export default combinedStyles; 
