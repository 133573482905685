import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import returnPolicyStyle from './ReturnPolicyStyle';
import {
    CssBaseline, Grid, Button,
} from '@material-ui/core';
import Iframe from 'react-iframe';
import Footer from '../../components/Header/Footer';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Title from "../../components/Header/Title";
import CompareIcon from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUsIcon from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";
class ReturnPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: true,
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid style={{ width: '100%' }}>
                <Helmet>
                    <title>Know about medkart pharmacy medicine return policy</title>
                    <meta name="description" content="Read about Medkart return policy for medicines purchased online. Know when can you cancel or return the orders for medicines purchased from our online store." />
                    <meta name="keywords" content="Medicine Return Policy | Medkart Return Policy" />
                </Helmet>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    <Title title={'Return & Refund Policy'} isBack={false} history={this.props.history} />
                    <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">
                        <Grid style={mediumBrowser ? { width: '100%' } : { width: '98.5%' }} container frameBorder='0'>
                            <Grid style={{ marginRight: 3, marginLeft: 3, marginTop: '13px', fontFamily: 'Nunito Sans' }}>
                                Medkart Pharmacy Private Limited (Home Delivery department) team facilitates processing correct medicines as per order and prescription and strives to service the medicines and products in right conditions/ without any damage every time a consumer places an order. We also strongly recommend the items are checked at the time of delivery.
<h3>Definition:</h3>
                                <b>CANCEL:</b> ‘Cancel’ or ‘Cancellation’ of an order would refer to an order being cancelled before it is shipped by Medkart Pharmacy. Cancellations are allowed till the order has been shipped/dispatched from Medkart Pharmacy. Once the Order is dispatched/Shipped no cancellations are allowed.
<br />
                                <br />
                                <b>RETURN:</b> 'Return' means an action of giving back the product ordered at Medkart Pharmacy portal by the consumer. The following situations may arise which may cause the action of return of product:
<ul>
                                    <li>Product(s) delivered do not match your order;</li>
                                    <li>Product(s) delivered are past or near to its expiry date (medicines with an expiry date of less than 03 months shall be considered as near expiry);</li>
                                    <li>Product(s) delivered were damaged in transit (do not to accept any product which has a tampered seal).</li>
                                </ul>
                                <i>Note: If the product that you have received is damaged, then do not accept the delivery of that product. If after opening the package you discover that the product is damaged, the same may be returned for a refund. Please note that we cannot promise a replacement for all products as it will depend on the availability of the particular product, in such cases we will offer a refund.<br /></i>
                                <br />
In the aforesaid unlikely situations, if there is something wrong with the order, we'd be happy to assist and resolve your concern. You may raise a Return request with our customer care within 03 (Three) days from the delivery of the product. Medkart Pharmacy reserves the right to cancel the Return request, if the customer reaches out to Medkart Pharmacy after 3 days of delivery.<br />
                                <br />
Upon receiving your Return/Refund request, Medkart Pharmacy shall verify the authenticity and the nature of the request. If Medkart Pharmacy finds that the request is genuine, it will initiate the Return and Refund process. Medkart Pharmacy shall process the refund only once it has received the confirmation from the vendor concerned in respect of the contents of the product relating to that refund.<br />
                                <br />
In the event of frivolous and unjustified complaints regarding the quality and content of the products, Medkart Pharmacy reserves the right to pursue necessary legal actions against you and you will be solely liable for all costs incurred by Medkart Pharmacy in this regard.<br />
                                <br />
                                <b>The returns are subject to the below conditions:</b><ul>
                                    <li>Any wrong ordering of product doesn’t qualify for Return;</li>
                                    <li>Batch number of the product being returned should match as mentioned on the invoice;</li>
                                    <li>Return requests arising due to change in prescription do not qualify for Return;</li>
                                    <li>Product being returned should only be in their original manufacturer's packaging i.e. with original price tags, labels, bar-code and invoice; and</li>
                                    <li>Partially consumed strips or products do not qualify for Return, only fully unopened strips and undamaged or products can be returned.</li>
                                </ul>
                                <b>Return Process:</b><ul>
                                    <li>For Return intimation, please visit www.medkart.in or request on the Medkart app or alternatively call us at 7600001634 (Open 10 AM - 7 PM from Monday – Saturday).</li>
                                    <li>Medkart Pharmacy customer care team will verify the claim made by the customer within 72 (seventy-two) business hours from the time of receipt of complaint.</li>
                                    <li>Once the claim is verified as genuine and reasonable, Medkart Pharmacy will initiate the collection of product(s) to be returned.</li>
                                    <li>The customer will be required to pack the product(s) in original manufacturer’s packaging.</li>
                                    <li>Refund will be completed within 30 (thirty) days from date of reverse pick up (if required).</li>
                                </ul>
                                <b>Shipping Charges:</b><ul>
                                    <li>Orders with a payable amount of Rs. 500 or more will qualify for free delivery. A delivery charge of Rs. 25 will be applied otherwise.</li>
                                    <li>For any further Refund related information, please write to care@medkart.in</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* {window.innerWidth <= 920 ? <Grid/>:<BottomMenu history={this.props.history} />} */}
                </main>
                {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
            </Grid>
        );
    }
}
export default withStyles(returnPolicyStyle)(ReturnPolicy);
