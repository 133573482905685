import React from 'react';
import {
    Container, CssBaseline, Typography, Grid, Checkbox, InputAdornment,
    IconButton,
} from '@material-ui/core';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export default function Accordians() {
    return (
        <Accordion allowZeroExpanded={true}>
            {/* <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, }}>
                   Digital Marketing Manager 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        Franchisee Manager will be responsible for planning and managing the franchising business of the Company. They will be responsible for developing franchising opportunities and for offering continuous support to franchisees, to ensure the overall success of the franchisor as well as the franchisee.
                    </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '5%' }}>
                        Roles and Responsibilities
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Responsible for franchise expansion
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Initial contact with prospects
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Follows the sales process with prospective franchisees thoroughly
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Hold meetings with the prospective franchisee
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Work as a point of contact between the franchisee and the franchisor, and ensure that any issues/concerns are aptly and timely addressed.
                    </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Advice and support franchisees with all areas of their business including: store standards, stock management, customer relationship, staffing and recruitment, finances and payments, cost control, expansion.
                    </Typography>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, }}>
                        Area Sales Manager
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Direct and supervise retail staff across several retail outlets to ensure they meet set objectives
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Mentor and motivate sales staff to put in their best in order to achieve financial goals
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Visit retail establishments to ensure efficient and effective management as well as high performance sales
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Provide training to retail employees to develop their skills and enhance their job performance
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Ensure compliance with company policies and procedures
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Provide solutions that address performance issues in a timely and effective manner
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Monitor the activities of the customer service department of retail outlets to ensure a satisfied clientele
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Contact retail staff to communicate relevant information
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Ensure compliance with health/safety policies and procedures at retail sites
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Listen to customer complaints to address & resolve their issues
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Evaluate operational and financial records to determine sales performance of a retail store.
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Review and evaluate sales records and financial statements.
                </Typography>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '5%' }}>
                        Skills Required:
                </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Organizational Skills: Area sales managers should be able to coordinate sales operations across a retail chain.
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Communication Skills: Good communications skills to manage store staff and provide necessary information across.
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Team Management:- Area sales manager should be able to lead their team (store staff) to achieve the desired goal of the organization.
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Problem Solving skills- To provide solutions to the problem faced by store staff as well as customers.
                </Typography>
                </AccordionItemPanel>
            </AccordionItem>
           */}
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700 }}>
                    Digital Marketing Manager 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                Medkart Pharmacy, a pharmacy with difference, is leading retail pharmacy chain in Gujarat, Maharashtra & Rajasthan. It brings you to opportunity to learn the medicine, its uses, its contents. It provides you the opportunity to learn from Knowledgeable staff. 
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                        Job Description:
                    
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Plan and execute all digital marketing, including SEO/SEM, marketing database, email, social media and display advertising campaigns                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Design, build and maintain our social media presence                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Identify trends and insights, and optimize spend and performance based on the insights  
                                      </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Brainstorm new and creative growth strategies  
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Plan, execute, and measure experiments and conversion tests  
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Collaborate with internal teams to create landing pages and optimize user experience  
                                    </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Utilize strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touch points                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Instrument conversion points and optimize user funnels                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Collaborate with agencies and other vendor partners                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Evaluate emerging technologies. Provide thought leadership and perspective for adoption where appropriate                                      </Typography>


                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                    Requirements  :
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Master's degree in marketing or a related field                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Proven working experience in digital marketing                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Demonstrable experience leading and managing SEO/SEM, marketing database, email, social media and/or display advertising campaigns  
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Highly creative with experience in identifying target audiences and devising digital campaigns that engage, inform and motivate                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Experience in optimizing landing pages and user funnels                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Experience with A/B and multivariate experiments  
                                    </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Solid knowledge of website analytics tools (e.g., Google Analytics, Web Trends)                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Working knowledge of ad serving tools.                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Experience in setting up and optimizing Google AdWords campaigns                                      </Typography>
                                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                    •	Working knowledge of HTML, CSS, and JavaScript development and constraints  
                                                                        </Typography>
                                                                        <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                                                        •	Strong analytical skills and data-driven thinking                                                                          </Typography>
                                                                        <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                                                                        •	Up-to-date with the latest trends and best practices in online marketing and measurement                                                                          </Typography>


                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '2%' }}>
                        •	Location: - Ahmedabad 
                </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        •    Experience: 8 to 10 years 
                </p>
                   
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                    •  No of Position: 1                 </p>


                </AccordionItemPanel>
            </AccordionItem>
            
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700 }}>
                        Pharmacist (with License)
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                Medkart Pharmacy, a pharmacy with difference, is leading retail pharmacy chain in Gujarat, Maharashtra & Rajasthan. It brings you to opportunity to learn the medicine, its uses, its contents. It provides you the opportunity to learn from Knowledgeable staff. 
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                        Job Roles:
                    
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Medicine Dispensing
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •		Customer Counselling & Interaction
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Other Store related work
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •  Working Days - Monday to Saturday (SUNDAY- OFF)
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Customer Counselling and Interaction
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                        •	Other Store related work
                </Typography>


                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                         Benefits:
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	License Amount 
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Attendance Bonus  
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	ESIC
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Sales Incentive  
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Annual Bonus 
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Growth opportunity. 
                </Typography>


                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '2%' }}>
                        •	Location – Ahmedabad & Baroda
                </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        •    Education Qualification:  B.Pharm/D.pharm/M.pharm
                </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        • Experience: 0 to 3 years (Fresher can also Apply)
                </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        • No of Position: 5 +
                </p>


                </AccordionItemPanel>
            </AccordionItem>
            
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700 }}>
                    Warehouse Executive 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                     Medkart Pharmacy, a pharmacy with difference, is leading retail pharmacy chain in Gujarat, Maharashtra & Rajasthan. It brings you to opportunity to learn the medicine, its uses, its contents. It provides you the opportunity to learn from Knowledgeable staff. 
                    
                    
                    
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                        Job Roles:
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Medicine packing & Unpacking 
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Inward- outward                 </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Loading- Unloading                 </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Rack – Arrangement                 </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	 Working Days - Monday to Saturday (SUNDAY- OFF) </Typography>



                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '1%' }}>
                         Benefits:
                    </p>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Attendance Bonus                  </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	ESI                 </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	PF 
                </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Annual Bonus                 </Typography>
                    <Typography style={{ fontFamily: 'Nunito Sans', fontSize: '15px', textAlign: 'justify' }}>
                    •	Growth opportunity.                 </Typography>
              


                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '2%' }}>
                        •	Location – Ahmedabad 
                    </p>
                   
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        • Experience: 0 to 2 years (Fresher can also Apply) 
                    </p>
                    <p style={{ fontFamily: 'Nunito Sans', fontSize: '16px', fontWeight: 700, marginTop: '0.5%' }}>
                        • No of Position: 2+ 
                    </p>


                </AccordionItemPanel>
            </AccordionItem>
            
        </Accordion>
    );
}