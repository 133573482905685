import React from 'react';
import { withTranslation } from 'react-i18next';
import Compare from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import * as AppConstants from '../../common/Constants';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUs from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import { Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import PincodeDialogStyle from './PincodeDialogStyle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ButtonBase from '../ButtonBase/ButtonBase';
// import * as AppConstants from '../../common/Constants';
import Alertdialog from "../Mobile/Alertdialog/Alertdialog";
import FilledTextField from '../../components/FilledTextField/FilledTextField';

const PincodeDialog = props => {
    const classes = PincodeDialogStyle();
    const { open, onClose, onClick } = props;
    const [PostalCode, setPostalCode] = React.useState('');

    const handleChange = (e) => {
        setPostalCode(e.target.value)
    }
    const addpincode = (index) => {
       
        if (PostalCode != "" && PostalCode.length == 6) {
            localStorage.setItem('pincode', PostalCode)

            props.index == -1 ? props.wsAddToCart('1') :props.ClosePincode()
            //  props.wsAddToCart(index, '1');
        }
        else {
            alert("please enter 6 digit pincode");
        }
    };


    return (
        <React.Fragment>
            <Alertdialog
                onClose={onClose}
                style={{ height: '100%', borderRadius: '100px' }}
                open={open}>
                <Grid container justify='center' alignItems='center'
                    style={window.innerWidth<=420?{ width: '300px' }:{ width: '500px' }}>
                    <Grid direction={'row'} container justify='center' style={{ width: '80%' }}>
                        <Typography><h1>{'Please enter Pincode'}</h1></Typography>
                    </Grid>
                    <Grid direction={'row'} container justify='center' style={{ width: '80%',marginBottom:'5%' }}>
                        <FilledTextField
                            rows={1}
                            type={'number'}
                            inputProps={{
                                maxLength: 6
                            }}
                            value={PostalCode}
                            name="PostalCode"
                            label="Enter 6 digit Pincode"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid direction={window.innerWidth<=420?'column':'row'} container justify={window.innerWidth<=420?'center':'space-between'} alignItems='center' style={{ width: '80%',marginBottom:'5%' }}>
                        <ButtonBase title={'Save'}
                            variant="contained"
                            style={{ width: '150px' }}
                            onClick={() => addpincode(props.index)} />
                        <ButtonBase title={'CANCEL'}
                            variant="contained"
                            style={window.innerWidth<=420?{width:'150px',marginTop:'2%'}:{ width: '150px' }}
                            onClick={onClick} />
                    </Grid>
                </Grid>
            </Alertdialog>
        </React.Fragment>
    );
};

export default (PincodeDialog);
