import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const myOrderStyle = theme => ({
    mainContainerLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    Medicinetitle: {
        fontFamily: 'Nunito Sans', 
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '10px',
        letterSpacing: 0,
        color: theme.palette.textColor,
    },
    mainContainerRight: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
        paddingTop: '5.9%',
    },
    aprovedView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#dea833',
        borderRadius: '5px 5px 5px 5px',
    },
    cancelView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#e65e35',
        borderRadius: '5px 5px 5px 5px',
    },
    rejectView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        // backgroundColor: '#13bf58',
        backgroundColor: 'red',
        borderRadius: '5px 5px 5px 5px',
    },
    returnView: {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#3eb44',
        borderRadius: '5px 5px 5px 5px',
    },
    deliverView: {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: 'green',
        borderRadius: '5px 5px 5px 5px',
    },

    placeView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#39A749',
        borderRadius: '5px 5px 5px 5px',
    },
    despatchView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#4A90E2',
        borderRadius: '5px 5px 5px 5px',
    },
    codView:
    {
        width: '100px',
        height: '25px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#8c49bf',
        borderRadius: '5px 5px 5px 5px',
    },
    ContentSelectcardmainMyOrder: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentcardmainMyOrder: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    textField: {
        backgroundColor: '#fff',
        borderRadius: 4,
        border: '1px solid #f1f1f1',
        color: '#32373c',
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 600,
        height: '50px',
        lineHeight: 1.57,
        // paddingRight:'3.5%'
    },
    textField_withoutBorder: {
        backgroundColor: '#fff',
        borderRadius: 4,
        border: '1px solid #fff',
        color: '#32373c',
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 600,
        height: '50px',
        lineHeight: 1.57,
        width: '95%'
        // paddingRight:'3%'
    },
    textField_Select: {
        paddingRight: '10%'
    },
    label: {
        color: '#b5bbc1',
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 14,
    },
    ContentcardmainCart: {
        width: '98%',
        // margin: '10px',
        marginTop: '15px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
});
const combinedStyles = combineStyles(commonStyle, myOrderStyle);
export default combinedStyles; 
