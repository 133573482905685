import React, { Component } from 'react';
import {
    Container, CssBaseline, Typography, Grid, Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EcommerceHomePageStyle from './EcommerceHomePageStyle';
import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import MedkartHeader from '../../components/Header/MedkartHeader';
import Footer from '../../components/Header/Footer';
import MyorderIcon from '@material-ui/icons/ShoppingCart';
import RefillIcon from '@material-ui/icons/Sync';
import PaymentIcon from '@material-ui/icons/Payment';
import NotificationIcon from '@material-ui/icons/Notifications';
import WhyGenericIcon from '@material-ui/icons/HelpOutline';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AboutUs1 from "../AboutUs/AboutUs";
import BottomMenu from "../../components/Footer/BottomMenu";

class EcommerceHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            cart:''
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.WsGetcart()

    }
    componentWillUnmount() {
        localStorage.setItem('cartvalue', JSON.stringify(this.state.cart))
    }
    WsGetcart() {
        APIController.GetCart('8', AppConstants.userIdFromLocal(),
            AppConstants.mobilenoFromLocal(),
            '380051', '0')
            .then((response) => {
                if (response.error == null) {
                    this.setState({ cart: response.object[0].cartcount })
                    // localStorage.setItem('cartvalue', JSON.stringify(response.object[0].cartcount))
                }
                else {

                }
            })
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    renderForm() {
        const { showPassword, loading } = this.state;
        const { classes } = this.props;
        return (
            <Grid>

            </Grid>
        );
    }
    renderTopMenu() {
        const { classes } = this.props
        const { cart, isLoading } = this.state
        return (
            <Grid container justify='center' alignItems='center' style={{
                width: '100%',
                padding: '10px',
                // backgroundColor: 'green',
            }}>
                <Grid container justify='space-evenly' alignItems='center' style={{ width: '80%', }}>
                    {/* <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.TABBAR) }}><Compare className={classes.IconStyle} />MY ORDERES</Button> */}
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.EDITPROFILE) }}><AccountCircleOutlinedIcon className={classes.IconStyle} />MY PROFILE</Button>
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push({ pathname: '/myorder', state: { orderID: '' } }) }}><MyorderIcon className={classes.IconStyle} />MY ORDERS</Button>
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.REFILL) }}><RefillIcon className={classes.IconStyle} />MY REFILLS</Button>
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.PAYMENTS) }}><PaymentIcon className={classes.IconStyle} />MY PAYMENTS</Button>
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.NOTIFICATIONS) }}><NotificationIcon className={classes.IconStyle} />NOTIFICATIONS</Button>
                    <Button className={classes.buttonstyle} onClick={() => { this.props.history.push(AppConstants.SCREENS.WHYGENERIC) }}><WhyGenericIcon className={classes.IconStyle} />WHY GENERIC</Button>
                </Grid>
            </Grid>

        )
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) {}
    render() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                <main className={classes.main}>
                    {mediumBrowser == false && this.renderTopMenu()}
                    <Grid container style={{ width: '100%', marginTop: '20px' }} direction="row" justify="space-evenly" alignItems="center">
                        <Grid className={classes.Contentcardmain} style={mediumBrowser ? { width: '100%' } : { width: '30%' }} container>
                            {/* <Typography>Hello</Typography> */}
                            <img src={Images.EcommerceCard} style={{ width: '100%', height: '200px' }} alt="" />
                        </Grid>
                        <Grid className={classes.Contentcardmain} style={mediumBrowser ? { width: '100%' } : { width: '30%' }} container>
                            {/* <Typography>Hello</Typography> */}
                            <img src={Images.EcommerceCard1} style={{ width: '100%', height: '200px' }} alt="" />
                        </Grid>
                        <Grid className={classes.Contentcardmain} style={mediumBrowser ? { width: '100%' } : { width: '30%' }} container>
                            {/* <Typography>Hello</Typography> */}
                            <img src={Images.EcommerceCard2} style={{ width: '100%', height: '200px' }} alt="" />
                        </Grid>
                    </Grid>
                    <AboutUs1/>
                    {window.innerWidth <= 920 ? <Grid/>:<BottomMenu history={this.props.history} />}
                </main>
                {<Footer history={this.props.history} />}
            </React.Fragment>
        );
    }
}
export default withStyles(EcommerceHomePageStyle)(EcommerceHomePage);
