import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const cartStyle = theme => ({
    MedicineItem: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.textColor,
    },
    Medicinetitle: {
        fontFamily: 'Nunito Sans',
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.textColor,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '60%',
        minWidth: '60%',
        display: 'block'
    },
    SecoundLineContetnt: {
 
        overflowX: 'hidden',

        maxWidth: '80%',
        display: 'flex'
    },
    cartStyle:
    {
        fontSize: '24px', fontWeight: '600', fontFamily: 'Nunito Sans'
    },
    Medicinecount: {
        textAlign: 'start',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '16px',
        // lineHeight: '24px',
        letterSpacing: 0,
        color: theme.palette.textColor,
    },
    txtcouponcode: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '18px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.headerText,
    },
    txtcouponmessagecount: {
        textAlign: 'start',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '16px',
        letterSpacing: 0,
        color: theme.palette.primary.main,
    },
    calculatetxt: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    qntytxt: {
        fontFamily: 'Nunito Sans', fontWeight: 400,
        fontSize: AppConstants.FONTSIZE.FS13,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
    },
    selectiontxt: {
        with: "100%",
        textAlign: 'center',
        fontFamily: 'Nunito Sans', fontWeight: 900,
        fontSize: AppConstants.FONTSIZE.FS20,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
        margin: 10
    },
    Mobileselectiontxt: {
        fontFamily: 'Nunito Sans', fontWeight: 900,
        fontSize: AppConstants.FONTSIZE.FS14,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.textColor,
        margin: 10
    },
    txttotalsaving: {
        fontFamily: 'Nunito Sans', fontWeight: 900,
        fontSize: AppConstants.FONTSIZE.FS15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#fff',
        margin: 10
    },
    oldprice: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: '18px',
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.red,
    },
    newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
    },
    ContentSelectcardmainCart: {
        width: '98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentcardmainCart: {
        marginLeft: '0.8%',
        width: '98%',
        // margin: '10px',
        backgroundColor: '#fff',
        padding: '0px 8px ',
        // borderBottom: '1px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        textALign: 'center',
        color: '#262628',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Nunito Sans',
    },
    disableoldprice: {
        fontFamily: 'Nunito Sans', fontWeight: 600,
        fontSize: '18px',
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: theme.palette.headerText,
    },
});
const combinedStyles = combineStyles(commonStyle, cartStyle);
export default combinedStyles;

