import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';

const TrackOrderDialogStyle = makeStyles(theme => ({
    mainView: {
        width: '100%', zIndex: 300,
        bottom: '63px',
        backgroundColor: '#fff',
        position: 'fixed'
    },
    buttonstyle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: 0,
        color:'#262628',
    },
    titletext: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS30,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.commonTitletxt,
        // marginBottom: '10px'
      },
    OrderIdtext: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS20,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: theme.palette.primary.main,
        marginBottom: '10px'
      },
    IconStyle: {
        color: '#39A749',
        fontSize: AppConstants.FONTSIZE.FS20,
        margin: '10px'
    },
    
}));

export default TrackOrderDialogStyle;
