import React, { Component } from 'react';
import { CssBaseline, Typography, Grid, Tabs, Tab, ListItemText, ListItem, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TabStyle from './TabStyle';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import MyOrder from "../MyOrder/MyOrder";
import Refill from '../Refill/Refill';
import EditProfile from "../EditProfile/EditProfile";
import ContactMedkart from "../ContactMedkart/ContactMedkart";
import MedkartHeader from "../../components/Header/MedkartHeader";
import CallToOrder from "../CallToOrder/CallToOrder";
import Footer from "../../components/Header/Footer";
const CustomTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    color: '#696974',
    backgroundColor: '#ffff',
    width: 'max-content'
  },
  indicator: {
    backgroundColor: '#0062ff'
  }
})(Tabs);

const TabItem = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 'bold',
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#0062ff',
      opacity: 1
    },
    '&$selected': {
      color: '#0062ff',
      fontWeight: 'bold'
    },
    '&:focus': {
      color: '#0062ff'
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

class TabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      id: '',
      open: false,
      mediumBrowser: false,

    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

  }
  resize() {
    this.setState({ mediumBrowser: window.innerWidth <= 920 })
  }

  renderTabs() {
    const { classes } = this.props;
    const { value, cardDatas } = this.state;
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <Typography
          // component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {children}
        </Typography>
      );
    }
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
      };
    }
    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    return (
      <Grid container style={{ width: '100%' }} direction='row'>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example" >
          <TabItem label="MY PROFILE" {...a11yProps(0)} />
          <TabItem label="MY ORDER" {...a11yProps(1)} />
          <TabItem label="MY RIFFILS" {...a11yProps(2)} />
          <TabItem label="CONTACT MEDKART" {...a11yProps(3)} />
        </CustomTabs>
        <TabPanel value={value} index={0} className={classes.tabDataContainer}>
          <EditProfile history={this.props.history} />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabDataContainer}>
          <MyOrder history={this.props.history} />
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabDataContainer}>
          <Refill />
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabDataContainer}>
          <EditProfile history={this.props.history} />
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.tabDataContainer}>
          <ContactMedkart />
        </TabPanel>
      </Grid>
    );
  }
  handleStep(step) {
    this.setState({ open: step })
  }
  dataFromsearch(data) {
    this.setState({cart:data.data.length})
}
medicineDetailFromsearch(data) {}
  render() {
    const { classes } = this.props;
    return (
      <Grid style={{ width: '100%' }} container>
        <CssBaseline />
        <ToastsContainer store={ToastsStore} />
        <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart}  dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
        <main className={classes.main}>
          {this.renderTabs()}
        </main>
        <Footer history={this.props.history} />
      </Grid>
    );
  }
}
export default withStyles(TabStyle)(TabBar);