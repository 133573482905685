import { makeStyles } from '@material-ui/core/styles';
import * as AppConstants from '../../common/Constants';
const PayAgreeDialogStyle = makeStyles(theme => ({
    mainView: {
        width: '100%', zIndex: 300,
        bottom: '63px',
        backgroundColor: '#fff',
        position: 'fixed'
    },
    buttonstyle: {
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: AppConstants.FONTSIZE.FS16,
        lineHeight: 1.13,
        letterSpacing: 0,
        color: '#262628',
    },
    IconStyle: {
        color: '#39A749',
        fontSize: AppConstants.FONTSIZE.FS20,
        margin: '10px'
    },
    txtModalDescription: {
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        fontSize: 17,
        lineHeight: 1.13,
        marginBottom: '15px',
        letterSpacing: 0,
        color: '#262628',
    }

}));

export default PayAgreeDialogStyle;
