import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const PaymentsStyle = theme => ({

    newprice: {
        fontFamily: 'Nunito Sans', fontWeight: 800,
        fontSize: 15,
        lineHeight: 1.13,
        letterSpacing: -0.5,
        color: '#39A749',
    },
    Medicinetitle: {
        fontFamily: 'Nunito Sans',
        // fontWeight: 700,
        fontSize: '13px',
        lineHeight: '34px',
        letterSpacing: 0,
        color: theme.palette.textColor,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '80%',
        display: 'block'
    },
    ContentSelectcardmainPayment: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        padding: '8px 8px',
        border: '2px solid ',
        borderColor: theme.palette.primary.main,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentcardmainCart: {
        width: window.innerWidth <= 420 ? '98%' : '98%',
        // margin: '10px',
        marginTop: '6px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    ContentcardmainPayment: {
        width: window.innerWidth <= 420 ? '94%' : '98%',
        margin: '10px',
        backgroundColor: '#fff',
        padding: '8px 8px ',
        border: '2px solid ',
        borderColor: theme.palette.headerText,
        borderRadius: '0px 0px 0px 0px',
    },
    placeView: {
        width: '100px',
        height: '25px',
        border: '1px solid #39A749',
        // backgroundColor: '#34baeb',
        backgroundColor: '#39A749',
        borderRadius: '5px 5px 5px 5px',
    },

});
const combinedStyles = combineStyles(commonStyle, PaymentsStyle);
export default combinedStyles; 
