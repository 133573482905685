import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import jap from './languages/jap';
import en from './languages/en.js';
i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translation: en
    },
    jap: {
      translation: jap
    },
    fallbackLng: 'en',
    debug: true,
    nsSeparator: false,
    keySeparator: false,
    // do not load a fallback
    // fallbackLng: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  },
  lng: 'en'
});

export default i18n;
