import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import privacyPolicyStyle from './PrivacyPolicyStyle';
import {
    CssBaseline, Grid, Button,
} from '@material-ui/core';
import Iframe from 'react-iframe';
import Footer from '../../components/Header/Footer';
import MedkartHeader from "../../components/Header/MedkartHeader";
import Title from "../../components/Header/Title";
import CompareIcon from '@material-ui/icons/AutorenewOutlined';
import Buy from '@material-ui/icons/ShoppingCartOutlined';
import StoreLocator from '@material-ui/icons/LocationOnOutlined';
import WhyMedkart from '@material-ui/icons/InfoOutlined';
import JoinUsIcon from '@material-ui/icons/Cached';
import Testimonials from '@material-ui/icons/PersonOutline';
import Partnerwithus from '@material-ui/icons/GroupOutlined';
import * as AppConstants from '../../common/Constants';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            loading: true,
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    medicineDetailFromsearch(data) { }
    render() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Medkart privacy policy for data usage</title>
                    <meta name="description" content="Medkart privacy policy for data usage" />
                    <meta name="keywords" content="Medkart Privacy Policy" />
                </Helmet>
                <CssBaseline />
                <Grid style={{ width: '100%' }}>
                    <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />
                    <main className={classes.main}>
                        <Title title={'Privacy Policy'} isBack={false} history={this.props.history} />
                        <Grid container style={{ width: '100%', }} direction="row" justify="space-evenly" alignItems="center">
                            <Grid style={mediumBrowser ? { width: '100%' } : { width: '98.5%' }} container frameBorder='0'>
                                <Grid style={{ marginRight: 3, marginLeft: 3, marginTop: '13px', fontFamily: 'Nunito Sans' }}>
                                    Please read this privacy policy carefully by accessing or using the website, you agree to be bound by the terms described herein and all the terms incorporated by reference. If you do not agree to all of these terms, do not use the website.
                                    <h4>1. Content & Purpose</h4>
                                    <ul>
                                        <li>This privacy policy (“Privacy Policy”) applies to your use of the domain name www.medkart.in, an internet based portal, and Medkart Pharmacy, a mobile application, owned and operated by Medkart Pharmacy Private Limited, a company duly incorporated under the provisions of the Companies Act, 2013 (hereinafter, referred to as “Medkart Pharmacy” or “Medkart” or “We” or “Our” or “Us” or “Company”). The domain name and the mobile application are collectively referred to as “Website”.</li>
                                        <li>The Website is a platform that facilitates the purchase of pharmaceutical products for sale by various relevant pharmacies (hereinafter referred to as “Services”, with the relevant pharmacies referred to as “Sellers”). The arrangement between the Sellers and Medkart Pharmacy shall be governed in accordance with this Privacy Policy and the Terms of Use. The Services would be made available to such natural persons who have agreed to become buyers on the Website after obtaining due registration, in accordance with the procedure as determined by Medkart Pharmacy, from time to time (referred to as “You” or “Your” or “Yourself” or “User”, which terms shall also include natural persons who are accessing the Website merely as visitors). The Services are offered to the Users through various modes which shall include issue of discount coupons and vouchers that can be redeemed for various goods/ services offered for sale by the Sellers.</li>
                                        <li>We have implemented reasonable security practices and procedures that are commensurate with the information assets being protected and with the nature of our business. While we try our best to provide security that is commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot ensure or warrant complete security of all information that is being transmitted to us by you.</li>
                                        <li>
                                            For the purpose of providing the Services and for other purposes identified in this Privacy Policy, Medkart Pharmacy will be required to collect and host certain data and information of the Users. Medkart Pharmacy is committed to protecting the Personal Information of the Users and takes all reasonable precautions for maintaining confidentiality of the User’s Personal Information. This Privacy Policy has been designed and developed to help you understand the following:
                                            <ul>
                                                <li>The type of Personal Information (including Sensitive Personal Data or Information) that Medkart Pharmacy collects from the Users;</li>
                                                <li>The purpose of collection, means and modes of usage of such Personal Information by Medkart Pharmacy;</li>
                                                <li>How and to whom Medkart Pharmacy will disclose such information;</li>
                                                <li>How Medkart Pharmacy will protect the Personal Information including Sensitive Personal Data or Information that is collected from the Users; and</li>
                                                <li>How Users may access and/ or modify their Personal Information.</li>
                                            </ul>
                                        </li>
                                        <li>This Privacy Policy shall apply to the use of the Website by all Users / Sellers. Accordingly, a condition of each User's use of and access to the Website and to the other services provided by Medkart Pharmacy to Users is their acceptance of this Privacy policy. Any User is required to read and understand the provisions set out herein prior to submitting any Sensitive Personal Data or Information to Medkart Pharmacy, failing which they are required to leave the Website immediately.</li>
                                        <li>This Privacy Policy is published in compliance of the (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</li>
                                    </ul>
                                    <h4>2. What is the personal information?</h4>
                                    <ul>
                                        <li>"Personal Information" means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available with Medkart Pharmacy, is capable of identifying the person concerned.</li>
                                        <li>"Sensitive Personal Data or Information" means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by Medkart Pharmacy for processing or storage. However, any data/ information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law shall not qualify as Sensitive Personal Data or Information.</li>
                                    </ul>
                                    <h4>3. Types of personal information collected by Medkart Pharmacy:</h4>
                                    <ul>
                                        <li>
                                            A User may have limited access to the Website and utilize some of the Services provided by Medkart Pharmacy without creating an account on the Website. Unregistered Users can access some of the information and details available on the Website. In order to have access to all the features and benefits on our Website, a User may be required to first create an account on our Website. As part of the registration process, Medkart Pharmacy may collect the following categories of Personal Information from the Users (hereinafter collectively referred to as “User Information”):
                                            <ul>
                                                <li>Name;</li>
                                                <li>User ID;</li>
                                                <li>Email address;</li>
                                                <li>Address (including country and ZIP/postal code);</li>
                                                <li>Gender;</li>
                                                <li>Age;</li>
                                                <li>Phone Number;</li>
                                                <li>Password chosen by the User;</li>
                                                <li>Valid financial account information; and</li>
                                                <li>Other details as the User may volunteer.</li>
                                            </ul>
                                        </li>
                                        <li>In order to avail the Services, the Users may be required to upload copies of their prescriptions, on the Website and/ or e-mail the same to Medkart Pharmacy in accordance with the Terms of Use and the prescriptions will be stored/ disclosed by Medkart Pharmacy only in the manner specified in this Privacy Policy and the Terms of Use. The term “User Information” shall also include any such prescriptions uploaded or otherwise provided by Users.</li>
                                        <li>Medkart Pharmacy may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.</li>
                                        <li>Internet use: Medkart Pharmacy may also receive and/or hold information about the User’s browsing history including the URL of the site that the User visited prior to visiting the website as well as the Internet Protocol (IP) address of each User's computer/Mobile (or the proxy server a User used to access the World Wide Web), User's computer operating system and type of web browser the User is using as well as the name of User's ISP. The Website uses temporary cookies to store certain data (that is not Sensitive Personal Data or Information) that is used by Medkart Pharmacy and its service providers for the technical administration of the Website, research and development, and for User administration.</li>
                                        <li>The Website/App may enable User to communicate with other Users or to post information to be accessed by others, whereupon other Users may collect such data. Medkart Pharmacy hereby expressly disclaims any liability for any misuse of such information that is made available by visitors in such a manner.</li>
                                        <li>Medkart Pharmacy does not knowingly collect Personal Information from children.</li>
                                        <li>Medkart Pharmacy may in future include other optional requests for information from the User including through user surveys in order to help Medkart Pharmacy customize the Website to deliver personalized information to the User and for other purposes are mentioned herein. Such information may also be collected in the course of contests conducted by Medkart Pharmacy. Any such additional Personal Information will also be processed in accordance with this Privacy Policy.</li>
                                    </ul>
                                    <h4>4. Purposes for which your information may be used by Medkart Pharmacy:</h4>
                                    <ul>
                                        <li>
                                            Medkart Pharmacy will retain User Information only to the extent it is necessary to provide Services to the Users. The information which Medkart Pharmacy collects from you may be utilized for various business and/or regulatory purposes including for the following purposes:
                                            <ul>
                                                <li>Registration of the User on the Website/App;</li>
                                                <li>Processing the User’s orders / requests and provision of Services (including provision of safe Services);</li>
                                                <li>Completing transactions with Users effectively and billing for the products/ Services provided;</li>
                                                <li>Technical administration and customization of Website/App;</li>
                                                <li>Ensuring that the Website/App content is presented to the Users in an effective manner;</li>
                                                <li>Delivery of personalized information and target advertisements to the User;</li>
                                                <li>Improvement of Services, features and functionality of the Website/App;</li>
                                                <li>Research and development and for User administration (including conducting user surveys);</li>
                                                <li>Non-personally identifiable information, exclusively owned by Medkart Pharmacy may be used in an aggregated or non-personally identifiable form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details, and Medkart Pharmacy may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;</li>
                                                <li>Dealing with requests, enquiries, complaints or disputes and other customer care related activities including those arising out of the Users’ request of the Services and all other general administrative and business purposes;</li>
                                                <li>In case of any contests conducted by Medkart Pharmacy in which the User participates, the User Information may be used for prize fulfillment and other aspects of any contest or similar offering;</li>
                                                <li>Communicate any changes in our Services or this Privacy Policy or the Terms of Use to the Users;</li>
                                                <li>Verification of identity of Users and perform checks to prevent frauds; and</li>
                                                <li>Investigating, enforcing, resolving disputes and applying our Terms of Use and Privacy Policy, either ourselves or through third party service providers.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h4>5.	Disclosure and transfer of your personal information:</h4>
                                    <ul>
                                        <li>
                                            Medkart Pharmacy may need to disclose/ transfer User’s Personal Information to the following third parties for the purposes mentioned in this Privacy Policy and the Terms of Use:
                                            <ul>
                                                <li>i.	To Sellers and other service providers appointed by Medkart Pharmacy for the purpose of carrying out services on Medkart Pharmacy’s behalf under contract. Generally these contractors do not have any independent right to share this information, however certain contractors who provide services on the Website, including the providers of online communications services, will have rights to use and disclose the Personal Information collected in connection with the provision of these services in accordance with their own privacy policies.
                                                    <li>To our affiliates in India or in other countries who may use and disclose your information for the same purposes as us.</li>

                                                    To government institutions/ authorities to the extent required a) under the laws, rules, and regulations and/ or under orders of any relevant judicial or quasi-judicial authority; b) to protect and defend the rights or property of Medkart Pharmacy; c) to fight fraud and credit risk; d) to enforce Medkart Pharmacy 's Terms of Use (to which this Privacy Policy is also a part) ; or e) when Medkart Pharmacy, in its sole discretion, deems it necessary in order to protect its rights or the rights of others.
                                                </li>
                                                <li>If otherwise required by an order under any law for the time being in force including in response to enquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences.</li>
                                                <li>In case of contests conducted by Medkart Pharmacy in which the User participates, the concerned User’s information may be disclosed to third parties, also be disclosed to third parties to the extent necessary for prize fulfillment and other aspects of such contest or similar offering.</li>
                                            </ul>
                                        </li>
                                        <li>Medkart Pharmacy makes all User Information accessible to its employees and data processors only on a need-to-know basis. All Medkart Pharmacy employees and data processors, who have access to, and are associated with the processing of User Information, are obliged to respect its confidentiality.</li>
                                        <li>Non-personally identifiable information may be disclosed to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the Users. Medkart Pharmacy may also share its aggregate findings (not specific information) based on information relating to the User’s internet use to prospective, investors, strategic partners, sponsors and others in order to help growth of Medkart Pharmacy 's business.</li>
                                        <li>Medkart Pharmacy may also disclose or transfer the User Information, to another third party as part of reorganization or a sale of the assets or business of a Medkart Pharmacy corporation division or company. Any third party to which Medkart Pharmacy transfers or sells its assets will have the right to continue to use the Personal Information and/ or other information that a User provide to us.</li>
                                    </ul>
                                    <h4>6. Retention of the information:</h4>
                                    <ul>
                                        <li>All the information collected/ stored under this Privacy Policy and Terms of Use is maintained by Medkart Pharmacy in electronic form on its equipment, and on the equipment of its employees. User Information may also be converted to physical form from time to time. Regardless of the manner of storage, Medkart Pharmacy will keep all User Information confidential and will use/ disclose it only the manner specified under the Privacy Policy and Terms of Use.</li>
                                        <li>Part of the functionality of the Website is assisting the Sellers to maintain and organize such information to effect sale and purchase of products. Medkart Pharmacy may, therefore, retain and submit all such records to the appropriate authorities, or to Sellers who request access to such information.</li>
                                        <li>The Website is also designed for assisting the Users to access information relating to pharmaceutical products. Medkart Pharmacy may, therefore, retain and submit all such records to the relevant Users.</li>
                                        <li>Medkart Pharmacy will also ensure that User Information is not kept for a period longer than is required for the purposes for which it is collected or as required under any applicable law.</li>
                                    </ul>
                                    <h4>7. Links to third-party advertisements:</h4>
                                    <ul>
                                        <li>The links to third-party advertisements, third party websites or any third party electronic communication services (referred to as “Third Party Links”) may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with Medkart Pharmacy unless expressly specified on the Website.</li>
                                        <li>If you access any such Third Party Links, we request you review the website’s privacy policy. We are not responsible for the policies or practices of Third Party Links.</li>
                                    </ul>
                                    <h4>8. Security practices and procedures:</h4>
                                    <ul>
                                        <li>Medkart Pharmacy adopts reasonable security practices and procedures to include, technical, operational, managerial and physical security control measures in order to protect the Personal Information in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction.</li>
                                        <li>Medkart Pharmacy takes adequate steps to ensure that third parties to whom the Users’ Sensitive Personal Data or Information may be transferred adopt reasonable level of security practices and procedures to ensure security of Personal Information.</li>
                                        <li>You hereby acknowledge that Medkart Pharmacy is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.</li>
                                    </ul>
                                    <h4>9. User's rights in relation to their personal information collected by Medkart Pharmacy:</h4>
                                    <ul>
                                        <li>All the information provided to Medkart Pharmacy by a User, including Sensitive Personal Data or Information, is voluntary. User has the right to withdraw his/ her/ its consent at any time, in accordance with the terms of this Privacy Policy and the Terms of Use, but please note that withdrawal of consent will not be retroactive.</li>
                                        <li>
                                            Users can access, modify, correct and delete the Personal Information provided by them which has been voluntarily given by the User and collected by Medkart Pharmacy in accordance with this Privacy Policy and Terms of Use. However, if the User updates his/ her information, Medkart Pharmacy may keep a copy of the information which User originally provided to Medkart Pharmacy in its archives for User documented herein. In case the User seeks to update or correct, his/ her Personal Information, the User may exercise these rights by emailing Medkart Pharmacy at
                                            info@medkart.in
                                            and communicate the change(s) for updating Medkart Pharmacy’s records.
                                        </li>
                                        <li>If a User, as a casual visitor, has inadvertently browsed any other pages of this Website prior to reading the Privacy Policy and the Terms of Use, and such User does not agree with the manner in which such information is obtained, stored or used, merely quitting this browser application should ordinarily clear all temporary cookies installed by Medkart Pharmacy. All visitors, however, are encouraged to use the "clear cookies" functionality of their browsers to ensure such clearing/ deletion, as Medkart Pharmacy cannot guarantee, predict or provide for the behavior of the equipment of all the visitors of the Website.</li>
                                        <li>
                                            If a User has inadvertently submitted any Personal Information to Medkart Pharmacy prior to reading the Privacy Policy and Terms of Use, and such User does not agree with the manner in which such information is collected, stored or used, then such User can ask Medkart Pharmacy, by sending an email to
                                            info@medkart.in
                                            containing the rectification required, whether Medkart Pharmacy is keeping Personal Information about such User, and every User is also entitled to require Medkart Pharmacy to delete and destroy all such information relating to such user (but not other Users) in its possession.
                                        </li>
                                        <li>In case the User does not provide his/ her information or consent for usage of Personal Information or subsequently withdraws his/ her consent for usage of the Personal Information so collected, Medkart Pharmacy reserves the right to discontinue the services for which the said information was sought.</li>
                                    </ul>
                                    <h4>10.	Additional notes to the user:</h4>
                                    <ul>
                                        <li>Medkart Pharmacy does not exercise control over the sites displayed as search results or links from within its Services. These other sites may place their own cookies or other files on the Users' computer, collect data or solicit Personal Information from the Users, for which Medkart Pharmacy is not responsible or liable. Accordingly, Medkart Pharmacy does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does Medkart Pharmacy guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. Medkart Pharmacy encourages the User to read the privacy policies of that website.</li>
                                        <li>Medkart Pharmacy shall not be responsible in any manner for the authenticity of the Personal Information or Sensitive Personal Data or Information supplied by the User to Medkart Pharmacy or any Seller. If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Medkart Pharmacy has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Medkart Pharmacy has the right to suspend or terminate such account at its sole discretion.</li>
                                        <li>Medkart Pharmacy shall not be responsible for any breach of security or for any actions of any third parties that receive Users' Personal Information or events that are beyond the reasonable control of Medkart Pharmacy including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc.</li>
                                        <li>The User is responsible for maintaining the confidentiality of the User's account access information and password. The User shall be responsible for all uses of the User's account and password, whether or not authorized by the User. The User shall immediately notify Medkart Pharmacy of any actual or suspected unauthorized use of the User's account or password.</li>
                                        <li>
                                            Medkart Pharmacy will communicate with the Users through email and notices posted on the Website or through other means available through the Service, including text and other forms of messaging. The Users can ask Medkart Pharmacy, by sending an email to
                                            care@medkart.in
                                            Pharmacy.com containing the rectification required.
                                        </li>
                                    </ul>
                                    <h4>11. Changes in the privacy policy:</h4>
                                    <ul>
                                        <li>Medkart Pharmacy may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way Medkart Pharmacy treats User's Personal Information, Medkart Pharmacy will display a notice on the Website or send Users an email. If a User uses the Service after notice of changes have been sent to such User or published on the Website, such User hereby provides his/ her/ its consent to the changed practices.</li>
                                    </ul>
                                    <h4>12. Complaints and grievance redressal:</h4>
                                    <ul>
                                        <li>
                                            Medkart Pharmacy addresses discrepancies and grievances of all Users with respect to processing of information in a time bound manner. For this purpose, Medkart Pharmacy has designated Mr. Parthiv Shah as the grievance officer, who will redress the grievances of the Users expeditiously but within one month from the date of receipt of grievance, and who can be reached by:
                                            <ul>
                                                <li>Sending a letter marked to the attention of “Grievance Officer, GF1, Sangini Complex, near doctor house, above parimal underpass, near parimal crossroads, ambawadi, Ahmedabad 380006”, or</li>
                                                <li>
                                                    Sending an email to
                                                    info@medkart.in
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h4>Contact us</h4>

                                    You may contact us with any questions relating to this Privacy Policy by submitting your request to
                                    info@medkart.in
                                    or by postal mail at:
                                    Registered Address:
                                    Medkart Pharmacy, GF1, Sangini Complex, near doctor house, above parimal underpass, near parimal crossroads, ambawadi, Ahmedabad 380006.</Grid>
                            </Grid>
                        </Grid>
                        {/* {window.innerWidth <= 920 ? <Grid/>:<BottomMenu history={this.props.history} />} */}
                    </main>
                    {this.props.fromHomepage == true ? '' : <Footer history={this.props.history} />}
                </Grid>
            </React.Fragment>
        );
    }
}
export default withStyles(privacyPolicyStyle)(PrivacyPolicy);
