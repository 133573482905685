import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
import * as AppConstants from '../../common/Constants';
const VerifyOTPStyle = theme => ({

    mainContainer: {
        // marginTop: '100px',
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)',
    },
    txtVerifyOTP: {
        marginTop: '5%',
        marginBottom: '5%',
        fontFamily: 'Nunito Sans', fontWeight: 700,
        fontSize: '22px',
        marginLeft: window.innerWidth<=920?'':'7%',
    },
    txtEnterOTP: {
        // marginTop: '5%',
        marginBottom:'5%',
        color: '#262628',
        fontSize: AppConstants.FONTSIZE.FS16,
        fontFamily:'Nunito Sans',
        // lineHeight: '26px',
        // letterSpacing: 0,

    },
    img: {
        width: '100%',
        hight: 450,
    },

});
const combinedStyles = combineStyles(commonStyle, VerifyOTPStyle);
export default combinedStyles; 
