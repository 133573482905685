import React from 'react';
import { TextField } from '@material-ui/core';

import filledTextFieldStyle from './FilledTextFieldUpdatedStyle';

const FilledTextField_Updated = props => {
    const classes = filledTextFieldStyle();
    return (
        <TextField
            className={classes.textField}
            variant="filled"
            fullWidth
            InputProps={{
                className: classes.textField,
                disableUnderline: true
            }}
            InputLabelProps={{
                className: classes.label,                
            }}
            {...props} >
            {props.children}
        </TextField>
    );
};

export default FilledTextField_Updated;
