import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import StoresStyle from './StoresStyle';
import { CssBaseline, Typography, Grid, Divider, CircularProgress, Button } from '@material-ui/core';
import MedkartHeader from "../../components/Header/MedkartHeader";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MedkartFooter from "../../components/Header/Footer";
import { Scrollbars } from 'react-custom-scrollbars';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import FilledTextField from "../../components/FilledTextField/FilledTextField";
import Title from "../../components/Header/Title";
import * as APIController from '../../api/APIController';
import BottomMenu from "../../components/Footer/BottomMenu";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

class VadodaraStores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mediumBrowser: false,
            dataSource: [],
            isLoading: true,
            text: '',
            latitude: localStorage.getItem('lat') != null ? localStorage.getItem('lat') : '',
            longitude: localStorage.getItem('long') != null ? localStorage.getItem('long') : '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            address1: '',
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.wsGetStoreList()
    }

    wsGetStoreList() {
        var lat = localStorage.getItem('lat') != null ? localStorage.getItem('lat') : '23.026207';
        var long = localStorage.getItem('long') != null ? localStorage.getItem('long') : '72.5472083';
        this.setState({ isLoading: true })
        APIController.GetStoreList(99999, 9999999999, '', '', 'Vadodara').then((response) => {
            if (response.error == null) {
                this.arrayholder = response.object;
                this.setState({ isLoading: false, dataSource: response.object, })
            }
            else if (response.error.message && response.error.message.length > 0) {
                this.setState({ isLoading: false })
            }
        })
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleStep(step) {
        this.setState({ open: step })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.SearchFilterFunction(e.target.value)
    }

    SearchFilterFunction(text) {
        const { dataSource, } = this.state;
        if (text != '') {
            const newData1 = this.arrayholder.filter(function (item) {
                return item.name.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.address1.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.address2.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.address3.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.area.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.city.toLowerCase().indexOf(text.toLowerCase()) != -1 ||
                    item.postcode.toLowerCase().indexOf(text.toLowerCase()) != -1
            });
            this.setState({ dataSource: newData1 });
        }
        else {
            this.setState({ dataSource: this.arrayholder });
        }
    }

    displayMarkers = () => {
        return this.state.dataSource.map((item, index) => {
            return <Marker
                // style={{ backgroundColor: 'green' }}
                icon={{ url: "https://medkart.in/assets/image/icons/map-marker.png" }}
                key={index}
                id={index}
                position={{
                    lat: item.latitude,
                    lng: item.longitude
                }}
                onClick={this.onMarkerClick}
            >
            </Marker>

        })
    }
    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    }

    displayInfoWindow = () => {
        const { dataSource, } = this.state;
        return <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <h2>{this.state.dataSource[this.state.selectedPlace.id] == undefined ? "" : this.state.dataSource[this.state.selectedPlace.id].name}</h2>
            <div >{this.state.dataSource[this.state.selectedPlace.id] == undefined ? "" : this.state.dataSource[this.state.selectedPlace.id].address1
                + ", " + this.state.dataSource[this.state.selectedPlace.id].address2},</div>
            <div> {this.state.dataSource[this.state.selectedPlace.id] == undefined ? "" : this.state.dataSource[this.state.selectedPlace.id].address3
                + ", " + this.state.dataSource[this.state.selectedPlace.id].area},</div>
            <div> {this.state.dataSource[this.state.selectedPlace.id] == undefined ? "" : this.state.dataSource[this.state.selectedPlace.id].city}.</div>
            <div> {this.state.dataSource[this.state.selectedPlace.id] == undefined ? "" :
                <a style={{ marginTop: '8px' }} href={"tel:+91 " + this.state.dataSource[this.state.selectedPlace.id].mobileno} target="_blank">
                    {this.state.dataSource[this.state.selectedPlace.id].mobileno} </a>
            }</div>
        </InfoWindow>
    }

    renderSearchtitle() {
        const { dataSource, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={mediumBrowser ? { width: '100%', marginBottom: '10px' } : { width: '100%', marginLeft: '10px', marginBottom: '10px' }} direction={'row'} >
                {/* <Typography style={mediumBrowser ? { fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#9B9B9B', marginTop: '2%' } : { fontWeight: '400', fontSize: '14px', fontFamily: 'Nunito Sans', color: '#9B9B9B' }}>{dataSource.length} Stores available</Typography> */}
                <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                    <Grid container style={{ width: '100%' }} direction={'row'} >
                        {/* <FilledTextField
                            label={'Search Stores'}
                            name={'text'}
                            type={'search'}
                            onChange={this.handleChange}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderStoreList() {
        const { dataSource, mediumBrowser, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={mediumBrowser ? { width: '100%' } : { width: '30%', paddingBottom: '60px' }} direction={'row'} justify={'flex-start'} alignItems={'flex-start'}>
                {this.renderSearchtitle()}
                {mediumBrowser ? <Scrollbars
                    autoHeight
                    // style={{ height: '100%', width: '100%' }}
                    autoHeightMax={window.innerWidth<=920 ? '100%' : '0%'}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {isLoading ? this.LoadingView() :
                        <Grid container>
                            {dataSource.map((item, index) => {
                                return (
                                    <Grid container onClick={() => { window.open('https://www.google.com/maps?saddr=' + this.state.latitude + (this.state.longitude != '' ? ',' + this.state.longitude : '') + '&daddr=' + item.latitude + ',' + item.longitude, '_blank') }} style={{ width: '98%', cursor: 'pointer', marginLeft: mediumBrowser ? '0px' : '10px', marginBottom: '10px', marginRight: '10px' }} justify={'flex-start'} alignItems={'flex-start'} direction='row'>
                                        <Grid container>
                                            {/* <ArrowDropDownIcon /> */}
                                            <LocationOnIcon style={{ color: '#39A749' }} />
                                            <Typography className={classes.shopNameText}>{item.name}</Typography>
                                        </Grid>
                                        <Typography className={classes.shopAddressText}>{item.address1 + ', ' + item.address2 + ', ' + item.address3 + ', ' + item.area + ', ' + item.city + ' - ' + item.postcode}</Typography>
                                        <a style={{ marginTop: '6px' }} className={classes.shopPhoneNumberText} href={"tel:+91 " + item.mobileno} target="_blank">
                                            {item.mobileno}</a>
                                        <Divider variant="fullWidth" light={true} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                </Scrollbars> : <Scrollbars
                    // autoHeight
                    style={{ height: '100%', width: '100%' }}
                    autoHeightMax={'100%'}
                    autoHide hideTracksWhenNotNeeded={true} >
                        {isLoading ? this.LoadingView() :
                            <Grid container>
                                {dataSource.map((item, index) => {
                                    return (
                                        <Grid container onClick={() => { window.open('https://www.google.com/maps?saddr=' + this.state.latitude + (this.state.longitude != '' ? ',' + this.state.longitude : '') + '&daddr=' + item.latitude + ',' + item.longitude, '_blank') }} style={{ width: '98%', marginLeft: '10px', marginBottom: '10px', cursor: 'pointer' }} justify={'flex-start'} alignItems={'flex-start'} direction='row'>
                                            <Grid container>
                                                {/* <ArrowDropDownIcon /> */}
                                                <LocationOnIcon style={{ color: '#39A749' }} />
                                                <Typography className={classes.shopNameText}>{item.name}</Typography>
                                            </Grid>
                                            <Typography className={classes.shopAddressText}>{item.address1 + ', ' + item.address2 + ', ' + item.address3 + ', ' + item.area + ', ' + item.city + ' - ' + item.postcode}</Typography>
                                            <a style={{ marginTop: '8px' }} className={classes.shopPhoneNumberText} href={"tel:+91 " + item.mobileno} target="_blank">
                                                {item.mobileno}</a>
                                            <Divider variant="fullWidth" light={true} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                    </Scrollbars>}
            </Grid>
        )
    }

    renderMapView() {
        const { dataSource, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={mediumBrowser ? { width: '100%', height: 500, position: 'relative', marginTop: '5%' } : { width: '68.5%', height: window.innerHeight - (0.35 * window.innerHeight), position: 'relative' }} direction={'row'}>
                <Map
                    google={this.props.google}
                    zoom={12}
                    initialCenter={{ lat: 23.026207, lng: 72.5472083 }}
                    style={{ width: mediumBrowser ? '100%' : '99%' }}
                >
                    {this.displayMarkers()}
                    {this.displayInfoWindow()}

                </Map>
            </Grid>
        )
    }

    renderPagetitle() {
        const { mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <Grid container style={{ width: '100%' }} alignItems="center" justify={mediumBrowser ? '' : 'flex-start'} direction={'row'}>
                <Typography className={classes.Screentitle} >{'FIND STORES '}</Typography>&nbsp;&nbsp;
                <Typography className={classes.titletextGreen} >{'NEAR YOU'}</Typography>
            </Grid>
        )
    }
    dataFromsearch(data) {
        this.setState({ cart: data.data.length })
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', height: '400px' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )

    }
    medicineDetailFromsearch(data) { }
    render() {
        const { classes } = this.props;
        const { dataSource, isLoading } = this.state;
        return (
            <Grid style={{ width: '100%', overflow: 'hidden' }}>
                <Helmet>
                    <title>Check Medkart store near me in Ahmedabad</title>
                    <meta name="description" content="Know where are medkart store near you in Ahmedabad and elsewhere in India. Reach out to nearest medkart store for generic medicines" />
                    <meta name="keywords" content="Medkart Near Me | Medkart Ahmedabad | Medkart Store" />
                </Helmet>
                <CssBaseline />
                {this.props.fromHomepage == true ? '' : <MedkartHeader medicineDetailFromsearch={this.medicineDetailFromsearch.bind(this)} CartCount={this.state.cart} dataFromsearch={this.dataFromsearch.bind(this)} browser={this.state.mediumBrowser} handleStep={this.handleStep.bind(this)} {...this.props} history={this.props.history} />}
                <main className={classes.main} >
                    {this.props.fromHomepage ? this.renderPagetitle() : <Title isBack={false} title={'Stores in Vadodara'} history={this.props.history} />}
                    <Grid container justify='space-between' style={{ width: '100%' }} direction={'row'}>
                        {this.renderStoreList()}
                        {window.innerWidth<=920 ? <Grid/> : this.renderMapView()}
                    </Grid>
                    {window.innerWidth <= 920 ? <Grid /> : <BottomMenu history={this.props.history} />}
                </main>
                {this.props.fromHomepage == true ? '' : <MedkartFooter {...this.props} />}
            </Grid>
        );
    }
}
const LoadingContainer = (props) => <Grid container style={{ width: '100%', height: '450px' }} direction={'row'} justify={'center'} alignItems='center'>
    <CircularProgress />
</Grid>;
export default GoogleApiWrapper({ apiKey: 'AIzaSyBaIJVsQtgwtAAhjbvLtgKmOyHzKvzmOmE', LoadingContainer: LoadingContainer })(withStyles(StoresStyle)(withTranslation('translation')(VadodaraStores)));
