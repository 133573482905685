import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const TabStyle = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  content: {
    flexGrow: 1,
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  toolbar: theme.mixins.toolbar,
  tabDataContainer: {
    marginTop:-100,//temparory solution
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 2.25,
    color: theme.textColor,
    letterSpacing: 0.1,
    margin: '10px 3px'
  },
  tabsContentContainer: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#ffff',
    padding: '20px'
  },
  tabBarContainList: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: '#ffff',
    padding: ' 20px',
    margin: '20px',
    borderWidth: 1,
    borderColor: 'black'
  },
  listItemText: {
    flexDirection: 'column'
  },
  fullName: {
    fontSize: 16,
    lineHeight: 1.8,
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontWeight: '900'
  },
  company: {
    fontSize: 16,
    lineHeight: 1.8,
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
  },
  checkinButton: {
    borderRadius: 50
  },
  cards: {
    backgroundColor: '#F8F8FA',
    padding: '20px 15px',
    margin: '0px 20px 20px 0px'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  imgStyle: {
    height: 100,
    width: 100
  },
  qrCode: {
    fontFamily: 'Nunito Sans',
    fontWeight: 500,
    fontSize: 16,
    margin: '10px 0px 30px 0px',
    fontWeight: '500',
    color: '#000000'
  },
  buttonContainer: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: 16,
    margin: '10px 20px 0px 20px',
    color: '#000000'
  },
});
const combinedStyles = combineStyles(commonStyle, TabStyle);
export default combinedStyles;
